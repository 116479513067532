import React, { createContext, useContext, useReducer } from "react"
import { DraftsProvider } from "./drafts"
import { CommentsState } from "../types/reduxState/comments"
import { CommentsAction } from "../types/reduxActions/comments"

export const commentsReducer = (
  state: CommentsState,
  action: CommentsAction
) => {
  switch (action.type) {
    case "FETCH_COMMENTS": {
      const { sourceId, comments } = action.payload
      return {
        ...state,
        commentsBySourceId: {
          ...state.commentsBySourceId,
          [sourceId]: comments,
        },
      }
    }
    case "ADD_NEW_REPLY": {
      const { sourceId, reply, parentCommentId } = action.payload
      const comments = state.commentsBySourceId[sourceId]

      comments.map((comment) => {
        if (comment.id === parentCommentId) {
          if (comment.replies) {
            comment.replies = [...comment.replies, reply]
          } else {
            comment.replies = [reply]
          }
        }
        return comment
      })

      return {
        ...state,
        commentsBySourceId: {
          ...state.commentsBySourceId,
          [sourceId]: comments,
        },
      }
    }
    case "ADD_NEW_COMMENT": {
      const { sourceId, comment } = action.payload
      const comments = state.commentsBySourceId[sourceId]

      return {
        ...state,
        commentsBySourceId: {
          ...state.commentsBySourceId,
          [sourceId]: [...comments, comment],
        },
      }
    }
  }

  return state
}

export const CommentsContext = createContext<{
  state: CommentsState
  dispatch: React.Dispatch<CommentsAction>
}>({
  state: { commentsBySourceId: {} },
  dispatch: (state) => state,
})

export const CommentsProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const context = useContext(CommentsContext)
  const [state, dispatch] = useReducer(commentsReducer, context.state)

  return (
    <CommentsContext.Provider value={{ state, dispatch }}>
      <DraftsProvider>{children}</DraftsProvider>
    </CommentsContext.Provider>
  )
}
