import { assoc, differenceWith, dissoc, innerJoin, pipe } from "ramda"
import { KeyResult } from "@Goals/types"
import {
  GoalPriority,
  RawKeyResultRequest,
  UpdatingGoal,
  UpdatingTeamGoal,
} from "@Types/Goals"

export const encodeGoalPriority = (priority: GoalPriority): number => {
  switch (priority) {
    case "low":
      return 0
    case "medium":
      return 1
    case "high":
      return 2
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const encodeGoalToUpdate = (goal: UpdatingGoal): any => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const goalData: any = {
    name: goal.name,
    priority: goal.priority ? encodeGoalPriority(goal.priority) : undefined,
    description: goal.description,
    due_at: goal.dueDate && goal.dueDate.toISOString(),
    visibility: goal.visibility,
    key_results:
      goal.keyResults &&
      goal.keyResults.map((kr) => ({
        id: kr.id,
        title: kr.title,
        _destroy: kr.destroy,
        completion: kr.completion,
      })),
    completion: goal.keyResults ? undefined : goal.completion,
    status: goal.status,
    goal_owners: (goal as UpdatingTeamGoal).owners,
    outgoing_alignment_associations_attributes: goal.alignedGoals,
    access_permission:
      // @ts-expect-error - This should be typed
      goal.visibility === "individuals" && goal.individuals
        ? {
            // @ts-expect-error - This should be typed
            user_ids: goal.individuals.map((u) => u.id),
            permission: "read",
          }
        : undefined,
  }
  return { goal: goalData }
}

export const encodeKeyResult = (keyResult: KeyResult): RawKeyResultRequest =>
  pipe(
    assoc("metric_type", keyResult.metricType),
    dissoc("metricType")
  )(keyResult)

const sameId = <T extends { id: string }>(a: T, b: T) => a.id === b.id

const markAsRemoved = assoc("_destroy", true)
const markAsAdded = dissoc("id")

export const encodeUpdatedKeyResults = (
  keyResultsToUpdate: KeyResult[],
  originalKeyResults: KeyResult[]
): RawKeyResultRequest[] => {
  const removedKeyResults = differenceWith(
    sameId,
    originalKeyResults,
    keyResultsToUpdate
  )
    .map(encodeKeyResult)
    .map(markAsRemoved)

  const addedKeyResults = differenceWith(
    sameId,
    keyResultsToUpdate,
    originalKeyResults
  )
    .map(encodeKeyResult)
    .map(markAsAdded)

  const updatedKeyResults = innerJoin(
    sameId,
    keyResultsToUpdate,
    originalKeyResults
  ).map(encodeKeyResult)

  return [...removedKeyResults, ...updatedKeyResults, ...addedKeyResults]
}
