import React from "react"
import {
  AvatarGroup as KZAvatarGroup,
  AvatarGroupAvatarProps,
  AvatarList,
} from "@kaizen/draft-avatar/KaizenDraft/Avatar/AvatarGroup"
import { GoalOwner } from "@Goals/types"

export type AvatarGroupProps = {
  goalOwners: GoalOwner[]
}

const goalOwnerToAvatarProps = (
  goalOwner: GoalOwner
): AvatarGroupAvatarProps => ({
  fullName: goalOwner.fullName,
  avatarSrc: goalOwner.userProfileImageUrl,
})

export const AvatarGroup = ({ goalOwners }: AvatarGroupProps) => {
  const avatars = goalOwners.map(goalOwnerToAvatarProps) as AvatarList

  return <KZAvatarGroup size={"small"} avatars={avatars} maxVisible={2} />
}
