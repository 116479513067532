// Function to sort the filter option by its title
export const sortByName = <T extends { label: string }>(a: T, b: T) => {
  const titleA = a.label.toUpperCase()
  const titleB = b.label.toUpperCase()

  if (titleA < titleB) {
    return -1
  }

  if (titleA > titleB) {
    return 1
  }
  return 0
}

// Function to sort the filter option by its title
export const sortByPosition = <T extends { position: number }>(a: T, b: T) => {
  if (a.position < b.position) {
    return -1
  }
  return 1
}
