import {
  Paragraph,
  Heading,
  HeadingVariants,
  ParagraphVariants,
} from "@kaizen/typography"
import * as React from "react"
import { Avatar } from "@kaizen/draft-avatar"
import { useIntl } from "@Common/locale/useIntl"
import strings from "../../locale/strings"
import styles from "./AvatarDetails.scss"
import Aid from "../../constants/automationId"

type Size = "small" | "medium" | "large" | "xlarge"

type AvatarDetailsProps = {
  bestName: string
  avatarURL: string
  jobTitle?: string
  size?: Size
  deactivated?: boolean
}

const sizeToTitleVariant = (size: Size): HeadingVariants => {
  switch (size) {
    case "small":
      return "heading-5"
    case "medium":
      return "heading-4"
    case "large":
      return "heading-3"
    case "xlarge":
      return "heading-2"
  }
}

const sizeToSubtitleVariant = (size: Size): ParagraphVariants => {
  switch (size) {
    case "small":
      return "small"
    case "medium":
      return "small"
    case "large":
      return "body"
    case "xlarge":
      return "body"
  }
}

const AvatarDetails = ({
  bestName,
  avatarURL,
  jobTitle,
  size = "medium",
  deactivated = false,
}: AvatarDetailsProps) => {
  const { formatMessage } = useIntl()

  return (
    <div className={styles.container}>
      <div>
        <Avatar size={size} avatarSrc={avatarURL} fullName={bestName} />
      </div>
      <div className={styles.details}>
        <Heading variant={sizeToTitleVariant(size)}>
          {bestName}
          {deactivated && (
            <div
              className={styles.profileHeaderDeactivated}
              data-automation-id={Aid.profileHeaderDeactivated}
            >
              {formatMessage(strings.user.deactivated)}
            </div>
          )}
        </Heading>
        {jobTitle && (
          <div className={styles.jobTitle}>
            <Paragraph
              variant={sizeToSubtitleVariant(size)}
              data-automation-id={Aid.profileHeaderJobTitle}
            >
              {jobTitle}
            </Paragraph>
          </div>
        )}
      </div>
    </div>
  )
}

export default AvatarDetails
