import { Text } from "@kaizen/component-library"
import moment from "moment"
import * as React from "react"
import { Avatar } from "@kaizen/draft-avatar"
import MarkdownText from "@Common/components/MarkdownText/MarkdownText"
import styles from "./Comment.scss"

type CommentProps = {
  sender: string
  avatarURL: string
  message: string
  sent: Date
  children?: React.ReactNode
}

const Comment = ({
  sender,
  avatarURL,
  sent,
  message,
  children,
}: CommentProps) => {
  return (
    <div className={styles.container}>
      <Avatar avatarSrc={avatarURL} fullName={sender} />
      <div className={styles.content}>
        <Text tag="div" style="body-bold">
          {sender}
        </Text>
        <div className={styles.messageContainer}>
          <Text tag="div" style="small">
            {moment(sent).format("MMM DD, YYYY")}
          </Text>
          <div className={styles.message}>
            <MarkdownText text={message} />
          </div>
        </div>
        {children}
      </div>
    </div>
  )
}

export default Comment
