import "./ActionsInitializer"
import * as Admin from "../admin/refluxActions"
import User from "./UserActions"
import Review from "./ReviewActions"
import Feedback from "./FeedbackActions"
import UI from "./UIActions"
import Contact from "./ContactActions"
import Ontology from "./OntologyActions"
import Search from "./SearchActions"
import Notification from "./NotificationActions"
import PerformanceReview from "./PerformanceReviewActions"
import SelfReview from "./SelfReviewActions"
import DevelopmentResource from "./DevelopmentResourceActions"
import Team from "./TeamActions"
import UserSummary from "./UserSummaryActions"
import FeedbackRequests from "./FeedbackRequestsActions"
import Comments from "./CommentsAction"
import Goal from "./GoalActions"
import Company from "./CompanyActions"
import Draft from "./DraftActions"
import Survey from "./SurveyActions"
import SurveyResponse from "./SurveyResponseActions"
import IncompleteSurveyResponse from "./IncompleteSurveyResponseActions"
import SavedView from "./SavedViewActions"
import Template from "./TemplateActions"

export default {
  Admin,
  User,
  Review,
  UI,
  Feedback,
  Contact,
  Search,
  Ontology,
  Notification,
  PerformanceReview,
  DevelopmentResource,
  Team,
  UserSummary,
  FeedbackRequests,
  SelfReview,
  Comments,
  Goal,
  Company,
  Draft,
  Survey,
  SurveyResponse,
  IncompleteSurveyResponse,
  SavedView,
  Template,
}
