/**
 * A list of every feature flag used in the app.
 * Note, these are for the new feature flag system that uses flipper. Not
 * the old feature flag system where we access database entries from the
 * companies table.
 *
 * More info: docs/featureFlags.md
 */
enum FeatureFlags {
  /** enables page on admin dashboard to delete survey responses and reviews */
  allowAdminFeedbackDelete = "allow_admin_feedback_delete",
  calibrationsRoutes = "calibrations_routes",
  /** Refactor the table - need to be removed */
  calibrationsTableRebuild = "calibrations_table_rebuild",
  disableAssessmentGroupFilters = "disable_assessment_group_filters",
  disableGoalsExport = "disable_goals_export",
  disableManagerLearning = "disable_manager_learning",
  ecSelfReview = "ec_self_review",
  evaluationCycleSummaryStep = "evaluation_cycle_summary_step",
  goalsUseKaizenDatePicker = "goals_use_kaizen_date_picker",
  inProductGuidanceForEvaluationCycles = "show_in_product_guidance_for_evaluation_cycles",
  newGoalsForm = "new_goals_form",
  oneOnOneConversation = "one_on_one_conversation",
  oneOnOneUsageStats = "one_on_one_usage_stats",
  redirectNotificationsPageToUnifiedHome = "redirect_notifications_page_to_unified_home",
  /** enables all accordion tasks EXCEPT ProvideAdhocFeedbackTask */
  showAccordionTasks = "show_accordion_tasks",
  /** Demo feature flag for jest tests */
  testFlag = "test_flag",
  useNewSkillsAssignmentModal = "use_new_skills_assignment_modal",
  reviewAcknowledgment = "review_acknowledgement",
  splitDateTimePicker = "split_date_time_picker",
}

export default FeatureFlags
