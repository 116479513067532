// This function takes an event and some router settings and
// Overrides the event with the router if the user has clicked
import React from "react"
import { RouteChildrenProps } from "react-router-dom"
import { isRegularMouseClick } from "./domEvent"
import { buildUrl } from "./url"
import R, { basePath } from "../constants/routes"

type QueryObject = { [q: string]: string }

/**
 * Use `createRouterClickCallback` instead.
 *
 * It has some extra functionality where it stops execution when the `onClick.preventDefault`
 * is called. However, I noticed that the zen header buttons stopped working
 * as a result, so I kept this function for now.
 *
 * @deprecated
 */
export const createRouterLink =
  (
    history: RouteChildrenProps["history"],
    route: R | string,
    settings?: {
      query?: QueryObject
      replace?: boolean
    }
  ) =>
  (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    event: any
  ) => {
    const { replace = false, query } = settings || {}
    if (isRegularMouseClick(event)) {
      event.preventDefault()
      history[replace ? "replace" : "push"](
        query ? buildUrl(route, query) : route
      )
    }
  }

export const createRouterClickCallback =
  (
    history: RouteChildrenProps["history"],
    route: R | string,
    settings?: {
      query?: QueryObject
      replace?: boolean
      onClick?: (e: React.MouseEvent) => void
    }
  ) =>
  (event: React.MouseEvent) => {
    const { replace = false, query, onClick } = settings || {}

    // Helpful code was copy/pasted from react-router:
    //  https://github.com/ReactTraining/react-router/blob/master/packages/react-router-dom/modules/Link.js
    try {
      if (onClick) onClick(event)
    } catch (ex) {
      event.preventDefault()
      throw ex
    }

    if (
      !event.defaultPrevented && // ie. the passed in onClick event already called `event.preventDefault()`
      isRegularMouseClick(event)
    ) {
      event.preventDefault()
      history[replace ? "replace" : "push"](
        query ? buildUrl(route, query) : route
      )
    }
  }

/**
 * Duplicate of `prependBasePath` in ./routing.ts
 * @deprecated
 */
export const getTrueUrl = (route: R | string, query?: QueryObject) =>
  buildUrl(`${basePath}${route}`, query)
