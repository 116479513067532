/**
 * Constants that hold all of the selectors, which target the react portal dom
 * elements.
 *
 * MAKE SURE THAT THESE VALUES ARE SYNCED WITH index-template.html
 */
export const rootAppId = "app"
export const tooltipPortalId = "tooltip-portal"
export const menuPortalId = "menu-portal"
export const popoverPortalId = "popover-portal"
