import { useContext } from "react"
import { logGoalUpdated } from "@Goals/analytics"
import { getGoalDetailsResponseDecoder } from "../../api/decoders/GetGoalDetailsResponseDecoder/getGoalDetailsResponseDecoder"
import { encodeGoalToUpdate } from "../../api/encoders/goals"
import { GoalsContext } from "../../reduxReducers/goals"
import { UpdatingGoal } from "../../types/Goals"
import useDataAPI from "../performanceApi/useDataAPI"
import useTeamSummaries from "../teams/useTeamSummaries"
import { captureDecoderException } from "../../api/decoders/utils"

const useUpdateGoal = (goalId?: number) => {
  const { dispatch } = useContext(GoalsContext)
  const updateAPI = useDataAPI(`/goals/${goalId}`, "PUT")
  const { refetch: fetchTeamSummaries } = useTeamSummaries()

  const updateGoal = async (goal: UpdatingGoal) => {
    if (goalId) {
      try {
        const goalData = encodeGoalToUpdate(goal)
        const { data: updatedGoalData } = await updateAPI.fire(goalData)
        const updatedGoal = await getGoalDetailsResponseDecoder.decodePromise(
          updatedGoalData
        )

        dispatch({ type: "FETCH_GOAL_DETAILS", payload: updatedGoal })

        if (updatedGoal.type === "team") {
          fetchTeamSummaries()
        }

        logGoalUpdated(updatedGoal, goal)
      } catch (error) {
        captureDecoderException(error)
        throw error
      }
    }
  }

  return {
    updateGoal,
    error: updateAPI.error,
    success: !updateAPI.error && !!updateAPI.data,
    loading: updateAPI.loading,
  }
}

export default useUpdateGoal
