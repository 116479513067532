import React, { useCallback, useEffect, useRef, useState } from "react"
import { Input, InputStatus } from "@kaizen/draft-form"

import styles from "./NumberInput.scss"

export type NumberInputProps = {
  id: string
  value: number
  onChange?: (v: number) => void
  status?: InputStatus
  unitIcon?: React.ReactNode
  automationId?: string
}

const isValidNegativeNumberString = (str: string) => {
  if (str.length < 1) {
    return false
  }

  if (str[0] !== "-") {
    return false
  }

  return !isNaN(Number(str.slice(1)))
}

const isValidNumberString = (str: string) => {
  return !isNaN(Number(str)) || isValidNegativeNumberString(str)
}

export const NumberInput = ({
  id,
  automationId,
  value,
  onChange,
  status,
  unitIcon,
}: NumberInputProps) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const [inputStatus, setInputStatus] = useState<InputStatus>("default")
  const [internalValue, setInternalValue] = useState(value.toString())
  const prevValue = useRef<number>(value)
  const [isDirty, setIsDirty] = useState(false)

  useEffect(() => {
    setInternalValue(value.toString())
  }, [value])

  const attemptPropagateOnChange = useCallback(
    (newValueStr: string) => {
      if (!onChange) return

      const newValue = Number(newValueStr)
      const oldValueStr = value.toString()

      setInternalValue(newValue.toString())

      if (newValueStr === "" || newValueStr === "-") {
        setInternalValue(oldValueStr)
        onChange(value)
        return
      }

      if (isDirty && prevValue.current !== newValue) {
        onChange(newValue)
        setIsDirty(false)
        prevValue.current = newValue
      }
    },
    [isDirty, onChange, value]
  )

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.onkeydown = (e) => {
        if (e.key === ".") {
          e.preventDefault()
          e.stopPropagation()
          return
        }

        if (e.key === "Enter") {
          e.target && (e.target as HTMLInputElement).blur()
        }
      }
    }
  }, [])

  const handleInputOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (inputStatus === "error") {
      setInputStatus("default")
    }

    if (!isValidNumberString(e.target.value)) return

    setInternalValue(e.target.value)
    setIsDirty(true)
  }

  return (
    <Input
      className={styles.numberInput}
      automationId={automationId}
      inputValue={internalValue}
      id={id}
      onBlur={() => attemptPropagateOnChange(internalValue)}
      onChange={handleInputOnChange}
      inputRef={inputRef}
      status={status}
      endIconAdornment={
        unitIcon && <div className={styles.unitIconContainer}>{unitIcon}</div>
      }
    />
  )
}
