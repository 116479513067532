declare global {
  interface Window {
    DD_RUM: {
      onReady: (callback: Function) => void
      addError: (error: unknown, context: unknown) => void
    }
  }
}

// https://docs.datadoghq.com/real_user_monitoring/browser/collecting_browser_errors/?tab=cdnasync
export const sendErrorToDatadog = (error: unknown, context: Object = {}) => {
  window.DD_RUM &&
    window.DD_RUM.onReady(() => {
      window.DD_RUM.addError(error, context)
    })
}
