import React from "react"
import { withRouter, RouteComponentProps } from "react-router-dom"
import { Button as KaizenButton, ButtonProps } from "@kaizen/button"
import "./ButtonDeprecated.less"

export type ButtonActionType = "primary" | "secondary" | "plain" | "link"

type ButtonPropsModified = Omit<ButtonProps, "onClick">

export type Props = Partial<ButtonPropsModified> &
  RouteComponentProps & {
    actionType: ButtonActionType
    to?: object | string
    href?: string
    openLinkInNewWindow?: boolean
    "data-automation-id"?: string
    children?: string
    onClick?:
      | ((e: React.MouseEvent) => void)
      // Passing a promise, will automatically disable the button until the
      // promise is resolved.
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      | ((e: React.MouseEvent) => Promise<any>)
    disabled?: boolean
  }

type State = {
  disabledOverride: boolean
}

/**
 * Deprecated, please use the ../Button component instead.
 * This button does not use the latest kaizen button. The props also don't
 * correctly match up to the kaizen ones. Also the automatic button disabling,
 * although well intended, has lead to some bugs.
 * @deprecated
 */
class ButtonDeprecated extends React.Component<Props, State> {
  _unmounted = false

  constructor(props: Props) {
    super(props)

    this.state = {
      disabledOverride: false,
    }
  }

  componentWillUnmount() {
    this._unmounted = true
  }

  handleClick = (e: React.MouseEvent) => {
    const { onClick, to, disabled, history } = this.props

    if (!onClick && to) {
      history.push(to)
      return
    }

    if (!onClick || disabled || this.state.disabledOverride) {
      return
    }

    const clickResult = onClick(e)

    // If the onClick callback returns a promise, disable the button until
    // the promise resolves.
    if (clickResult && !!clickResult.then) {
      this.setState(
        () => ({ disabledOverride: true }),
        async () => {
          await clickResult
          if (!this._unmounted) {
            this.setState({ disabledOverride: false })
          }
        }
      )
    } else {
      return clickResult
    }
  }

  render() {
    const {
      actionType,
      children,
      href,
      openLinkInNewWindow,
      onClick,
      disabled,
      to,
      ...extraProps
    } = this.props
    const { disabledOverride } = this.state

    const classes = [`Button--${actionType}`]

    switch (actionType) {
      case "primary":
        classes.push("btn-large", "btn-light")
        break

      case "secondary":
        classes.push("btn-flat", "btn-bordered")
        break

      case "link":
        classes.push("btn-flat", "btn-light")
        break

      case "plain":
        classes.push("btn-flat")
        break
    }

    const hrefProps = {
      href,
      ...(openLinkInNewWindow && {
        target: "_blank",
        rel: "noopener noreferrer",
      }),
    }

    return (
      <KaizenButton
        {...hrefProps}
        {...extraProps}
        disabled={disabled || disabledOverride}
        // I'm not sure why this `href` condition is here. There's nothing
        // that should prevent you from having an onclick event, even on
        // anchor links. The onClick condition IS required however! For some
        // reason if the button type is `submit`, the form will not submit
        // if `this.handleClick` is passed in. If you wish to change this line,
        // please double check the Admin => Employee Evaluations => Create Cycle
        // screen, and make sure you can navigate to the next step.
        //
        // we might return a promise, in which case the typing breaks here.
        onClick={!to && (href || !onClick) ? undefined : this.handleClick}
        primary={actionType === "primary"}
        secondary={actionType === "secondary"}
        label={children || ""}
        working={false}
      />
    )
  }
}

export default withRouter(ButtonDeprecated)
