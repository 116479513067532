import { useContext, useEffect } from "react"
import { getGoalDetailsResponseDecoder } from "../../api/decoders/GetGoalDetailsResponseDecoder/getGoalDetailsResponseDecoder"
import { GoalsContext } from "../../reduxReducers/goals"
import useDataAPI from "../performanceApi/useDataAPI"
import { captureDecoderException } from "../../api/decoders/utils"

const useGoalDetails = (goalId?: number) => {
  const { state: goals, dispatch } = useContext(GoalsContext)
  const fetchAPI = useDataAPI(`goals/${goalId}`, "GET")

  useEffect(() => {
    if (goalId) {
      fetchAPI.fire()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [goalId])

  useEffect(() => {
    if (fetchAPI.data) {
      getGoalDetailsResponseDecoder
        .decodePromise(fetchAPI.data)
        .then((goal) => {
          dispatch({ type: "FETCH_GOAL_DETAILS", payload: goal })
        })
        .catch((error) => {
          captureDecoderException(error)
          throw error
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchAPI.data])

  const goal = goalId ? goals.goalsById[goalId] : undefined

  return {
    goal,
    loading: fetchAPI.loading,
  }
}
export default useGoalDetails
