import Reflux from "../../refluxActions/ActionsInitializer"
import SteadyfootAgent from "../../refluxActions/lib/SteadyfootAgent"
import StatsDateRange from "../../models/StatsDateRange"
import {
  addLoadingState,
  addToasts,
  extractResponseKey,
} from "../../refluxActions/lib/apiActionHelpers"
import endpoints from "../../constants/endpointsDeprecated"
import { showCsvConfirmation } from "../../refluxActions/lib/csvExportation"
import strings from "../../locale/strings"

const {
  ADMIN_FEEDBACK_STATS_URLS: {
    REVIEW_STATS_URL,
    REVIEW_PERIODS_URL,
    INACTIVE_STATS_URL,
    REVIEW_EXPORT_URL,
  },
} = endpoints

const agent = SteadyfootAgent.defaultInstance

const AdminFeedbackStatsActions = Reflux.createActions({
  getReviewStats: { asyncResult: true },
  getReviewPeriods: { asyncResult: true },
  exportInactiveStats: { asyncResult: true },
  exportStats: { asyncResult: true },
})

/**
 * @param {StatsDateRange} dateRangeId
 * @param {number=} departmentId
 * @param {Date=} endDate - defaults to now
 */
AdminFeedbackStatsActions.getReviewStats.listenAndPromise(
  ({ dateRangeId, departmentId, endDate = new Date() }) =>
    addToasts(
      {},
      addLoadingState(
        [true, { light: true }],
        extractResponseKey(
          "stats",
          agent.get(REVIEW_STATS_URL).query({
            department_id: departmentId || undefined,
            from: StatsDateRange.getStartDate(
              dateRangeId,
              endDate
            ).toISOString(),
            to: endDate.toISOString(),
          })
        ).then((reviewStats) => ({ reviewStats, dateRangeId }))
      )
    )
)

/**
 * @param {StatsDateRange} dateRangeId
 * @param {number=} departmentId
 */
AdminFeedbackStatsActions.getReviewPeriods.listenAndPromise(
  ({ dateRangeId, departmentId }) =>
    addToasts(
      {},
      addLoadingState(
        [true, { light: true }],
        extractResponseKey(
          "review_periods",
          agent.get(REVIEW_PERIODS_URL).query({
            department_ids: departmentId || undefined,

            // This endpoint (as of 2018-06-01) only accepts a start date; the end date is fixed
            // to the current date
            from: StatsDateRange.getStartDate(
              dateRangeId,
              new Date()
            ).toISOString(),
          })
        )
      )
    )
)

AdminFeedbackStatsActions.exportInactiveStats.listenAndPromise(
  ({ departmentId, startDate }) =>
    addLoadingState(
      true,
      addToasts(
        {
          defaultError: strings.feedbackStats.exportFailed,
        },
        extractResponseKey(
          "export_request",
          agent.post(REVIEW_EXPORT_URL).send({
            review_export_request: {
              options: {
                from: startDate.toISOString(),
                department_ids: departmentId,
              },
            },
          })
        ).then(showCsvConfirmation)
      )
    )
)

AdminFeedbackStatsActions.exportStats.listenAndPromise(
  ({ departmentId, startDate }) =>
    addLoadingState(
      true,
      addToasts(
        {
          defaultError:
            strings.feedbackStats.incompleteFeedbackUserStats.exportFailed,
        },
        extractResponseKey(
          "export_request",
          agent.post(INACTIVE_STATS_URL).send({
            inactive_review_user_export_request: {
              options: {
                from: startDate.toISOString(),
                department_ids: departmentId,
              },
            },
          })
        ).then(showCsvConfirmation)
      )
    )
)

export default AdminFeedbackStatsActions
