/**
 * DEPRECATED: we are no longer using decoders to validate server responses.
 * Please do not use them in new code.
 *
 * See src/api/decoders/README.md for more info.
 */
import { JsonDecoder } from "ts.data.json"
import { Omit } from "yargs"
import { AccessPermission, KeyResult, KeyResultMetricType } from "@Goals/types"
import {
  BaseGoal,
  BaseGoalPreview,
  GoalIndividual,
  GoalPriority,
  GoalType,
} from "@Types/Goals"

type Difference<T, S extends T> = Omit<S, keyof T>

export const goalTypeDecoder = JsonDecoder.oneOf<GoalType>(
  [
    JsonDecoder.isExactly("my_goal").map((): "personal" => "personal"),
    JsonDecoder.isExactly("team_goal").map((): "team" => "team"),
    JsonDecoder.isExactly("department_goal").map(
      (): "department" => "department"
    ),
    JsonDecoder.isExactly("company_goal").map((): "company" => "company"),
  ],
  "goalType"
)

export const goalPriorityDecoder = JsonDecoder.oneOf<GoalPriority>(
  [
    JsonDecoder.isExactly("none").map((): "low" => "low"),
    JsonDecoder.isExactly(0).map((): "low" => "low"),
    JsonDecoder.isExactly(1).map((): "medium" => "medium"),
    JsonDecoder.isExactly(2).map((): "high" => "high"),
  ],
  "goalPriority"
)

export const goalKeyResultDecoder: JsonDecoder.Decoder<KeyResult> =
  JsonDecoder.object<KeyResult>(
    {
      id: JsonDecoder.string,
      title: JsonDecoder.string,
      completion: JsonDecoder.number,
      current: JsonDecoder.number,
      start: JsonDecoder.number,
      target: JsonDecoder.number,
      metricType: JsonDecoder.oneOf<KeyResultMetricType>(
        [JsonDecoder.isExactly("percentage"), JsonDecoder.isExactly("numeric")],
        "keyResultMetricType"
      ),
    },
    "keyResult",
    {
      metricType: "metric_type",
    }
  )

export const goalIndividualsDecoder: JsonDecoder.Decoder<GoalIndividual> =
  JsonDecoder.object(
    {
      user: JsonDecoder.object<GoalIndividual>(
        {
          id: JsonDecoder.number,
          name: JsonDecoder.string,
          avatar: JsonDecoder.object(
            {
              thumb_url: JsonDecoder.string,
            },
            "avatar"
          ).map((avatarObj) => avatarObj.thumb_url),
          profileImage: JsonDecoder.failover("", JsonDecoder.string),
          role: JsonDecoder.failover("", JsonDecoder.string),
          aggregateId: JsonDecoder.failover("", JsonDecoder.string),
          email: JsonDecoder.failover("", JsonDecoder.string),
        },
        "user",
        {
          name: "best_name",
          avatar: "avatar_images",
          role: "job_title_name",
          aggregateId: "employee_aggregate_id",
          profileImage: "profile_image_url",
        }
      ),
    },
    "individuals"
  ).map(({ user }) => user)

type BaseGoalDecoders = {
  id: JsonDecoder.Decoder<number>
  name: JsonDecoder.Decoder<string>
  completion: JsonDecoder.Decoder<number>
  commentsCount: JsonDecoder.Decoder<number>
  dueDate: JsonDecoder.Decoder<Date>
  priority: JsonDecoder.Decoder<GoalPriority>
  type: JsonDecoder.Decoder<GoalType>
}

const baseGoalDecoders: BaseGoalDecoders = {
  id: JsonDecoder.number,
  name: JsonDecoder.string,
  completion: JsonDecoder.number,
  commentsCount: JsonDecoder.number,
  dueDate: JsonDecoder.string.map((dateString) => new Date(dateString)),
  priority: goalPriorityDecoder,
  type: goalTypeDecoder,
}

const baseGoalKeyMap = {
  commentsCount: "number_of_comments",
  dueDate: "due_at",
  priority: "priority",
  type: "goal_type",
}

export const extendGoalPreviewDecoder = <T extends BaseGoalPreview>(
  decoders: JsonDecoder.DecoderObject<
    Difference<BaseGoalPreview, T> & Partial<BaseGoalPreview>
  >,
  decoderName: string,
  keyMap?: JsonDecoder.DecoderObjectKeyMap<Difference<BaseGoalPreview, T>>
): JsonDecoder.Decoder<T> => {
  const mergedDecoders = {
    ...baseGoalDecoders,
    ...decoders,
  } as JsonDecoder.DecoderObject<T>

  const mergedKeyMaps: JsonDecoder.DecoderObjectKeyMap<T> = {
    ...baseGoalKeyMap,
    ...keyMap,
  } as JsonDecoder.DecoderObjectKeyMap<T>

  return JsonDecoder.object<T>(mergedDecoders, decoderName, mergedKeyMaps)
}

export const extendGoalDecoder = <T extends BaseGoal>(
  decoders: JsonDecoder.DecoderObject<
    Difference<BaseGoal, T> & Partial<BaseGoal>
  >,
  decoderName: string,
  keyMap?: JsonDecoder.DecoderObjectKeyMap<Difference<BaseGoal, T>>
): JsonDecoder.Decoder<T> => {
  const mergedDecoders = {
    ...baseGoalDecoders,
    // Note: these may just need to be deleted to prevent override error from
    // decoders. @ts-expect-error during TS upgrade to 3.9 `[key] specified
    // more than once.` Please fix if you have time.
    // @ts-expect-error - This should be typed
    description: JsonDecoder.failover(undefined, JsonDecoder.string),
    // @ts-expect-error - This should be typed
    status: JsonDecoder.failover(undefined, JsonDecoder.string),
    // @ts-expect-error - This should be typed
    keyResults: JsonDecoder.array(goalKeyResultDecoder, "keyResults"),
    // @ts-expect-error - This should be typed
    visibility: JsonDecoder.string,
    ...decoders,
  } as JsonDecoder.DecoderObject<T>

  const mergedKeyMaps: JsonDecoder.DecoderObjectKeyMap<T> = {
    ...baseGoalKeyMap,
    keyResults: "key_results",
    ...keyMap,
  } as JsonDecoder.DecoderObjectKeyMap<T>

  return JsonDecoder.object<T>(mergedDecoders, decoderName, mergedKeyMaps)
}

export const accessPermissionsDecoder: JsonDecoder.Decoder<
  AccessPermission[] | undefined
> = JsonDecoder.failover(
  undefined,
  JsonDecoder.array(
    JsonDecoder.object<AccessPermission>(
      {
        notes: JsonDecoder.nullable(JsonDecoder.string),
      },
      "access_permission_decoder"
    ),
    "access_permissions_decoder"
  )
)
