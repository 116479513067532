import { lazyLoader } from "@Utils/lazyLoading"

/**
 * TextEditor is a legacy .jsx component using propTypes
 * As such type information cannot be inferred via TS, so
 * we are creating the interface here to use in our TS components
 */
interface TextEditor {
  className?: string
  saveState?: "saving" | "saved" | "failed"
  value?: string
  initialValue?: string
  lastSavedAt?: string
  borderless?: boolean
  autoFocus?: boolean
  disabled?: boolean
  placeholder?: string
  clearable?: boolean
  onChange?: (editorState: string) => void
  onBlur?: (event: React.FocusEvent<HTMLElement>, value: string) => void
  onFocus?: (event: React.FocusEvent<HTMLElement>, value: string) => void
  onClear?: () => void
  toolbarButtonsToUse?: "bold" | "ul" | "ol" | "link"[]
  useRTE?: boolean
  inputPrompt?: React.ReactNode
  automationId?: string
  loadUsersForMention?: <T>(q: { query: string }) => Promise<T>
}

// prettier-ignore
// @ts-expect-error - This should be typed
const TextEditor: React.ComponentClass<TextEditor & { forwardedRef?: React.Ref<object> }> = lazyLoader(() => import(/* webpackChunkName: "TextEditor" */ "./TextEditor"))
export default TextEditor
