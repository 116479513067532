import React, { useEffect, useRef, useState, useMemo } from "react"
import ReactDOM from "react-dom"
// eslint-disable-next-line no-restricted-imports
import { Popover as KaizenPopover, PopoverProps } from "@kaizen/draft-popover"
import { keyCode } from "../../constants/keyCodes"
import { popoverPortalId } from "../../constants/portals"

type Props = PopoverProps & {
  onClose?: () => void
  isVisible?: boolean
  renderLocation?: "inline" | "root"
}

/**
 * A wrapper around the kaizen popover, which does the following:
 * - automatically calls the `onClose` callback, when the user clicks outside
 *   of the popover itself, or presses ESC
 * - Add portal support, via the renderLocation prop. Use this if the popover
 *   is getting cropped by a `overflow: hidden` or `scroll` element.
 *
 * Note: Make sure `onClose` is wrapped in useCallback, otherwise we'll be adding/removing
 * a lot of event listeners to the document.
 */
export const Popover = ({
  isVisible = true,
  onClose,
  renderLocation,
  referenceElement,
  ...rest
}: Props) => {
  const ref = useRef<HTMLDivElement>(null)

  // User clicks outside of the popover
  useEffect(() => {
    if (!isVisible || !onClose) return undefined

    const callback = (e: React.MouseEvent) => {
      const elem = ref.current
      if (!elem) return

      const didClickOnPopover =
        // @ts-expect-error: meh, it works
        elem === e.target || elem.contains(e.target)
      const didClickOnReferenceElement =
        referenceElement &&
        // @ts-expect-error - This should be typed
        (referenceElement === e.target || referenceElement.contains(e.target))
      if (!didClickOnPopover && !didClickOnReferenceElement) {
        // Ideally, it would be nice to prevent any click-through
        onClose()
      }
    }
    // @ts-expect-error - This should be typed
    document.addEventListener("click", callback, true)

    return () => {
      // @ts-expect-error - This should be typed
      document.removeEventListener("click", callback, true)
    }
  }, [isVisible, onClose, referenceElement])

  // User presses the escape key
  useEffect(() => {
    if (!isVisible || !onClose) return undefined

    const callback = (e: KeyboardEvent) => {
      // My guess here is that this will conflict with other keyboard events.
      // eg. what if the popover was inside a modal?
      if (e.keyCode === keyCode.escape) {
        onClose()
      }
    }
    document.addEventListener("keydown", callback, true)

    return () => {
      document.removeEventListener("keydown", callback, true)
    }
  }, [isVisible, onClose])

  if (!isVisible) return null

  const popover = (
    <div ref={ref}>
      <KaizenPopover referenceElement={referenceElement} {...rest} />
    </div>
  )

  const portalElement =
    renderLocation === "root"
      ? document.getElementById(popoverPortalId)
      : undefined

  return portalElement ? ReactDOM.createPortal(popover, portalElement) : popover
}

type PropsWithoutRef = Omit<Props, "referenceElement">

/**
 * How to use:
 *
 * const [popoverReferenceElementRef, Popover] = usePopover()
 *
 * return (<>
 *   <button ref={popoverReferenceElementRef}>
 *     Hello world
 *   </button>
 *   <Popover>Hello world</Popover>
 * </>)
 *
 * The purpose of this hook is to abstract away some of the awkwardness with the
 * requirement of passing in refs with popper (the layout engine that kaizen
 * uses behind the scenes). We need to use `useState` instead
 * of `useRef`, which may not be immediately intuitive.
 *
 * The popper documentation to help provide more context:
 *   https://popper.js.org/react-popper/v2/hook/
 *
 * Note, this code is essentially a copy paste of the `usePopover` hook in kaizen,
 * but uses the Popover wrapper instead.
 */
export const usePopover = (): [
  (element: HTMLElement | null) => void,
  React.FunctionComponent<PropsWithoutRef>
] => {
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(
    null
  )

  // I guess the problem with this pattern, is that every time referenceElement
  // changes, a brand new component is generated, which would be bad for memoization.
  // In this situation however, the value is rarely going to change, and
  // popovers aren't going to include content with expensive render times.
  const PopoverWithRef = useMemo(
    // eslint-disable-next-line react/display-name
    () => (props: PropsWithoutRef) =>
      referenceElement ? (
        <Popover {...props} referenceElement={referenceElement} />
      ) : null,
    [referenceElement]
  )

  return [setReferenceElement, PopoverWithRef]
}
