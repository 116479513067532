/**
 * This module contains a collection of tightly related Adapters for
 * calls to logKnownEvent in @Analytics/utils.
 *
 * The Adapters exported are intended to make calls to logKnownEvent more
 * ergonomic from our React components, as well as implement a thin
 * Anti-Corruption Layer (ACL).
 */
import { GoalFilterTypes, GoalPreview } from "@Types/Goals"

import { logKnownEvent } from "@Analytics/utils"
import Avo from "@Analytics/avo/Avo"
import { GoalForTree } from "@Goals/types"

type GoalsIndexViewedLoggerPayload = {
  goals: (GoalPreview | GoalForTree)[]
  filters: GoalFilterTypes
  nav_source?: string
}

enum FilterName {
  DUE_DATE = "Due date",
  SEARCH = "Search",
}

enum TeamGroupGoalOptions {
  ALL_TEAMS = "All teams",
  MY_TEAMS = "My teams",
  SPECIFIC_TEAM = "Specific team",
}

enum DepartmentGroupGoalOptions {
  MY_DEPARTMENT = "My department",
  ALL_DEPARTMENTS = "All departments",
}

export enum NavSources {
  TEAM_SUMMARY_DETAIL = "TeamSummaryDetail",
}

type FilterConfiguration = Record<
  FilterName,
  (filters: GoalFilterTypes) => boolean
>

const FILTER_CONFIGURATION: FilterConfiguration = {
  [FilterName.DUE_DATE]: (f) => !!(f.from && f.to),
  [FilterName.SEARCH]: (f) => !!f.q,
}

function filtersApplied(filters: GoalFilterTypes): string[] {
  return Object.entries(FILTER_CONFIGURATION).reduce(
    (result, [filterType, predicate]) =>
      predicate(filters) ? [...result, filterType] : result,
    [] as string[]
  )
}

function navigationSource(nav_source?: string): string {
  return nav_source || ""
}

function departmentView(myDepartment?: boolean): DepartmentGroupGoalOptions {
  if (myDepartment) {
    return DepartmentGroupGoalOptions.MY_DEPARTMENT
  }

  return DepartmentGroupGoalOptions.ALL_DEPARTMENTS
}

function teamView(
  allTeams?: boolean,
  nav_source?: string
): TeamGroupGoalOptions {
  if (nav_source === NavSources.TEAM_SUMMARY_DETAIL) {
    return TeamGroupGoalOptions.SPECIFIC_TEAM
  }

  if (allTeams) {
    return TeamGroupGoalOptions.ALL_TEAMS
  }

  return TeamGroupGoalOptions.MY_TEAMS
}

function stringifyDate(date?: Date | string): string | undefined {
  if (date instanceof Date) {
    return date.toString()
  }

  return date
}

function dateProperties(from?: Date | string, to?: Date | string) {
  if (from && to) {
    return {
      "Filter date from": stringifyDate(from),
      "Filter date to": stringifyDate(to),
    }
  }

  return {}
}

function baseProperties({
  goals,
  filters,
  nav_source,
}: GoalsIndexViewedLoggerPayload) {
  return {
    "Number of goals": goals.length,
    "Filters applied": filtersApplied(filters),
    "Navigation source": navigationSource(nav_source),
    "Goal ID": goals.map((g) => g.id),
    ...dateProperties(filters.from, filters.to),
  }
}

export const logPersonalGoalsIndexViewed = (
  payload: GoalsIndexViewedLoggerPayload
) => {
  Avo.individualGoalsIndexViewed({
    filterDateFrom: stringifyDate(payload.filters.from),
    filterDateTo: stringifyDate(payload.filters.to),
    filtersApplied: filtersApplied(payload.filters),
    goalId: payload.goals.map((g) => `${g.id}`),
    navigationSource: payload.nav_source,
    numberOfGoals: payload.goals.length,
  })
}

export const logTeamGoalsIndexViewed = (
  payload: GoalsIndexViewedLoggerPayload
) => {
  logKnownEvent({
    type: "Team Goals Index Viewed",
    properties: {
      ...baseProperties(payload),
      "Group goal viewed": teamView(
        payload.filters.all_teams,
        payload.nav_source
      ),
    },
  })
}

export const logDepartmentGoalsIndexViewed = (
  payload: GoalsIndexViewedLoggerPayload
) => {
  logKnownEvent({
    type: "Department Goals Index Viewed",
    properties: {
      ...baseProperties(payload),
      "Group goal viewed": departmentView(payload.filters.my_department),
    },
  })
}

export const logCompanyGoalsIndexViewed = (
  payload: GoalsIndexViewedLoggerPayload & { viewMode: "Tree" | "List" }
) => {
  Avo.companyGoalsIndexViewed({
    filterDateFrom: stringifyDate(payload.filters.from),
    filterDateTo: stringifyDate(payload.filters.to),
    filtersApplied: filtersApplied(payload.filters),
    goalId: payload.goals.map((g) => `${g.id}`),
    navigationSource: payload.nav_source,
    numberOfGoals: payload.goals.length,
    viewMode: payload.viewMode,
  })
}
