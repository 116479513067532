import { PaginatedResults, Pagination } from "../../types/Pagination"

export const getPaginationState = <T extends unknown>(
  state: PaginatedResults<T>,
  pagination: Pagination,
  elements: T[]
): PaginatedResults<T> => ({
  totalPages: pagination.totalPages,
  totalCount: pagination.totalCount,
  pages: {
    ...state.pages,
    [pagination.currentPage]: elements,
  },
  currentPage: pagination.currentPage,
  nextPage: pagination.nextPage,
  previousPage: pagination.previousPage,
})
