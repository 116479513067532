import React from "react"
import { FieldGroup, InputStatus, Label } from "@kaizen/draft-form"
import { NumberInput } from "@Goals/components"

export type NumberFieldProps = {
  id: string
  labelText: string
  onChange?: (v: number) => void
  status?: InputStatus
  value: number
}

export const NumberField = ({
  id,
  labelText,
  onChange,
  value,
  status,
}: NumberFieldProps) => {
  const numberFieldId = `${id}-number-field`
  const numberFieldDataAutomationId = `${id}-number-input-field`
  return (
    <FieldGroup>
      <Label htmlFor={numberFieldId} labelText={labelText} />

      <NumberInput
        id={numberFieldId}
        automationId={numberFieldDataAutomationId}
        onChange={onChange}
        value={value}
        status={status}
      />
    </FieldGroup>
  )
}
