/* eslint-disable @typescript-eslint/no-explicit-any */
import _ from "lodash"
import { simplifyImmutableJsObject } from "./object"
import { isLogTagEnabled } from "./logging"
import { REFLUX_ACTIONS } from "../constants/logging"

/**
 * Redux dev tools (the browser extension) set up. These types are not
 * exhaustive, only the ones needed. See docs for full options:
 *
 * https://github.com/zalmoxisus/redux-devtools-extension/blob/master/docs/API/Methods.md
 * https://github.com/zalmoxisus/redux-devtools-extension/blob/master/docs/API/Arguments.md
 */
type ReduxDevToolsConfig = {
  name: string
}
const devToolsConfig = {
  name: "refluxStore",
}

type ReduxDevToolsStore = {
  init: (initalState: RefluxStores) => void
  send: (
    action: string | { type: string; [x: string]: any },
    state: RefluxStores
  ) => void
}

type ReduxDevToolsExtension = {
  connect: (config: ReduxDevToolsConfig) => ReduxDevToolsStore
}

type WindowWithDevTools = typeof window & {
  __REDUX_DEVTOOLS_EXTENSION__: ReduxDevToolsExtension
}

const hasReduxDevTools = (w: typeof window): w is WindowWithDevTools =>
  !!(window as any).__REDUX_DEVTOOLS_EXTENSION__

let reduxDevTools: ReduxDevToolsStore | undefined

type RefluxActions = { [actionName: string]: any }
type RefluxStores = { [storeName: string]: { data: any } }

const extractDataFromStores = (refluxStores: RefluxStores) => {
  // The state for each of the reflux stores is stored in the `data` value.
  // Here we remove all the extra functions that we don't need to log.
  let ret = _.mapValues(refluxStores, (v) => v.data)
  // We use immutablejs for some of our reflux store. This requires us to
  // convert these objects back to js objects.
  ret = simplifyImmutableJsObject(
    ret,
    "<-- CIRCULAR REFERENCE, LOG NOT SHOWN -->"
  )
  return ret
}

export const initRefluxActionLogger = (
  refluxActions: RefluxActions,
  refluxStores: RefluxStores,
  path: string[] = []
) => {
  if (!isLogTagEnabled(REFLUX_ACTIONS)) return

  if (!reduxDevTools && hasReduxDevTools(window)) {
    reduxDevTools = window.__REDUX_DEVTOOLS_EXTENSION__.connect(devToolsConfig)
    reduxDevTools.init(extractDataFromStores(refluxStores))
  }

  const makeOnActionCallback =
    (actionNamePath: string[]) =>
    (...args: any) => {
      const newState = extractDataFromStores(refluxStores)
      if (reduxDevTools) {
        // assuming if someone has redux dev tools they won't want logs in their console too
        const actionNameString = actionNamePath.join(".")
        reduxDevTools.send({ type: actionNameString, args }, newState)
      }
    }

  _.keys(refluxActions).forEach((actionName) => {
    const action = refluxActions[actionName]
    if (action.listen) {
      action.listen(makeOnActionCallback([...path, actionName]))

      // Child actions seem to be typically used for async requests, such
      // as showing when an action is completed or failed.
      if (action.children) {
        action.children.forEach((childActionName: string) => {
          // I should be able to just call initRefluxActionLogger again, but
          // it returns with a stackoverflow error
          action[childActionName].listen(
            makeOnActionCallback([...path, actionName, childActionName])
          )
        })
      }
    } else if (typeof action === "object") {
      // The reflux actions are deeply nested. Recursively call `logRefluxActions`
      // until we find the listenable reflux action.
      initRefluxActionLogger(action, refluxStores, [...path, actionName])
    }
  })
}
