import React from "react"

import cx from "classnames"

import { mainContentId } from "@Constants/elementIds"
import styles from "./PageLayout.scss"

import { Drawer } from "../Drawer/Drawer"
import { Dossier } from "../Dossier/Dossier"
import ErrorBoundary from "../../../containers/ErrorBoundary/ErrorBoundary"
import { ContentPadding } from "../ContentPadding"

export type DossierAndDrawerState = {
  dossierIsOpen: boolean
  drawerIsOpen: boolean
}

type DossierSettings = {
  isOpen: boolean
  content: React.ReactElement
  onClose?: () => void
  size?: "small" | "default"
}
type DrawerSettings = {
  heading?: React.ReactElement | string
  footer?: React.ReactElement | string
  isOpen: boolean
  content: React.ReactElement
  onToggle?: () => void
}

type PageLayoutProps = {
  children?: React.ReactNode
  dossierSettings?: DossierSettings
  drawerSettings?: DrawerSettings
  headingContent?: React.ReactElement
  colorScheme?: "default" | "admin"
  legacyClassnames?: string
  forceShowHeader?: boolean
  // In rare circumstances, we may want to display content that extends the full
  // width of the screen. In which case, you can set this property to true,
  // and use the ContentPadding component to selectively add back in those
  // margins where appropriate. eg.
  //
  // <PageLayout noHorizontalMargins>
  //   <ContentPadding><p>Some intro</p></ContentPadding>
  //   <SomeFullWidthComponent />
  //   <ContentPadding><p>Some conclusion</p></ContentPadding>
  // </PageLayout>
  noHorizontalMargins?: boolean
}

export const PageLayout: React.FunctionComponent<PageLayoutProps> = ({
  children,
  colorScheme = "default",
  headingContent,
  drawerSettings,
  dossierSettings,
  legacyClassnames,
  forceShowHeader,
  noHorizontalMargins,
}) => {
  return (
    <main
      className={cx([styles.root, legacyClassnames], {
        [styles.dossierOpen]: dossierSettings?.isOpen,
        [styles.dossierIsSmall]: dossierSettings?.size === "small",
        [styles.drawerOpen]: drawerSettings?.isOpen,
        [styles.hasDrawer]: !!drawerSettings,
      })}
      id={mainContentId}
    >
      {headingContent && (
        <div className={cx(styles.headingWrapper)}>{headingContent}</div>
      )}
      <ErrorBoundary>
        {!!children && (
          <div className={cx(styles.container)}>
            {noHorizontalMargins ? (
              children
            ) : (
              <ContentPadding>{children}</ContentPadding>
            )}
          </div>
        )}
        {!!dossierSettings && (
          <Dossier
            isOpen={dossierSettings.isOpen}
            hasDrawer={!!drawerSettings}
            onClose={dossierSettings.onClose}
            size={dossierSettings.size}
          >
            {dossierSettings.content}
          </Dossier>
        )}
        {!!drawerSettings && (
          <Drawer
            drawerHeading={drawerSettings.heading}
            isOpen={drawerSettings.isOpen}
            onToggle={drawerSettings.onToggle}
            drawerFooter={drawerSettings.footer}
          >
            {drawerSettings.content}
          </Drawer>
        )}
      </ErrorBoundary>
    </main>
  )
}
