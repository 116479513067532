/**
 * This is intended to be a short-lived page (that is publicly accessible) to enable
 * a Datadaog browser synthetic test for the BFF. Since provisioned concurrency
 * is not available on the current tooling for the BFF, a successful experiment
 * pinging the BFF directly eradicated cold starts and the latency they were causing.
 *
 * As the BFF deploys a unique build on each change to it, we cannot dynamically target
 * the master version of it. By creating a public page that calls the heartbeat BFF
 * endpoint, we can get around this limitation.
 */
import React from "react"
import { Route } from "react-router-dom"

const HeartbeatApp = React.lazy(
  () => import(/* webpackChunkName: "heartbeat" */ "./heartbeat")
)

export const heartbeatRoute = (
  <Route path="/heartbeat" exact component={HeartbeatApp} />
)
