import _ from "lodash"

export default class BaseModel {
  /**
   * @param {Object} props - the properties of the model object
   *
   * @example
   *
   *    class MyModel extends BaseModel { }
   *
   *    const myObj = new MyModel({
   *      prop1: value1,
   *      prop2: value2,
   *      // ...
   *    });
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(props: any) {
    _.assign(this, props)
  }

  /**
   * Returns a new instance of the derived class with the given properties.
   * Alternative to the constructor for easier use in a functional context.
   *
   * @param {Object} props - the properties of the model object
   * @return {?} an instance of the derived class
   *
   * @example
   *
   *    class MyModel extends BaseModel { }
   *
   *    const myObj = someObjects.map(MyModel.of);
   *
   */
  static get of() {
    // We write this as a static getter that returns a function instead of as
    // a static method to ensure that the derived class (which shows up as
    // `this` in static methods/getters) is bound, no matter how `of` is called.
    //
    // For instance, considering the example in the JSDoc above, `of` being a
    // getter means that the expression `MyModel.of` binds `MyModel` before being
    // passed into `map`. `map` in that example will call the mapping function
    // without any context, so pre-binding `MyModel` is necessary to have it
    // available in the function.
    //
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const DerivedClass = this
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (props: any) => new DerivedClass(props)
  }

  /**
   * Returns a new instance of the derived class with the properties of the current
   * object, updated with the given props.
   *
   * @param {Object} props - the properties to add to the new object
   * @return {?} an instance of the derived class
   *
   * @example
   *
   *    class MyModel extends BaseModel {};
   *
   *    const myObj = new MyModel({
   *      someValue: 24,
   *      someOtherValue: 56,
   *    });
   *
   *    const myChangedObj = myObj.with({
   *      someValue: 36,
   *    });
   *
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  with(props: any) {
    // @ts-expect-error - This should be typed
    return new this.constructor({ ...this, ...props })
  }
}
