import Reflux from "reflux-core"
import SteadyfootAgent from "./lib/SteadyfootAgent"
import UIActions from "./UIActions"
import SearchAgent from "./lib/SearchAgent"
import {
  addLoadingState,
  addToasts,
  extractResponseBody,
  extractResponseKey,
} from "./lib/apiActionHelpers"
import strings from "../locale/strings"
import endpoints from "../constants/endpointsDeprecated"

const { GENERAL_URLS, REVIEWS_URLS, SAVED_VIEW_URLS, ADMIN_FEEDBACK_URLS } =
  endpoints
const { FEEDBACK_URL } = GENERAL_URLS
const { ADMIN_FEEDBACK_URL } = ADMIN_FEEDBACK_URLS
const { REVIEWS_URL, INVITATIONS_URL, REVIEW_SHARES_URL } = REVIEWS_URLS
const { SAVED_VIEW_URL } = SAVED_VIEW_URLS
const agent = SteadyfootAgent.defaultInstance

const ASYNC_SETTINGS = {
  children: ["completed", "failed"],
}

const givenFeedbackSearchAgent = new SearchAgent({
  requestAgent: agent,
  resourcePath: `${FEEDBACK_URL}/sent`,
  resourceName: "feedbacks",
  pageSize: 25,
})

const praiseSearchAgent = new SearchAgent({
  requestAgent: agent,
  resourcePath: REVIEWS_URL,
  resourceName: "reviews",
  pageSize: 10,
})

const FeedbackActions = Reflux.createActions({
  loadReviews: ASYNC_SETTINGS,
  loadReviewsGivenByRevieweeId: ASYNC_SETTINGS,
  localClearReviewsGivenByRevieweeId: {},

  loadReviewsGiven: ASYNC_SETTINGS,
  pageReviewsGiven: ASYNC_SETTINGS,

  loadPublicPraise: ASYNC_SETTINGS,
  pagePublicPraise: ASYNC_SETTINGS,

  shareReview: ASYNC_SETTINGS,
  loadReview: ASYNC_SETTINGS,
  loadShare: ASYNC_SETTINGS,
  loadReplies: ASYNC_SETTINGS,
  comment: ASYNC_SETTINGS,
  view: ASYNC_SETTINGS,
  request: ASYNC_SETTINGS,
  reply: ASYNC_SETTINGS,

  updateReactions: ASYNC_SETTINGS,

  localClear: {},
  loadingMRF: ASYNC_SETTINGS,
})

FeedbackActions.loadReviews.listenAndPromise(
  ({ page = 1, perPage = 5, viewedOnly } = {}) =>
    addLoadingState(
      page === 1,
      addToasts(
        { defaultError: strings.myFeedback.errorLoadingFeedback },
        extractResponseBody(
          agent
            .get(REVIEWS_URL)
            .query({ per_page: perPage, page, viewed: viewedOnly || undefined })
        )
      )
    ).then((body) => ({ reviews: body.reviews, meta: body.meta }))
)

FeedbackActions.loadPublicPraise.listenAndPromise(() =>
  addLoadingState(true, searchPublicPraise())
)

FeedbackActions.pagePublicPraise.listenAndPromise(({ page = 1 }) =>
  searchPublicPraise({ page })
)

function searchPublicPraise({ page = 1 } = {}) {
  return praiseSearchAgent.search({
    searchParams: { everyone: true },
    page,
  })
}

FeedbackActions.loadReviewsGivenByRevieweeId.listenAndPromise(
  ({
    revieweeId,
    type = undefined,
    beforeDate,
    limit = 10,
    savedViewId = null,
    userId = null,
    isOnAdminDashboard = "false",
  }) => {
    const feedbackUrl =
      isOnAdminDashboard === "true" ? ADMIN_FEEDBACK_URL : FEEDBACK_URL

    const url = savedViewId
      ? `${SAVED_VIEW_URL}/${savedViewId}/feedbacks`
      : feedbackUrl
    return extractResponseKey(
      "feedbacks",
      agent.get(url).query({
        reviewee_id: revieweeId,
        user_id: userId,
        include_review_shares: true,
        type: type === "all" ? undefined : type,
        before_date: beforeDate,
        limit,
      })
    ).then((reviews) => ({ revieweeId, reviews, pageLimit: limit }))
  }
)

function searchReviewsGiven({ page, query, type }) {
  return givenFeedbackSearchAgent.search({
    searchParams: {
      q: query,
      type,
    },
    page,
  })
}

FeedbackActions.loadReviewsGiven.listenAndPromise((searchParams = {}) =>
  addLoadingState(true, searchReviewsGiven(searchParams))
)

FeedbackActions.pageReviewsGiven.listenAndPromise((searchParams) =>
  searchReviewsGiven(searchParams)
)

FeedbackActions.shareReview.listenAndPromise(
  ({ recipientIds, notes, reviewId, formatMessage }) =>
    addToasts(
      { success: formatMessage(strings.comments.share.sharedSuccess) },
      agent.post(REVIEW_SHARES_URL).send({
        review_share: {
          user_ids: recipientIds,
          notes,
          review_id: reviewId,
        },
      })
    ).then(
      () =>
        // Return the revewId so we can reload the review
        // TODO: Update SF to return the review_share
        reviewId
    )
)

FeedbackActions.loadShare.listenAndPromise(({ shareId }) =>
  extractResponseKey(
    "review_share",
    addToasts({}, agent.get(`${REVIEW_SHARES_URL}/${shareId}`))
  )
)

FeedbackActions.loadReview.listenAndPromise((reviewId) =>
  addToasts(
    {},
    extractResponseKey("review", agent.get(`${REVIEWS_URL}/${reviewId}`))
  )
)

/**
 * Comment on feedback
 * @param  {object} review
 * @param  {object} commentInfo
 *   {
 *     notes: string,
 *     score: 1
 *   }
 */

FeedbackActions.comment.listenAndPromise((review, commentInfo) =>
  extractResponseKey(
    "review",
    agent.put(`${REVIEWS_URL}/${review.id}`).send({
      review: {
        id: review.id,
        feedback: commentInfo,
      },
    })
  ).then(() => ({ ...review, reviewee_comment: commentInfo }))
)

FeedbackActions.view.preEmit = function (review) {
  FeedbackActions.view.completed(review)
  agent.post(`${REVIEWS_URL}/${review.id}/view`).end((err, res) => {
    if (err) {
      FeedbackActions.view.failed(review)
    } else {
      FeedbackActions.loadReview(review.id)
    }
  })
}

FeedbackActions.request.preEmit = function ({
  reviewers,
  question = null,
  successMessage,
  values = null,
}) {
  addLoadingState(
    true,
    agent
      .post(INVITATIONS_URL)
      .send({
        review_invitation: {
          ...(reviewers.length === 1
            ? {
                reviewer_email: reviewers[0].email,
              }
            : {
                reviewer_emails: reviewers.map((r) => r.email),
              }),
          question,
        },
      })
      .end((err, res) => {
        if (
          err ||
          (reviewers.length === 1
            ? !res.body.review_invitation
            : !res.body.review_invitations)
        ) {
          UIActions.error(
            (err &&
              err.response &&
              err.response.body &&
              err.response.body.error) ||
              strings.feedbackRequests.errorMessage
          )
          FeedbackActions.request.failed(res && res.body, err)
        } else {
          UIActions.success(successMessage, values)
          FeedbackActions.request.completed(res.body.review_invitation)
        }
      })
  )
}

FeedbackActions.loadReplies.listenAndPromise((review, perPage = 100) =>
  extractResponseKey(
    "followups",
    agent.get(`${REVIEWS_URL}/${review.id}/followups`).query({ perPage })
  ).then((followups) => ({ replies: followups, reviewId: review.id }))
)

FeedbackActions.reply.listenAndPromise((review, reply) =>
  extractResponseKey(
    "followup",
    agent.post(`${REVIEWS_URL}/${review.id}/followups`).send({
      followup: {
        body: reply,
      },
    })
  ).then((followup) => ({ reply: followup, reviewId: review.id }))
)

FeedbackActions.updateReactions.listenAndPromise(({ review, updates }) =>
  addToasts(
    { success: strings.feedbackReactions.successMessage },
    extractResponseKey(
      "review",
      agent.put(`${REVIEWS_URL}/${review.id}`).send({
        review: {
          reactions_attributes: updates,
        },
      })
    )
  )
)

FeedbackActions.loadingMRF.listenAndPromise(
  ({
    revieweeId,
    type = undefined,
    beforeDate,
    limit = 10,
    userId = null,
    isOnAdminDashboard = false,
  }) => {
    const feedbackUrl = isOnAdminDashboard ? ADMIN_FEEDBACK_URL : FEEDBACK_URL
    return extractResponseKey(
      "feedbacks",
      agent.get(feedbackUrl).query({
        reviewee_id: revieweeId,
        user_id: userId,
        include_review_shares: true,
        type: type === "all" ? undefined : type,
        before_date: beforeDate,
        limit,
      })
    )
  }
)

export default FeedbackActions
