import Reflux from "reflux-core"
import _ from "lodash"
import AdminTemplateActions from "../refluxActions/AdminTemplateActions"

const AdminTemplateStore = Reflux.createStore({
  init() {
    this.data = {
      templates: null,
      policies: null,
    }

    this.listenTo(AdminTemplateActions.getPolicies.completed, this.getPolicies)
    this.listenTo(
      AdminTemplateActions.getTemplates.completed,
      this.getTemplates
    )
    this.listenTo(
      AdminTemplateActions.updateMRFTemplate.completed,
      this.replaceMRFTemplate
    )
    this.listenTo(
      AdminTemplateActions.createMRFTemplate.completed,
      this.replaceMRFTemplate
    )
    this.listenTo(
      AdminTemplateActions.deleteTemplate.completed,
      this.deleteTemplates
    )
  },

  deleteTemplates(template) {
    const index = _.findIndex(this.data.templates, { id: template.id })
    const { templates } = this.data
    this.data.templates.splice(index, 1)
    this.data = {
      ...this.data,
      templates: templates,
    }
    this.trigger(this.data)
  },

  replaceMRFTemplate(template) {
    const index = _.findIndex(this.data.templates, { id: template.id })
    let { templates } = this.data
    if (index >= 0) {
      this.data.templates.splice(index, 1, template)
    } else {
      templates = _.union(templates, [template])
    }
    this.data = {
      ...this.data,
      templates: templates,
    }

    this.trigger(this.data)
  },

  getTemplates(templates) {
    this.data = {
      ...this.data,
      templates,
    }
    this.trigger(this.data)
  },

  getPolicies(policies) {
    this.data = {
      ...this.data,
      policies,
    }
    this.trigger(this.data)
  },

  getInitialState() {
    return this.data
  },
})

export default AdminTemplateStore
