/* eslint-disable @typescript-eslint/no-explicit-any */

import _ from "lodash"
import { DeepPartial } from "../types/utils"

const mapArrayOrObject = (arrOrObj: any[] | object, fn: (val: any) => any) => {
  if (_.isArray(arrOrObj)) {
    return arrOrObj.map(fn)
  } else {
    return _.mapValues(arrOrObj, fn)
  }
}

// Takes a nested object, and converts any ImmutableJS object into regular
// js objects
// No need to pass the `objectReferencePath` parameter, that's for internal
// recursive purposes.
export const simplifyImmutableJsObject = (
  obj: any,
  circularReferenceReplacement: any = null,
  objectReferencePath: object[] = []
): any => {
  if (!obj) return obj
  if (obj.toJS) return obj.toJS()
  if (typeof obj !== "object") return obj

  // If the object has a cyclic object reference, this recursive function will
  // run to infinity.
  if (objectReferencePath.includes(obj)) return circularReferenceReplacement

  return mapArrayOrObject(obj, (val) => {
    if (val && val.toJS) {
      return val.toJS()
    } else if (typeof val === "object") {
      return simplifyImmutableJsObject(val, circularReferenceReplacement, [
        ...objectReferencePath,
        obj,
      ])
    }
    return val
  })
}

// See `removeEmptyValues` below
const removeEmptyValuesRecursive = <T>(
  val: T,
  options: { removeEmptyStrings?: boolean },
  level: number
): any => {
  if (_.isArray(val)) {
    const newArr = val.map((v) =>
      removeEmptyValuesRecursive(v, options, level + 1)
    )
    if (newArr.every((v) => v == null)) {
      return level === 0 ? [] : undefined
    } else {
      return newArr
    }
  } else if (_.isObject(val)) {
    const entries = Object.entries(val)
      .map(([key, val]) => [
        key,
        removeEmptyValuesRecursive(val, options, level + 1),
      ])
      .filter(([_, val]) => val != null)

    if (entries.length === 0) {
      return level === 0 ? {} : undefined
    } else {
      return Object.fromEntries(entries)
    }
  } else if (
    options.removeEmptyStrings &&
    typeof val === "string" &&
    val === ""
  ) {
    return undefined
  } else {
    // eg. strings, numbers, booleans
    return val
  }
}

/**
 * Takes in an object or array, and removes any value that is `undefined`, `null`, `[]`, or `{}`
 * It will also remove an object/array, if every value in that object/array is empty.
 *
 * Use case: for our validation, we want to return an object that shows the
 * appropriate errors. It isn't a huge deal if we have a bunch of undefined
 * entries - the validations will still function fine. It just make the unit
 * tests a bit messy.
 *
 * Note: this function was designed for plain old data structures. If you pass in classes,
 * or anything that plays with `prototype`, expect unexpected results.
 */
export const removeEmptyValues = <T>(
  val: T & (object | any[]),
  options: { removeEmptyStrings?: boolean } = {}
): DeepPartial<T> => {
  return removeEmptyValuesRecursive(val, options, 0) as DeepPartial<T>
}

/**
 * Takes in a list, and converts it to a key value object. Each item in that
 * list much have an `id` value.
 */
export const listToKeyValueObject = <
  T extends object & { id: number | string }
>(
  list: T[]
): Record<string | number, T> => {
  const keyValueObject: Record<string | number, T> = {}
  for (const item of list) {
    keyValueObject[item.id] = item
  }
  return keyValueObject
}

/**
 * Takes a list of ids, and a key value, and maps the list with the values in
 * the key value store.
 * Any values not found in the supplied key/value object will appear as `undefined`.
 */
export const mapToValues = <T extends object>(
  list: (number | string)[],
  keyValueObject: Record<string | number, T>
): T[] => {
  return list.map((id) => keyValueObject[id])
}
