import moment from "moment"
import { RequireAtLeastOne } from "@Types/utils"
import LaunchDarklyFeatureFlags, {
  ILaunchDarklyFlagsAndVariations,
} from "@Constants/launchDarklyFlags"
import strings from "../../../src/locale/strings"
import { ConfigurationOptions } from "../../../src/constants/configurationOptions"
import FeatureFlags from "../../../src/constants/featureFlags"
import { CurrentUser, Department } from "../../types/User"

/**
 * Move all auxiliar functions to get user information that we use in the app.
 * This was moved from ../src/models/User.ts.
 * The next step will be to deprecate the mentioned file and also deprecate the legacy context api.
 */

export const getDisplayName = (
  // There seems to be a number of different User type object shapes. This should allow some flexibility to use different user types whilst maintaining type safety
  user: RequireAtLeastOne<{
    best_name: string
    full_name: string | null
    email: string
  }> & { [key: string]: unknown }
) => {
  return (user.best_name || user.full_name || user.email) as string
}

export const getFirstName = (user: CurrentUser, emailOk?: boolean) => {
  return (
    user.best_name ||
    (user.full_name && user.full_name.trim().split(" ")[0]) ||
    (emailOk ? user.email : null)
  )
}

export const getLastName = (user: CurrentUser) => {
  return user.full_name && user.full_name.trim().split(" ")[1]
}

export const getFullName = (user: CurrentUser) => {
  return user.full_name || user.email
}

export const getJobTitle = (user: CurrentUser) => {
  return user.job_title_name
}

export const getDomain = (user: CurrentUser) => {
  return user.email.split("@")[1]
}

export const isAdmin = (user: CurrentUser | null) => {
  return Boolean(user?.role_names.includes("admin"))
}

export const isHrbp = (user: CurrentUser | null) => {
  return Boolean(user?.role_names.includes("hr_business_partner"))
}

export const getHrbPartners = (user: CurrentUser) => {
  return user.hr_business_partners
    ? user.hr_business_partners.map((jobTitle) => jobTitle)
    : []
}

export const isDeptManager = (user: CurrentUser) => {
  return user.department_managers.length > 0
}

export const managesDepartment = (
  user: CurrentUser,
  department: Department
) => {
  const { department_managers: departmentManagers } = user

  return (
    !!departmentManagers &&
    departmentManagers.some(
      (departmentManager) => departmentManager.department_id === department.id
    )
  )
}

export const getManagedDepartments = (user: CurrentUser) => {
  return user.department_managers
    ? user.department_managers.map(({ department }) => department)
    : []
}

export const equals = (a: CurrentUser, b: CurrentUser) => {
  return a.id === b.id
}

export const isManagerOf = (manager: CurrentUser, user: CurrentUser) => {
  return user.manager_email && user.manager_email === manager.email
}

export const getUserDepartment = (user: CurrentUser) => {
  return user.job_title ? user.job_title.parent_job_title : undefined
}

export const getDisplayAccountType = (user: CurrentUser) => {
  return isAdmin(user)
    ? strings.general.admin
    : isHrbp(user)
    ? strings.general.hrbp
    : isDeptManager(user)
    ? strings.general.deptManager
    : strings.general.employee
}

export const isDeactivated = (user: {
  deleted_at?: string | null
  deactivated_at?: string | null
}) => {
  return !!user.deleted_at || moment().isAfter(user.deactivated_at || "")
}

/**
 * More info: docs/featureFlags.md
 *
 * @static
 * @param {(CurrentUser | CurrentUserDeprecated)} user
 * @param {FeatureFlags} flag
 * @returns {Boolean} User has requested flag
 * @memberof User
 */
export const hasFlag = (user: CurrentUser, flag: FeatureFlags) => {
  return Boolean(user?.flags?.[flag])
}

export const hasLaunchDarklyFlag = <T extends LaunchDarklyFeatureFlags>(
  user: CurrentUser,
  flag: T,
  variation: ILaunchDarklyFlagsAndVariations[T]
) => {
  return user.launchdarkly_flags[flag] === variation
}

export const hasConfigOption = (
  user: CurrentUser,
  option: ConfigurationOptions
) => {
  return Boolean(
    user?.configuration_options_obj?.map((o) => o.name).includes(option)
  )
}

export const getConfigurationOption = (
  user: CurrentUser,
  option: ConfigurationOptions
) => {
  return user?.configuration_options_obj?.find((o) => o.name === option)
}

export const hasAdminSettings = (user: CurrentUser) => {
  return user.role_names.some(
    (role) => ["admin", "hr_business_partner"].indexOf(role) >= 0
  )
}

export const getGoalCycle = (user: CurrentUser) => {
  // Note: subtract one from months because Date and moment's months are zero-based
  const cycleStart = moment({
    year: moment().year(),
    month: user.company.goal_cycle_month - 1,
    date: user.company.goal_cycle_day,
  })

  const monthsFromStart = moment().diff(cycleStart, "months", true)
  const periodOffset = Math.floor(
    monthsFromStart / user.company.goal_cycle_length
  )

  const startDate = moment(cycleStart)
    .add(periodOffset * user.company.goal_cycle_length, "months")
    .startOf("day")

  const endDate = moment(startDate)
    .add(user.company.goal_cycle_length, "months")
    .subtract(1, "day")
    .startOf("day")

  return {
    start: startDate.toDate(),
    end: endDate.toDate(),
  }
}
