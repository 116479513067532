/**
 * @file Plugin for the remark Markdown processor that restricts the parser to only recognize a
 * given subset of Markdown. Configured with two arrays: `block` and `inline`, which represent
 * the names of block and inline tokenizers, respectively, that are allowed. The full list of
 * tokenizers can be found at https://github.com/remarkjs/remark/tree/master/packages/remark-parse.
 *
 * @example
 *    import remark from 'remark';
 *    import remarkSyntaxWhitelist from './remarkSyntaxWhitelist';
 *
 *    // Will only parse paragraphs, lists, and links. Everything else will show up as plain text.
 *    remark()
 *      .use(remarkSyntaxWhitelist, {
 *        block: ['newline', 'list', 'paragraph'],
 *        inline: ['escape', 'link', 'break', 'text'],
 *      })
 */

export default function remarkSyntaxWhitelist({
  block = [],
  inline = [],
} = {}) {
  // @ts-expect-error - This should be typed
  Object.assign(this.Parser.prototype, {
    blockMethods: block,
    inlineMethods: inline,
  })
}
