import React, { useContext, useEffect, useState } from "react"
import moment from "moment"
import { CurrentUserContext } from "@Redux/reducers/currentUser"
import { replaceCustomTerms } from "@Locale/translations"
import { IntlContext, IntlProvider } from "react-intl"

const momentLocale = (locale: string) => {
  switch (locale) {
    case "zh":
      return "zh-cn"
    case "kr":
      return "ko"
    default:
      return locale
  }
}

type LocalisedMessages = { [key: string]: string | null }

export type LocaleDataBundle = LocalisedMessages

export type LocaleDataLoader = {
  [key: string]: () => Promise<LocaleDataBundle>
}

export const StringsProvider: React.FunctionComponent<{
  loaders: LocaleDataLoader
}> = ({ children, loaders }) => {
  const [bundle, setBundle] = useState<{
    messages?: LocalisedMessages
    locale: string
  }>({ locale: "en" })

  const intlContext = useContext(IntlContext)
  const currentUser = useContext(CurrentUserContext)
  useEffect(() => {
    const localeCode = currentUser == null ? "en" : currentUser.locale
    const shortLocaleCode = localeCode.split("-")[0]
    const locale = loaders[localeCode] ? localeCode : shortLocaleCode
    const loaderFn = loaders[locale]
    if (loaderFn == null) {
      return
    }
    loaderFn().then((module) => {
      // update moment's locale when it changes on the user
      moment.locale(momentLocale(locale))

      const messages = replaceCustomTerms(
        module || {},
        currentUser?.custom_terms || {}
      )

      setBundle({
        locale,
        messages: {
          ...((intlContext?.messages || {}) as Record<string, string>),
          ...messages,
        },
      })
    })
  }, [currentUser, loaders, intlContext])

  return (
    <IntlProvider
      key={bundle.locale}
      locale={bundle.locale}
      messages={(bundle.messages || {}) as Record<string, string>}
      textComponent={"span"}
    >
      {children}
    </IntlProvider>
  )
}
