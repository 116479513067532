// We were using the emojione package but it was 300KiB and we weren't using much of it,
// so we just took what we needed from this file https://github.com/joypixels/emojione/blob/master/lib/js/emojione.js

const EMOJI_LIST: { [key: string]: string } = {
  ":100:": "1f4af",
  ":1234:": "1f522",
  ":8ball:": "1f3b1",
  ":a:": "1f170",
  ":ab:": "1f18e",
  ":abacus:": "1f9ee",
  ":abc:": "1f524",
  ":abcd:": "1f521",
  ":accept:": "1f251",
  ":adult:": "1f9d1",
  ":adult_tone1:": "1f9d1-1f3fb",
  ":adult_tone2:": "1f9d1-1f3fc",
  ":adult_tone3:": "1f9d1-1f3fd",
  ":adult_tone4:": "1f9d1-1f3fe",
  ":adult_tone5:": "1f9d1-1f3ff",
  ":aerial_tramway:": "1f6a1",
  ":airplane:": "2708",
  ":airplane_arriving:": "1f6ec",
  ":airplane_departure:": "1f6eb",
  ":airplane_small:": "1f6e9",
  ":alarm_clock:": "23f0",
  ":alembic:": "2697",
  ":alien:": "1f47d",
  ":ambulance:": "1f691",
  ":amphora:": "1f3fa",
  ":anchor:": "2693",
  ":angel:": "1f47c",
  ":angel_tone1:": "1f47c-1f3fb",
  ":angel_tone2:": "1f47c-1f3fc",
  ":angel_tone3:": "1f47c-1f3fd",
  ":angel_tone4:": "1f47c-1f3fe",
  ":angel_tone5:": "1f47c-1f3ff",
  ":anger:": "1f4a2",
  ":anger_right:": "1f5ef",
  ":angry:": "1f620",
  ":anguished:": "1f627",
  ":ant:": "1f41c",
  ":apple:": "1f34e",
  ":aquarius:": "2652",
  ":aries:": "2648",
  ":arrow_backward:": "25c0",
  ":arrow_double_down:": "23ec",
  ":arrow_double_up:": "23eb",
  ":arrow_down:": "2b07",
  ":arrow_down_small:": "1f53d",
  ":arrow_forward:": "25b6",
  ":arrow_heading_down:": "2935",
  ":arrow_heading_up:": "2934",
  ":arrow_left:": "2b05",
  ":arrow_lower_left:": "2199",
  ":arrow_lower_right:": "2198",
  ":arrow_right:": "27a1",
  ":arrow_right_hook:": "21aa",
  ":arrow_up:": "2b06",
  ":arrow_up_down:": "2195",
  ":arrow_up_small:": "1f53c",
  ":arrow_upper_left:": "2196",
  ":arrow_upper_right:": "2197",
  ":arrows_clockwise:": "1f503",
  ":arrows_counterclockwise:": "1f504",
  ":art:": "1f3a8",
  ":articulated_lorry:": "1f69b",
  ":asterisk:": "002a-fe0f-20e3",
  ":astonished:": "1f632",
  ":athletic_shoe:": "1f45f",
  ":atm:": "1f3e7",
  ":atom:": "269b",
  ":avocado:": "1f951",
  ":b:": "1f171",
  ":baby:": "1f476",
  ":baby_bottle:": "1f37c",
  ":baby_chick:": "1f424",
  ":baby_symbol:": "1f6bc",
  ":baby_tone1:": "1f476-1f3fb",
  ":baby_tone2:": "1f476-1f3fc",
  ":baby_tone3:": "1f476-1f3fd",
  ":baby_tone4:": "1f476-1f3fe",
  ":baby_tone5:": "1f476-1f3ff",
  ":back:": "1f519",
  ":bacon:": "1f953",
  ":badger:": "1f9a1",
  ":badminton:": "1f3f8",
  ":bagel:": "1f96f",
  ":baggage_claim:": "1f6c4",
  ":bald:": "1f9b2",
  ":balloon:": "1f388",
  ":ballot_box:": "1f5f3",
  ":ballot_box_with_check:": "2611",
  ":bamboo:": "1f38d",
  ":banana:": "1f34c",
  ":bangbang:": "203c",
  ":bank:": "1f3e6",
  ":bar_chart:": "1f4ca",
  ":barber:": "1f488",
  ":baseball:": "26be",
  ":basket:": "1f9fa",
  ":basketball:": "1f3c0",
  ":bat:": "1f987",
  ":bath:": "1f6c0",
  ":bath_tone1:": "1f6c0-1f3fb",
  ":bath_tone2:": "1f6c0-1f3fc",
  ":bath_tone3:": "1f6c0-1f3fd",
  ":bath_tone4:": "1f6c0-1f3fe",
  ":bath_tone5:": "1f6c0-1f3ff",
  ":bathtub:": "1f6c1",
  ":battery:": "1f50b",
  ":beach:": "1f3d6",
  ":beach_umbrella:": "26f1",
  ":bear:": "1f43b",
  ":bearded_person:": "1f9d4",
  ":bearded_person_tone1:": "1f9d4-1f3fb",
  ":bearded_person_tone2:": "1f9d4-1f3fc",
  ":bearded_person_tone3:": "1f9d4-1f3fd",
  ":bearded_person_tone4:": "1f9d4-1f3fe",
  ":bearded_person_tone5:": "1f9d4-1f3ff",
  ":bed:": "1f6cf",
  ":bee:": "1f41d",
  ":beer:": "1f37a",
  ":beers:": "1f37b",
  ":beetle:": "1f41e",
  ":beginner:": "1f530",
  ":bell:": "1f514",
  ":bellhop:": "1f6ce",
  ":bento:": "1f371",
  ":bike:": "1f6b2",
  ":bikini:": "1f459",
  ":billed_cap:": "1f9e2",
  ":biohazard:": "2623",
  ":bird:": "1f426",
  ":birthday:": "1f382",
  ":black_circle:": "26ab",
  ":black_heart:": "1f5a4",
  ":black_joker:": "1f0cf",
  ":black_large_square:": "2b1b",
  ":black_medium_small_square:": "25fe",
  ":black_medium_square:": "25fc",
  ":black_nib:": "2712",
  ":black_small_square:": "25aa",
  ":black_square_button:": "1f532",
  ":blond-haired_man:": "1f471-200d-2642-fe0f",
  ":blond-haired_man_tone1:": "1f471-1f3fb-200d-2642-fe0f",
  ":blond-haired_man_tone2:": "1f471-1f3fc-200d-2642-fe0f",
  ":blond-haired_man_tone3:": "1f471-1f3fd-200d-2642-fe0f",
  ":blond-haired_man_tone4:": "1f471-1f3fe-200d-2642-fe0f",
  ":blond-haired_man_tone5:": "1f471-1f3ff-200d-2642-fe0f",
  ":blond-haired_woman:": "1f471-200d-2640-fe0f",
  ":blond-haired_woman_tone1:": "1f471-1f3fb-200d-2640-fe0f",
  ":blond-haired_woman_tone2:": "1f471-1f3fc-200d-2640-fe0f",
  ":blond-haired_woman_tone3:": "1f471-1f3fd-200d-2640-fe0f",
  ":blond-haired_woman_tone4:": "1f471-1f3fe-200d-2640-fe0f",
  ":blond-haired_woman_tone5:": "1f471-1f3ff-200d-2640-fe0f",
  ":blond_haired_person:": "1f471",
  ":blond_haired_person_tone1:": "1f471-1f3fb",
  ":blond_haired_person_tone2:": "1f471-1f3fc",
  ":blond_haired_person_tone3:": "1f471-1f3fd",
  ":blond_haired_person_tone4:": "1f471-1f3fe",
  ":blond_haired_person_tone5:": "1f471-1f3ff",
  ":blossom:": "1f33c",
  ":blowfish:": "1f421",
  ":blue_book:": "1f4d8",
  ":blue_car:": "1f699",
  ":blue_circle:": "1f535",
  ":blue_heart:": "1f499",
  ":blush:": "1f60a",
  ":boar:": "1f417",
  ":bomb:": "1f4a3",
  ":bone:": "1f9b4",
  ":book:": "1f4d6",
  ":bookmark:": "1f516",
  ":bookmark_tabs:": "1f4d1",
  ":books:": "1f4da",
  ":boom:": "1f4a5",
  ":boot:": "1f462",
  ":bouquet:": "1f490",
  ":bow_and_arrow:": "1f3f9",
  ":bowl_with_spoon:": "1f963",
  ":bowling:": "1f3b3",
  ":boxing_glove:": "1f94a",
  ":boy:": "1f466",
  ":boy_tone1:": "1f466-1f3fb",
  ":boy_tone2:": "1f466-1f3fc",
  ":boy_tone3:": "1f466-1f3fd",
  ":boy_tone4:": "1f466-1f3fe",
  ":boy_tone5:": "1f466-1f3ff",
  ":brain:": "1f9e0",
  ":bread:": "1f35e",
  ":breast_feeding:": "1f931",
  ":breast_feeding_tone1:": "1f931-1f3fb",
  ":breast_feeding_tone2:": "1f931-1f3fc",
  ":breast_feeding_tone3:": "1f931-1f3fd",
  ":breast_feeding_tone4:": "1f931-1f3fe",
  ":breast_feeding_tone5:": "1f931-1f3ff",
  ":bricks:": "1f9f1",
  ":bride_with_veil:": "1f470",
  ":bride_with_veil_tone1:": "1f470-1f3fb",
  ":bride_with_veil_tone2:": "1f470-1f3fc",
  ":bride_with_veil_tone3:": "1f470-1f3fd",
  ":bride_with_veil_tone4:": "1f470-1f3fe",
  ":bride_with_veil_tone5:": "1f470-1f3ff",
  ":bridge_at_night:": "1f309",
  ":briefcase:": "1f4bc",
  ":broccoli:": "1f966",
  ":broken_heart:": "1f494",
  ":broom:": "1f9f9",
  ":bug:": "1f41b",
  ":bulb:": "1f4a1",
  ":bullettrain_front:": "1f685",
  ":bullettrain_side:": "1f684",
  ":burrito:": "1f32f",
  ":bus:": "1f68c",
  ":busstop:": "1f68f",
  ":bust_in_silhouette:": "1f464",
  ":busts_in_silhouette:": "1f465",
  ":butterfly:": "1f98b",
  ":cactus:": "1f335",
  ":cake:": "1f370",
  ":calendar:": "1f4c6",
  ":calendar_spiral:": "1f5d3",
  ":call_me:": "1f919",
  ":call_me_tone1:": "1f919-1f3fb",
  ":call_me_tone2:": "1f919-1f3fc",
  ":call_me_tone3:": "1f919-1f3fd",
  ":call_me_tone4:": "1f919-1f3fe",
  ":call_me_tone5:": "1f919-1f3ff",
  ":calling:": "1f4f2",
  ":camel:": "1f42b",
  ":camera:": "1f4f7",
  ":camera_with_flash:": "1f4f8",
  ":camping:": "1f3d5",
  ":cancer:": "264b",
  ":candle:": "1f56f",
  ":candy:": "1f36c",
  ":canned_food:": "1f96b",
  ":canoe:": "1f6f6",
  ":capital_abcd:": "1f520",
  ":capricorn:": "2651",
  ":card_box:": "1f5c3",
  ":card_index:": "1f4c7",
  ":carousel_horse:": "1f3a0",
  ":carrot:": "1f955",
  ":cat2:": "1f408",
  ":cat:": "1f431",
  ":cd:": "1f4bf",
  ":chains:": "26d3",
  ":champagne:": "1f37e",
  ":champagne_glass:": "1f942",
  ":chart:": "1f4b9",
  ":chart_with_downwards_trend:": "1f4c9",
  ":chart_with_upwards_trend:": "1f4c8",
  ":checkered_flag:": "1f3c1",
  ":cheese:": "1f9c0",
  ":cherries:": "1f352",
  ":cherry_blossom:": "1f338",
  ":chess_pawn:": "265f-fe0f",
  ":chestnut:": "1f330",
  ":chicken:": "1f414",
  ":child:": "1f9d2",
  ":child_tone1:": "1f9d2-1f3fb",
  ":child_tone2:": "1f9d2-1f3fc",
  ":child_tone3:": "1f9d2-1f3fd",
  ":child_tone4:": "1f9d2-1f3fe",
  ":child_tone5:": "1f9d2-1f3ff",
  ":children_crossing:": "1f6b8",
  ":chipmunk:": "1f43f",
  ":chocolate_bar:": "1f36b",
  ":chopsticks:": "1f962",
  ":christmas_tree:": "1f384",
  ":church:": "26ea",
  ":cinema:": "1f3a6",
  ":circus_tent:": "1f3aa",
  ":city_dusk:": "1f306",
  ":city_sunset:": "1f307",
  ":cityscape:": "1f3d9",
  ":cl:": "1f191",
  ":clap:": "1f44f",
  ":clap_tone1:": "1f44f-1f3fb",
  ":clap_tone2:": "1f44f-1f3fc",
  ":clap_tone3:": "1f44f-1f3fd",
  ":clap_tone4:": "1f44f-1f3fe",
  ":clap_tone5:": "1f44f-1f3ff",
  ":clapper:": "1f3ac",
  ":classical_building:": "1f3db",
  ":clipboard:": "1f4cb",
  ":clock1030:": "1f565",
  ":clock10:": "1f559",
  ":clock1130:": "1f566",
  ":clock11:": "1f55a",
  ":clock1230:": "1f567",
  ":clock12:": "1f55b",
  ":clock130:": "1f55c",
  ":clock1:": "1f550",
  ":clock230:": "1f55d",
  ":clock2:": "1f551",
  ":clock330:": "1f55e",
  ":clock3:": "1f552",
  ":clock430:": "1f55f",
  ":clock4:": "1f553",
  ":clock530:": "1f560",
  ":clock5:": "1f554",
  ":clock630:": "1f561",
  ":clock6:": "1f555",
  ":clock730:": "1f562",
  ":clock7:": "1f556",
  ":clock830:": "1f563",
  ":clock8:": "1f557",
  ":clock930:": "1f564",
  ":clock9:": "1f558",
  ":clock:": "1f570",
  ":closed_book:": "1f4d5",
  ":closed_lock_with_key:": "1f510",
  ":closed_umbrella:": "1f302",
  ":cloud:": "2601",
  ":cloud_lightning:": "1f329",
  ":cloud_rain:": "1f327",
  ":cloud_snow:": "1f328",
  ":cloud_tornado:": "1f32a",
  ":clown:": "1f921",
  ":clubs:": "2663",
  ":coat:": "1f9e5",
  ":cocktail:": "1f378",
  ":coconut:": "1f965",
  ":coffee:": "2615",
  ":coffin:": "26b0",
  ":cold_face:": "1f976",
  ":cold_sweat:": "1f630",
  ":comet:": "2604",
  ":compass:": "1f9ed",
  ":compression:": "1f5dc",
  ":computer:": "1f4bb",
  ":confetti_ball:": "1f38a",
  ":confounded:": "1f616",
  ":confused:": "1f615",
  ":congratulations:": "3297",
  ":construction:": "1f6a7",
  ":construction_site:": "1f3d7",
  ":construction_worker:": "1f477",
  ":construction_worker_tone1:": "1f477-1f3fb",
  ":construction_worker_tone2:": "1f477-1f3fc",
  ":construction_worker_tone3:": "1f477-1f3fd",
  ":construction_worker_tone4:": "1f477-1f3fe",
  ":construction_worker_tone5:": "1f477-1f3ff",
  ":control_knobs:": "1f39b",
  ":convenience_store:": "1f3ea",
  ":cookie:": "1f36a",
  ":cooking:": "1f373",
  ":cool:": "1f192",
  ":corn:": "1f33d",
  ":couch:": "1f6cb",
  ":couple:": "1f46b",
  ":couple_mm:": "1f468-200d-2764-fe0f-200d-1f468",
  ":couple_with_heart:": "1f491",
  ":couple_with_heart_woman_man:": "1f469-200d-2764-fe0f-200d-1f468",
  ":couple_ww:": "1f469-200d-2764-fe0f-200d-1f469",
  ":couplekiss:": "1f48f",
  ":cow2:": "1f404",
  ":cow:": "1f42e",
  ":cowboy:": "1f920",
  ":crab:": "1f980",
  ":crayon:": "1f58d",
  ":credit_card:": "1f4b3",
  ":crescent_moon:": "1f319",
  ":cricket:": "1f997",
  ":cricket_game:": "1f3cf",
  ":crocodile:": "1f40a",
  ":croissant:": "1f950",
  ":cross:": "271d",
  ":crossed_flags:": "1f38c",
  ":crossed_swords:": "2694",
  ":crown:": "1f451",
  ":cruise_ship:": "1f6f3",
  ":cry:": "1f622",
  ":crying_cat_face:": "1f63f",
  ":crystal_ball:": "1f52e",
  ":cucumber:": "1f952",
  ":cup_with_straw:": "1f964",
  ":cupcake:": "1f9c1",
  ":cupid:": "1f498",
  ":curling_stone:": "1f94c",
  ":curly_haired:": "1f9b1",
  ":curly_loop:": "27b0",
  ":currency_exchange:": "1f4b1",
  ":curry:": "1f35b",
  ":custard:": "1f36e",
  ":customs:": "1f6c3",
  ":cut_of_meat:": "1f969",
  ":cyclone:": "1f300",
  ":dagger:": "1f5e1",
  ":dancer:": "1f483",
  ":dancer_tone1:": "1f483-1f3fb",
  ":dancer_tone2:": "1f483-1f3fc",
  ":dancer_tone3:": "1f483-1f3fd",
  ":dancer_tone4:": "1f483-1f3fe",
  ":dancer_tone5:": "1f483-1f3ff",
  ":dango:": "1f361",
  ":dark_sunglasses:": "1f576",
  ":dart:": "1f3af",
  ":dash:": "1f4a8",
  ":date:": "1f4c5",
  ":deciduous_tree:": "1f333",
  ":deer:": "1f98c",
  ":department_store:": "1f3ec",
  ":desert:": "1f3dc",
  ":desktop:": "1f5a5",
  ":detective:": "1f575",
  ":detective_tone1:": "1f575-1f3fb",
  ":detective_tone2:": "1f575-1f3fc",
  ":detective_tone3:": "1f575-1f3fd",
  ":detective_tone4:": "1f575-1f3fe",
  ":detective_tone5:": "1f575-1f3ff",
  ":diamond_shape_with_a_dot_inside:": "1f4a0",
  ":diamonds:": "2666",
  ":disappointed:": "1f61e",
  ":disappointed_relieved:": "1f625",
  ":dividers:": "1f5c2",
  ":dizzy:": "1f4ab",
  ":dizzy_face:": "1f635",
  ":dna:": "1f9ec",
  ":do_not_litter:": "1f6af",
  ":dog2:": "1f415",
  ":dog:": "1f436",
  ":dollar:": "1f4b5",
  ":dolls:": "1f38e",
  ":dolphin:": "1f42c",
  ":door:": "1f6aa",
  ":doughnut:": "1f369",
  ":dove:": "1f54a",
  ":dragon:": "1f409",
  ":dragon_face:": "1f432",
  ":dress:": "1f457",
  ":dromedary_camel:": "1f42a",
  ":drooling_face:": "1f924",
  ":droplet:": "1f4a7",
  ":drum:": "1f941",
  ":duck:": "1f986",
  ":dumpling:": "1f95f",
  ":dvd:": "1f4c0",
  ":e-mail:": "1f4e7",
  ":eagle:": "1f985",
  ":ear:": "1f442",
  ":ear_of_rice:": "1f33e",
  ":ear_tone1:": "1f442-1f3fb",
  ":ear_tone2:": "1f442-1f3fc",
  ":ear_tone3:": "1f442-1f3fd",
  ":ear_tone4:": "1f442-1f3fe",
  ":ear_tone5:": "1f442-1f3ff",
  ":earth_africa:": "1f30d",
  ":earth_americas:": "1f30e",
  ":earth_asia:": "1f30f",
  ":egg:": "1f95a",
  ":eggplant:": "1f346",
  ":eight:": "0038-fe0f-20e3",
  ":eight_pointed_black_star:": "2734",
  ":eight_spoked_asterisk:": "2733",
  ":eject:": "23cf",
  ":electric_plug:": "1f50c",
  ":elephant:": "1f418",
  ":elf:": "1f9dd",
  ":elf_tone1:": "1f9dd-1f3fb",
  ":elf_tone2:": "1f9dd-1f3fc",
  ":elf_tone3:": "1f9dd-1f3fd",
  ":elf_tone4:": "1f9dd-1f3fe",
  ":elf_tone5:": "1f9dd-1f3ff",
  ":end:": "1f51a",
  ":england:": "1f3f4-e0067-e0062-e0065-e006e-e0067-e007f",
  ":envelope:": "2709",
  ":envelope_with_arrow:": "1f4e9",
  ":euro:": "1f4b6",
  ":european_castle:": "1f3f0",
  ":european_post_office:": "1f3e4",
  ":evergreen_tree:": "1f332",
  ":exclamation:": "2757",
  ":exploding_head:": "1f92f",
  ":expressionless:": "1f611",
  ":eye:": "1f441",
  ":eye_in_speech_bubble:": "1f441-fe0f-200d-1f5e8-fe0f",
  ":eyeglasses:": "1f453",
  ":eyes:": "1f440",
  ":face_vomiting:": "1f92e",
  ":face_with_hand_over_mouth:": "1f92d",
  ":face_with_monocle:": "1f9d0",
  ":face_with_raised_eyebrow:": "1f928",
  ":face_with_symbols_over_mouth:": "1f92c",
  ":factory:": "1f3ed",
  ":fairy:": "1f9da",
  ":fairy_tone1:": "1f9da-1f3fb",
  ":fairy_tone2:": "1f9da-1f3fc",
  ":fairy_tone3:": "1f9da-1f3fd",
  ":fairy_tone4:": "1f9da-1f3fe",
  ":fairy_tone5:": "1f9da-1f3ff",
  ":fallen_leaf:": "1f342",
  ":family:": "1f46a",
  ":family_man_boy:": "1f468-200d-1f466",
  ":family_man_boy_boy:": "1f468-200d-1f466-200d-1f466",
  ":family_man_girl:": "1f468-200d-1f467",
  ":family_man_girl_boy:": "1f468-200d-1f467-200d-1f466",
  ":family_man_girl_girl:": "1f468-200d-1f467-200d-1f467",
  ":family_man_woman_boy:": "1f468-200d-1f469-200d-1f466",
  ":family_mmb:": "1f468-200d-1f468-200d-1f466",
  ":family_mmbb:": "1f468-200d-1f468-200d-1f466-200d-1f466",
  ":family_mmg:": "1f468-200d-1f468-200d-1f467",
  ":family_mmgb:": "1f468-200d-1f468-200d-1f467-200d-1f466",
  ":family_mmgg:": "1f468-200d-1f468-200d-1f467-200d-1f467",
  ":family_mwbb:": "1f468-200d-1f469-200d-1f466-200d-1f466",
  ":family_mwg:": "1f468-200d-1f469-200d-1f467",
  ":family_mwgb:": "1f468-200d-1f469-200d-1f467-200d-1f466",
  ":family_mwgg:": "1f468-200d-1f469-200d-1f467-200d-1f467",
  ":family_woman_boy:": "1f469-200d-1f466",
  ":family_woman_boy_boy:": "1f469-200d-1f466-200d-1f466",
  ":family_woman_girl:": "1f469-200d-1f467",
  ":family_woman_girl_boy:": "1f469-200d-1f467-200d-1f466",
  ":family_woman_girl_girl:": "1f469-200d-1f467-200d-1f467",
  ":family_wwb:": "1f469-200d-1f469-200d-1f466",
  ":family_wwbb:": "1f469-200d-1f469-200d-1f466-200d-1f466",
  ":family_wwg:": "1f469-200d-1f469-200d-1f467",
  ":family_wwgb:": "1f469-200d-1f469-200d-1f467-200d-1f466",
  ":family_wwgg:": "1f469-200d-1f469-200d-1f467-200d-1f467",
  ":fast_forward:": "23e9",
  ":fax:": "1f4e0",
  ":fearful:": "1f628",
  ":feet:": "1f43e",
  ":female_sign:": "2640",
  ":ferris_wheel:": "1f3a1",
  ":ferry:": "26f4",
  ":field_hockey:": "1f3d1",
  ":file_cabinet:": "1f5c4",
  ":file_folder:": "1f4c1",
  ":film_frames:": "1f39e",
  ":fingers_crossed:": "1f91e",
  ":fingers_crossed_tone1:": "1f91e-1f3fb",
  ":fingers_crossed_tone2:": "1f91e-1f3fc",
  ":fingers_crossed_tone3:": "1f91e-1f3fd",
  ":fingers_crossed_tone4:": "1f91e-1f3fe",
  ":fingers_crossed_tone5:": "1f91e-1f3ff",
  ":fire:": "1f525",
  ":fire_engine:": "1f692",
  ":fire_extinguisher:": "1f9ef",
  ":firecracker:": "1f9e8",
  ":fireworks:": "1f386",
  ":first_place:": "1f947",
  ":first_quarter_moon:": "1f313",
  ":first_quarter_moon_with_face:": "1f31b",
  ":fish:": "1f41f",
  ":fish_cake:": "1f365",
  ":fishing_pole_and_fish:": "1f3a3",
  ":fist:": "270a",
  ":fist_tone1:": "270a-1f3fb",
  ":fist_tone2:": "270a-1f3fc",
  ":fist_tone3:": "270a-1f3fd",
  ":fist_tone4:": "270a-1f3fe",
  ":fist_tone5:": "270a-1f3ff",
  ":five:": "0035-fe0f-20e3",
  ":flag_ac:": "1f1e6-1f1e8",
  ":flag_ad:": "1f1e6-1f1e9",
  ":flag_ae:": "1f1e6-1f1ea",
  ":flag_af:": "1f1e6-1f1eb",
  ":flag_ag:": "1f1e6-1f1ec",
  ":flag_ai:": "1f1e6-1f1ee",
  ":flag_al:": "1f1e6-1f1f1",
  ":flag_am:": "1f1e6-1f1f2",
  ":flag_ao:": "1f1e6-1f1f4",
  ":flag_aq:": "1f1e6-1f1f6",
  ":flag_ar:": "1f1e6-1f1f7",
  ":flag_as:": "1f1e6-1f1f8",
  ":flag_at:": "1f1e6-1f1f9",
  ":flag_au:": "1f1e6-1f1fa",
  ":flag_aw:": "1f1e6-1f1fc",
  ":flag_ax:": "1f1e6-1f1fd",
  ":flag_az:": "1f1e6-1f1ff",
  ":flag_ba:": "1f1e7-1f1e6",
  ":flag_bb:": "1f1e7-1f1e7",
  ":flag_bd:": "1f1e7-1f1e9",
  ":flag_be:": "1f1e7-1f1ea",
  ":flag_bf:": "1f1e7-1f1eb",
  ":flag_bg:": "1f1e7-1f1ec",
  ":flag_bh:": "1f1e7-1f1ed",
  ":flag_bi:": "1f1e7-1f1ee",
  ":flag_bj:": "1f1e7-1f1ef",
  ":flag_bl:": "1f1e7-1f1f1",
  ":flag_black:": "1f3f4",
  ":flag_bm:": "1f1e7-1f1f2",
  ":flag_bn:": "1f1e7-1f1f3",
  ":flag_bo:": "1f1e7-1f1f4",
  ":flag_bq:": "1f1e7-1f1f6",
  ":flag_br:": "1f1e7-1f1f7",
  ":flag_bs:": "1f1e7-1f1f8",
  ":flag_bt:": "1f1e7-1f1f9",
  ":flag_bv:": "1f1e7-1f1fb",
  ":flag_bw:": "1f1e7-1f1fc",
  ":flag_by:": "1f1e7-1f1fe",
  ":flag_bz:": "1f1e7-1f1ff",
  ":flag_ca:": "1f1e8-1f1e6",
  ":flag_cc:": "1f1e8-1f1e8",
  ":flag_cd:": "1f1e8-1f1e9",
  ":flag_cf:": "1f1e8-1f1eb",
  ":flag_cg:": "1f1e8-1f1ec",
  ":flag_ch:": "1f1e8-1f1ed",
  ":flag_ci:": "1f1e8-1f1ee",
  ":flag_ck:": "1f1e8-1f1f0",
  ":flag_cl:": "1f1e8-1f1f1",
  ":flag_cm:": "1f1e8-1f1f2",
  ":flag_cn:": "1f1e8-1f1f3",
  ":flag_co:": "1f1e8-1f1f4",
  ":flag_cp:": "1f1e8-1f1f5",
  ":flag_cr:": "1f1e8-1f1f7",
  ":flag_cu:": "1f1e8-1f1fa",
  ":flag_cv:": "1f1e8-1f1fb",
  ":flag_cw:": "1f1e8-1f1fc",
  ":flag_cx:": "1f1e8-1f1fd",
  ":flag_cy:": "1f1e8-1f1fe",
  ":flag_cz:": "1f1e8-1f1ff",
  ":flag_de:": "1f1e9-1f1ea",
  ":flag_dg:": "1f1e9-1f1ec",
  ":flag_dj:": "1f1e9-1f1ef",
  ":flag_dk:": "1f1e9-1f1f0",
  ":flag_dm:": "1f1e9-1f1f2",
  ":flag_do:": "1f1e9-1f1f4",
  ":flag_dz:": "1f1e9-1f1ff",
  ":flag_ea:": "1f1ea-1f1e6",
  ":flag_ec:": "1f1ea-1f1e8",
  ":flag_ee:": "1f1ea-1f1ea",
  ":flag_eg:": "1f1ea-1f1ec",
  ":flag_eh:": "1f1ea-1f1ed",
  ":flag_er:": "1f1ea-1f1f7",
  ":flag_es:": "1f1ea-1f1f8",
  ":flag_et:": "1f1ea-1f1f9",
  ":flag_eu:": "1f1ea-1f1fa",
  ":flag_fi:": "1f1eb-1f1ee",
  ":flag_fj:": "1f1eb-1f1ef",
  ":flag_fk:": "1f1eb-1f1f0",
  ":flag_fm:": "1f1eb-1f1f2",
  ":flag_fo:": "1f1eb-1f1f4",
  ":flag_fr:": "1f1eb-1f1f7",
  ":flag_ga:": "1f1ec-1f1e6",
  ":flag_gb:": "1f1ec-1f1e7",
  ":flag_gd:": "1f1ec-1f1e9",
  ":flag_ge:": "1f1ec-1f1ea",
  ":flag_gf:": "1f1ec-1f1eb",
  ":flag_gg:": "1f1ec-1f1ec",
  ":flag_gh:": "1f1ec-1f1ed",
  ":flag_gi:": "1f1ec-1f1ee",
  ":flag_gl:": "1f1ec-1f1f1",
  ":flag_gm:": "1f1ec-1f1f2",
  ":flag_gn:": "1f1ec-1f1f3",
  ":flag_gp:": "1f1ec-1f1f5",
  ":flag_gq:": "1f1ec-1f1f6",
  ":flag_gr:": "1f1ec-1f1f7",
  ":flag_gs:": "1f1ec-1f1f8",
  ":flag_gt:": "1f1ec-1f1f9",
  ":flag_gu:": "1f1ec-1f1fa",
  ":flag_gw:": "1f1ec-1f1fc",
  ":flag_gy:": "1f1ec-1f1fe",
  ":flag_hk:": "1f1ed-1f1f0",
  ":flag_hm:": "1f1ed-1f1f2",
  ":flag_hn:": "1f1ed-1f1f3",
  ":flag_hr:": "1f1ed-1f1f7",
  ":flag_ht:": "1f1ed-1f1f9",
  ":flag_hu:": "1f1ed-1f1fa",
  ":flag_ic:": "1f1ee-1f1e8",
  ":flag_id:": "1f1ee-1f1e9",
  ":flag_ie:": "1f1ee-1f1ea",
  ":flag_il:": "1f1ee-1f1f1",
  ":flag_im:": "1f1ee-1f1f2",
  ":flag_in:": "1f1ee-1f1f3",
  ":flag_io:": "1f1ee-1f1f4",
  ":flag_iq:": "1f1ee-1f1f6",
  ":flag_ir:": "1f1ee-1f1f7",
  ":flag_is:": "1f1ee-1f1f8",
  ":flag_it:": "1f1ee-1f1f9",
  ":flag_je:": "1f1ef-1f1ea",
  ":flag_jm:": "1f1ef-1f1f2",
  ":flag_jo:": "1f1ef-1f1f4",
  ":flag_jp:": "1f1ef-1f1f5",
  ":flag_ke:": "1f1f0-1f1ea",
  ":flag_kg:": "1f1f0-1f1ec",
  ":flag_kh:": "1f1f0-1f1ed",
  ":flag_ki:": "1f1f0-1f1ee",
  ":flag_km:": "1f1f0-1f1f2",
  ":flag_kn:": "1f1f0-1f1f3",
  ":flag_kp:": "1f1f0-1f1f5",
  ":flag_kr:": "1f1f0-1f1f7",
  ":flag_kw:": "1f1f0-1f1fc",
  ":flag_ky:": "1f1f0-1f1fe",
  ":flag_kz:": "1f1f0-1f1ff",
  ":flag_la:": "1f1f1-1f1e6",
  ":flag_lb:": "1f1f1-1f1e7",
  ":flag_lc:": "1f1f1-1f1e8",
  ":flag_li:": "1f1f1-1f1ee",
  ":flag_lk:": "1f1f1-1f1f0",
  ":flag_lr:": "1f1f1-1f1f7",
  ":flag_ls:": "1f1f1-1f1f8",
  ":flag_lt:": "1f1f1-1f1f9",
  ":flag_lu:": "1f1f1-1f1fa",
  ":flag_lv:": "1f1f1-1f1fb",
  ":flag_ly:": "1f1f1-1f1fe",
  ":flag_ma:": "1f1f2-1f1e6",
  ":flag_mc:": "1f1f2-1f1e8",
  ":flag_md:": "1f1f2-1f1e9",
  ":flag_me:": "1f1f2-1f1ea",
  ":flag_mf:": "1f1f2-1f1eb",
  ":flag_mg:": "1f1f2-1f1ec",
  ":flag_mh:": "1f1f2-1f1ed",
  ":flag_mk:": "1f1f2-1f1f0",
  ":flag_ml:": "1f1f2-1f1f1",
  ":flag_mm:": "1f1f2-1f1f2",
  ":flag_mn:": "1f1f2-1f1f3",
  ":flag_mo:": "1f1f2-1f1f4",
  ":flag_mp:": "1f1f2-1f1f5",
  ":flag_mq:": "1f1f2-1f1f6",
  ":flag_mr:": "1f1f2-1f1f7",
  ":flag_ms:": "1f1f2-1f1f8",
  ":flag_mt:": "1f1f2-1f1f9",
  ":flag_mu:": "1f1f2-1f1fa",
  ":flag_mv:": "1f1f2-1f1fb",
  ":flag_mw:": "1f1f2-1f1fc",
  ":flag_mx:": "1f1f2-1f1fd",
  ":flag_my:": "1f1f2-1f1fe",
  ":flag_mz:": "1f1f2-1f1ff",
  ":flag_na:": "1f1f3-1f1e6",
  ":flag_nc:": "1f1f3-1f1e8",
  ":flag_ne:": "1f1f3-1f1ea",
  ":flag_nf:": "1f1f3-1f1eb",
  ":flag_ng:": "1f1f3-1f1ec",
  ":flag_ni:": "1f1f3-1f1ee",
  ":flag_nl:": "1f1f3-1f1f1",
  ":flag_no:": "1f1f3-1f1f4",
  ":flag_np:": "1f1f3-1f1f5",
  ":flag_nr:": "1f1f3-1f1f7",
  ":flag_nu:": "1f1f3-1f1fa",
  ":flag_nz:": "1f1f3-1f1ff",
  ":flag_om:": "1f1f4-1f1f2",
  ":flag_pa:": "1f1f5-1f1e6",
  ":flag_pe:": "1f1f5-1f1ea",
  ":flag_pf:": "1f1f5-1f1eb",
  ":flag_pg:": "1f1f5-1f1ec",
  ":flag_ph:": "1f1f5-1f1ed",
  ":flag_pk:": "1f1f5-1f1f0",
  ":flag_pl:": "1f1f5-1f1f1",
  ":flag_pm:": "1f1f5-1f1f2",
  ":flag_pn:": "1f1f5-1f1f3",
  ":flag_pr:": "1f1f5-1f1f7",
  ":flag_ps:": "1f1f5-1f1f8",
  ":flag_pt:": "1f1f5-1f1f9",
  ":flag_pw:": "1f1f5-1f1fc",
  ":flag_py:": "1f1f5-1f1fe",
  ":flag_qa:": "1f1f6-1f1e6",
  ":flag_re:": "1f1f7-1f1ea",
  ":flag_ro:": "1f1f7-1f1f4",
  ":flag_rs:": "1f1f7-1f1f8",
  ":flag_ru:": "1f1f7-1f1fa",
  ":flag_rw:": "1f1f7-1f1fc",
  ":flag_sa:": "1f1f8-1f1e6",
  ":flag_sb:": "1f1f8-1f1e7",
  ":flag_sc:": "1f1f8-1f1e8",
  ":flag_sd:": "1f1f8-1f1e9",
  ":flag_se:": "1f1f8-1f1ea",
  ":flag_sg:": "1f1f8-1f1ec",
  ":flag_sh:": "1f1f8-1f1ed",
  ":flag_si:": "1f1f8-1f1ee",
  ":flag_sj:": "1f1f8-1f1ef",
  ":flag_sk:": "1f1f8-1f1f0",
  ":flag_sl:": "1f1f8-1f1f1",
  ":flag_sm:": "1f1f8-1f1f2",
  ":flag_sn:": "1f1f8-1f1f3",
  ":flag_so:": "1f1f8-1f1f4",
  ":flag_sr:": "1f1f8-1f1f7",
  ":flag_ss:": "1f1f8-1f1f8",
  ":flag_st:": "1f1f8-1f1f9",
  ":flag_sv:": "1f1f8-1f1fb",
  ":flag_sx:": "1f1f8-1f1fd",
  ":flag_sy:": "1f1f8-1f1fe",
  ":flag_sz:": "1f1f8-1f1ff",
  ":flag_ta:": "1f1f9-1f1e6",
  ":flag_tc:": "1f1f9-1f1e8",
  ":flag_td:": "1f1f9-1f1e9",
  ":flag_tf:": "1f1f9-1f1eb",
  ":flag_tg:": "1f1f9-1f1ec",
  ":flag_th:": "1f1f9-1f1ed",
  ":flag_tj:": "1f1f9-1f1ef",
  ":flag_tk:": "1f1f9-1f1f0",
  ":flag_tl:": "1f1f9-1f1f1",
  ":flag_tm:": "1f1f9-1f1f2",
  ":flag_tn:": "1f1f9-1f1f3",
  ":flag_to:": "1f1f9-1f1f4",
  ":flag_tr:": "1f1f9-1f1f7",
  ":flag_tt:": "1f1f9-1f1f9",
  ":flag_tv:": "1f1f9-1f1fb",
  ":flag_tw:": "1f1f9-1f1fc",
  ":flag_tz:": "1f1f9-1f1ff",
  ":flag_ua:": "1f1fa-1f1e6",
  ":flag_ug:": "1f1fa-1f1ec",
  ":flag_um:": "1f1fa-1f1f2",
  ":flag_us:": "1f1fa-1f1f8",
  ":flag_uy:": "1f1fa-1f1fe",
  ":flag_uz:": "1f1fa-1f1ff",
  ":flag_va:": "1f1fb-1f1e6",
  ":flag_vc:": "1f1fb-1f1e8",
  ":flag_ve:": "1f1fb-1f1ea",
  ":flag_vg:": "1f1fb-1f1ec",
  ":flag_vi:": "1f1fb-1f1ee",
  ":flag_vn:": "1f1fb-1f1f3",
  ":flag_vu:": "1f1fb-1f1fa",
  ":flag_wf:": "1f1fc-1f1eb",
  ":flag_white:": "1f3f3",
  ":flag_ws:": "1f1fc-1f1f8",
  ":flag_xk:": "1f1fd-1f1f0",
  ":flag_ye:": "1f1fe-1f1ea",
  ":flag_yt:": "1f1fe-1f1f9",
  ":flag_za:": "1f1ff-1f1e6",
  ":flag_zm:": "1f1ff-1f1f2",
  ":flag_zw:": "1f1ff-1f1fc",
  ":flags:": "1f38f",
  ":flashlight:": "1f526",
  ":fleur-de-lis:": "269c",
  ":floppy_disk:": "1f4be",
  ":flower_playing_cards:": "1f3b4",
  ":flushed:": "1f633",
  ":flying_disc:": "1f94f",
  ":flying_saucer:": "1f6f8",
  ":fog:": "1f32b",
  ":foggy:": "1f301",
  ":foot:": "1f9b6",
  ":foot_tone1:": "1f9b6-1f3fb",
  ":foot_tone2:": "1f9b6-1f3fc",
  ":foot_tone3:": "1f9b6-1f3fd",
  ":foot_tone4:": "1f9b6-1f3fe",
  ":foot_tone5:": "1f9b6-1f3ff",
  ":football:": "1f3c8",
  ":footprints:": "1f463",
  ":fork_and_knife:": "1f374",
  ":fork_knife_plate:": "1f37d",
  ":fortune_cookie:": "1f960",
  ":fountain:": "26f2",
  ":four:": "0034-fe0f-20e3",
  ":four_leaf_clover:": "1f340",
  ":fox:": "1f98a",
  ":frame_photo:": "1f5bc",
  ":free:": "1f193",
  ":french_bread:": "1f956",
  ":fried_shrimp:": "1f364",
  ":fries:": "1f35f",
  ":frog:": "1f438",
  ":frowning2:": "2639",
  ":frowning:": "1f626",
  ":fuelpump:": "26fd",
  ":full_moon:": "1f315",
  ":full_moon_with_face:": "1f31d",
  ":game_die:": "1f3b2",
  ":gear:": "2699",
  ":gem:": "1f48e",
  ":gemini:": "264a",
  ":genie:": "1f9de",
  ":ghost:": "1f47b",
  ":gift:": "1f381",
  ":gift_heart:": "1f49d",
  ":giraffe:": "1f992",
  ":girl:": "1f467",
  ":girl_tone1:": "1f467-1f3fb",
  ":girl_tone2:": "1f467-1f3fc",
  ":girl_tone3:": "1f467-1f3fd",
  ":girl_tone4:": "1f467-1f3fe",
  ":girl_tone5:": "1f467-1f3ff",
  ":globe_with_meridians:": "1f310",
  ":gloves:": "1f9e4",
  ":goal:": "1f945",
  ":goat:": "1f410",
  ":goggles:": "1f97d",
  ":golf:": "26f3",
  ":gorilla:": "1f98d",
  ":grapes:": "1f347",
  ":green_apple:": "1f34f",
  ":green_book:": "1f4d7",
  ":green_heart:": "1f49a",
  ":grey_exclamation:": "2755",
  ":grey_question:": "2754",
  ":grimacing:": "1f62c",
  ":grin:": "1f601",
  ":grinning:": "1f600",
  ":guard:": "1f482",
  ":guard_tone1:": "1f482-1f3fb",
  ":guard_tone2:": "1f482-1f3fc",
  ":guard_tone3:": "1f482-1f3fd",
  ":guard_tone4:": "1f482-1f3fe",
  ":guard_tone5:": "1f482-1f3ff",
  ":guitar:": "1f3b8",
  ":gun:": "1f52b",
  ":hamburger:": "1f354",
  ":hammer:": "1f528",
  ":hammer_pick:": "2692",
  ":hamster:": "1f439",
  ":hand_splayed:": "1f590",
  ":hand_splayed_tone1:": "1f590-1f3fb",
  ":hand_splayed_tone2:": "1f590-1f3fc",
  ":hand_splayed_tone3:": "1f590-1f3fd",
  ":hand_splayed_tone4:": "1f590-1f3fe",
  ":hand_splayed_tone5:": "1f590-1f3ff",
  ":handbag:": "1f45c",
  ":handshake:": "1f91d",
  ":hash:": "0023-fe0f-20e3",
  ":hatched_chick:": "1f425",
  ":hatching_chick:": "1f423",
  ":head_bandage:": "1f915",
  ":headphones:": "1f3a7",
  ":hear_no_evil:": "1f649",
  ":heart:": "2764",
  ":heart_decoration:": "1f49f",
  ":heart_exclamation:": "2763",
  ":heart_eyes:": "1f60d",
  ":heart_eyes_cat:": "1f63b",
  ":heartbeat:": "1f493",
  ":heartpulse:": "1f497",
  ":hearts:": "2665",
  ":heavy_check_mark:": "2714",
  ":heavy_division_sign:": "2797",
  ":heavy_dollar_sign:": "1f4b2",
  ":heavy_minus_sign:": "2796",
  ":heavy_multiplication_x:": "2716",
  ":heavy_plus_sign:": "2795",
  ":hedgehog:": "1f994",
  ":helicopter:": "1f681",
  ":helmet_with_cross:": "26d1",
  ":herb:": "1f33f",
  ":hibiscus:": "1f33a",
  ":high_brightness:": "1f506",
  ":high_heel:": "1f460",
  ":hiking_boot:": "1f97e",
  ":hippopotamus:": "1f99b",
  ":hockey:": "1f3d2",
  ":hole:": "1f573",
  ":homes:": "1f3d8",
  ":honey_pot:": "1f36f",
  ":horse:": "1f434",
  ":horse_racing:": "1f3c7",
  ":horse_racing_tone1:": "1f3c7-1f3fb",
  ":horse_racing_tone2:": "1f3c7-1f3fc",
  ":horse_racing_tone3:": "1f3c7-1f3fd",
  ":horse_racing_tone4:": "1f3c7-1f3fe",
  ":horse_racing_tone5:": "1f3c7-1f3ff",
  ":hospital:": "1f3e5",
  ":hot_face:": "1f975",
  ":hot_pepper:": "1f336",
  ":hotdog:": "1f32d",
  ":hotel:": "1f3e8",
  ":hotsprings:": "2668",
  ":hourglass:": "231b",
  ":hourglass_flowing_sand:": "23f3",
  ":house:": "1f3e0",
  ":house_abandoned:": "1f3da",
  ":house_with_garden:": "1f3e1",
  ":hugging:": "1f917",
  ":hushed:": "1f62f",
  ":ice_cream:": "1f368",
  ":ice_skate:": "26f8",
  ":icecream:": "1f366",
  ":id:": "1f194",
  ":ideograph_advantage:": "1f250",
  ":imp:": "1f47f",
  ":inbox_tray:": "1f4e5",
  ":incoming_envelope:": "1f4e8",
  ":infinity:": "267e-fe0f",
  ":information_source:": "2139",
  ":innocent:": "1f607",
  ":interrobang:": "2049",
  ":iphone:": "1f4f1",
  ":island:": "1f3dd",
  ":izakaya_lantern:": "1f3ee",
  ":jack_o_lantern:": "1f383",
  ":japan:": "1f5fe",
  ":japanese_castle:": "1f3ef",
  ":japanese_goblin:": "1f47a",
  ":japanese_ogre:": "1f479",
  ":jeans:": "1f456",
  ":jigsaw:": "1f9e9",
  ":joy:": "1f602",
  ":joy_cat:": "1f639",
  ":joystick:": "1f579",
  ":kaaba:": "1f54b",
  ":kangaroo:": "1f998",
  ":key2:": "1f5dd",
  ":key:": "1f511",
  ":keyboard:": "2328",
  ":keycap_ten:": "1f51f",
  ":kimono:": "1f458",
  ":kiss:": "1f48b",
  ":kiss_mm:": "1f468-200d-2764-fe0f-200d-1f48b-200d-1f468",
  ":kiss_woman_man:": "1f469-200d-2764-fe0f-200d-1f48b-200d-1f468",
  ":kiss_ww:": "1f469-200d-2764-fe0f-200d-1f48b-200d-1f469",
  ":kissing:": "1f617",
  ":kissing_cat:": "1f63d",
  ":kissing_closed_eyes:": "1f61a",
  ":kissing_heart:": "1f618",
  ":kissing_smiling_eyes:": "1f619",
  ":kiwi:": "1f95d",
  ":knife:": "1f52a",
  ":koala:": "1f428",
  ":koko:": "1f201",
  ":lab_coat:": "1f97c",
  ":label:": "1f3f7",
  ":lacrosse:": "1f94d",
  ":large_blue_diamond:": "1f537",
  ":large_orange_diamond:": "1f536",
  ":last_quarter_moon:": "1f317",
  ":last_quarter_moon_with_face:": "1f31c",
  ":laughing:": "1f606",
  ":leafy_green:": "1f96c",
  ":leaves:": "1f343",
  ":ledger:": "1f4d2",
  ":left_facing_fist:": "1f91b",
  ":left_facing_fist_tone1:": "1f91b-1f3fb",
  ":left_facing_fist_tone2:": "1f91b-1f3fc",
  ":left_facing_fist_tone3:": "1f91b-1f3fd",
  ":left_facing_fist_tone4:": "1f91b-1f3fe",
  ":left_facing_fist_tone5:": "1f91b-1f3ff",
  ":left_luggage:": "1f6c5",
  ":left_right_arrow:": "2194",
  ":leftwards_arrow_with_hook:": "21a9",
  ":leg:": "1f9b5",
  ":leg_tone1:": "1f9b5-1f3fb",
  ":leg_tone2:": "1f9b5-1f3fc",
  ":leg_tone3:": "1f9b5-1f3fd",
  ":leg_tone4:": "1f9b5-1f3fe",
  ":leg_tone5:": "1f9b5-1f3ff",
  ":lemon:": "1f34b",
  ":leo:": "264c",
  ":leopard:": "1f406",
  ":level_slider:": "1f39a",
  ":levitate:": "1f574",
  ":levitate_tone1:": "1f574-1f3fb",
  ":levitate_tone2:": "1f574-1f3fc",
  ":levitate_tone3:": "1f574-1f3fd",
  ":levitate_tone4:": "1f574-1f3fe",
  ":levitate_tone5:": "1f574-1f3ff",
  ":libra:": "264e",
  ":light_rail:": "1f688",
  ":link:": "1f517",
  ":lion_face:": "1f981",
  ":lips:": "1f444",
  ":lipstick:": "1f484",
  ":lizard:": "1f98e",
  ":llama:": "1f999",
  ":lobster:": "1f99e",
  ":lock:": "1f512",
  ":lock_with_ink_pen:": "1f50f",
  ":lollipop:": "1f36d",
  ":loop:": "27bf",
  ":loud_sound:": "1f50a",
  ":loudspeaker:": "1f4e2",
  ":love_hotel:": "1f3e9",
  ":love_letter:": "1f48c",
  ":love_you_gesture:": "1f91f",
  ":love_you_gesture_tone1:": "1f91f-1f3fb",
  ":love_you_gesture_tone2:": "1f91f-1f3fc",
  ":love_you_gesture_tone3:": "1f91f-1f3fd",
  ":love_you_gesture_tone4:": "1f91f-1f3fe",
  ":love_you_gesture_tone5:": "1f91f-1f3ff",
  ":low_brightness:": "1f505",
  ":luggage:": "1f9f3",
  ":lying_face:": "1f925",
  ":m:": "24c2",
  ":mag:": "1f50d",
  ":mag_right:": "1f50e",
  ":mage:": "1f9d9",
  ":mage_tone1:": "1f9d9-1f3fb",
  ":mage_tone2:": "1f9d9-1f3fc",
  ":mage_tone3:": "1f9d9-1f3fd",
  ":mage_tone4:": "1f9d9-1f3fe",
  ":mage_tone5:": "1f9d9-1f3ff",
  ":magnet:": "1f9f2",
  ":mahjong:": "1f004",
  ":mailbox:": "1f4eb",
  ":mailbox_closed:": "1f4ea",
  ":mailbox_with_mail:": "1f4ec",
  ":mailbox_with_no_mail:": "1f4ed",
  ":male_sign:": "2642",
  ":man:": "1f468",
  ":man_artist:": "1f468-200d-1f3a8",
  ":man_artist_tone1:": "1f468-1f3fb-200d-1f3a8",
  ":man_artist_tone2:": "1f468-1f3fc-200d-1f3a8",
  ":man_artist_tone3:": "1f468-1f3fd-200d-1f3a8",
  ":man_artist_tone4:": "1f468-1f3fe-200d-1f3a8",
  ":man_artist_tone5:": "1f468-1f3ff-200d-1f3a8",
  ":man_astronaut:": "1f468-200d-1f680",
  ":man_astronaut_tone1:": "1f468-1f3fb-200d-1f680",
  ":man_astronaut_tone2:": "1f468-1f3fc-200d-1f680",
  ":man_astronaut_tone3:": "1f468-1f3fd-200d-1f680",
  ":man_astronaut_tone4:": "1f468-1f3fe-200d-1f680",
  ":man_astronaut_tone5:": "1f468-1f3ff-200d-1f680",
  ":man_bald:": "1f468-200d-1f9b2",
  ":man_bald_tone1:": "1f468-1f3fb-200d-1f9b2",
  ":man_bald_tone2:": "1f468-1f3fc-200d-1f9b2",
  ":man_bald_tone3:": "1f468-1f3fd-200d-1f9b2",
  ":man_bald_tone4:": "1f468-1f3fe-200d-1f9b2",
  ":man_bald_tone5:": "1f468-1f3ff-200d-1f9b2",
  ":man_biking:": "1f6b4-200d-2642-fe0f",
  ":man_biking_tone1:": "1f6b4-1f3fb-200d-2642-fe0f",
  ":man_biking_tone2:": "1f6b4-1f3fc-200d-2642-fe0f",
  ":man_biking_tone3:": "1f6b4-1f3fd-200d-2642-fe0f",
  ":man_biking_tone4:": "1f6b4-1f3fe-200d-2642-fe0f",
  ":man_biking_tone5:": "1f6b4-1f3ff-200d-2642-fe0f",
  ":man_bouncing_ball:": "26f9-fe0f-200d-2642-fe0f",
  ":man_bouncing_ball_tone1:": "26f9-1f3fb-200d-2642-fe0f",
  ":man_bouncing_ball_tone2:": "26f9-1f3fc-200d-2642-fe0f",
  ":man_bouncing_ball_tone3:": "26f9-1f3fd-200d-2642-fe0f",
  ":man_bouncing_ball_tone4:": "26f9-1f3fe-200d-2642-fe0f",
  ":man_bouncing_ball_tone5:": "26f9-1f3ff-200d-2642-fe0f",
  ":man_bowing:": "1f647-200d-2642-fe0f",
  ":man_bowing_tone1:": "1f647-1f3fb-200d-2642-fe0f",
  ":man_bowing_tone2:": "1f647-1f3fc-200d-2642-fe0f",
  ":man_bowing_tone3:": "1f647-1f3fd-200d-2642-fe0f",
  ":man_bowing_tone4:": "1f647-1f3fe-200d-2642-fe0f",
  ":man_bowing_tone5:": "1f647-1f3ff-200d-2642-fe0f",
  ":man_cartwheeling:": "1f938-200d-2642-fe0f",
  ":man_cartwheeling_tone1:": "1f938-1f3fb-200d-2642-fe0f",
  ":man_cartwheeling_tone2:": "1f938-1f3fc-200d-2642-fe0f",
  ":man_cartwheeling_tone3:": "1f938-1f3fd-200d-2642-fe0f",
  ":man_cartwheeling_tone4:": "1f938-1f3fe-200d-2642-fe0f",
  ":man_cartwheeling_tone5:": "1f938-1f3ff-200d-2642-fe0f",
  ":man_climbing:": "1f9d7-200d-2642-fe0f",
  ":man_climbing_tone1:": "1f9d7-1f3fb-200d-2642-fe0f",
  ":man_climbing_tone2:": "1f9d7-1f3fc-200d-2642-fe0f",
  ":man_climbing_tone3:": "1f9d7-1f3fd-200d-2642-fe0f",
  ":man_climbing_tone4:": "1f9d7-1f3fe-200d-2642-fe0f",
  ":man_climbing_tone5:": "1f9d7-1f3ff-200d-2642-fe0f",
  ":man_construction_worker:": "1f477-200d-2642-fe0f",
  ":man_construction_worker_tone1:": "1f477-1f3fb-200d-2642-fe0f",
  ":man_construction_worker_tone2:": "1f477-1f3fc-200d-2642-fe0f",
  ":man_construction_worker_tone3:": "1f477-1f3fd-200d-2642-fe0f",
  ":man_construction_worker_tone4:": "1f477-1f3fe-200d-2642-fe0f",
  ":man_construction_worker_tone5:": "1f477-1f3ff-200d-2642-fe0f",
  ":man_cook:": "1f468-200d-1f373",
  ":man_cook_tone1:": "1f468-1f3fb-200d-1f373",
  ":man_cook_tone2:": "1f468-1f3fc-200d-1f373",
  ":man_cook_tone3:": "1f468-1f3fd-200d-1f373",
  ":man_cook_tone4:": "1f468-1f3fe-200d-1f373",
  ":man_cook_tone5:": "1f468-1f3ff-200d-1f373",
  ":man_curly_haired:": "1f468-200d-1f9b1",
  ":man_curly_haired_tone1:": "1f468-1f3fb-200d-1f9b1",
  ":man_curly_haired_tone2:": "1f468-1f3fc-200d-1f9b1",
  ":man_curly_haired_tone3:": "1f468-1f3fd-200d-1f9b1",
  ":man_curly_haired_tone4:": "1f468-1f3fe-200d-1f9b1",
  ":man_curly_haired_tone5:": "1f468-1f3ff-200d-1f9b1",
  ":man_dancing:": "1f57a",
  ":man_dancing_tone1:": "1f57a-1f3fb",
  ":man_dancing_tone2:": "1f57a-1f3fc",
  ":man_dancing_tone3:": "1f57a-1f3fd",
  ":man_dancing_tone4:": "1f57a-1f3fe",
  ":man_dancing_tone5:": "1f57a-1f3ff",
  ":man_detective:": "1f575-fe0f-200d-2642-fe0f",
  ":man_detective_tone1:": "1f575-1f3fb-200d-2642-fe0f",
  ":man_detective_tone2:": "1f575-1f3fc-200d-2642-fe0f",
  ":man_detective_tone3:": "1f575-1f3fd-200d-2642-fe0f",
  ":man_detective_tone4:": "1f575-1f3fe-200d-2642-fe0f",
  ":man_detective_tone5:": "1f575-1f3ff-200d-2642-fe0f",
  ":man_elf:": "1f9dd-200d-2642-fe0f",
  ":man_elf_tone1:": "1f9dd-1f3fb-200d-2642-fe0f",
  ":man_elf_tone2:": "1f9dd-1f3fc-200d-2642-fe0f",
  ":man_elf_tone3:": "1f9dd-1f3fd-200d-2642-fe0f",
  ":man_elf_tone4:": "1f9dd-1f3fe-200d-2642-fe0f",
  ":man_elf_tone5:": "1f9dd-1f3ff-200d-2642-fe0f",
  ":man_facepalming:": "1f926-200d-2642-fe0f",
  ":man_facepalming_tone1:": "1f926-1f3fb-200d-2642-fe0f",
  ":man_facepalming_tone2:": "1f926-1f3fc-200d-2642-fe0f",
  ":man_facepalming_tone3:": "1f926-1f3fd-200d-2642-fe0f",
  ":man_facepalming_tone4:": "1f926-1f3fe-200d-2642-fe0f",
  ":man_facepalming_tone5:": "1f926-1f3ff-200d-2642-fe0f",
  ":man_factory_worker:": "1f468-200d-1f3ed",
  ":man_factory_worker_tone1:": "1f468-1f3fb-200d-1f3ed",
  ":man_factory_worker_tone2:": "1f468-1f3fc-200d-1f3ed",
  ":man_factory_worker_tone3:": "1f468-1f3fd-200d-1f3ed",
  ":man_factory_worker_tone4:": "1f468-1f3fe-200d-1f3ed",
  ":man_factory_worker_tone5:": "1f468-1f3ff-200d-1f3ed",
  ":man_fairy:": "1f9da-200d-2642-fe0f",
  ":man_fairy_tone1:": "1f9da-1f3fb-200d-2642-fe0f",
  ":man_fairy_tone2:": "1f9da-1f3fc-200d-2642-fe0f",
  ":man_fairy_tone3:": "1f9da-1f3fd-200d-2642-fe0f",
  ":man_fairy_tone4:": "1f9da-1f3fe-200d-2642-fe0f",
  ":man_fairy_tone5:": "1f9da-1f3ff-200d-2642-fe0f",
  ":man_farmer:": "1f468-200d-1f33e",
  ":man_farmer_tone1:": "1f468-1f3fb-200d-1f33e",
  ":man_farmer_tone2:": "1f468-1f3fc-200d-1f33e",
  ":man_farmer_tone3:": "1f468-1f3fd-200d-1f33e",
  ":man_farmer_tone4:": "1f468-1f3fe-200d-1f33e",
  ":man_farmer_tone5:": "1f468-1f3ff-200d-1f33e",
  ":man_firefighter:": "1f468-200d-1f692",
  ":man_firefighter_tone1:": "1f468-1f3fb-200d-1f692",
  ":man_firefighter_tone2:": "1f468-1f3fc-200d-1f692",
  ":man_firefighter_tone3:": "1f468-1f3fd-200d-1f692",
  ":man_firefighter_tone4:": "1f468-1f3fe-200d-1f692",
  ":man_firefighter_tone5:": "1f468-1f3ff-200d-1f692",
  ":man_frowning:": "1f64d-200d-2642-fe0f",
  ":man_frowning_tone1:": "1f64d-1f3fb-200d-2642-fe0f",
  ":man_frowning_tone2:": "1f64d-1f3fc-200d-2642-fe0f",
  ":man_frowning_tone3:": "1f64d-1f3fd-200d-2642-fe0f",
  ":man_frowning_tone4:": "1f64d-1f3fe-200d-2642-fe0f",
  ":man_frowning_tone5:": "1f64d-1f3ff-200d-2642-fe0f",
  ":man_genie:": "1f9de-200d-2642-fe0f",
  ":man_gesturing_no:": "1f645-200d-2642-fe0f",
  ":man_gesturing_no_tone1:": "1f645-1f3fb-200d-2642-fe0f",
  ":man_gesturing_no_tone2:": "1f645-1f3fc-200d-2642-fe0f",
  ":man_gesturing_no_tone3:": "1f645-1f3fd-200d-2642-fe0f",
  ":man_gesturing_no_tone4:": "1f645-1f3fe-200d-2642-fe0f",
  ":man_gesturing_no_tone5:": "1f645-1f3ff-200d-2642-fe0f",
  ":man_gesturing_ok:": "1f646-200d-2642-fe0f",
  ":man_gesturing_ok_tone1:": "1f646-1f3fb-200d-2642-fe0f",
  ":man_gesturing_ok_tone2:": "1f646-1f3fc-200d-2642-fe0f",
  ":man_gesturing_ok_tone3:": "1f646-1f3fd-200d-2642-fe0f",
  ":man_gesturing_ok_tone4:": "1f646-1f3fe-200d-2642-fe0f",
  ":man_gesturing_ok_tone5:": "1f646-1f3ff-200d-2642-fe0f",
  ":man_getting_face_massage:": "1f486-200d-2642-fe0f",
  ":man_getting_face_massage_tone1:": "1f486-1f3fb-200d-2642-fe0f",
  ":man_getting_face_massage_tone2:": "1f486-1f3fc-200d-2642-fe0f",
  ":man_getting_face_massage_tone3:": "1f486-1f3fd-200d-2642-fe0f",
  ":man_getting_face_massage_tone4:": "1f486-1f3fe-200d-2642-fe0f",
  ":man_getting_face_massage_tone5:": "1f486-1f3ff-200d-2642-fe0f",
  ":man_getting_haircut:": "1f487-200d-2642-fe0f",
  ":man_getting_haircut_tone1:": "1f487-1f3fb-200d-2642-fe0f",
  ":man_getting_haircut_tone2:": "1f487-1f3fc-200d-2642-fe0f",
  ":man_getting_haircut_tone3:": "1f487-1f3fd-200d-2642-fe0f",
  ":man_getting_haircut_tone4:": "1f487-1f3fe-200d-2642-fe0f",
  ":man_getting_haircut_tone5:": "1f487-1f3ff-200d-2642-fe0f",
  ":man_golfing:": "1f3cc-fe0f-200d-2642-fe0f",
  ":man_golfing_tone1:": "1f3cc-1f3fb-200d-2642-fe0f",
  ":man_golfing_tone2:": "1f3cc-1f3fc-200d-2642-fe0f",
  ":man_golfing_tone3:": "1f3cc-1f3fd-200d-2642-fe0f",
  ":man_golfing_tone4:": "1f3cc-1f3fe-200d-2642-fe0f",
  ":man_golfing_tone5:": "1f3cc-1f3ff-200d-2642-fe0f",
  ":man_guard:": "1f482-200d-2642-fe0f",
  ":man_guard_tone1:": "1f482-1f3fb-200d-2642-fe0f",
  ":man_guard_tone2:": "1f482-1f3fc-200d-2642-fe0f",
  ":man_guard_tone3:": "1f482-1f3fd-200d-2642-fe0f",
  ":man_guard_tone4:": "1f482-1f3fe-200d-2642-fe0f",
  ":man_guard_tone5:": "1f482-1f3ff-200d-2642-fe0f",
  ":man_health_worker:": "1f468-200d-2695-fe0f",
  ":man_health_worker_tone1:": "1f468-1f3fb-200d-2695-fe0f",
  ":man_health_worker_tone2:": "1f468-1f3fc-200d-2695-fe0f",
  ":man_health_worker_tone3:": "1f468-1f3fd-200d-2695-fe0f",
  ":man_health_worker_tone4:": "1f468-1f3fe-200d-2695-fe0f",
  ":man_health_worker_tone5:": "1f468-1f3ff-200d-2695-fe0f",
  ":man_in_lotus_position:": "1f9d8-200d-2642-fe0f",
  ":man_in_lotus_position_tone1:": "1f9d8-1f3fb-200d-2642-fe0f",
  ":man_in_lotus_position_tone2:": "1f9d8-1f3fc-200d-2642-fe0f",
  ":man_in_lotus_position_tone3:": "1f9d8-1f3fd-200d-2642-fe0f",
  ":man_in_lotus_position_tone4:": "1f9d8-1f3fe-200d-2642-fe0f",
  ":man_in_lotus_position_tone5:": "1f9d8-1f3ff-200d-2642-fe0f",
  ":man_in_steamy_room:": "1f9d6-200d-2642-fe0f",
  ":man_in_steamy_room_tone1:": "1f9d6-1f3fb-200d-2642-fe0f",
  ":man_in_steamy_room_tone2:": "1f9d6-1f3fc-200d-2642-fe0f",
  ":man_in_steamy_room_tone3:": "1f9d6-1f3fd-200d-2642-fe0f",
  ":man_in_steamy_room_tone4:": "1f9d6-1f3fe-200d-2642-fe0f",
  ":man_in_steamy_room_tone5:": "1f9d6-1f3ff-200d-2642-fe0f",
  ":man_in_tuxedo:": "1f935",
  ":man_in_tuxedo_tone1:": "1f935-1f3fb",
  ":man_in_tuxedo_tone2:": "1f935-1f3fc",
  ":man_in_tuxedo_tone3:": "1f935-1f3fd",
  ":man_in_tuxedo_tone4:": "1f935-1f3fe",
  ":man_in_tuxedo_tone5:": "1f935-1f3ff",
  ":man_judge:": "1f468-200d-2696-fe0f",
  ":man_judge_tone1:": "1f468-1f3fb-200d-2696-fe0f",
  ":man_judge_tone2:": "1f468-1f3fc-200d-2696-fe0f",
  ":man_judge_tone3:": "1f468-1f3fd-200d-2696-fe0f",
  ":man_judge_tone4:": "1f468-1f3fe-200d-2696-fe0f",
  ":man_judge_tone5:": "1f468-1f3ff-200d-2696-fe0f",
  ":man_juggling:": "1f939-200d-2642-fe0f",
  ":man_juggling_tone1:": "1f939-1f3fb-200d-2642-fe0f",
  ":man_juggling_tone2:": "1f939-1f3fc-200d-2642-fe0f",
  ":man_juggling_tone3:": "1f939-1f3fd-200d-2642-fe0f",
  ":man_juggling_tone4:": "1f939-1f3fe-200d-2642-fe0f",
  ":man_juggling_tone5:": "1f939-1f3ff-200d-2642-fe0f",
  ":man_lifting_weights:": "1f3cb-fe0f-200d-2642-fe0f",
  ":man_lifting_weights_tone1:": "1f3cb-1f3fb-200d-2642-fe0f",
  ":man_lifting_weights_tone2:": "1f3cb-1f3fc-200d-2642-fe0f",
  ":man_lifting_weights_tone3:": "1f3cb-1f3fd-200d-2642-fe0f",
  ":man_lifting_weights_tone4:": "1f3cb-1f3fe-200d-2642-fe0f",
  ":man_lifting_weights_tone5:": "1f3cb-1f3ff-200d-2642-fe0f",
  ":man_mage:": "1f9d9-200d-2642-fe0f",
  ":man_mage_tone1:": "1f9d9-1f3fb-200d-2642-fe0f",
  ":man_mage_tone2:": "1f9d9-1f3fc-200d-2642-fe0f",
  ":man_mage_tone3:": "1f9d9-1f3fd-200d-2642-fe0f",
  ":man_mage_tone4:": "1f9d9-1f3fe-200d-2642-fe0f",
  ":man_mage_tone5:": "1f9d9-1f3ff-200d-2642-fe0f",
  ":man_mechanic:": "1f468-200d-1f527",
  ":man_mechanic_tone1:": "1f468-1f3fb-200d-1f527",
  ":man_mechanic_tone2:": "1f468-1f3fc-200d-1f527",
  ":man_mechanic_tone3:": "1f468-1f3fd-200d-1f527",
  ":man_mechanic_tone4:": "1f468-1f3fe-200d-1f527",
  ":man_mechanic_tone5:": "1f468-1f3ff-200d-1f527",
  ":man_mountain_biking:": "1f6b5-200d-2642-fe0f",
  ":man_mountain_biking_tone1:": "1f6b5-1f3fb-200d-2642-fe0f",
  ":man_mountain_biking_tone2:": "1f6b5-1f3fc-200d-2642-fe0f",
  ":man_mountain_biking_tone3:": "1f6b5-1f3fd-200d-2642-fe0f",
  ":man_mountain_biking_tone4:": "1f6b5-1f3fe-200d-2642-fe0f",
  ":man_mountain_biking_tone5:": "1f6b5-1f3ff-200d-2642-fe0f",
  ":man_office_worker:": "1f468-200d-1f4bc",
  ":man_office_worker_tone1:": "1f468-1f3fb-200d-1f4bc",
  ":man_office_worker_tone2:": "1f468-1f3fc-200d-1f4bc",
  ":man_office_worker_tone3:": "1f468-1f3fd-200d-1f4bc",
  ":man_office_worker_tone4:": "1f468-1f3fe-200d-1f4bc",
  ":man_office_worker_tone5:": "1f468-1f3ff-200d-1f4bc",
  ":man_pilot:": "1f468-200d-2708-fe0f",
  ":man_pilot_tone1:": "1f468-1f3fb-200d-2708-fe0f",
  ":man_pilot_tone2:": "1f468-1f3fc-200d-2708-fe0f",
  ":man_pilot_tone3:": "1f468-1f3fd-200d-2708-fe0f",
  ":man_pilot_tone4:": "1f468-1f3fe-200d-2708-fe0f",
  ":man_pilot_tone5:": "1f468-1f3ff-200d-2708-fe0f",
  ":man_playing_handball:": "1f93e-200d-2642-fe0f",
  ":man_playing_handball_tone1:": "1f93e-1f3fb-200d-2642-fe0f",
  ":man_playing_handball_tone2:": "1f93e-1f3fc-200d-2642-fe0f",
  ":man_playing_handball_tone3:": "1f93e-1f3fd-200d-2642-fe0f",
  ":man_playing_handball_tone4:": "1f93e-1f3fe-200d-2642-fe0f",
  ":man_playing_handball_tone5:": "1f93e-1f3ff-200d-2642-fe0f",
  ":man_playing_water_polo:": "1f93d-200d-2642-fe0f",
  ":man_playing_water_polo_tone1:": "1f93d-1f3fb-200d-2642-fe0f",
  ":man_playing_water_polo_tone2:": "1f93d-1f3fc-200d-2642-fe0f",
  ":man_playing_water_polo_tone3:": "1f93d-1f3fd-200d-2642-fe0f",
  ":man_playing_water_polo_tone4:": "1f93d-1f3fe-200d-2642-fe0f",
  ":man_playing_water_polo_tone5:": "1f93d-1f3ff-200d-2642-fe0f",
  ":man_police_officer:": "1f46e-200d-2642-fe0f",
  ":man_police_officer_tone1:": "1f46e-1f3fb-200d-2642-fe0f",
  ":man_police_officer_tone2:": "1f46e-1f3fc-200d-2642-fe0f",
  ":man_police_officer_tone3:": "1f46e-1f3fd-200d-2642-fe0f",
  ":man_police_officer_tone4:": "1f46e-1f3fe-200d-2642-fe0f",
  ":man_police_officer_tone5:": "1f46e-1f3ff-200d-2642-fe0f",
  ":man_pouting:": "1f64e-200d-2642-fe0f",
  ":man_pouting_tone1:": "1f64e-1f3fb-200d-2642-fe0f",
  ":man_pouting_tone2:": "1f64e-1f3fc-200d-2642-fe0f",
  ":man_pouting_tone3:": "1f64e-1f3fd-200d-2642-fe0f",
  ":man_pouting_tone4:": "1f64e-1f3fe-200d-2642-fe0f",
  ":man_pouting_tone5:": "1f64e-1f3ff-200d-2642-fe0f",
  ":man_raising_hand:": "1f64b-200d-2642-fe0f",
  ":man_raising_hand_tone1:": "1f64b-1f3fb-200d-2642-fe0f",
  ":man_raising_hand_tone2:": "1f64b-1f3fc-200d-2642-fe0f",
  ":man_raising_hand_tone3:": "1f64b-1f3fd-200d-2642-fe0f",
  ":man_raising_hand_tone4:": "1f64b-1f3fe-200d-2642-fe0f",
  ":man_raising_hand_tone5:": "1f64b-1f3ff-200d-2642-fe0f",
  ":man_red_haired:": "1f468-200d-1f9b0",
  ":man_red_haired_tone1:": "1f468-1f3fb-200d-1f9b0",
  ":man_red_haired_tone2:": "1f468-1f3fc-200d-1f9b0",
  ":man_red_haired_tone3:": "1f468-1f3fd-200d-1f9b0",
  ":man_red_haired_tone4:": "1f468-1f3fe-200d-1f9b0",
  ":man_red_haired_tone5:": "1f468-1f3ff-200d-1f9b0",
  ":man_rowing_boat:": "1f6a3-200d-2642-fe0f",
  ":man_rowing_boat_tone1:": "1f6a3-1f3fb-200d-2642-fe0f",
  ":man_rowing_boat_tone2:": "1f6a3-1f3fc-200d-2642-fe0f",
  ":man_rowing_boat_tone3:": "1f6a3-1f3fd-200d-2642-fe0f",
  ":man_rowing_boat_tone4:": "1f6a3-1f3fe-200d-2642-fe0f",
  ":man_rowing_boat_tone5:": "1f6a3-1f3ff-200d-2642-fe0f",
  ":man_running:": "1f3c3-200d-2642-fe0f",
  ":man_running_tone1:": "1f3c3-1f3fb-200d-2642-fe0f",
  ":man_running_tone2:": "1f3c3-1f3fc-200d-2642-fe0f",
  ":man_running_tone3:": "1f3c3-1f3fd-200d-2642-fe0f",
  ":man_running_tone4:": "1f3c3-1f3fe-200d-2642-fe0f",
  ":man_running_tone5:": "1f3c3-1f3ff-200d-2642-fe0f",
  ":man_scientist:": "1f468-200d-1f52c",
  ":man_scientist_tone1:": "1f468-1f3fb-200d-1f52c",
  ":man_scientist_tone2:": "1f468-1f3fc-200d-1f52c",
  ":man_scientist_tone3:": "1f468-1f3fd-200d-1f52c",
  ":man_scientist_tone4:": "1f468-1f3fe-200d-1f52c",
  ":man_scientist_tone5:": "1f468-1f3ff-200d-1f52c",
  ":man_shrugging:": "1f937-200d-2642-fe0f",
  ":man_shrugging_tone1:": "1f937-1f3fb-200d-2642-fe0f",
  ":man_shrugging_tone2:": "1f937-1f3fc-200d-2642-fe0f",
  ":man_shrugging_tone3:": "1f937-1f3fd-200d-2642-fe0f",
  ":man_shrugging_tone4:": "1f937-1f3fe-200d-2642-fe0f",
  ":man_shrugging_tone5:": "1f937-1f3ff-200d-2642-fe0f",
  ":man_singer:": "1f468-200d-1f3a4",
  ":man_singer_tone1:": "1f468-1f3fb-200d-1f3a4",
  ":man_singer_tone2:": "1f468-1f3fc-200d-1f3a4",
  ":man_singer_tone3:": "1f468-1f3fd-200d-1f3a4",
  ":man_singer_tone4:": "1f468-1f3fe-200d-1f3a4",
  ":man_singer_tone5:": "1f468-1f3ff-200d-1f3a4",
  ":man_student:": "1f468-200d-1f393",
  ":man_student_tone1:": "1f468-1f3fb-200d-1f393",
  ":man_student_tone2:": "1f468-1f3fc-200d-1f393",
  ":man_student_tone3:": "1f468-1f3fd-200d-1f393",
  ":man_student_tone4:": "1f468-1f3fe-200d-1f393",
  ":man_student_tone5:": "1f468-1f3ff-200d-1f393",
  ":man_superhero:": "1f9b8-200d-2642-fe0f",
  ":man_superhero_tone1:": "1f9b8-1f3fb-200d-2642-fe0f",
  ":man_superhero_tone2:": "1f9b8-1f3fc-200d-2642-fe0f",
  ":man_superhero_tone3:": "1f9b8-1f3fd-200d-2642-fe0f",
  ":man_superhero_tone4:": "1f9b8-1f3fe-200d-2642-fe0f",
  ":man_superhero_tone5:": "1f9b8-1f3ff-200d-2642-fe0f",
  ":man_supervillain:": "1f9b9-200d-2642-fe0f",
  ":man_supervillain_tone1:": "1f9b9-1f3fb-200d-2642-fe0f",
  ":man_supervillain_tone2:": "1f9b9-1f3fc-200d-2642-fe0f",
  ":man_supervillain_tone3:": "1f9b9-1f3fd-200d-2642-fe0f",
  ":man_supervillain_tone4:": "1f9b9-1f3fe-200d-2642-fe0f",
  ":man_supervillain_tone5:": "1f9b9-1f3ff-200d-2642-fe0f",
  ":man_surfing:": "1f3c4-200d-2642-fe0f",
  ":man_surfing_tone1:": "1f3c4-1f3fb-200d-2642-fe0f",
  ":man_surfing_tone2:": "1f3c4-1f3fc-200d-2642-fe0f",
  ":man_surfing_tone3:": "1f3c4-1f3fd-200d-2642-fe0f",
  ":man_surfing_tone4:": "1f3c4-1f3fe-200d-2642-fe0f",
  ":man_surfing_tone5:": "1f3c4-1f3ff-200d-2642-fe0f",
  ":man_swimming:": "1f3ca-200d-2642-fe0f",
  ":man_swimming_tone1:": "1f3ca-1f3fb-200d-2642-fe0f",
  ":man_swimming_tone2:": "1f3ca-1f3fc-200d-2642-fe0f",
  ":man_swimming_tone3:": "1f3ca-1f3fd-200d-2642-fe0f",
  ":man_swimming_tone4:": "1f3ca-1f3fe-200d-2642-fe0f",
  ":man_swimming_tone5:": "1f3ca-1f3ff-200d-2642-fe0f",
  ":man_teacher:": "1f468-200d-1f3eb",
  ":man_teacher_tone1:": "1f468-1f3fb-200d-1f3eb",
  ":man_teacher_tone2:": "1f468-1f3fc-200d-1f3eb",
  ":man_teacher_tone3:": "1f468-1f3fd-200d-1f3eb",
  ":man_teacher_tone4:": "1f468-1f3fe-200d-1f3eb",
  ":man_teacher_tone5:": "1f468-1f3ff-200d-1f3eb",
  ":man_technologist:": "1f468-200d-1f4bb",
  ":man_technologist_tone1:": "1f468-1f3fb-200d-1f4bb",
  ":man_technologist_tone2:": "1f468-1f3fc-200d-1f4bb",
  ":man_technologist_tone3:": "1f468-1f3fd-200d-1f4bb",
  ":man_technologist_tone4:": "1f468-1f3fe-200d-1f4bb",
  ":man_technologist_tone5:": "1f468-1f3ff-200d-1f4bb",
  ":man_tipping_hand:": "1f481-200d-2642-fe0f",
  ":man_tipping_hand_tone1:": "1f481-1f3fb-200d-2642-fe0f",
  ":man_tipping_hand_tone2:": "1f481-1f3fc-200d-2642-fe0f",
  ":man_tipping_hand_tone3:": "1f481-1f3fd-200d-2642-fe0f",
  ":man_tipping_hand_tone4:": "1f481-1f3fe-200d-2642-fe0f",
  ":man_tipping_hand_tone5:": "1f481-1f3ff-200d-2642-fe0f",
  ":man_tone1:": "1f468-1f3fb",
  ":man_tone2:": "1f468-1f3fc",
  ":man_tone3:": "1f468-1f3fd",
  ":man_tone4:": "1f468-1f3fe",
  ":man_tone5:": "1f468-1f3ff",
  ":man_vampire:": "1f9db-200d-2642-fe0f",
  ":man_vampire_tone1:": "1f9db-1f3fb-200d-2642-fe0f",
  ":man_vampire_tone2:": "1f9db-1f3fc-200d-2642-fe0f",
  ":man_vampire_tone3:": "1f9db-1f3fd-200d-2642-fe0f",
  ":man_vampire_tone4:": "1f9db-1f3fe-200d-2642-fe0f",
  ":man_vampire_tone5:": "1f9db-1f3ff-200d-2642-fe0f",
  ":man_walking:": "1f6b6-200d-2642-fe0f",
  ":man_walking_tone1:": "1f6b6-1f3fb-200d-2642-fe0f",
  ":man_walking_tone2:": "1f6b6-1f3fc-200d-2642-fe0f",
  ":man_walking_tone3:": "1f6b6-1f3fd-200d-2642-fe0f",
  ":man_walking_tone4:": "1f6b6-1f3fe-200d-2642-fe0f",
  ":man_walking_tone5:": "1f6b6-1f3ff-200d-2642-fe0f",
  ":man_wearing_turban:": "1f473-200d-2642-fe0f",
  ":man_wearing_turban_tone1:": "1f473-1f3fb-200d-2642-fe0f",
  ":man_wearing_turban_tone2:": "1f473-1f3fc-200d-2642-fe0f",
  ":man_wearing_turban_tone3:": "1f473-1f3fd-200d-2642-fe0f",
  ":man_wearing_turban_tone4:": "1f473-1f3fe-200d-2642-fe0f",
  ":man_wearing_turban_tone5:": "1f473-1f3ff-200d-2642-fe0f",
  ":man_white_haired:": "1f468-200d-1f9b3",
  ":man_white_haired_tone1:": "1f468-1f3fb-200d-1f9b3",
  ":man_white_haired_tone2:": "1f468-1f3fc-200d-1f9b3",
  ":man_white_haired_tone3:": "1f468-1f3fd-200d-1f9b3",
  ":man_white_haired_tone4:": "1f468-1f3fe-200d-1f9b3",
  ":man_white_haired_tone5:": "1f468-1f3ff-200d-1f9b3",
  ":man_with_chinese_cap:": "1f472",
  ":man_with_chinese_cap_tone1:": "1f472-1f3fb",
  ":man_with_chinese_cap_tone2:": "1f472-1f3fc",
  ":man_with_chinese_cap_tone3:": "1f472-1f3fd",
  ":man_with_chinese_cap_tone4:": "1f472-1f3fe",
  ":man_with_chinese_cap_tone5:": "1f472-1f3ff",
  ":man_zombie:": "1f9df-200d-2642-fe0f",
  ":mango:": "1f96d",
  ":mans_shoe:": "1f45e",
  ":map:": "1f5fa",
  ":maple_leaf:": "1f341",
  ":martial_arts_uniform:": "1f94b",
  ":mask:": "1f637",
  ":meat_on_bone:": "1f356",
  ":medal:": "1f3c5",
  ":medical_symbol:": "2695",
  ":mega:": "1f4e3",
  ":melon:": "1f348",
  ":men_with_bunny_ears_partying:": "1f46f-200d-2642-fe0f",
  ":men_wrestling:": "1f93c-200d-2642-fe0f",
  ":menorah:": "1f54e",
  ":mens:": "1f6b9",
  ":mermaid:": "1f9dc-200d-2640-fe0f",
  ":mermaid_tone1:": "1f9dc-1f3fb-200d-2640-fe0f",
  ":mermaid_tone2:": "1f9dc-1f3fc-200d-2640-fe0f",
  ":mermaid_tone3:": "1f9dc-1f3fd-200d-2640-fe0f",
  ":mermaid_tone4:": "1f9dc-1f3fe-200d-2640-fe0f",
  ":mermaid_tone5:": "1f9dc-1f3ff-200d-2640-fe0f",
  ":merman:": "1f9dc-200d-2642-fe0f",
  ":merman_tone1:": "1f9dc-1f3fb-200d-2642-fe0f",
  ":merman_tone2:": "1f9dc-1f3fc-200d-2642-fe0f",
  ":merman_tone3:": "1f9dc-1f3fd-200d-2642-fe0f",
  ":merman_tone4:": "1f9dc-1f3fe-200d-2642-fe0f",
  ":merman_tone5:": "1f9dc-1f3ff-200d-2642-fe0f",
  ":merperson:": "1f9dc",
  ":merperson_tone1:": "1f9dc-1f3fb",
  ":merperson_tone2:": "1f9dc-1f3fc",
  ":merperson_tone3:": "1f9dc-1f3fd",
  ":merperson_tone4:": "1f9dc-1f3fe",
  ":merperson_tone5:": "1f9dc-1f3ff",
  ":metal:": "1f918",
  ":metal_tone1:": "1f918-1f3fb",
  ":metal_tone2:": "1f918-1f3fc",
  ":metal_tone3:": "1f918-1f3fd",
  ":metal_tone4:": "1f918-1f3fe",
  ":metal_tone5:": "1f918-1f3ff",
  ":metro:": "1f687",
  ":microbe:": "1f9a0",
  ":microphone2:": "1f399",
  ":microphone:": "1f3a4",
  ":microscope:": "1f52c",
  ":middle_finger:": "1f595",
  ":middle_finger_tone1:": "1f595-1f3fb",
  ":middle_finger_tone2:": "1f595-1f3fc",
  ":middle_finger_tone3:": "1f595-1f3fd",
  ":middle_finger_tone4:": "1f595-1f3fe",
  ":middle_finger_tone5:": "1f595-1f3ff",
  ":military_medal:": "1f396",
  ":milk:": "1f95b",
  ":milky_way:": "1f30c",
  ":minibus:": "1f690",
  ":minidisc:": "1f4bd",
  ":mobile_phone_off:": "1f4f4",
  ":money_mouth:": "1f911",
  ":money_with_wings:": "1f4b8",
  ":moneybag:": "1f4b0",
  ":monkey:": "1f412",
  ":monkey_face:": "1f435",
  ":monorail:": "1f69d",
  ":moon_cake:": "1f96e",
  ":mortar_board:": "1f393",
  ":mosque:": "1f54c",
  ":mosquito:": "1f99f",
  ":motor_scooter:": "1f6f5",
  ":motorboat:": "1f6e5",
  ":motorcycle:": "1f3cd",
  ":motorway:": "1f6e3",
  ":mount_fuji:": "1f5fb",
  ":mountain:": "26f0",
  ":mountain_cableway:": "1f6a0",
  ":mountain_railway:": "1f69e",
  ":mountain_snow:": "1f3d4",
  ":mouse2:": "1f401",
  ":mouse:": "1f42d",
  ":mouse_three_button:": "1f5b1",
  ":movie_camera:": "1f3a5",
  ":moyai:": "1f5ff",
  ":mrs_claus:": "1f936",
  ":mrs_claus_tone1:": "1f936-1f3fb",
  ":mrs_claus_tone2:": "1f936-1f3fc",
  ":mrs_claus_tone3:": "1f936-1f3fd",
  ":mrs_claus_tone4:": "1f936-1f3fe",
  ":mrs_claus_tone5:": "1f936-1f3ff",
  ":muscle:": "1f4aa",
  ":muscle_tone1:": "1f4aa-1f3fb",
  ":muscle_tone2:": "1f4aa-1f3fc",
  ":muscle_tone3:": "1f4aa-1f3fd",
  ":muscle_tone4:": "1f4aa-1f3fe",
  ":muscle_tone5:": "1f4aa-1f3ff",
  ":mushroom:": "1f344",
  ":musical_keyboard:": "1f3b9",
  ":musical_note:": "1f3b5",
  ":musical_score:": "1f3bc",
  ":mute:": "1f507",
  ":nail_care:": "1f485",
  ":nail_care_tone1:": "1f485-1f3fb",
  ":nail_care_tone2:": "1f485-1f3fc",
  ":nail_care_tone3:": "1f485-1f3fd",
  ":nail_care_tone4:": "1f485-1f3fe",
  ":nail_care_tone5:": "1f485-1f3ff",
  ":name_badge:": "1f4db",
  ":nauseated_face:": "1f922",
  ":nazar_amulet:": "1f9ff",
  ":necktie:": "1f454",
  ":negative_squared_cross_mark:": "274e",
  ":nerd:": "1f913",
  ":neutral_face:": "1f610",
  ":new:": "1f195",
  ":new_moon:": "1f311",
  ":new_moon_with_face:": "1f31a",
  ":newspaper2:": "1f5de",
  ":newspaper:": "1f4f0",
  ":ng:": "1f196",
  ":night_with_stars:": "1f303",
  ":nine:": "0039-fe0f-20e3",
  ":no_bell:": "1f515",
  ":no_bicycles:": "1f6b3",
  ":no_entry:": "26d4",
  ":no_entry_sign:": "1f6ab",
  ":no_mobile_phones:": "1f4f5",
  ":no_mouth:": "1f636",
  ":no_pedestrians:": "1f6b7",
  ":no_smoking:": "1f6ad",
  ":non-potable_water:": "1f6b1",
  ":nose:": "1f443",
  ":nose_tone1:": "1f443-1f3fb",
  ":nose_tone2:": "1f443-1f3fc",
  ":nose_tone3:": "1f443-1f3fd",
  ":nose_tone4:": "1f443-1f3fe",
  ":nose_tone5:": "1f443-1f3ff",
  ":notebook:": "1f4d3",
  ":notebook_with_decorative_cover:": "1f4d4",
  ":notepad_spiral:": "1f5d2",
  ":notes:": "1f3b6",
  ":nut_and_bolt:": "1f529",
  ":o2:": "1f17e",
  ":o:": "2b55",
  ":ocean:": "1f30a",
  ":octagonal_sign:": "1f6d1",
  ":octopus:": "1f419",
  ":oden:": "1f362",
  ":office:": "1f3e2",
  ":oil:": "1f6e2",
  ":ok:": "1f197",
  ":ok_hand:": "1f44c",
  ":ok_hand_tone1:": "1f44c-1f3fb",
  ":ok_hand_tone2:": "1f44c-1f3fc",
  ":ok_hand_tone3:": "1f44c-1f3fd",
  ":ok_hand_tone4:": "1f44c-1f3fe",
  ":ok_hand_tone5:": "1f44c-1f3ff",
  ":older_adult:": "1f9d3",
  ":older_adult_tone1:": "1f9d3-1f3fb",
  ":older_adult_tone2:": "1f9d3-1f3fc",
  ":older_adult_tone3:": "1f9d3-1f3fd",
  ":older_adult_tone4:": "1f9d3-1f3fe",
  ":older_adult_tone5:": "1f9d3-1f3ff",
  ":older_man:": "1f474",
  ":older_man_tone1:": "1f474-1f3fb",
  ":older_man_tone2:": "1f474-1f3fc",
  ":older_man_tone3:": "1f474-1f3fd",
  ":older_man_tone4:": "1f474-1f3fe",
  ":older_man_tone5:": "1f474-1f3ff",
  ":older_woman:": "1f475",
  ":older_woman_tone1:": "1f475-1f3fb",
  ":older_woman_tone2:": "1f475-1f3fc",
  ":older_woman_tone3:": "1f475-1f3fd",
  ":older_woman_tone4:": "1f475-1f3fe",
  ":older_woman_tone5:": "1f475-1f3ff",
  ":om_symbol:": "1f549",
  ":on:": "1f51b",
  ":oncoming_automobile:": "1f698",
  ":oncoming_bus:": "1f68d",
  ":oncoming_police_car:": "1f694",
  ":oncoming_taxi:": "1f696",
  ":one:": "0031-fe0f-20e3",
  ":open_file_folder:": "1f4c2",
  ":open_hands:": "1f450",
  ":open_hands_tone1:": "1f450-1f3fb",
  ":open_hands_tone2:": "1f450-1f3fc",
  ":open_hands_tone3:": "1f450-1f3fd",
  ":open_hands_tone4:": "1f450-1f3fe",
  ":open_hands_tone5:": "1f450-1f3ff",
  ":open_mouth:": "1f62e",
  ":ophiuchus:": "26ce",
  ":orange_book:": "1f4d9",
  ":orange_heart:": "1f9e1",
  ":orthodox_cross:": "2626",
  ":outbox_tray:": "1f4e4",
  ":owl:": "1f989",
  ":ox:": "1f402",
  ":package:": "1f4e6",
  ":page_facing_up:": "1f4c4",
  ":page_with_curl:": "1f4c3",
  ":pager:": "1f4df",
  ":paintbrush:": "1f58c",
  ":palm_tree:": "1f334",
  ":palms_up_together:": "1f932",
  ":palms_up_together_tone1:": "1f932-1f3fb",
  ":palms_up_together_tone2:": "1f932-1f3fc",
  ":palms_up_together_tone3:": "1f932-1f3fd",
  ":palms_up_together_tone4:": "1f932-1f3fe",
  ":palms_up_together_tone5:": "1f932-1f3ff",
  ":pancakes:": "1f95e",
  ":panda_face:": "1f43c",
  ":paperclip:": "1f4ce",
  ":paperclips:": "1f587",
  ":park:": "1f3de",
  ":parking:": "1f17f",
  ":parrot:": "1f99c",
  ":part_alternation_mark:": "303d",
  ":partly_sunny:": "26c5",
  ":partying_face:": "1f973",
  ":passport_control:": "1f6c2",
  ":pause_button:": "23f8",
  ":peace:": "262e",
  ":peach:": "1f351",
  ":peacock:": "1f99a",
  ":peanuts:": "1f95c",
  ":pear:": "1f350",
  ":pen_ballpoint:": "1f58a",
  ":pen_fountain:": "1f58b",
  ":pencil2:": "270f",
  ":pencil:": "1f4dd",
  ":penguin:": "1f427",
  ":pensive:": "1f614",
  ":people_with_bunny_ears_partying:": "1f46f",
  ":people_wrestling:": "1f93c",
  ":performing_arts:": "1f3ad",
  ":persevere:": "1f623",
  ":person_biking:": "1f6b4",
  ":person_biking_tone1:": "1f6b4-1f3fb",
  ":person_biking_tone2:": "1f6b4-1f3fc",
  ":person_biking_tone3:": "1f6b4-1f3fd",
  ":person_biking_tone4:": "1f6b4-1f3fe",
  ":person_biking_tone5:": "1f6b4-1f3ff",
  ":person_bouncing_ball:": "26f9",
  ":person_bouncing_ball_tone1:": "26f9-1f3fb",
  ":person_bouncing_ball_tone2:": "26f9-1f3fc",
  ":person_bouncing_ball_tone3:": "26f9-1f3fd",
  ":person_bouncing_ball_tone4:": "26f9-1f3fe",
  ":person_bouncing_ball_tone5:": "26f9-1f3ff",
  ":person_bowing:": "1f647",
  ":person_bowing_tone1:": "1f647-1f3fb",
  ":person_bowing_tone2:": "1f647-1f3fc",
  ":person_bowing_tone3:": "1f647-1f3fd",
  ":person_bowing_tone4:": "1f647-1f3fe",
  ":person_bowing_tone5:": "1f647-1f3ff",
  ":person_climbing:": "1f9d7",
  ":person_climbing_tone1:": "1f9d7-1f3fb",
  ":person_climbing_tone2:": "1f9d7-1f3fc",
  ":person_climbing_tone3:": "1f9d7-1f3fd",
  ":person_climbing_tone4:": "1f9d7-1f3fe",
  ":person_climbing_tone5:": "1f9d7-1f3ff",
  ":person_doing_cartwheel:": "1f938",
  ":person_doing_cartwheel_tone1:": "1f938-1f3fb",
  ":person_doing_cartwheel_tone2:": "1f938-1f3fc",
  ":person_doing_cartwheel_tone3:": "1f938-1f3fd",
  ":person_doing_cartwheel_tone4:": "1f938-1f3fe",
  ":person_doing_cartwheel_tone5:": "1f938-1f3ff",
  ":person_facepalming:": "1f926",
  ":person_facepalming_tone1:": "1f926-1f3fb",
  ":person_facepalming_tone2:": "1f926-1f3fc",
  ":person_facepalming_tone3:": "1f926-1f3fd",
  ":person_facepalming_tone4:": "1f926-1f3fe",
  ":person_facepalming_tone5:": "1f926-1f3ff",
  ":person_fencing:": "1f93a",
  ":person_frowning:": "1f64d",
  ":person_frowning_tone1:": "1f64d-1f3fb",
  ":person_frowning_tone2:": "1f64d-1f3fc",
  ":person_frowning_tone3:": "1f64d-1f3fd",
  ":person_frowning_tone4:": "1f64d-1f3fe",
  ":person_frowning_tone5:": "1f64d-1f3ff",
  ":person_gesturing_no:": "1f645",
  ":person_gesturing_no_tone1:": "1f645-1f3fb",
  ":person_gesturing_no_tone2:": "1f645-1f3fc",
  ":person_gesturing_no_tone3:": "1f645-1f3fd",
  ":person_gesturing_no_tone4:": "1f645-1f3fe",
  ":person_gesturing_no_tone5:": "1f645-1f3ff",
  ":person_gesturing_ok:": "1f646",
  ":person_gesturing_ok_tone1:": "1f646-1f3fb",
  ":person_gesturing_ok_tone2:": "1f646-1f3fc",
  ":person_gesturing_ok_tone3:": "1f646-1f3fd",
  ":person_gesturing_ok_tone4:": "1f646-1f3fe",
  ":person_gesturing_ok_tone5:": "1f646-1f3ff",
  ":person_getting_haircut:": "1f487",
  ":person_getting_haircut_tone1:": "1f487-1f3fb",
  ":person_getting_haircut_tone2:": "1f487-1f3fc",
  ":person_getting_haircut_tone3:": "1f487-1f3fd",
  ":person_getting_haircut_tone4:": "1f487-1f3fe",
  ":person_getting_haircut_tone5:": "1f487-1f3ff",
  ":person_getting_massage:": "1f486",
  ":person_getting_massage_tone1:": "1f486-1f3fb",
  ":person_getting_massage_tone2:": "1f486-1f3fc",
  ":person_getting_massage_tone3:": "1f486-1f3fd",
  ":person_getting_massage_tone4:": "1f486-1f3fe",
  ":person_getting_massage_tone5:": "1f486-1f3ff",
  ":person_golfing:": "1f3cc",
  ":person_golfing_tone1:": "1f3cc-1f3fb",
  ":person_golfing_tone2:": "1f3cc-1f3fc",
  ":person_golfing_tone3:": "1f3cc-1f3fd",
  ":person_golfing_tone4:": "1f3cc-1f3fe",
  ":person_golfing_tone5:": "1f3cc-1f3ff",
  ":person_in_bed_tone1:": "1f6cc-1f3fb",
  ":person_in_bed_tone2:": "1f6cc-1f3fc",
  ":person_in_bed_tone3:": "1f6cc-1f3fd",
  ":person_in_bed_tone4:": "1f6cc-1f3fe",
  ":person_in_bed_tone5:": "1f6cc-1f3ff",
  ":person_in_lotus_position:": "1f9d8",
  ":person_in_lotus_position_tone1:": "1f9d8-1f3fb",
  ":person_in_lotus_position_tone2:": "1f9d8-1f3fc",
  ":person_in_lotus_position_tone3:": "1f9d8-1f3fd",
  ":person_in_lotus_position_tone4:": "1f9d8-1f3fe",
  ":person_in_lotus_position_tone5:": "1f9d8-1f3ff",
  ":person_in_steamy_room:": "1f9d6",
  ":person_in_steamy_room_tone1:": "1f9d6-1f3fb",
  ":person_in_steamy_room_tone2:": "1f9d6-1f3fc",
  ":person_in_steamy_room_tone3:": "1f9d6-1f3fd",
  ":person_in_steamy_room_tone4:": "1f9d6-1f3fe",
  ":person_in_steamy_room_tone5:": "1f9d6-1f3ff",
  ":person_juggling:": "1f939",
  ":person_juggling_tone1:": "1f939-1f3fb",
  ":person_juggling_tone2:": "1f939-1f3fc",
  ":person_juggling_tone3:": "1f939-1f3fd",
  ":person_juggling_tone4:": "1f939-1f3fe",
  ":person_juggling_tone5:": "1f939-1f3ff",
  ":person_lifting_weights:": "1f3cb",
  ":person_lifting_weights_tone1:": "1f3cb-1f3fb",
  ":person_lifting_weights_tone2:": "1f3cb-1f3fc",
  ":person_lifting_weights_tone3:": "1f3cb-1f3fd",
  ":person_lifting_weights_tone4:": "1f3cb-1f3fe",
  ":person_lifting_weights_tone5:": "1f3cb-1f3ff",
  ":person_mountain_biking:": "1f6b5",
  ":person_mountain_biking_tone1:": "1f6b5-1f3fb",
  ":person_mountain_biking_tone2:": "1f6b5-1f3fc",
  ":person_mountain_biking_tone3:": "1f6b5-1f3fd",
  ":person_mountain_biking_tone4:": "1f6b5-1f3fe",
  ":person_mountain_biking_tone5:": "1f6b5-1f3ff",
  ":person_playing_handball:": "1f93e",
  ":person_playing_handball_tone1:": "1f93e-1f3fb",
  ":person_playing_handball_tone2:": "1f93e-1f3fc",
  ":person_playing_handball_tone3:": "1f93e-1f3fd",
  ":person_playing_handball_tone4:": "1f93e-1f3fe",
  ":person_playing_handball_tone5:": "1f93e-1f3ff",
  ":person_playing_water_polo:": "1f93d",
  ":person_playing_water_polo_tone1:": "1f93d-1f3fb",
  ":person_playing_water_polo_tone2:": "1f93d-1f3fc",
  ":person_playing_water_polo_tone3:": "1f93d-1f3fd",
  ":person_playing_water_polo_tone4:": "1f93d-1f3fe",
  ":person_playing_water_polo_tone5:": "1f93d-1f3ff",
  ":person_pouting:": "1f64e",
  ":person_pouting_tone1:": "1f64e-1f3fb",
  ":person_pouting_tone2:": "1f64e-1f3fc",
  ":person_pouting_tone3:": "1f64e-1f3fd",
  ":person_pouting_tone4:": "1f64e-1f3fe",
  ":person_pouting_tone5:": "1f64e-1f3ff",
  ":person_raising_hand:": "1f64b",
  ":person_raising_hand_tone1:": "1f64b-1f3fb",
  ":person_raising_hand_tone2:": "1f64b-1f3fc",
  ":person_raising_hand_tone3:": "1f64b-1f3fd",
  ":person_raising_hand_tone4:": "1f64b-1f3fe",
  ":person_raising_hand_tone5:": "1f64b-1f3ff",
  ":person_rowing_boat:": "1f6a3",
  ":person_rowing_boat_tone1:": "1f6a3-1f3fb",
  ":person_rowing_boat_tone2:": "1f6a3-1f3fc",
  ":person_rowing_boat_tone3:": "1f6a3-1f3fd",
  ":person_rowing_boat_tone4:": "1f6a3-1f3fe",
  ":person_rowing_boat_tone5:": "1f6a3-1f3ff",
  ":person_running:": "1f3c3",
  ":person_running_tone1:": "1f3c3-1f3fb",
  ":person_running_tone2:": "1f3c3-1f3fc",
  ":person_running_tone3:": "1f3c3-1f3fd",
  ":person_running_tone4:": "1f3c3-1f3fe",
  ":person_running_tone5:": "1f3c3-1f3ff",
  ":person_shrugging:": "1f937",
  ":person_shrugging_tone1:": "1f937-1f3fb",
  ":person_shrugging_tone2:": "1f937-1f3fc",
  ":person_shrugging_tone3:": "1f937-1f3fd",
  ":person_shrugging_tone4:": "1f937-1f3fe",
  ":person_shrugging_tone5:": "1f937-1f3ff",
  ":person_surfing:": "1f3c4",
  ":person_surfing_tone1:": "1f3c4-1f3fb",
  ":person_surfing_tone2:": "1f3c4-1f3fc",
  ":person_surfing_tone3:": "1f3c4-1f3fd",
  ":person_surfing_tone4:": "1f3c4-1f3fe",
  ":person_surfing_tone5:": "1f3c4-1f3ff",
  ":person_swimming:": "1f3ca",
  ":person_swimming_tone1:": "1f3ca-1f3fb",
  ":person_swimming_tone2:": "1f3ca-1f3fc",
  ":person_swimming_tone3:": "1f3ca-1f3fd",
  ":person_swimming_tone4:": "1f3ca-1f3fe",
  ":person_swimming_tone5:": "1f3ca-1f3ff",
  ":person_tipping_hand:": "1f481",
  ":person_tipping_hand_tone1:": "1f481-1f3fb",
  ":person_tipping_hand_tone2:": "1f481-1f3fc",
  ":person_tipping_hand_tone3:": "1f481-1f3fd",
  ":person_tipping_hand_tone4:": "1f481-1f3fe",
  ":person_tipping_hand_tone5:": "1f481-1f3ff",
  ":person_walking:": "1f6b6",
  ":person_walking_tone1:": "1f6b6-1f3fb",
  ":person_walking_tone2:": "1f6b6-1f3fc",
  ":person_walking_tone3:": "1f6b6-1f3fd",
  ":person_walking_tone4:": "1f6b6-1f3fe",
  ":person_walking_tone5:": "1f6b6-1f3ff",
  ":person_wearing_turban:": "1f473",
  ":person_wearing_turban_tone1:": "1f473-1f3fb",
  ":person_wearing_turban_tone2:": "1f473-1f3fc",
  ":person_wearing_turban_tone3:": "1f473-1f3fd",
  ":person_wearing_turban_tone4:": "1f473-1f3fe",
  ":person_wearing_turban_tone5:": "1f473-1f3ff",
  ":petri_dish:": "1f9eb",
  ":pick:": "26cf",
  ":pie:": "1f967",
  ":pig2:": "1f416",
  ":pig:": "1f437",
  ":pig_nose:": "1f43d",
  ":pill:": "1f48a",
  ":pineapple:": "1f34d",
  ":ping_pong:": "1f3d3",
  ":pirate_flag:": "1f3f4-200d-2620-fe0f",
  ":pisces:": "2653",
  ":pizza:": "1f355",
  ":place_of_worship:": "1f6d0",
  ":play_pause:": "23ef",
  ":pleading_face:": "1f97a",
  ":point_down:": "1f447",
  ":point_down_tone1:": "1f447-1f3fb",
  ":point_down_tone2:": "1f447-1f3fc",
  ":point_down_tone3:": "1f447-1f3fd",
  ":point_down_tone4:": "1f447-1f3fe",
  ":point_down_tone5:": "1f447-1f3ff",
  ":point_left:": "1f448",
  ":point_left_tone1:": "1f448-1f3fb",
  ":point_left_tone2:": "1f448-1f3fc",
  ":point_left_tone3:": "1f448-1f3fd",
  ":point_left_tone4:": "1f448-1f3fe",
  ":point_left_tone5:": "1f448-1f3ff",
  ":point_right:": "1f449",
  ":point_right_tone1:": "1f449-1f3fb",
  ":point_right_tone2:": "1f449-1f3fc",
  ":point_right_tone3:": "1f449-1f3fd",
  ":point_right_tone4:": "1f449-1f3fe",
  ":point_right_tone5:": "1f449-1f3ff",
  ":point_up:": "261d",
  ":point_up_2:": "1f446",
  ":point_up_2_tone1:": "1f446-1f3fb",
  ":point_up_2_tone2:": "1f446-1f3fc",
  ":point_up_2_tone3:": "1f446-1f3fd",
  ":point_up_2_tone4:": "1f446-1f3fe",
  ":point_up_2_tone5:": "1f446-1f3ff",
  ":point_up_tone1:": "261d-1f3fb",
  ":point_up_tone2:": "261d-1f3fc",
  ":point_up_tone3:": "261d-1f3fd",
  ":point_up_tone4:": "261d-1f3fe",
  ":point_up_tone5:": "261d-1f3ff",
  ":police_car:": "1f693",
  ":police_officer:": "1f46e",
  ":police_officer_tone1:": "1f46e-1f3fb",
  ":police_officer_tone2:": "1f46e-1f3fc",
  ":police_officer_tone3:": "1f46e-1f3fd",
  ":police_officer_tone4:": "1f46e-1f3fe",
  ":police_officer_tone5:": "1f46e-1f3ff",
  ":poodle:": "1f429",
  ":poop:": "1f4a9",
  ":popcorn:": "1f37f",
  ":post_office:": "1f3e3",
  ":postal_horn:": "1f4ef",
  ":postbox:": "1f4ee",
  ":potable_water:": "1f6b0",
  ":potato:": "1f954",
  ":pouch:": "1f45d",
  ":poultry_leg:": "1f357",
  ":pound:": "1f4b7",
  ":pouting_cat:": "1f63e",
  ":pray:": "1f64f",
  ":pray_tone1:": "1f64f-1f3fb",
  ":pray_tone2:": "1f64f-1f3fc",
  ":pray_tone3:": "1f64f-1f3fd",
  ":pray_tone4:": "1f64f-1f3fe",
  ":pray_tone5:": "1f64f-1f3ff",
  ":prayer_beads:": "1f4ff",
  ":pregnant_woman:": "1f930",
  ":pregnant_woman_tone1:": "1f930-1f3fb",
  ":pregnant_woman_tone2:": "1f930-1f3fc",
  ":pregnant_woman_tone3:": "1f930-1f3fd",
  ":pregnant_woman_tone4:": "1f930-1f3fe",
  ":pregnant_woman_tone5:": "1f930-1f3ff",
  ":pretzel:": "1f968",
  ":prince:": "1f934",
  ":prince_tone1:": "1f934-1f3fb",
  ":prince_tone2:": "1f934-1f3fc",
  ":prince_tone3:": "1f934-1f3fd",
  ":prince_tone4:": "1f934-1f3fe",
  ":prince_tone5:": "1f934-1f3ff",
  ":princess:": "1f478",
  ":princess_tone1:": "1f478-1f3fb",
  ":princess_tone2:": "1f478-1f3fc",
  ":princess_tone3:": "1f478-1f3fd",
  ":princess_tone4:": "1f478-1f3fe",
  ":princess_tone5:": "1f478-1f3ff",
  ":printer:": "1f5a8",
  ":projector:": "1f4fd",
  ":punch:": "1f44a",
  ":punch_tone1:": "1f44a-1f3fb",
  ":punch_tone2:": "1f44a-1f3fc",
  ":punch_tone3:": "1f44a-1f3fd",
  ":punch_tone4:": "1f44a-1f3fe",
  ":punch_tone5:": "1f44a-1f3ff",
  ":purple_heart:": "1f49c",
  ":purse:": "1f45b",
  ":pushpin:": "1f4cc",
  ":put_litter_in_its_place:": "1f6ae",
  ":question:": "2753",
  ":rabbit2:": "1f407",
  ":rabbit:": "1f430",
  ":raccoon:": "1f99d",
  ":race_car:": "1f3ce",
  ":racehorse:": "1f40e",
  ":radio:": "1f4fb",
  ":radio_button:": "1f518",
  ":radioactive:": "2622",
  ":rage:": "1f621",
  ":railway_car:": "1f683",
  ":railway_track:": "1f6e4",
  ":rainbow:": "1f308",
  ":rainbow_flag:": "1f3f3-fe0f-200d-1f308",
  ":raised_back_of_hand:": "1f91a",
  ":raised_back_of_hand_tone1:": "1f91a-1f3fb",
  ":raised_back_of_hand_tone2:": "1f91a-1f3fc",
  ":raised_back_of_hand_tone3:": "1f91a-1f3fd",
  ":raised_back_of_hand_tone4:": "1f91a-1f3fe",
  ":raised_back_of_hand_tone5:": "1f91a-1f3ff",
  ":raised_hand:": "270b",
  ":raised_hand_tone1:": "270b-1f3fb",
  ":raised_hand_tone2:": "270b-1f3fc",
  ":raised_hand_tone3:": "270b-1f3fd",
  ":raised_hand_tone4:": "270b-1f3fe",
  ":raised_hand_tone5:": "270b-1f3ff",
  ":raised_hands:": "1f64c",
  ":raised_hands_tone1:": "1f64c-1f3fb",
  ":raised_hands_tone2:": "1f64c-1f3fc",
  ":raised_hands_tone3:": "1f64c-1f3fd",
  ":raised_hands_tone4:": "1f64c-1f3fe",
  ":raised_hands_tone5:": "1f64c-1f3ff",
  ":ram:": "1f40f",
  ":ramen:": "1f35c",
  ":rat:": "1f400",
  ":receipt:": "1f9fe",
  ":record_button:": "23fa",
  ":recycle:": "267b",
  ":red_car:": "1f697",
  ":red_circle:": "1f534",
  ":red_envelope:": "1f9e7",
  ":red_haired:": "1f9b0",
  ":regional_indicator_a:": "1f1e6",
  ":regional_indicator_b:": "1f1e7",
  ":regional_indicator_c:": "1f1e8",
  ":regional_indicator_d:": "1f1e9",
  ":regional_indicator_e:": "1f1ea",
  ":regional_indicator_f:": "1f1eb",
  ":regional_indicator_g:": "1f1ec",
  ":regional_indicator_h:": "1f1ed",
  ":regional_indicator_i:": "1f1ee",
  ":regional_indicator_j:": "1f1ef",
  ":regional_indicator_k:": "1f1f0",
  ":regional_indicator_l:": "1f1f1",
  ":regional_indicator_m:": "1f1f2",
  ":regional_indicator_n:": "1f1f3",
  ":regional_indicator_o:": "1f1f4",
  ":regional_indicator_p:": "1f1f5",
  ":regional_indicator_q:": "1f1f6",
  ":regional_indicator_r:": "1f1f7",
  ":regional_indicator_s:": "1f1f8",
  ":regional_indicator_t:": "1f1f9",
  ":regional_indicator_u:": "1f1fa",
  ":regional_indicator_v:": "1f1fb",
  ":regional_indicator_w:": "1f1fc",
  ":regional_indicator_x:": "1f1fd",
  ":regional_indicator_y:": "1f1fe",
  ":regional_indicator_z:": "1f1ff",
  ":relaxed:": "263a",
  ":relieved:": "1f60c",
  ":reminder_ribbon:": "1f397",
  ":repeat:": "1f501",
  ":repeat_one:": "1f502",
  ":restroom:": "1f6bb",
  ":revolving_hearts:": "1f49e",
  ":rewind:": "23ea",
  ":rhino:": "1f98f",
  ":ribbon:": "1f380",
  ":rice:": "1f35a",
  ":rice_ball:": "1f359",
  ":rice_cracker:": "1f358",
  ":rice_scene:": "1f391",
  ":right_facing_fist:": "1f91c",
  ":right_facing_fist_tone1:": "1f91c-1f3fb",
  ":right_facing_fist_tone2:": "1f91c-1f3fc",
  ":right_facing_fist_tone3:": "1f91c-1f3fd",
  ":right_facing_fist_tone4:": "1f91c-1f3fe",
  ":right_facing_fist_tone5:": "1f91c-1f3ff",
  ":ring:": "1f48d",
  ":robot:": "1f916",
  ":rocket:": "1f680",
  ":rofl:": "1f923",
  ":roll_of_paper:": "1f9fb",
  ":roller_coaster:": "1f3a2",
  ":rolling_eyes:": "1f644",
  ":rooster:": "1f413",
  ":rose:": "1f339",
  ":rosette:": "1f3f5",
  ":rotating_light:": "1f6a8",
  ":round_pushpin:": "1f4cd",
  ":rugby_football:": "1f3c9",
  ":running_shirt_with_sash:": "1f3bd",
  ":sa:": "1f202",
  ":safety_pin:": "1f9f7",
  ":sagittarius:": "2650",
  ":sailboat:": "26f5",
  ":sake:": "1f376",
  ":salad:": "1f957",
  ":salt:": "1f9c2",
  ":sandal:": "1f461",
  ":sandwich:": "1f96a",
  ":santa:": "1f385",
  ":santa_tone1:": "1f385-1f3fb",
  ":santa_tone2:": "1f385-1f3fc",
  ":santa_tone3:": "1f385-1f3fd",
  ":santa_tone4:": "1f385-1f3fe",
  ":santa_tone5:": "1f385-1f3ff",
  ":satellite:": "1f4e1",
  ":satellite_orbital:": "1f6f0",
  ":sauropod:": "1f995",
  ":saxophone:": "1f3b7",
  ":scales:": "2696",
  ":scarf:": "1f9e3",
  ":school:": "1f3eb",
  ":school_satchel:": "1f392",
  ":scissors:": "2702",
  ":scooter:": "1f6f4",
  ":scorpion:": "1f982",
  ":scorpius:": "264f",
  ":scotland:": "1f3f4-e0067-e0062-e0073-e0063-e0074-e007f",
  ":scream:": "1f631",
  ":scream_cat:": "1f640",
  ":scroll:": "1f4dc",
  ":seat:": "1f4ba",
  ":second_place:": "1f948",
  ":secret:": "3299",
  ":see_no_evil:": "1f648",
  ":seedling:": "1f331",
  ":selfie:": "1f933",
  ":selfie_tone1:": "1f933-1f3fb",
  ":selfie_tone2:": "1f933-1f3fc",
  ":selfie_tone3:": "1f933-1f3fd",
  ":selfie_tone4:": "1f933-1f3fe",
  ":selfie_tone5:": "1f933-1f3ff",
  ":seven:": "0037-fe0f-20e3",
  ":shallow_pan_of_food:": "1f958",
  ":shamrock:": "2618",
  ":shark:": "1f988",
  ":shaved_ice:": "1f367",
  ":sheep:": "1f411",
  ":shell:": "1f41a",
  ":shield:": "1f6e1",
  ":shinto_shrine:": "26e9",
  ":ship:": "1f6a2",
  ":shirt:": "1f455",
  ":shopping_bags:": "1f6cd",
  ":shopping_cart:": "1f6d2",
  ":shower:": "1f6bf",
  ":shrimp:": "1f990",
  ":shushing_face:": "1f92b",
  ":signal_strength:": "1f4f6",
  ":six:": "0036-fe0f-20e3",
  ":six_pointed_star:": "1f52f",
  ":skateboard:": "1f6f9",
  ":ski:": "1f3bf",
  ":skier:": "26f7",
  ":skull:": "1f480",
  ":skull_crossbones:": "2620",
  ":sled:": "1f6f7",
  ":sleeping:": "1f634",
  ":sleeping_accommodation:": "1f6cc",
  ":sleepy:": "1f62a",
  ":slight_frown:": "1f641",
  ":slight_smile:": "1f642",
  ":slot_machine:": "1f3b0",
  ":small_blue_diamond:": "1f539",
  ":small_orange_diamond:": "1f538",
  ":small_red_triangle:": "1f53a",
  ":small_red_triangle_down:": "1f53b",
  ":smile:": "1f604",
  ":smile_cat:": "1f638",
  ":smiley:": "1f603",
  ":smiley_cat:": "1f63a",
  ":smiling_face_with_3_hearts:": "1f970",
  ":smiling_imp:": "1f608",
  ":smirk:": "1f60f",
  ":smirk_cat:": "1f63c",
  ":smoking:": "1f6ac",
  ":snail:": "1f40c",
  ":snake:": "1f40d",
  ":sneezing_face:": "1f927",
  ":snowboarder:": "1f3c2",
  ":snowboarder_tone1:": "1f3c2-1f3fb",
  ":snowboarder_tone2:": "1f3c2-1f3fc",
  ":snowboarder_tone3:": "1f3c2-1f3fd",
  ":snowboarder_tone4:": "1f3c2-1f3fe",
  ":snowboarder_tone5:": "1f3c2-1f3ff",
  ":snowflake:": "2744",
  ":snowman2:": "2603",
  ":snowman:": "26c4",
  ":soap:": "1f9fc",
  ":sob:": "1f62d",
  ":soccer:": "26bd",
  ":socks:": "1f9e6",
  ":softball:": "1f94e",
  ":soon:": "1f51c",
  ":sos:": "1f198",
  ":sound:": "1f509",
  ":space_invader:": "1f47e",
  ":spades:": "2660",
  ":spaghetti:": "1f35d",
  ":sparkle:": "2747",
  ":sparkler:": "1f387",
  ":sparkles:": "2728",
  ":sparkling_heart:": "1f496",
  ":speak_no_evil:": "1f64a",
  ":speaker:": "1f508",
  ":speaking_head:": "1f5e3",
  ":speech_balloon:": "1f4ac",
  ":speech_left:": "1f5e8",
  ":speedboat:": "1f6a4",
  ":spider:": "1f577",
  ":spider_web:": "1f578",
  ":sponge:": "1f9fd",
  ":spoon:": "1f944",
  ":squeeze_bottle:": "1f9f4",
  ":squid:": "1f991",
  ":stadium:": "1f3df",
  ":star2:": "1f31f",
  ":star:": "2b50",
  ":star_and_crescent:": "262a",
  ":star_of_david:": "2721",
  ":star_struck:": "1f929",
  ":stars:": "1f320",
  ":station:": "1f689",
  ":statue_of_liberty:": "1f5fd",
  ":steam_locomotive:": "1f682",
  ":stew:": "1f372",
  ":stop_button:": "23f9",
  ":stopwatch:": "23f1",
  ":straight_ruler:": "1f4cf",
  ":strawberry:": "1f353",
  ":stuck_out_tongue:": "1f61b",
  ":stuck_out_tongue_closed_eyes:": "1f61d",
  ":stuck_out_tongue_winking_eye:": "1f61c",
  ":stuffed_flatbread:": "1f959",
  ":sun_with_face:": "1f31e",
  ":sunflower:": "1f33b",
  ":sunglasses:": "1f60e",
  ":sunny:": "2600",
  ":sunrise:": "1f305",
  ":sunrise_over_mountains:": "1f304",
  ":superhero:": "1f9b8",
  ":superhero_tone1:": "1f9b8-1f3fb",
  ":superhero_tone2:": "1f9b8-1f3fc",
  ":superhero_tone3:": "1f9b8-1f3fd",
  ":superhero_tone4:": "1f9b8-1f3fe",
  ":superhero_tone5:": "1f9b8-1f3ff",
  ":supervillain:": "1f9b9",
  ":supervillain_tone1:": "1f9b9-1f3fb",
  ":supervillain_tone2:": "1f9b9-1f3fc",
  ":supervillain_tone3:": "1f9b9-1f3fd",
  ":supervillain_tone4:": "1f9b9-1f3fe",
  ":supervillain_tone5:": "1f9b9-1f3ff",
  ":sushi:": "1f363",
  ":suspension_railway:": "1f69f",
  ":swan:": "1f9a2",
  ":sweat:": "1f613",
  ":sweat_drops:": "1f4a6",
  ":sweat_smile:": "1f605",
  ":sweet_potato:": "1f360",
  ":symbols:": "1f523",
  ":synagogue:": "1f54d",
  ":syringe:": "1f489",
  ":t_rex:": "1f996",
  ":taco:": "1f32e",
  ":tada:": "1f389",
  ":takeout_box:": "1f961",
  ":tanabata_tree:": "1f38b",
  ":tangerine:": "1f34a",
  ":taurus:": "2649",
  ":taxi:": "1f695",
  ":tea:": "1f375",
  ":teddy_bear:": "1f9f8",
  ":telephone:": "260e",
  ":telephone_receiver:": "1f4de",
  ":telescope:": "1f52d",
  ":tennis:": "1f3be",
  ":tent:": "26fa",
  ":test_tube:": "1f9ea",
  ":thermometer:": "1f321",
  ":thermometer_face:": "1f912",
  ":thinking:": "1f914",
  ":third_place:": "1f949",
  ":thought_balloon:": "1f4ad",
  ":thread:": "1f9f5",
  ":three:": "0033-fe0f-20e3",
  ":thumbsdown:": "1f44e",
  ":thumbsdown_tone1:": "1f44e-1f3fb",
  ":thumbsdown_tone2:": "1f44e-1f3fc",
  ":thumbsdown_tone3:": "1f44e-1f3fd",
  ":thumbsdown_tone4:": "1f44e-1f3fe",
  ":thumbsdown_tone5:": "1f44e-1f3ff",
  ":thumbsup:": "1f44d",
  ":thumbsup_tone1:": "1f44d-1f3fb",
  ":thumbsup_tone2:": "1f44d-1f3fc",
  ":thumbsup_tone3:": "1f44d-1f3fd",
  ":thumbsup_tone4:": "1f44d-1f3fe",
  ":thumbsup_tone5:": "1f44d-1f3ff",
  ":thunder_cloud_rain:": "26c8",
  ":ticket:": "1f3ab",
  ":tickets:": "1f39f",
  ":tiger2:": "1f405",
  ":tiger:": "1f42f",
  ":timer:": "23f2",
  ":tired_face:": "1f62b",
  ":toilet:": "1f6bd",
  ":tokyo_tower:": "1f5fc",
  ":tomato:": "1f345",
  ":tone1:": "1f3fb",
  ":tone2:": "1f3fc",
  ":tone3:": "1f3fd",
  ":tone4:": "1f3fe",
  ":tone5:": "1f3ff",
  ":tongue:": "1f445",
  ":toolbox:": "1f9f0",
  ":tools:": "1f6e0",
  ":tooth:": "1f9b7",
  ":top:": "1f51d",
  ":tophat:": "1f3a9",
  ":track_next:": "23ed",
  ":track_previous:": "23ee",
  ":trackball:": "1f5b2",
  ":tractor:": "1f69c",
  ":traffic_light:": "1f6a5",
  ":train2:": "1f686",
  ":train:": "1f68b",
  ":tram:": "1f68a",
  ":triangular_flag_on_post:": "1f6a9",
  ":triangular_ruler:": "1f4d0",
  ":trident:": "1f531",
  ":triumph:": "1f624",
  ":trolleybus:": "1f68e",
  ":trophy:": "1f3c6",
  ":tropical_drink:": "1f379",
  ":tropical_fish:": "1f420",
  ":truck:": "1f69a",
  ":trumpet:": "1f3ba",
  ":tulip:": "1f337",
  ":tumbler_glass:": "1f943",
  ":turkey:": "1f983",
  ":turtle:": "1f422",
  ":tv:": "1f4fa",
  ":twisted_rightwards_arrows:": "1f500",
  ":two:": "0032-fe0f-20e3",
  ":two_hearts:": "1f495",
  ":two_men_holding_hands:": "1f46c",
  ":two_women_holding_hands:": "1f46d",
  ":u5272:": "1f239",
  ":u5408:": "1f234",
  ":u55b6:": "1f23a",
  ":u6307:": "1f22f",
  ":u6708:": "1f237",
  ":u6709:": "1f236",
  ":u6e80:": "1f235",
  ":u7121:": "1f21a",
  ":u7533:": "1f238",
  ":u7981:": "1f232",
  ":u7a7a:": "1f233",
  ":umbrella2:": "2602",
  ":umbrella:": "2614",
  ":unamused:": "1f612",
  ":underage:": "1f51e",
  ":unicorn:": "1f984",
  ":united_nations:": "1f1fa-1f1f3",
  ":unlock:": "1f513",
  ":up:": "1f199",
  ":upside_down:": "1f643",
  ":urn:": "26b1",
  ":v:": "270c",
  ":v_tone1:": "270c-1f3fb",
  ":v_tone2:": "270c-1f3fc",
  ":v_tone3:": "270c-1f3fd",
  ":v_tone4:": "270c-1f3fe",
  ":v_tone5:": "270c-1f3ff",
  ":vampire:": "1f9db",
  ":vampire_tone1:": "1f9db-1f3fb",
  ":vampire_tone2:": "1f9db-1f3fc",
  ":vampire_tone3:": "1f9db-1f3fd",
  ":vampire_tone4:": "1f9db-1f3fe",
  ":vampire_tone5:": "1f9db-1f3ff",
  ":vertical_traffic_light:": "1f6a6",
  ":vhs:": "1f4fc",
  ":vibration_mode:": "1f4f3",
  ":video_camera:": "1f4f9",
  ":video_game:": "1f3ae",
  ":violin:": "1f3bb",
  ":virgo:": "264d",
  ":volcano:": "1f30b",
  ":volleyball:": "1f3d0",
  ":vs:": "1f19a",
  ":vulcan:": "1f596",
  ":vulcan_tone1:": "1f596-1f3fb",
  ":vulcan_tone2:": "1f596-1f3fc",
  ":vulcan_tone3:": "1f596-1f3fd",
  ":vulcan_tone4:": "1f596-1f3fe",
  ":vulcan_tone5:": "1f596-1f3ff",
  ":wales:": "1f3f4-e0067-e0062-e0077-e006c-e0073-e007f",
  ":waning_crescent_moon:": "1f318",
  ":waning_gibbous_moon:": "1f316",
  ":warning:": "26a0",
  ":wastebasket:": "1f5d1",
  ":watch:": "231a",
  ":water_buffalo:": "1f403",
  ":watermelon:": "1f349",
  ":wave:": "1f44b",
  ":wave_tone1:": "1f44b-1f3fb",
  ":wave_tone2:": "1f44b-1f3fc",
  ":wave_tone3:": "1f44b-1f3fd",
  ":wave_tone4:": "1f44b-1f3fe",
  ":wave_tone5:": "1f44b-1f3ff",
  ":wavy_dash:": "3030",
  ":waxing_crescent_moon:": "1f312",
  ":waxing_gibbous_moon:": "1f314",
  ":wc:": "1f6be",
  ":weary:": "1f629",
  ":wedding:": "1f492",
  ":whale2:": "1f40b",
  ":whale:": "1f433",
  ":wheel_of_dharma:": "2638",
  ":wheelchair:": "267f",
  ":white_check_mark:": "2705",
  ":white_circle:": "26aa",
  ":white_flower:": "1f4ae",
  ":white_haired:": "1f9b3",
  ":white_large_square:": "2b1c",
  ":white_medium_small_square:": "25fd",
  ":white_medium_square:": "25fb",
  ":white_small_square:": "25ab",
  ":white_square_button:": "1f533",
  ":white_sun_cloud:": "1f325",
  ":white_sun_rain_cloud:": "1f326",
  ":white_sun_small_cloud:": "1f324",
  ":wilted_rose:": "1f940",
  ":wind_blowing_face:": "1f32c",
  ":wind_chime:": "1f390",
  ":wine_glass:": "1f377",
  ":wink:": "1f609",
  ":wolf:": "1f43a",
  ":woman:": "1f469",
  ":woman_artist:": "1f469-200d-1f3a8",
  ":woman_artist_tone1:": "1f469-1f3fb-200d-1f3a8",
  ":woman_artist_tone2:": "1f469-1f3fc-200d-1f3a8",
  ":woman_artist_tone3:": "1f469-1f3fd-200d-1f3a8",
  ":woman_artist_tone4:": "1f469-1f3fe-200d-1f3a8",
  ":woman_artist_tone5:": "1f469-1f3ff-200d-1f3a8",
  ":woman_astronaut:": "1f469-200d-1f680",
  ":woman_astronaut_tone1:": "1f469-1f3fb-200d-1f680",
  ":woman_astronaut_tone2:": "1f469-1f3fc-200d-1f680",
  ":woman_astronaut_tone3:": "1f469-1f3fd-200d-1f680",
  ":woman_astronaut_tone4:": "1f469-1f3fe-200d-1f680",
  ":woman_astronaut_tone5:": "1f469-1f3ff-200d-1f680",
  ":woman_bald:": "1f469-200d-1f9b2",
  ":woman_bald_tone1:": "1f469-1f3fb-200d-1f9b2",
  ":woman_bald_tone2:": "1f469-1f3fc-200d-1f9b2",
  ":woman_bald_tone3:": "1f469-1f3fd-200d-1f9b2",
  ":woman_bald_tone4:": "1f469-1f3fe-200d-1f9b2",
  ":woman_bald_tone5:": "1f469-1f3ff-200d-1f9b2",
  ":woman_biking:": "1f6b4-200d-2640-fe0f",
  ":woman_biking_tone1:": "1f6b4-1f3fb-200d-2640-fe0f",
  ":woman_biking_tone2:": "1f6b4-1f3fc-200d-2640-fe0f",
  ":woman_biking_tone3:": "1f6b4-1f3fd-200d-2640-fe0f",
  ":woman_biking_tone4:": "1f6b4-1f3fe-200d-2640-fe0f",
  ":woman_biking_tone5:": "1f6b4-1f3ff-200d-2640-fe0f",
  ":woman_bouncing_ball:": "26f9-fe0f-200d-2640-fe0f",
  ":woman_bouncing_ball_tone1:": "26f9-1f3fb-200d-2640-fe0f",
  ":woman_bouncing_ball_tone2:": "26f9-1f3fc-200d-2640-fe0f",
  ":woman_bouncing_ball_tone3:": "26f9-1f3fd-200d-2640-fe0f",
  ":woman_bouncing_ball_tone4:": "26f9-1f3fe-200d-2640-fe0f",
  ":woman_bouncing_ball_tone5:": "26f9-1f3ff-200d-2640-fe0f",
  ":woman_bowing:": "1f647-200d-2640-fe0f",
  ":woman_bowing_tone1:": "1f647-1f3fb-200d-2640-fe0f",
  ":woman_bowing_tone2:": "1f647-1f3fc-200d-2640-fe0f",
  ":woman_bowing_tone3:": "1f647-1f3fd-200d-2640-fe0f",
  ":woman_bowing_tone4:": "1f647-1f3fe-200d-2640-fe0f",
  ":woman_bowing_tone5:": "1f647-1f3ff-200d-2640-fe0f",
  ":woman_cartwheeling:": "1f938-200d-2640-fe0f",
  ":woman_cartwheeling_tone1:": "1f938-1f3fb-200d-2640-fe0f",
  ":woman_cartwheeling_tone2:": "1f938-1f3fc-200d-2640-fe0f",
  ":woman_cartwheeling_tone3:": "1f938-1f3fd-200d-2640-fe0f",
  ":woman_cartwheeling_tone4:": "1f938-1f3fe-200d-2640-fe0f",
  ":woman_cartwheeling_tone5:": "1f938-1f3ff-200d-2640-fe0f",
  ":woman_climbing:": "1f9d7-200d-2640-fe0f",
  ":woman_climbing_tone1:": "1f9d7-1f3fb-200d-2640-fe0f",
  ":woman_climbing_tone2:": "1f9d7-1f3fc-200d-2640-fe0f",
  ":woman_climbing_tone3:": "1f9d7-1f3fd-200d-2640-fe0f",
  ":woman_climbing_tone4:": "1f9d7-1f3fe-200d-2640-fe0f",
  ":woman_climbing_tone5:": "1f9d7-1f3ff-200d-2640-fe0f",
  ":woman_construction_worker:": "1f477-200d-2640-fe0f",
  ":woman_construction_worker_tone1:": "1f477-1f3fb-200d-2640-fe0f",
  ":woman_construction_worker_tone2:": "1f477-1f3fc-200d-2640-fe0f",
  ":woman_construction_worker_tone3:": "1f477-1f3fd-200d-2640-fe0f",
  ":woman_construction_worker_tone4:": "1f477-1f3fe-200d-2640-fe0f",
  ":woman_construction_worker_tone5:": "1f477-1f3ff-200d-2640-fe0f",
  ":woman_cook:": "1f469-200d-1f373",
  ":woman_cook_tone1:": "1f469-1f3fb-200d-1f373",
  ":woman_cook_tone2:": "1f469-1f3fc-200d-1f373",
  ":woman_cook_tone3:": "1f469-1f3fd-200d-1f373",
  ":woman_cook_tone4:": "1f469-1f3fe-200d-1f373",
  ":woman_cook_tone5:": "1f469-1f3ff-200d-1f373",
  ":woman_curly_haired:": "1f469-200d-1f9b1",
  ":woman_curly_haired_tone1:": "1f469-1f3fb-200d-1f9b1",
  ":woman_curly_haired_tone2:": "1f469-1f3fc-200d-1f9b1",
  ":woman_curly_haired_tone3:": "1f469-1f3fd-200d-1f9b1",
  ":woman_curly_haired_tone4:": "1f469-1f3fe-200d-1f9b1",
  ":woman_curly_haired_tone5:": "1f469-1f3ff-200d-1f9b1",
  ":woman_detective:": "1f575-fe0f-200d-2640-fe0f",
  ":woman_detective_tone1:": "1f575-1f3fb-200d-2640-fe0f",
  ":woman_detective_tone2:": "1f575-1f3fc-200d-2640-fe0f",
  ":woman_detective_tone3:": "1f575-1f3fd-200d-2640-fe0f",
  ":woman_detective_tone4:": "1f575-1f3fe-200d-2640-fe0f",
  ":woman_detective_tone5:": "1f575-1f3ff-200d-2640-fe0f",
  ":woman_elf:": "1f9dd-200d-2640-fe0f",
  ":woman_elf_tone1:": "1f9dd-1f3fb-200d-2640-fe0f",
  ":woman_elf_tone2:": "1f9dd-1f3fc-200d-2640-fe0f",
  ":woman_elf_tone3:": "1f9dd-1f3fd-200d-2640-fe0f",
  ":woman_elf_tone4:": "1f9dd-1f3fe-200d-2640-fe0f",
  ":woman_elf_tone5:": "1f9dd-1f3ff-200d-2640-fe0f",
  ":woman_facepalming:": "1f926-200d-2640-fe0f",
  ":woman_facepalming_tone1:": "1f926-1f3fb-200d-2640-fe0f",
  ":woman_facepalming_tone2:": "1f926-1f3fc-200d-2640-fe0f",
  ":woman_facepalming_tone3:": "1f926-1f3fd-200d-2640-fe0f",
  ":woman_facepalming_tone4:": "1f926-1f3fe-200d-2640-fe0f",
  ":woman_facepalming_tone5:": "1f926-1f3ff-200d-2640-fe0f",
  ":woman_factory_worker:": "1f469-200d-1f3ed",
  ":woman_factory_worker_tone1:": "1f469-1f3fb-200d-1f3ed",
  ":woman_factory_worker_tone2:": "1f469-1f3fc-200d-1f3ed",
  ":woman_factory_worker_tone3:": "1f469-1f3fd-200d-1f3ed",
  ":woman_factory_worker_tone4:": "1f469-1f3fe-200d-1f3ed",
  ":woman_factory_worker_tone5:": "1f469-1f3ff-200d-1f3ed",
  ":woman_fairy:": "1f9da-200d-2640-fe0f",
  ":woman_fairy_tone1:": "1f9da-1f3fb-200d-2640-fe0f",
  ":woman_fairy_tone2:": "1f9da-1f3fc-200d-2640-fe0f",
  ":woman_fairy_tone3:": "1f9da-1f3fd-200d-2640-fe0f",
  ":woman_fairy_tone4:": "1f9da-1f3fe-200d-2640-fe0f",
  ":woman_fairy_tone5:": "1f9da-1f3ff-200d-2640-fe0f",
  ":woman_farmer:": "1f469-200d-1f33e",
  ":woman_farmer_tone1:": "1f469-1f3fb-200d-1f33e",
  ":woman_farmer_tone2:": "1f469-1f3fc-200d-1f33e",
  ":woman_farmer_tone3:": "1f469-1f3fd-200d-1f33e",
  ":woman_farmer_tone4:": "1f469-1f3fe-200d-1f33e",
  ":woman_farmer_tone5:": "1f469-1f3ff-200d-1f33e",
  ":woman_firefighter:": "1f469-200d-1f692",
  ":woman_firefighter_tone1:": "1f469-1f3fb-200d-1f692",
  ":woman_firefighter_tone2:": "1f469-1f3fc-200d-1f692",
  ":woman_firefighter_tone3:": "1f469-1f3fd-200d-1f692",
  ":woman_firefighter_tone4:": "1f469-1f3fe-200d-1f692",
  ":woman_firefighter_tone5:": "1f469-1f3ff-200d-1f692",
  ":woman_frowning:": "1f64d-200d-2640-fe0f",
  ":woman_frowning_tone1:": "1f64d-1f3fb-200d-2640-fe0f",
  ":woman_frowning_tone2:": "1f64d-1f3fc-200d-2640-fe0f",
  ":woman_frowning_tone3:": "1f64d-1f3fd-200d-2640-fe0f",
  ":woman_frowning_tone4:": "1f64d-1f3fe-200d-2640-fe0f",
  ":woman_frowning_tone5:": "1f64d-1f3ff-200d-2640-fe0f",
  ":woman_genie:": "1f9de-200d-2640-fe0f",
  ":woman_gesturing_no:": "1f645-200d-2640-fe0f",
  ":woman_gesturing_no_tone1:": "1f645-1f3fb-200d-2640-fe0f",
  ":woman_gesturing_no_tone2:": "1f645-1f3fc-200d-2640-fe0f",
  ":woman_gesturing_no_tone3:": "1f645-1f3fd-200d-2640-fe0f",
  ":woman_gesturing_no_tone4:": "1f645-1f3fe-200d-2640-fe0f",
  ":woman_gesturing_no_tone5:": "1f645-1f3ff-200d-2640-fe0f",
  ":woman_gesturing_ok:": "1f646-200d-2640-fe0f",
  ":woman_gesturing_ok_tone1:": "1f646-1f3fb-200d-2640-fe0f",
  ":woman_gesturing_ok_tone2:": "1f646-1f3fc-200d-2640-fe0f",
  ":woman_gesturing_ok_tone3:": "1f646-1f3fd-200d-2640-fe0f",
  ":woman_gesturing_ok_tone4:": "1f646-1f3fe-200d-2640-fe0f",
  ":woman_gesturing_ok_tone5:": "1f646-1f3ff-200d-2640-fe0f",
  ":woman_getting_face_massage:": "1f486-200d-2640-fe0f",
  ":woman_getting_face_massage_tone1:": "1f486-1f3fb-200d-2640-fe0f",
  ":woman_getting_face_massage_tone2:": "1f486-1f3fc-200d-2640-fe0f",
  ":woman_getting_face_massage_tone3:": "1f486-1f3fd-200d-2640-fe0f",
  ":woman_getting_face_massage_tone4:": "1f486-1f3fe-200d-2640-fe0f",
  ":woman_getting_face_massage_tone5:": "1f486-1f3ff-200d-2640-fe0f",
  ":woman_getting_haircut:": "1f487-200d-2640-fe0f",
  ":woman_getting_haircut_tone1:": "1f487-1f3fb-200d-2640-fe0f",
  ":woman_getting_haircut_tone2:": "1f487-1f3fc-200d-2640-fe0f",
  ":woman_getting_haircut_tone3:": "1f487-1f3fd-200d-2640-fe0f",
  ":woman_getting_haircut_tone4:": "1f487-1f3fe-200d-2640-fe0f",
  ":woman_getting_haircut_tone5:": "1f487-1f3ff-200d-2640-fe0f",
  ":woman_golfing:": "1f3cc-fe0f-200d-2640-fe0f",
  ":woman_golfing_tone1:": "1f3cc-1f3fb-200d-2640-fe0f",
  ":woman_golfing_tone2:": "1f3cc-1f3fc-200d-2640-fe0f",
  ":woman_golfing_tone3:": "1f3cc-1f3fd-200d-2640-fe0f",
  ":woman_golfing_tone4:": "1f3cc-1f3fe-200d-2640-fe0f",
  ":woman_golfing_tone5:": "1f3cc-1f3ff-200d-2640-fe0f",
  ":woman_guard:": "1f482-200d-2640-fe0f",
  ":woman_guard_tone1:": "1f482-1f3fb-200d-2640-fe0f",
  ":woman_guard_tone2:": "1f482-1f3fc-200d-2640-fe0f",
  ":woman_guard_tone3:": "1f482-1f3fd-200d-2640-fe0f",
  ":woman_guard_tone4:": "1f482-1f3fe-200d-2640-fe0f",
  ":woman_guard_tone5:": "1f482-1f3ff-200d-2640-fe0f",
  ":woman_health_worker:": "1f469-200d-2695-fe0f",
  ":woman_health_worker_tone1:": "1f469-1f3fb-200d-2695-fe0f",
  ":woman_health_worker_tone2:": "1f469-1f3fc-200d-2695-fe0f",
  ":woman_health_worker_tone3:": "1f469-1f3fd-200d-2695-fe0f",
  ":woman_health_worker_tone4:": "1f469-1f3fe-200d-2695-fe0f",
  ":woman_health_worker_tone5:": "1f469-1f3ff-200d-2695-fe0f",
  ":woman_in_lotus_position:": "1f9d8-200d-2640-fe0f",
  ":woman_in_lotus_position_tone1:": "1f9d8-1f3fb-200d-2640-fe0f",
  ":woman_in_lotus_position_tone2:": "1f9d8-1f3fc-200d-2640-fe0f",
  ":woman_in_lotus_position_tone3:": "1f9d8-1f3fd-200d-2640-fe0f",
  ":woman_in_lotus_position_tone4:": "1f9d8-1f3fe-200d-2640-fe0f",
  ":woman_in_lotus_position_tone5:": "1f9d8-1f3ff-200d-2640-fe0f",
  ":woman_in_steamy_room:": "1f9d6-200d-2640-fe0f",
  ":woman_in_steamy_room_tone1:": "1f9d6-1f3fb-200d-2640-fe0f",
  ":woman_in_steamy_room_tone2:": "1f9d6-1f3fc-200d-2640-fe0f",
  ":woman_in_steamy_room_tone3:": "1f9d6-1f3fd-200d-2640-fe0f",
  ":woman_in_steamy_room_tone4:": "1f9d6-1f3fe-200d-2640-fe0f",
  ":woman_in_steamy_room_tone5:": "1f9d6-1f3ff-200d-2640-fe0f",
  ":woman_judge:": "1f469-200d-2696-fe0f",
  ":woman_judge_tone1:": "1f469-1f3fb-200d-2696-fe0f",
  ":woman_judge_tone2:": "1f469-1f3fc-200d-2696-fe0f",
  ":woman_judge_tone3:": "1f469-1f3fd-200d-2696-fe0f",
  ":woman_judge_tone4:": "1f469-1f3fe-200d-2696-fe0f",
  ":woman_judge_tone5:": "1f469-1f3ff-200d-2696-fe0f",
  ":woman_juggling:": "1f939-200d-2640-fe0f",
  ":woman_juggling_tone1:": "1f939-1f3fb-200d-2640-fe0f",
  ":woman_juggling_tone2:": "1f939-1f3fc-200d-2640-fe0f",
  ":woman_juggling_tone3:": "1f939-1f3fd-200d-2640-fe0f",
  ":woman_juggling_tone4:": "1f939-1f3fe-200d-2640-fe0f",
  ":woman_juggling_tone5:": "1f939-1f3ff-200d-2640-fe0f",
  ":woman_lifting_weights:": "1f3cb-fe0f-200d-2640-fe0f",
  ":woman_lifting_weights_tone1:": "1f3cb-1f3fb-200d-2640-fe0f",
  ":woman_lifting_weights_tone2:": "1f3cb-1f3fc-200d-2640-fe0f",
  ":woman_lifting_weights_tone3:": "1f3cb-1f3fd-200d-2640-fe0f",
  ":woman_lifting_weights_tone4:": "1f3cb-1f3fe-200d-2640-fe0f",
  ":woman_lifting_weights_tone5:": "1f3cb-1f3ff-200d-2640-fe0f",
  ":woman_mage:": "1f9d9-200d-2640-fe0f",
  ":woman_mage_tone1:": "1f9d9-1f3fb-200d-2640-fe0f",
  ":woman_mage_tone2:": "1f9d9-1f3fc-200d-2640-fe0f",
  ":woman_mage_tone3:": "1f9d9-1f3fd-200d-2640-fe0f",
  ":woman_mage_tone4:": "1f9d9-1f3fe-200d-2640-fe0f",
  ":woman_mage_tone5:": "1f9d9-1f3ff-200d-2640-fe0f",
  ":woman_mechanic:": "1f469-200d-1f527",
  ":woman_mechanic_tone1:": "1f469-1f3fb-200d-1f527",
  ":woman_mechanic_tone2:": "1f469-1f3fc-200d-1f527",
  ":woman_mechanic_tone3:": "1f469-1f3fd-200d-1f527",
  ":woman_mechanic_tone4:": "1f469-1f3fe-200d-1f527",
  ":woman_mechanic_tone5:": "1f469-1f3ff-200d-1f527",
  ":woman_mountain_biking:": "1f6b5-200d-2640-fe0f",
  ":woman_mountain_biking_tone1:": "1f6b5-1f3fb-200d-2640-fe0f",
  ":woman_mountain_biking_tone2:": "1f6b5-1f3fc-200d-2640-fe0f",
  ":woman_mountain_biking_tone3:": "1f6b5-1f3fd-200d-2640-fe0f",
  ":woman_mountain_biking_tone4:": "1f6b5-1f3fe-200d-2640-fe0f",
  ":woman_mountain_biking_tone5:": "1f6b5-1f3ff-200d-2640-fe0f",
  ":woman_office_worker:": "1f469-200d-1f4bc",
  ":woman_office_worker_tone1:": "1f469-1f3fb-200d-1f4bc",
  ":woman_office_worker_tone2:": "1f469-1f3fc-200d-1f4bc",
  ":woman_office_worker_tone3:": "1f469-1f3fd-200d-1f4bc",
  ":woman_office_worker_tone4:": "1f469-1f3fe-200d-1f4bc",
  ":woman_office_worker_tone5:": "1f469-1f3ff-200d-1f4bc",
  ":woman_pilot:": "1f469-200d-2708-fe0f",
  ":woman_pilot_tone1:": "1f469-1f3fb-200d-2708-fe0f",
  ":woman_pilot_tone2:": "1f469-1f3fc-200d-2708-fe0f",
  ":woman_pilot_tone3:": "1f469-1f3fd-200d-2708-fe0f",
  ":woman_pilot_tone4:": "1f469-1f3fe-200d-2708-fe0f",
  ":woman_pilot_tone5:": "1f469-1f3ff-200d-2708-fe0f",
  ":woman_playing_handball:": "1f93e-200d-2640-fe0f",
  ":woman_playing_handball_tone1:": "1f93e-1f3fb-200d-2640-fe0f",
  ":woman_playing_handball_tone2:": "1f93e-1f3fc-200d-2640-fe0f",
  ":woman_playing_handball_tone3:": "1f93e-1f3fd-200d-2640-fe0f",
  ":woman_playing_handball_tone4:": "1f93e-1f3fe-200d-2640-fe0f",
  ":woman_playing_handball_tone5:": "1f93e-1f3ff-200d-2640-fe0f",
  ":woman_playing_water_polo:": "1f93d-200d-2640-fe0f",
  ":woman_playing_water_polo_tone1:": "1f93d-1f3fb-200d-2640-fe0f",
  ":woman_playing_water_polo_tone2:": "1f93d-1f3fc-200d-2640-fe0f",
  ":woman_playing_water_polo_tone3:": "1f93d-1f3fd-200d-2640-fe0f",
  ":woman_playing_water_polo_tone4:": "1f93d-1f3fe-200d-2640-fe0f",
  ":woman_playing_water_polo_tone5:": "1f93d-1f3ff-200d-2640-fe0f",
  ":woman_police_officer:": "1f46e-200d-2640-fe0f",
  ":woman_police_officer_tone1:": "1f46e-1f3fb-200d-2640-fe0f",
  ":woman_police_officer_tone2:": "1f46e-1f3fc-200d-2640-fe0f",
  ":woman_police_officer_tone3:": "1f46e-1f3fd-200d-2640-fe0f",
  ":woman_police_officer_tone4:": "1f46e-1f3fe-200d-2640-fe0f",
  ":woman_police_officer_tone5:": "1f46e-1f3ff-200d-2640-fe0f",
  ":woman_pouting:": "1f64e-200d-2640-fe0f",
  ":woman_pouting_tone1:": "1f64e-1f3fb-200d-2640-fe0f",
  ":woman_pouting_tone2:": "1f64e-1f3fc-200d-2640-fe0f",
  ":woman_pouting_tone3:": "1f64e-1f3fd-200d-2640-fe0f",
  ":woman_pouting_tone4:": "1f64e-1f3fe-200d-2640-fe0f",
  ":woman_pouting_tone5:": "1f64e-1f3ff-200d-2640-fe0f",
  ":woman_raising_hand:": "1f64b-200d-2640-fe0f",
  ":woman_raising_hand_tone1:": "1f64b-1f3fb-200d-2640-fe0f",
  ":woman_raising_hand_tone2:": "1f64b-1f3fc-200d-2640-fe0f",
  ":woman_raising_hand_tone3:": "1f64b-1f3fd-200d-2640-fe0f",
  ":woman_raising_hand_tone4:": "1f64b-1f3fe-200d-2640-fe0f",
  ":woman_raising_hand_tone5:": "1f64b-1f3ff-200d-2640-fe0f",
  ":woman_red_haired:": "1f469-200d-1f9b0",
  ":woman_red_haired_tone1:": "1f469-1f3fb-200d-1f9b0",
  ":woman_red_haired_tone2:": "1f469-1f3fc-200d-1f9b0",
  ":woman_red_haired_tone3:": "1f469-1f3fd-200d-1f9b0",
  ":woman_red_haired_tone4:": "1f469-1f3fe-200d-1f9b0",
  ":woman_red_haired_tone5:": "1f469-1f3ff-200d-1f9b0",
  ":woman_rowing_boat:": "1f6a3-200d-2640-fe0f",
  ":woman_rowing_boat_tone1:": "1f6a3-1f3fb-200d-2640-fe0f",
  ":woman_rowing_boat_tone2:": "1f6a3-1f3fc-200d-2640-fe0f",
  ":woman_rowing_boat_tone3:": "1f6a3-1f3fd-200d-2640-fe0f",
  ":woman_rowing_boat_tone4:": "1f6a3-1f3fe-200d-2640-fe0f",
  ":woman_rowing_boat_tone5:": "1f6a3-1f3ff-200d-2640-fe0f",
  ":woman_running:": "1f3c3-200d-2640-fe0f",
  ":woman_running_tone1:": "1f3c3-1f3fb-200d-2640-fe0f",
  ":woman_running_tone2:": "1f3c3-1f3fc-200d-2640-fe0f",
  ":woman_running_tone3:": "1f3c3-1f3fd-200d-2640-fe0f",
  ":woman_running_tone4:": "1f3c3-1f3fe-200d-2640-fe0f",
  ":woman_running_tone5:": "1f3c3-1f3ff-200d-2640-fe0f",
  ":woman_scientist:": "1f469-200d-1f52c",
  ":woman_scientist_tone1:": "1f469-1f3fb-200d-1f52c",
  ":woman_scientist_tone2:": "1f469-1f3fc-200d-1f52c",
  ":woman_scientist_tone3:": "1f469-1f3fd-200d-1f52c",
  ":woman_scientist_tone4:": "1f469-1f3fe-200d-1f52c",
  ":woman_scientist_tone5:": "1f469-1f3ff-200d-1f52c",
  ":woman_shrugging:": "1f937-200d-2640-fe0f",
  ":woman_shrugging_tone1:": "1f937-1f3fb-200d-2640-fe0f",
  ":woman_shrugging_tone2:": "1f937-1f3fc-200d-2640-fe0f",
  ":woman_shrugging_tone3:": "1f937-1f3fd-200d-2640-fe0f",
  ":woman_shrugging_tone4:": "1f937-1f3fe-200d-2640-fe0f",
  ":woman_shrugging_tone5:": "1f937-1f3ff-200d-2640-fe0f",
  ":woman_singer:": "1f469-200d-1f3a4",
  ":woman_singer_tone1:": "1f469-1f3fb-200d-1f3a4",
  ":woman_singer_tone2:": "1f469-1f3fc-200d-1f3a4",
  ":woman_singer_tone3:": "1f469-1f3fd-200d-1f3a4",
  ":woman_singer_tone4:": "1f469-1f3fe-200d-1f3a4",
  ":woman_singer_tone5:": "1f469-1f3ff-200d-1f3a4",
  ":woman_student:": "1f469-200d-1f393",
  ":woman_student_tone1:": "1f469-1f3fb-200d-1f393",
  ":woman_student_tone2:": "1f469-1f3fc-200d-1f393",
  ":woman_student_tone3:": "1f469-1f3fd-200d-1f393",
  ":woman_student_tone4:": "1f469-1f3fe-200d-1f393",
  ":woman_student_tone5:": "1f469-1f3ff-200d-1f393",
  ":woman_superhero:": "1f9b8-200d-2640-fe0f",
  ":woman_superhero_tone1:": "1f9b8-1f3fb-200d-2640-fe0f",
  ":woman_superhero_tone2:": "1f9b8-1f3fc-200d-2640-fe0f",
  ":woman_superhero_tone3:": "1f9b8-1f3fd-200d-2640-fe0f",
  ":woman_superhero_tone4:": "1f9b8-1f3fe-200d-2640-fe0f",
  ":woman_superhero_tone5:": "1f9b8-1f3ff-200d-2640-fe0f",
  ":woman_supervillain:": "1f9b9-200d-2640-fe0f",
  ":woman_supervillain_tone1:": "1f9b9-1f3fb-200d-2640-fe0f",
  ":woman_supervillain_tone2:": "1f9b9-1f3fc-200d-2640-fe0f",
  ":woman_supervillain_tone3:": "1f9b9-1f3fd-200d-2640-fe0f",
  ":woman_supervillain_tone4:": "1f9b9-1f3fe-200d-2640-fe0f",
  ":woman_supervillain_tone5:": "1f9b9-1f3ff-200d-2640-fe0f",
  ":woman_surfing:": "1f3c4-200d-2640-fe0f",
  ":woman_surfing_tone1:": "1f3c4-1f3fb-200d-2640-fe0f",
  ":woman_surfing_tone2:": "1f3c4-1f3fc-200d-2640-fe0f",
  ":woman_surfing_tone3:": "1f3c4-1f3fd-200d-2640-fe0f",
  ":woman_surfing_tone4:": "1f3c4-1f3fe-200d-2640-fe0f",
  ":woman_surfing_tone5:": "1f3c4-1f3ff-200d-2640-fe0f",
  ":woman_swimming:": "1f3ca-200d-2640-fe0f",
  ":woman_swimming_tone1:": "1f3ca-1f3fb-200d-2640-fe0f",
  ":woman_swimming_tone2:": "1f3ca-1f3fc-200d-2640-fe0f",
  ":woman_swimming_tone3:": "1f3ca-1f3fd-200d-2640-fe0f",
  ":woman_swimming_tone4:": "1f3ca-1f3fe-200d-2640-fe0f",
  ":woman_swimming_tone5:": "1f3ca-1f3ff-200d-2640-fe0f",
  ":woman_teacher:": "1f469-200d-1f3eb",
  ":woman_teacher_tone1:": "1f469-1f3fb-200d-1f3eb",
  ":woman_teacher_tone2:": "1f469-1f3fc-200d-1f3eb",
  ":woman_teacher_tone3:": "1f469-1f3fd-200d-1f3eb",
  ":woman_teacher_tone4:": "1f469-1f3fe-200d-1f3eb",
  ":woman_teacher_tone5:": "1f469-1f3ff-200d-1f3eb",
  ":woman_technologist:": "1f469-200d-1f4bb",
  ":woman_technologist_tone1:": "1f469-1f3fb-200d-1f4bb",
  ":woman_technologist_tone2:": "1f469-1f3fc-200d-1f4bb",
  ":woman_technologist_tone3:": "1f469-1f3fd-200d-1f4bb",
  ":woman_technologist_tone4:": "1f469-1f3fe-200d-1f4bb",
  ":woman_technologist_tone5:": "1f469-1f3ff-200d-1f4bb",
  ":woman_tipping_hand:": "1f481-200d-2640-fe0f",
  ":woman_tipping_hand_tone1:": "1f481-1f3fb-200d-2640-fe0f",
  ":woman_tipping_hand_tone2:": "1f481-1f3fc-200d-2640-fe0f",
  ":woman_tipping_hand_tone3:": "1f481-1f3fd-200d-2640-fe0f",
  ":woman_tipping_hand_tone4:": "1f481-1f3fe-200d-2640-fe0f",
  ":woman_tipping_hand_tone5:": "1f481-1f3ff-200d-2640-fe0f",
  ":woman_tone1:": "1f469-1f3fb",
  ":woman_tone2:": "1f469-1f3fc",
  ":woman_tone3:": "1f469-1f3fd",
  ":woman_tone4:": "1f469-1f3fe",
  ":woman_tone5:": "1f469-1f3ff",
  ":woman_vampire:": "1f9db-200d-2640-fe0f",
  ":woman_vampire_tone1:": "1f9db-1f3fb-200d-2640-fe0f",
  ":woman_vampire_tone2:": "1f9db-1f3fc-200d-2640-fe0f",
  ":woman_vampire_tone3:": "1f9db-1f3fd-200d-2640-fe0f",
  ":woman_vampire_tone4:": "1f9db-1f3fe-200d-2640-fe0f",
  ":woman_vampire_tone5:": "1f9db-1f3ff-200d-2640-fe0f",
  ":woman_walking:": "1f6b6-200d-2640-fe0f",
  ":woman_walking_tone1:": "1f6b6-1f3fb-200d-2640-fe0f",
  ":woman_walking_tone2:": "1f6b6-1f3fc-200d-2640-fe0f",
  ":woman_walking_tone3:": "1f6b6-1f3fd-200d-2640-fe0f",
  ":woman_walking_tone4:": "1f6b6-1f3fe-200d-2640-fe0f",
  ":woman_walking_tone5:": "1f6b6-1f3ff-200d-2640-fe0f",
  ":woman_wearing_turban:": "1f473-200d-2640-fe0f",
  ":woman_wearing_turban_tone1:": "1f473-1f3fb-200d-2640-fe0f",
  ":woman_wearing_turban_tone2:": "1f473-1f3fc-200d-2640-fe0f",
  ":woman_wearing_turban_tone3:": "1f473-1f3fd-200d-2640-fe0f",
  ":woman_wearing_turban_tone4:": "1f473-1f3fe-200d-2640-fe0f",
  ":woman_wearing_turban_tone5:": "1f473-1f3ff-200d-2640-fe0f",
  ":woman_white_haired:": "1f469-200d-1f9b3",
  ":woman_white_haired_tone1:": "1f469-1f3fb-200d-1f9b3",
  ":woman_white_haired_tone2:": "1f469-1f3fc-200d-1f9b3",
  ":woman_white_haired_tone3:": "1f469-1f3fd-200d-1f9b3",
  ":woman_white_haired_tone4:": "1f469-1f3fe-200d-1f9b3",
  ":woman_white_haired_tone5:": "1f469-1f3ff-200d-1f9b3",
  ":woman_with_headscarf:": "1f9d5",
  ":woman_with_headscarf_tone1:": "1f9d5-1f3fb",
  ":woman_with_headscarf_tone2:": "1f9d5-1f3fc",
  ":woman_with_headscarf_tone3:": "1f9d5-1f3fd",
  ":woman_with_headscarf_tone4:": "1f9d5-1f3fe",
  ":woman_with_headscarf_tone5:": "1f9d5-1f3ff",
  ":woman_zombie:": "1f9df-200d-2640-fe0f",
  ":womans_clothes:": "1f45a",
  ":womans_flat_shoe:": "1f97f",
  ":womans_hat:": "1f452",
  ":women_with_bunny_ears_partying:": "1f46f-200d-2640-fe0f",
  ":women_wrestling:": "1f93c-200d-2640-fe0f",
  ":womens:": "1f6ba",
  ":woozy_face:": "1f974",
  ":worried:": "1f61f",
  ":wrench:": "1f527",
  ":writing_hand:": "270d",
  ":writing_hand_tone1:": "270d-1f3fb",
  ":writing_hand_tone2:": "270d-1f3fc",
  ":writing_hand_tone3:": "270d-1f3fd",
  ":writing_hand_tone4:": "270d-1f3fe",
  ":writing_hand_tone5:": "270d-1f3ff",
  ":x:": "274c",
  ":yarn:": "1f9f6",
  ":yellow_heart:": "1f49b",
  ":yen:": "1f4b4",
  ":yin_yang:": "262f",
  ":yum:": "1f60b",
  ":zany_face:": "1f92a",
  ":zap:": "26a1",
  ":zebra:": "1f993",
  ":zero:": "0030-fe0f-20e3",
  ":zipper_mouth:": "1f910",
  ":zombie:": "1f9df",
  ":zzz:": "1f4a4",
}

const emojiNames = Object.keys(EMOJI_LIST).map((emojiName) =>
  emojiName.replace(/[+]/g, "\\$&")
)
const regEmojiNames = new RegExp(emojiNames.join("|"), "gi")

const convert: (unicode: string) => string = (unicode: string) =>
  unicode
    .split("-")
    .map((s) => {
      const sInt: number = parseInt(s, 16)
      if (sInt >= 0x10000 && sInt <= 0x10ffff) {
        const hi = Math.floor((sInt - 0x10000) / 0x400) + 0xd800
        const lo = ((sInt - 0x10000) % 0x400) + 0xdc00
        return String.fromCharCode(hi) + String.fromCharCode(lo)
      }
      return String.fromCharCode(sInt)
    })
    .join("")

export const shortnameToUnicode: (shortname: string) => string = (
  str: string
) =>
  str.replace(regEmojiNames, (emojiName: string) => {
    if (
      typeof emojiName === "undefined" ||
      emojiName === "" ||
      !(emojiName in EMOJI_LIST)
    ) {
      return emojiName
    }
    const unicode = EMOJI_LIST[emojiName].toUpperCase()
    return convert(unicode)
  })
