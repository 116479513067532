/* eslint-disable import/no-named-as-default-member, import/default, import/named */
import qs from "query-string"
import { useCallback } from "react"
import { useHistory, useLocation } from "react-router-dom"
import { buildUrl } from "@Utils/url"

const useGoalDossier = () => {
  const history = useHistory()
  const location = useLocation()
  const { goalId } = qs.parse(location.search)

  const open = useCallback(
    (goalId: string) => {
      const params = qs.parse(location.search)
      history.push(
        buildUrl(location.pathname, {
          ...params,
          goalId,
        })
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location]
  )

  const close = useCallback(() => {
    const { goalId, ...restOfParams } = qs.parse(location.search)

    history.push(
      buildUrl(location.pathname, {
        ...restOfParams,
      })
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  return { isOpen: !!goalId, open, close, goalId }
}

export default useGoalDossier
