/**
 * DEPRECATED: we are no longer using decoders to validate server responses.
 * Please do not use them in new code.
 *
 * See src/api/decoders/README.md for more info.
 */
import { JsonDecoder } from "ts.data.json"
import { Goal } from "../../../types/Goals"
import { personalGoalDecoder } from "../PersonalGoalDecoder/personalGoalDecoder"
import { teamGoalDecoder } from "../TeamGoalDecoder/teamGoalDecoder"
import { departmentGoalDecoder } from "../GetDepartmentGoalsResponseDecoder/getDepartmentGoalsResponseDecoder"
import { companyGoalDecoder } from "../getCompanyGoalsDecoder/getCompanyGoalsResponseDecoder"

type ResponseType = {
  goal: Goal
}

export const getGoalDetailsResponseDecoder = JsonDecoder.object<ResponseType>(
  {
    goal: JsonDecoder.oneOf<Goal>(
      [
        personalGoalDecoder,
        teamGoalDecoder,
        departmentGoalDecoder,
        companyGoalDecoder,
      ],
      "goalDetails"
    ),
  },
  "getGoalDetailsResponse"
).map(({ goal }) => goal)
