import React from "react"
import Aid from "@Constants/automationId"
import { createKeyResult } from "@Goals/domain/keyResult"
import { KeyResult } from "@Goals/types"
import GoalKeyResultItem from "./GoalKeyResultItem"

type LegacyKeyResultFormListProps = {
  currentKeyResultId: string | null
  keyResults: KeyResult[]
  onSelect: (id: string) => void
  onCancel: () => void
  onUpdate: (keyResults: KeyResult[]) => void
}

const emptyAgendaItemTemplate: KeyResult = {
  ...createKeyResult(),
  id: "empty-key-result",
  title: "",
}

export const LegacyKeyResultFormList = ({
  currentKeyResultId,
  keyResults,
  onSelect,
  onUpdate,
  onCancel,
}: LegacyKeyResultFormListProps) => {
  const addKeyResult = (title: string) => {
    const updatedKeyResults = [...keyResults, { ...createKeyResult(), title }]
    onUpdate(updatedKeyResults)
  }

  const removeKeyResult = (id: string) => {
    const updatedKeyResults = keyResults.filter((kr) => kr.id !== id)
    onUpdate(updatedKeyResults)
  }

  const updateKeyResult = (id: string, title: string) => {
    const updatedKeyResults = keyResults.map((kr) =>
      id === kr.id ? { ...kr, title } : kr
    )
    onUpdate(updatedKeyResults)
  }

  return (
    <div data-automation-id={Aid.goalsLegacyKeyResultFormList}>
      {keyResults &&
        keyResults.map((item) => (
          <GoalKeyResultItem
            automationId={Aid.newGoalCreateKeyResultItem}
            key={item.id}
            GoalKeyResultItem={item}
            canRemove={true}
            isEditing={currentKeyResultId === item.id}
            onUpdate={(v: string) => updateKeyResult(item.id, v)}
            onSelect={() => onSelect(item.id)}
            onCancelSelect={onCancel}
            onRemove={() => removeKeyResult(item.id)}
          />
        ))}
      <GoalKeyResultItem
        automationId={Aid.newGoalCreateKeyResultButton}
        GoalKeyResultItem={emptyAgendaItemTemplate}
        canRemove={false}
        isEditing={currentKeyResultId === emptyAgendaItemTemplate.id}
        isEmptyItem
        onUpdate={(v: string) => addKeyResult(v)}
        onSelect={() => onSelect(emptyAgendaItemTemplate.id)}
        onCancelSelect={onCancel}
      />
    </div>
  )
}
