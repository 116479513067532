/**
 * DEPRECATED: we are no longer using decoders to validate server responses.
 * Please do not use them in new code.
 *
 * See src/api/decoders/README.md for more info.
 */
import { Draft } from "../../../types/reduxState/drafts"

/**
 * Response format:
 * {
 *  drafts: [{
 *    body: "Draft body"
 *  }]
 * }
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getDraftDecoder = (data: any): Draft => {
  const { drafts: draftData } = data
  return {
    body: draftData[0].body || "",
  }
}

export default getDraftDecoder
