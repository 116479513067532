import React from "react"
import { PrivateRoute, ValidatedRoute } from "@Common/routing"
import { Switch } from "react-router-dom"

const FeedbackApp = React.lazy(
  () => import(/* webpackChunkName: "Feedback" */ "@Feedback/index")
)
const FeedbackResponse = React.lazy(
  () =>
    import(
      /* webpackChunkName: "FeedbackResponse" */ "@Feedback/individual/pages/FeedbackResponse"
    )
)
const FeedbackRequests = React.lazy(
  () =>
    import(
      /* webpackChunkName: "FeedbackRequests" */ "@Feedback/manager/pages/RequestFeedback"
    )
)
const FeedbackNominations = React.lazy(
  () =>
    import(
      /* webpackChunkName: "FeedbackNominations" */ "@Feedback/individual/pages/FeedbackNominations"
    )
)

export const FeedbackRoutes = (
  <PrivateRoute path="/feedback">
    <FeedbackApp>
      <Switch>
        <PrivateRoute
          path="/feedback/requests/:peerFeedbackCycleId/:subjectId"
          authorisedRoles={["admin", "manager"]}
        >
          <ValidatedRoute
            exact
            path="/feedback/requests/:evaluationCycleId/:subjectId"
            component={FeedbackRequests}
            validators={{ evaluationCycleId: /^\d+$/, subjectId: /^\d+$/ }}
          />
        </PrivateRoute>
        <ValidatedRoute
          exact
          path="/feedback/peer/nominations/:peerFeedbackCycleId"
          component={FeedbackNominations}
          validators={{ peerFeedbackCycleId: /^\d+$/ }}
        />
        <ValidatedRoute
          exact
          path="/feedback/:peerFeedbackCycleId/:responseId"
          component={FeedbackResponse}
          validators={{ peerFeedbackCycleId: /^\d+$/, responseId: /^\d+$/ }}
        />
      </Switch>
    </FeedbackApp>
  </PrivateRoute>
)
