import Reflux from "reflux-core"
import SteadyfootAgent from "../../refluxActions/lib/SteadyfootAgent"
import {
  addLoadingState,
  addToasts,
  extractResponseKey,
} from "../../refluxActions/lib/apiActionHelpers"
import SearchAgent from "../../refluxActions/lib/SearchAgent"
import { adaptGoalsParams } from "../../refluxActions/lib/goalsHelpers"
import endpoints from "../../constants/endpointsDeprecated"
import strings from "../../locale/strings"
import { showCsvConfirmation } from "../../refluxActions/lib/csvExportation"

const { ADMIN_GOALS_URLS } = endpoints
const {
  ADMIN_GOALS_URL,
  ADMIN_GOALS_STATS_EXPORT_REQUESTS_URL,
  ADMIN_GOAL_EXPORT_KEY_REQUEST_URL,
  ADMIN_GOAL_EXPORT_ALIGN_REQUEST_URL,
} = ADMIN_GOALS_URLS

const agent = SteadyfootAgent.defaultInstance

const searchAgent = new SearchAgent({
  requestAgent: agent,
  resourcePath: ADMIN_GOALS_URL,
  resourceName: "goals",
  resourceNameSingle: "goal",
  pageSize: 25,
})

const AdminGoalActions = Reflux.createActions({
  list: { asyncResult: true },
  load: { asyncResult: true },
  searchGoals: { asyncResult: true },
  pageGoals: { asyncResult: true },
  loadStatsOverview: { asyncResult: true },
  loadDepartmentStats: { asyncResult: true },
  loadTeamStats: { asyncResult: true },
  exportStats: { asyncResult: true },
  exportKeyResults: { asyncResult: true },
  exportGoalAlign: { asyncResult: true },
})

AdminGoalActions.load.listenAndPromise(({ id }) =>
  searchAgent.get({ itemId: id })
)

/**
 * @param {string=} query
 * @param {string=} managerId
 * @param {number=} jobTitleId
 * @param {number=} departmentId
 * @param {string=} status
 * @param {Date=} startDate
 * @param {Date=} endDate
 * @param {string=} sortBy - priority | status | due_at | owner_name | completion
 * @param {string=} sortOrder - asc | desc
 */
AdminGoalActions.searchGoals.listenAndPromise((searchParams = {}) =>
  addLoadingState(
    [true, { light: true }],
    searchAgent.search(adaptGoalsParams(searchParams))
  )
)

/**
 * @param {number} page
 * @param {string=} query
 * @param {string=} managerId
 * @param {number=} jobTitleId
 * @param {string=} status
 * @param {Date=} startDate
 * @param {Date=} endDate
 * @param {string=} sortBy - priority | status | due_at | owner_name
 * @param {string=} sortOrder - asc | desc
 */
AdminGoalActions.pageGoals.listenAndPromise((searchParams) =>
  searchAgent.search(adaptGoalsParams(searchParams))
)

/**
 * @param {Date} startDate - starting date for goals with due_at within that period
 * @param {Date} endDate - end date for goals with due_at within that period
 * @param {Number} departmentId - department id, if undefined, scope to all depts
 */
AdminGoalActions.loadStatsOverview.listenAndPromise(
  ({ startDate, endDate, departmentId }) =>
    addToasts(
      {},
      extractResponseKey(
        "stats",
        agent.get(`${ADMIN_GOALS_URL}/company_stats`).query({
          start_date: startDate.toISOString(),
          end_date: endDate.toISOString(),
          parent_job_title_ids: departmentId ? [departmentId] : undefined,
        })
      ).then((stats) => ({ stats, departmentId }))
    )
)

AdminGoalActions.loadDepartmentStats.listenAndPromise(
  ({ startDate, endDate, sortBy, sortOrder }) =>
    addLoadingState(
      [true, { light: true }],
      addToasts(
        {},
        extractResponseKey(
          "stats",
          agent.get(`${ADMIN_GOALS_URL}/department_stats`).query({
            start_date: startDate.toISOString(),
            end_date: endDate.toISOString(),
            sort_by: sortBy || undefined,
            sort_order: sortOrder || undefined,
          })
        )
      )
    )
)

/**
 * @param {Date} startDate - starting date for goals with due_at within that period
 * @param {Date} endDate - end date for goals with due_at within that period
 * @param {Number} departmentId - department id, it is required
 */
AdminGoalActions.loadTeamStats.listenAndPromise(
  ({
    startDate,
    endDate,
    departmentId,
    departmentSortBy,
    departmentSortOrder,
  }) =>
    addToasts(
      {},
      extractResponseKey(
        "stats",
        agent.get(`${ADMIN_GOALS_URL}/team_stats`).query({
          start_date: startDate.toISOString(),
          end_date: endDate.toISOString(),
          parent_job_title_ids: departmentId,
          sort_by: departmentSortBy || undefined,
          sort_order: departmentSortOrder || undefined,
        })
      ).then((stats) => ({ stats, departmentId }))
    )
)

AdminGoalActions.exportStats.listenAndPromise(
  ({ departmentId, startDate, endDate }) =>
    addLoadingState(
      true,
      addToasts(
        { defaultError: strings.adminGoals.stats.exportFailed },
        extractResponseKey(
          "export_request",
          agent.post(ADMIN_GOALS_STATS_EXPORT_REQUESTS_URL).send({
            goal_stat_export_request: {
              options: {
                min_due_at: startDate.toISOString(),
                max_due_at: endDate.toISOString(),
                department_ids: departmentId ? [departmentId] : undefined,
              },
            },
          })
        ).then(showCsvConfirmation)
      )
    )
)

AdminGoalActions.exportKeyResults.listenAndPromise(({ startDate, endDate }) =>
  addLoadingState(
    true,
    addToasts(
      { defaultError: strings.adminGoals.stats.exportFailed },
      extractResponseKey(
        "export_request",
        agent.post(ADMIN_GOAL_EXPORT_KEY_REQUEST_URL).send({
          goal_key_results_export_request: {
            options: {
              min_due_at: startDate.toISOString(),
              max_due_at: endDate.toISOString(),
            },
          },
        })
      ).then(showCsvConfirmation)
    )
  )
)

AdminGoalActions.exportGoalAlign.listenAndPromise(({ startDate, endDate }) =>
  addLoadingState(
    true,
    addToasts(
      { defaultError: strings.adminGoals.stats.exportFailed },
      extractResponseKey(
        "export_request",
        agent.post(ADMIN_GOAL_EXPORT_ALIGN_REQUEST_URL).send({
          aligned_goals_export_request: {
            options: {
              min_due_at: startDate.toISOString(),
              max_due_at: endDate.toISOString(),
            },
          },
        })
      ).then(showCsvConfirmation)
    )
  )
)

/**
 * @param {number} userId
 * @param {number} usersDepartmentId
 * @param {number} departmentId
 * @param {string} type
 * @param {boolean} showLoadingState
 * @param {date} from
 * @param {date} to
 */

AdminGoalActions.list.listenAndPromise(
  ({
    userId = null,
    usersDepartmentId = null,
    departmentId = null,
    type = null,
    showLoadingState = false,
    from = null,
    to = null,
    sortBy,
    sortOrder = "asc",
    query = null,
  } = {}) => {
    if (!(departmentId || userId)) {
      throw new Error("You must either supply departmentId or userId")
    }

    if (departmentId && (userId || usersDepartmentId)) {
      throw new Error(
        "If departmentId is provided, userId and usersDepartmentId must not \
      be provided"
      )
    }

    return addLoadingState(
      showLoadingState,
      addToasts(
        { defaultError: strings.goals.errorMessages.loadingGoals },
        extractResponseKey(
          "goals",
          agent.get(ADMIN_GOALS_URL).query({
            per_page: 1e9,
            user_id: userId || undefined,
            type: type || undefined,
            department_id: userId ? undefined : departmentId,
            sort_by: sortBy,
            sort_order: sortBy ? sortOrder : undefined,
            from: from ? from.toISOString() : undefined,
            to: to ? to.toISOString() : undefined,
            query: query,
          })
        ).then((goals) => ({
          goals,
          userId,
          departmentId: usersDepartmentId || departmentId,
          couldIncludeCompanyGoals: !!userId,
        }))
      )
    )
  }
)
export default AdminGoalActions
