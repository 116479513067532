/**
 * A list of the configuration options added to the
 * performance-api ConfigurationOptions table via migration.
 * Note, these are unrelated to the feature flag system that uses flipper,
 * and are a first step in moving away from using Account settings
 * (where we access database entries from the companies table).
 */

export enum ConfigurationOptions {
  disablePersonalViewPdfExport = "disable_personal_view_pdf_export",
  goalsDefaultVisibility = "goals_default_visibility",
  managerViewPdfExport = "manager_view_pdf_export",
  shareReviewsWithManager = "share_reviews_with_manager",
  teamBasedFeedback = "team_based_feedback",
  teamGoals = "team_goals",
  teams = "teams",
}

export type ConfigurationOption = {
  name: string
  value: string | null
}
