import userIcon from "@kaizen/component-library/icons/user.icon.svg"
import usersIcon from "@kaizen/component-library/icons/users.icon.svg"
import departmentIcon from "@kaizen/component-library/icons/department.icon.svg"
import companyIcon from "@kaizen/component-library/icons/organization.icon.svg"
import strings from "../../locale/strings"

// we set this ourselves for UI sake - the real max is 65535
export const SQL_TEXT_MAX_LENGTH = 1000

const {
  individualGoalForm,
  teamGoalForm,
  departmentGoalForm,
  companyGoalForm,
  goalsPage,
} = strings

export const FIELD_STRING = {
  team: teamGoalForm,
  personal: individualGoalForm,
  department: departmentGoalForm,
  company: companyGoalForm,
}

export const GOALS_PAGE_STRING = {
  personal: goalsPage.goalType.individual,
  team: goalsPage.goalType.team,
  department: goalsPage.goalType.department,
  company: goalsPage.goalType.company,
}

export const GOAL_ICON = {
  personal: userIcon,
  team: usersIcon,
  department: departmentIcon,
  company: companyIcon,
}

export const BUSINESS_SUBTYPE = "business"
export const DEVELOPMENT_SUBTYPE = "development"

export const SUBTYPE_VALUES = [
  {
    label: strings.individualGoalForm.subTypeField.noType,
    value: "",
  },
  {
    label: strings.individualGoalForm.subTypeField.businessType,
    value: BUSINESS_SUBTYPE,
  },
  {
    label: strings.individualGoalForm.subTypeField.developmentType,
    value: DEVELOPMENT_SUBTYPE,
  },
]

export const GOAL_PRIORITY_OPTIONS = [
  {
    value: "0",
    label: strings.prioritySelector.low,
  },
  {
    value: "1",
    label: strings.prioritySelector.medium,
  },
  {
    value: "2",
    label: strings.prioritySelector.high,
  },
]
