import _ from "lodash"
import { perfApiPut, perfApiGet } from "@API/utils"
import Reflux from "../../refluxActions/ActionsInitializer"
import PerformanceReview from "../../models/PerformanceReview"
import PerformanceCycle from "../../models/PerformanceCycle"
import SteadyfootAgent from "../../refluxActions/lib/SteadyfootAgent"
import {
  addBucketAssignment,
  getTeamReviews,
  updateAnswer,
  updateReview,
} from "../../refluxActions/PerformanceReviewActions"
import {
  CycleSearchAgent,
  getCycles,
} from "../../refluxActions/lib/cycleActionHelpers"
import { collapseGroupTypes } from "../../refluxActions/lib/teamHelpers"
import UIActions from "../../refluxActions/UIActions"
import {
  addLoadingState,
  addToasts,
  extractResponseKey,
} from "../../refluxActions/lib/apiActionHelpers"
import endpoints from "../../constants/endpointsDeprecated"
import strings from "../../locale/strings"
import { showCsvConfirmation } from "../../refluxActions/lib/csvExportation"
import { loadUserFilterOptions } from "../../refluxActions/lib/userFilterHelpers"
import { buildUrl } from "../../utils/url"

const {
  ADMIN_GENERAL_URLS,
  ADMIN_PERF_REVIEWS_URLS,
  ADMIN_PERF_REVIEW_FILTER_URLS,
  ADMIN_PERF_REVIEW_FILTER_URLS_FOR_SNAPSHOT,
  ADMIN_SAVED_VIEW_URLS,
} = endpoints
const { ADMIN_BASE_URL } = ADMIN_GENERAL_URLS
const {
  ADMIN_PERF_CYCLES_URL,
  ADMIN_PERF_ANSWERS_URL,
  ADMIN_PERF_BUCKETS_ASSIGNMENT_URL,
  ADMIN_PERF_EXPORT_REQUESTS_URL,
  ADMIN_PERF_MANAGERS_URL,
  ADMIN_PERF_DEFAULTS_URL,
  ADMIN_PERF_REVIEW_URL,
  ADMIN_PERF_ADD_COLLABORATOR,
  NEW_URL,
} = ADMIN_PERF_REVIEWS_URLS
const agent = SteadyfootAgent.defaultInstance

const searchAgent = new CycleSearchAgent({
  baseUrl: ADMIN_BASE_URL,
  cycleItemType: PerformanceReview,
  cycleKey: "performance_cycles",
  cycleKeySingle: "performance_cycle",
  itemKey: "performance_reviews",
  itemKeySingle: "performance_review",
})

const PerformanceReviewAdminActions = Reflux.createActions({
  getCycles: { asyncResult: true },
  getCycle: { asyncResult: true },
  getLocalisedCycle: { asyncResult: true },
  getDefaultBucketsAndQuestions: { asyncResult: true },
  getUserIds: { asyncResult: true },
  getReview: { asyncResult: true },
  getStarterTemplate: { asyncResult: true },
  getTeamReviews: { asyncResult: true },
  setNextReview: { sync: true },
  searchReviews: { asyncResult: true },
  pageSearchResults: { asyncResult: true },
  clearSearchResults: {},
  createCycle: { asyncResult: true },
  updateCycle: { asyncResult: true },
  completeCycle: { asyncResult: true },
  reopenCycle: { asyncResult: true },
  deleteCycle: { asyncResult: true },
  notifyBucketEditing: {},
  addBucketAssignment: { asyncResult: true },
  addPendingBucketAssignment: { asyncResult: true },
  updateReview: { asyncResult: true },
  updateFlag: { asyncResult: true },
  updateAnswer: { asyncResult: true },
  notifyAnswerEditing: {},
  exportCycle: { asyncResult: true },
  getIncompleteManagers: { asyncResult: true },
  shareReview: { asyncResult: true },
  syncManager: { asyncResult: true },
  clearReviews: {},
  loadFilterOptions: { asyncResult: true },
  loadFilterOptionsForSavedView: { asyncResult: true },
  shareAllReviews: { asyncResult: true },
  addCollaborators: { asyncResult: true },
  removeCollaborator: { asyncResult: true },
  searchSavedViewPerformanceReviews: { asyncResult: true },
  pageSearchResultsSavedViewPerformanceReviews: { asyncResult: true },
  pageSearchResultsSavedViewPerformanceReviewsWithoutMergingResults: {
    asyncResult: true,
  },
  getPerformanceCyclesForCalibrationView: { asyncResult: true },
  saveCalibrationViewPerformanceReviewNote: { asyncResult: true },
})

PerformanceReviewAdminActions.getCycles.listenAndPromise(
  ({ loadingState = true, includeDrafts } = {}) =>
    getCycles({
      baseUrl: ADMIN_BASE_URL,
      loadingState,
      cycleKey: "performance_cycles",
      cycleType: PerformanceCycle,
      includeDrafts,
    })
)

PerformanceReviewAdminActions.getCycle.listenAndPromise(({ cycleId }) =>
  addToasts(
    {},
    extractResponseKey(
      "performance_cycle",
      PerformanceCycle.of,
      agent.get(`${ADMIN_PERF_CYCLES_URL}/${cycleId}`)
    )
  )
)

PerformanceReviewAdminActions.getLocalisedCycle.listenAndPromise(
  ({ cycleId, locale } = {}) =>
    addLoadingState(
      [true, { light: true }],
      extractResponseKey(
        "performance_cycle",
        PerformanceCycle.of,
        agent.get(`${ADMIN_PERF_CYCLES_URL}/${cycleId}`).query({
          locale,
        })
      )
    )
)

PerformanceReviewAdminActions.getDefaultBucketsAndQuestions.listenAndPromise(
  () =>
    extractResponseKey(
      "performance_default",
      agent.get(ADMIN_PERF_DEFAULTS_URL)
    )
)

PerformanceReviewAdminActions.getUserIds.listenAndPromise((cycleId) =>
  addToasts(
    {},
    extractResponseKey(
      "user_ids",
      agent.get(
        `${ADMIN_PERF_CYCLES_URL}/${cycleId}/performance_reviews/user_ids`
      )
    )
  )
)

function adaptSearchParams(
  cycleId,
  {
    performance_bucket_in_cycle_ids: bucketIds,
    manager_ids: managerIds,
    job_title_ids: jobTitleIds,
    department_ids: departmentIds,
    demographic_value_ids: demographicValueIds,
    user_ids: userIds,
    group_types: groupTypes,
    user_status: userStatus,
    levels,
    status,
    ...params
  }
) {
  return {
    ...params,
    grouped_by_title: Array.isArray(cycleId) ? true : undefined,
    performance_bucket_in_cycle_ids: bucketIds && bucketIds.join(","),
    status: Array.isArray(status) ? status.join(",") : status,
    manager_ids: managerIds && managerIds.join(","),
    job_title_ids: jobTitleIds && jobTitleIds.join(","),
    levels: levels && levels.join(","),
    department_ids: departmentIds && departmentIds.join(","),
    demographic_value_ids: demographicValueIds && demographicValueIds.join(","),
    user_ids: userIds && userIds.join(","),
    group_type: collapseGroupTypes(groupTypes),
    user_status: userStatus && userStatus.join(","),
  }
}

function createJoinOptionalParam(params) {
  return (key) => {
    return params[key] && Array.isArray(params[key])
      ? params[key].join(",")
      : undefined
  }
}

PerformanceReviewAdminActions.getReview.listenAndPromise(
  ({ cycleId, reviewId, loadingState = true } = {}) =>
    addLoadingState(
      loadingState,
      searchAgent.get({ cycleId, itemId: reviewId })
    )
)

export const _adaptStarterTemplate = ({ body: { performance_cycle } }) => {
  // If creating a new cycle, without cycleToDuplicateId,
  // perf-api will return the assessment buckets, but not the
  // bucket position itself. Here we add it ourselves, as not
  // to have invalid data. ie. how can we have bucket questions,
  // but no position to insert them.
  let bucketPosition = performance_cycle.performance_bucket_position
  if (bucketPosition == null) {
    const {
      performance_bucket_in_cycles: buckets,
      performance_question_in_cycles: questions,
    } = performance_cycle
    bucketPosition = !buckets?.length ? null : questions.length
  }

  return {
    ...performance_cycle,
    performance_buckets_position: bucketPosition,
  }
}

PerformanceReviewAdminActions.getStarterTemplate.listenAndPromise(
  ({ cycleToDuplicateId }) =>
    addLoadingState(
      true,
      agent
        .get(NEW_URL)
        .query({ performance_cycle_to_duplicate_id: cycleToDuplicateId })
        .then(_adaptStarterTemplate)
    )
)

PerformanceReviewAdminActions.getTeamReviews.listenAndPromise((params) =>
  getTeamReviews(params, ADMIN_PERF_REVIEW_URL)
)

PerformanceReviewAdminActions.searchSavedViewPerformanceReviews.listenAndPromise(
  ({ savedViewId, cycleId, searchParams, perPage, page }) => {
    const overwrittenAttributes = {}

    overwrittenAttributes.fullResourcePath = buildUrl(
      ADMIN_SAVED_VIEW_URLS.ADMIN_PERF_REVIEW_FOR_SNAPSHOT
    )

    if (savedViewId) {
      overwrittenAttributes.fullResourcePath = buildUrl(
        ADMIN_SAVED_VIEW_URLS.ADMIN_PERF_REVIEW_FROM_SNAPSHOT,
        { savedViewId }
      )
    }
    return addLoadingState(
      [true, { light: true }],
      searchAgent.search({
        savedViewId,
        cycleId,
        page: page || 1,
        per_page: perPage,
        searchParams: adaptSearchParams(cycleId, searchParams),
        ...overwrittenAttributes,
      })
    )
  }
)

PerformanceReviewAdminActions.pageSearchResultsSavedViewPerformanceReviews.listenAndPromise(
  ({ savedViewId, cycleId, page, searchParams }) => {
    const overwrittenAttributes = {}

    overwrittenAttributes.fullResourcePath = buildUrl(
      ADMIN_SAVED_VIEW_URLS.ADMIN_PERF_REVIEW_FOR_SNAPSHOT
    )

    if (savedViewId) {
      overwrittenAttributes.fullResourcePath = buildUrl(
        ADMIN_SAVED_VIEW_URLS.ADMIN_PERF_REVIEW_FROM_SNAPSHOT,
        { savedViewId }
      )
    }

    return addLoadingState(
      [true, { light: true }],
      searchAgent.search({
        cycleId,
        savedViewId,
        page,
        searchParams: adaptSearchParams(cycleId, searchParams),
        ...overwrittenAttributes,
      })
    )
  }
)

// This action is exactly the same to the former above, but we don't resolve it using the observeSearchAction that merge the results.
// This piece of code will be removed when we migrate the AdminCalibrationViewShowPage to use react-query

PerformanceReviewAdminActions.pageSearchResultsSavedViewPerformanceReviewsWithoutMergingResults.listenAndPromise(
  ({ savedViewId, cycleId, page, searchParams, perPage }) => {
    const overwrittenAttributes = {}

    overwrittenAttributes.fullResourcePath = buildUrl(
      ADMIN_SAVED_VIEW_URLS.ADMIN_PERF_REVIEW_FROM_SNAPSHOT,
      { savedViewId }
    )

    return addLoadingState(
      [true, { light: true }],
      searchAgent.search({
        cycleId,
        savedViewId,
        page,
        per_page: perPage,
        searchParams: adaptSearchParams(cycleId, searchParams),
        ...overwrittenAttributes,
      })
    )
  }
)

PerformanceReviewAdminActions.searchReviews.listenAndPromise(
  ({ savedViewId, cycleId, searchParams }) => {
    return addLoadingState(
      [true, { light: true }],
      searchAgent.search({
        savedViewId,
        cycleId,
        page: 1,
        searchParams: adaptSearchParams(cycleId, searchParams),
      })
    )
  }
)

PerformanceReviewAdminActions.pageSearchResults.listenAndPromise(
  ({ savedViewId, cycleId, page, searchParams }) => {
    return addLoadingState(
      [true, { light: true }],
      searchAgent.search({
        cycleId,
        savedViewId,
        page,
        searchParams: adaptSearchParams(cycleId, searchParams),
      })
    )
  }
)

PerformanceReviewAdminActions.createCycle.listenAndPromise(
  ({ performance_cycle }) => {
    return addLoadingState(
      true,
      addToasts(
        { defaultError: strings.adminPerformanceReview.schedulingError },
        extractResponseKey(
          "performance_cycle",
          PerformanceCycle.of,
          agent.post(ADMIN_PERF_CYCLES_URL).send({
            performance_cycle: {
              ..._.omit(performance_cycle, [
                "performance_question_in_cycles",
                "performance_bucket_in_cycles",
              ]),
              name: performance_cycle.name || "Unnamed",
              // The backend requires the `_attributes` appended to the
              // end of these two object keys
              performance_question_in_cycles_attributes:
                performance_cycle.performance_question_in_cycles?.filter(
                  (q) => !q.deleted_at
                ),
              // In the frontend, the thing that determines if
              // we have an assessment question or not is if
              // performance_buckets_position is null or a number.
              // However, if you pass this to the backend, it will create an
              // assessment question anyway.
              performance_bucket_in_cycles_attributes:
                performance_cycle.performance_buckets_position == null
                  ? null
                  : performance_cycle.performance_bucket_in_cycles?.filter(
                      (q) => !q.deleted_at
                    ),
              use_v2: true,
            },
          })
        )
      )
    )
  }
)

PerformanceReviewAdminActions.updateCycle.listenAndPromise(
  async ({
    performance_cycle,
    includeQuestionsAndBuckets,
    cycleStarted,
    hideSuccessToast,
  }) => {
    const { id, completed_at, name, can_share_performance_review } =
      performance_cycle

    const updatedCycle = await sendUpdatedCycle(
      completed_at
        ? { id, name, can_share_performance_review, use_v2: true }
        : { ...performance_cycle, use_v2: true },
      !cycleStarted && includeQuestionsAndBuckets
    )
    if (!hideSuccessToast) {
      UIActions.success(strings.cycle.updateSaveMessage)
    }
    return updatedCycle
  }
)

PerformanceReviewAdminActions.completeCycle.listenAndPromise(
  (performanceCycle) => {
    return sendUpdatedCycle(
      { ...performanceCycle, complete: true },
      false
    ).then((updatedCycle) => {
      return updatedCycle
    })
  }
)

PerformanceReviewAdminActions.reopenCycle.listenAndPromise(
  (performanceCycle) => {
    return sendUpdatedCycle(
      { ...performanceCycle, complete: false },
      false
    ).then((updatedCycle) => {
      UIActions.success(strings.cycle.reopenCycleSuccess, {
        cycleName: updatedCycle.name,
      })
      return updatedCycle
    })
  }
)

function sendUpdatedCycle(performanceCycle, includeQuestionsAndBuckets) {
  // This is quick and messy code, but we are deprecating these actions soon anyway.
  let questionIndex = -1

  // If in draft mode, we auto fill out the name as "Unnamed".
  // Otherwise, we should throw an error
  // This is quick and dirty - the code will be thrown out soon anyway.
  const name = performanceCycle.is_draft
    ? performanceCycle.name === ""
      ? "Unnamed"
      : performanceCycle.name
    : performanceCycle.name

  // Adding the _attributes suffix to the end of the properties
  // is required to get the questions/buckets to actually save.
  const adjustedPc = includeQuestionsAndBuckets
    ? {
        ..._.omit(performanceCycle, [
          "performance_question_in_cycles",
          "performance_bucket_in_cycles",
        ]),
        name,
        performance_question_in_cycles_attributes:
          performanceCycle.performance_question_in_cycles.map((q) => {
            // Don't increment the question index when it's a deleted question
            if (!q.deleted_at) {
              questionIndex++
            }

            return {
              ...q,
              // Quick hack required by the backend to get question deleting working
              _destroy: Boolean(q.deleted_at),
              // The api layer doesn't understand the order of the array, and
              // this value must be added.
              position: q.deleted_at ? undefined : questionIndex,
            }
          }),
        performance_bucket_in_cycles_attributes:
          performanceCycle.performance_bucket_in_cycles.map((b) => {
            return {
              ...b,
              // Quick hack required by the backend to get question deleting working
              _destroy: Boolean(b.deleted_at),
            }
          }),
      }
    : {
        ...performanceCycle,
        name,
      }

  return addToasts(
    { defaultError: strings.adminPerformanceReview.updatingCycleError },
    extractResponseKey(
      "performance_cycle",
      PerformanceCycle.of,
      agent
        .put(`${ADMIN_PERF_CYCLES_URL}/${adjustedPc.id}`)
        .send({ performance_cycle: adjustedPc })
    )
  )
}

PerformanceReviewAdminActions.deleteCycle.listenAndPromise(({ id }) =>
  addToasts(
    {
      defaultError: strings.cycle.deleteCycleError,
      success: strings.cycle.deleteCycleSuccess,
    },
    extractResponseKey(
      "performance_cycle",
      PerformanceCycle.of,
      agent.del(`${ADMIN_PERF_CYCLES_URL}/${id}`)
    )
  )
)

PerformanceReviewAdminActions.updateReview.listenAndPromise(
  (review, { showLoadingState = true } = {}) => {
    return addLoadingState(
      showLoadingState,
      updateReview({
        review,
        perfReviewUrl: `${ADMIN_PERF_CYCLES_URL}/${review.performance_cycle_id}/performance_reviews`,
        perfAnswersUrl: ADMIN_PERF_ANSWERS_URL,
        perfBucketUrl: ADMIN_PERF_BUCKETS_ASSIGNMENT_URL,
      })
    )
  }
)

PerformanceReviewAdminActions.updateFlag.listenAndPromise(({ review, flag }) =>
  updateReview({
    review: { id: review.id, flag },
    loadingState: false,
    perfReviewUrl: `${ADMIN_PERF_CYCLES_URL}/${review.performance_cycle_id}/performance_reviews`,
  }).catch(() => Promise.reject({ review }))
)

PerformanceReviewAdminActions.addBucketAssignment.listenAndPromise((params) =>
  addBucketAssignment(params, ADMIN_PERF_BUCKETS_ASSIGNMENT_URL)
)

PerformanceReviewAdminActions.addPendingBucketAssignment.listenAndPromise(
  (params) => {
    const joinOptionalParam = createJoinOptionalParam(params.queryParams)
    return perfApiPut(
      "/dashboard/saved_views/:saved_view_id/performance_reviews/:performance_review_id/pending/performance_bucket_in_cycle",
      {
        params: {
          saved_view_id: params.savedViewId,
          performance_review_id: params.performanceReviewId,
        },
        body: {
          performance_bucket_in_cycle_id: params.performanceBucketInCycleId,
          performance_cycle_ids: joinOptionalParam("cycleIds"),
          manager_ids: joinOptionalParam("managerIds"),
          group_type: collapseGroupTypes(params.queryParams.groupTypes),
          job_title_ids: joinOptionalParam("jobTitleIds"),
          demographic_value_ids: joinOptionalParam("demographicValueIds"),
          department_ids: joinOptionalParam("departmentIds"),
          user_ids: joinOptionalParam("userIds"),
          user_status: joinOptionalParam("userStatus"),
          performance_bucket_in_cycle_ids: joinOptionalParam("bucketIds"),
          status: Array.isArray(params.queryParams.status)
            ? joinOptionalParam("status")
            : params.queryParams.status,
          levels: joinOptionalParam("levelIds"),
        },
      }
    )
  }
)

PerformanceReviewAdminActions.updateAnswer.listenAndPromise((params) =>
  updateAnswer(params, ADMIN_PERF_ANSWERS_URL)
)

PerformanceReviewAdminActions.exportCycle.listenAndPromise(
  ({ cycleId, searchParams }) =>
    addLoadingState(
      true,
      addToasts(
        { defaultError: strings.cycle.exportFailed },
        extractResponseKey(
          "performance_export_request",
          agent.post(ADMIN_PERF_EXPORT_REQUESTS_URL).send({
            performance_export_request: {
              performance_cycle_id: cycleId,
              options: searchParams,
            },
          })
        ).then(showCsvConfirmation)
      )
    )
)

PerformanceReviewAdminActions.getIncompleteManagers.listenAndPromise(
  (cycleId) =>
    extractResponseKey(
      "performance_managers",
      // FIXME: rather than trying to load all the incomplete managers, we should just get a
      // summary from SF
      agent.get(ADMIN_PERF_MANAGERS_URL).query({
        not_completed: true,
        performance_cycle_id: cycleId,
        per_page: 1e9,
      })
    )
)

PerformanceReviewAdminActions.shareReview.listenAndPromise(
  ({ id, performance_cycle_id: cycleId }) =>
    addLoadingState(
      true,
      addToasts(
        { defaultError: strings.performanceReviews.toasts.errorSharing },
        extractResponseKey(
          "performance_review",
          PerformanceReview.of,
          agent
            .put(
              `${ADMIN_PERF_CYCLES_URL}/${cycleId}/performance_reviews/${id}`
            )
            .send({ performance_review: { share: true } })
        )
      )
    ).then((review) => {
      UIActions.success(strings.performanceReviews.toasts.shared, {
        name: review.user.full_name,
      })
      return review
    })
)

PerformanceReviewAdminActions.syncManager.listenAndPromise(
  ({ reviewId, cycleId }) =>
    addLoadingState(
      true,
      addToasts(
        {
          success: strings.performanceReviews.toasts.managerSynced,
          defaultError: strings.performanceReviews.toasts.errorSyncingManager,
        },
        agent.post(`/dashboard/performance_reviews/${reviewId}/sync_manager`)
      )
    )
)

PerformanceReviewAdminActions.shareAllReviews.listenAndPromise(
  ({
    cycleId,
    filters: {
      departmentIds = null,
      groupTypes = null,
      jobTitleIds = null,
      managerIds = null,
      userIds = null,
      bucketId = null,
    },
  }) =>
    addToasts(
      {
        defaultError: strings.performanceReviews.toasts.errorSharingAll,
        success: strings.performanceReviews.toasts.sharedAll,
      },
      addLoadingState(
        true,
        agent
          .post(`${ADMIN_PERF_CYCLES_URL}/${cycleId}/performance_reviews/share`)
          .query({
            department_ids: departmentIds ? departmentIds.join(",") : undefined,
            manager_ids: managerIds ? managerIds.join(",") : undefined,
            job_title_ids: jobTitleIds ? jobTitleIds.join(",") : undefined,
            group_types: collapseGroupTypes(groupTypes),
            [typeof bucketId === "string"
              ? "status"
              : "performance_bucket_in_cycle_id"]: bucketId,
            user_ids: userIds ? userIds.join(",") : undefined,
          })
      )
    )
)

/**
 * @param {UserFilterType} userFilterType
 * @param {Array<number>=} cycleIds - performance cycle IDs
 * @param {{departmentIds, managerIds, groupTypes, jobTitleIds}} currentFilters - current filter
 *    selections
 * @param {string=} query - textual query
 * @param {Array<number>=} ids - specific ids to return
 * @param {number=} page - Page of results to return
 */
PerformanceReviewAdminActions.loadFilterOptions.listenAndPromise(
  ({
    userFilterType,
    filter,
    cycleIds,
    ecId,
    currentFilters,
    query,
    ids,
    page = 1,
    distinct_by = "title,parent_job_title_id",
  }) =>
    loadUserFilterOptions({
      urls: ADMIN_PERF_REVIEW_FILTER_URLS,
      userFilterType,
      filter,
      cycleIds,
      ecId,
      cycleParamName: "performance_cycle_ids",
      currentFilters,
      query,
      ids,
      page,
      distinct_by,
    })
)

PerformanceReviewAdminActions.loadFilterOptionsForSavedView.listenAndPromise(
  ({
    savedViewId,
    userFilterType,
    filter,
    cycleIds,
    currentFilters,
    query,
    ids,
    page = 1,
    distinct_by = "title,parent_job_title_id",
  }) => {
    return loadUserFilterOptions({
      urls: {
        MANAGERS: buildUrl(
          ADMIN_PERF_REVIEW_FILTER_URLS_FOR_SNAPSHOT.MANAGERS,
          { savedViewId }
        ),
        JOB_TITLES: buildUrl(
          ADMIN_PERF_REVIEW_FILTER_URLS_FOR_SNAPSHOT.JOB_TITLES,
          { savedViewId }
        ),
        LEVELS: buildUrl(ADMIN_PERF_REVIEW_FILTER_URLS_FOR_SNAPSHOT.LEVELS, {
          savedViewId,
        }),
        DEPARTMENTS: buildUrl(
          ADMIN_PERF_REVIEW_FILTER_URLS_FOR_SNAPSHOT.DEPARTMENTS,
          { savedViewId }
        ),
        USERS: buildUrl(ADMIN_PERF_REVIEW_FILTER_URLS_FOR_SNAPSHOT.USERS, {
          savedViewId,
        }),
        DEMOGRAPHIC_VALUES: buildUrl(
          ADMIN_PERF_REVIEW_FILTER_URLS_FOR_SNAPSHOT.DEMOGRAPHIC_VALUES,
          { savedViewId }
        ),
        // More does not take a savedView or have a seperate endpoint
        MORE: ADMIN_PERF_REVIEW_FILTER_URLS_FOR_SNAPSHOT.MORE,
      },
      savedViewId,
      userFilterType,
      filter,
      cycleIds,
      cycleParamName: "performance_cycle_ids",
      currentFilters,
      query,
      ids,
      page,
      distinct_by,
    })
  }
)

PerformanceReviewAdminActions.addCollaborators.listenAndPromise(
  ({ collaborators, performanceReviewId, notes }) =>
    addLoadingState(
      true,
      agent.post(`${ADMIN_PERF_ADD_COLLABORATOR}`).send({
        access_permission: {
          user_ids: collaborators ? collaborators.join(",") : undefined,
          source_id: performanceReviewId,
          source_type: "performance_review",
          permission: "all",
          notes,
        },
      })
    )
)

PerformanceReviewAdminActions.removeCollaborator.listenAndPromise(
  ({ permissionId }) =>
    addLoadingState(
      true,
      agent.del(`${ADMIN_PERF_ADD_COLLABORATOR}/${permissionId}`)
    )
)

PerformanceReviewAdminActions.getPerformanceCyclesForCalibrationView.listenAndPromise(
  ({ calibrationViewId }) =>
    perfApiGet("/dashboard/saved_views/:saved_view_id/performance_cycles", {
      params: { saved_view_id: calibrationViewId },
    })
)

PerformanceReviewAdminActions.saveCalibrationViewPerformanceReviewNote.listenAndPromise(
  ({ calibrationViewId, performanceReviewId, note }) =>
    perfApiPut(
      "/dashboard/saved_views/:saved_view_id/performance_reviews/:performance_review_id/pending/note",
      {
        params: {
          saved_view_id: calibrationViewId,
          performance_review_id: performanceReviewId,
        },
        body: {
          note: note,
        },
      }
    )
)

export default PerformanceReviewAdminActions
