import * as React from "react"
import { createContext, useReducer } from "react"
import { getPaginationState } from "@Redux/reducers/shared/pagination"
import { TeamSummariesAction } from "@Types/reduxActions/teamSummaries"
import {
  TeamSummariesState,
  TeamSummary,
} from "@Types/reduxState/teamSummaries"

const INITIAL_STATE: TeamSummariesState = {
  teamSummariesById: {},
  myTeams: {
    pages: {},
    totalCount: 0,
    totalPages: 0,
  },
  allTeams: {
    pages: {},
    totalCount: 0,
    totalPages: 0,
  },
  searchResults: {
    pages: {},
    totalCount: 0,
    totalPages: 0,
  },
}

export const teamsReducer = (
  state: TeamSummariesState,
  action: TeamSummariesAction
): TeamSummariesState => {
  switch (action.type) {
    case "FETCH_MY_TEAM_SUMMARIES": {
      const teamSummariesById: Record<string, TeamSummary> = {}
      const myTeams: string[] = []
      const { teamSummaries, pagination } = action.payload
      teamSummaries.map((teamSummary) => {
        teamSummariesById[teamSummary.id] = teamSummary
        myTeams.push(teamSummary.id)
      })
      return {
        ...state,
        teamSummariesById: {
          ...state.teamSummariesById,
          ...teamSummariesById,
        },
        myTeams: getPaginationState(state.myTeams, pagination, myTeams),
      }
    }

    case "UPDATE_TEAM_SUMMARIES": {
      // NOTE: Used for updating the Team Goal Summaries UI
      // does not address pagination state issues for myTeams
      // or allTeams key
      const { teamId } = action.payload
      const teamSummariesById: Record<string, TeamSummary> = {}
      // find current state, filter out the removed
      Object.keys(state.teamSummariesById).map((key) => {
        const teamSummary = state.teamSummariesById[key]
        if (key === teamId) {
          teamSummary.goalsCount--
        }
        teamSummariesById[key] = teamSummary
      })

      return {
        ...state,
        teamSummariesById: teamSummariesById,
      }
    }

    case "FETCH_ALL_TEAM_SUMMARIES": {
      const teamSummariesById: Record<string, TeamSummary> = {}
      const allTeams: string[] = []
      const { teamSummaries, pagination } = action.payload
      teamSummaries.map((teamSummary) => {
        teamSummariesById[teamSummary.id] = teamSummary
        allTeams.push(teamSummary.id)
      })
      return {
        ...state,
        teamSummariesById: {
          ...state.teamSummariesById,
          ...teamSummariesById,
        },
        allTeams: getPaginationState(state.allTeams, pagination, allTeams),
      }
    }

    case "FETCH_TEAM_SUMMARIES_SEARCH": {
      const teamSummariesById: Record<string, TeamSummary> = {}
      const searchResults: string[] = []
      const { teamSummaries, pagination } = action.payload
      teamSummaries.map((teamSummary) => {
        teamSummariesById[teamSummary.id] = teamSummary
        searchResults.push(teamSummary.id)
      })
      return {
        ...state,
        teamSummariesById: {
          ...state.teamSummariesById,
          ...teamSummariesById,
        },
        searchResults: getPaginationState(
          state.searchResults,
          pagination,
          searchResults
        ),
      }
    }

    case "FETCH_TEAM_SUMMARY": {
      const { teamSummary } = action.payload
      return {
        ...state,
        teamSummariesById: {
          ...state.teamSummariesById,
          [teamSummary.id]: teamSummary,
        },
      }
    }

    case "UPDATE_TEAM_OVERALL_PROGRESS": {
      const { teamId, progress } = action.payload
      return {
        ...state,
        teamSummariesById: {
          ...state.teamSummariesById,
          [teamId]: {
            ...state.teamSummariesById[teamId],
            overallProgress: progress,
          },
        },
      }
    }
  }
  return { ...state }
}

export const TeamSummariesContext: React.Context<{
  state: TeamSummariesState
  dispatch: React.Dispatch<TeamSummariesAction>
}> = createContext({
  state: INITIAL_STATE,
  dispatch: (action: TeamSummariesAction) => {},
})

export const TeamSummariesProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const [state, dispatch] = useReducer(teamsReducer, INITIAL_STATE)

  return (
    <TeamSummariesContext.Provider value={{ state, dispatch }}>
      {children}
    </TeamSummariesContext.Provider>
  )
}
