import Settings from "../../settings"
import SupremeAgent from "../../utils/supremeagent"

class SteadyfootAgent {
  constructor() {
    return new SupremeAgent({
      base: Settings.STEADYFOOT_URL,
    })
  }
}

// Ideally, this would be
//
//    static defaultInstance = new SteadyfootAgent();
//
// inside the class. But currently, the Babel plugin for compiling decorators changes class
// properties such that you can't reference the class being defined in a static class property.
// See https://github.com/loganfsmyth/babel-plugin-transform-decorators-legacy/issues/17.
//
SteadyfootAgent.defaultInstance = new SteadyfootAgent()

export default SteadyfootAgent
