import React, { createContext, useContext, useReducer } from "react"
import { DraftsState } from "../types/reduxState/drafts"
import { DraftsAction } from "../types/reduxActions/drafts"

export const draftsReducer = (
  state: DraftsState,
  action: DraftsAction
): DraftsState => {
  switch (action.type) {
    case "FETCH_DRAFT": {
      const { sourceType, sourceId, recipientId, draft } = action.payload
      return {
        ...state,
        draftBySourceType: {
          ...state.draftBySourceType,
          [sourceType]: {
            ...state.draftBySourceType[sourceType],
            [sourceId]: {
              ...state.draftBySourceType[sourceType][sourceId],
              [recipientId || 0]: draft,
            },
          },
        },
      }
    }
  }

  return state
}

export const DraftsContext = createContext<{
  state: DraftsState
  dispatch: React.Dispatch<DraftsAction>
}>({
  state: {
    draftBySourceType: {
      goal: {},
    },
  },
  dispatch: (state) => state,
})

export const DraftsProvider = ({ children }: { children: React.ReactNode }) => {
  const context = useContext(DraftsContext)
  const [state, dispatch] = useReducer(draftsReducer, context.state)

  return (
    <DraftsContext.Provider value={{ state, dispatch }}>
      {children}
    </DraftsContext.Provider>
  )
}
