import _ from "lodash"
import Reflux from "./ActionsInitializer"
import { extractResponseKey } from "./lib/apiActionHelpers"
import OntologyItem from "../models/OntologyItem"
import UIActions from "./UIActions"
import SteadyfootAgent from "./lib/SteadyfootAgent"
import endpoints from "../constants/endpointsDeprecated"
import strings from "../locale/strings"

const { ADMIN_SKILLS_URLS, GENERAL_URLS, SKILLS_URLS } = endpoints
const { ONTOLOGY_URL } = GENERAL_URLS
const { ADMIN_SKILLS_URL } = ADMIN_SKILLS_URLS
const { SKILLS_DEFAULT_URL } = SKILLS_URLS
const agent = SteadyfootAgent.defaultInstance
const ASYNC_SETTINGS = {
  children: ["completed", "failed"],
}

const OntologyActions = Reflux.createActions({
  loadSkills: ASYNC_SETTINGS,
  loadDefaultSkills: ASYNC_SETTINGS,

  loadStagedSkills: ASYNC_SETTINGS,
  loadStagedSkill: ASYNC_SETTINGS,

  createSkill: ASYNC_SETTINGS,
  updateSkill: ASYNC_SETTINGS,

  createCategory: ASYNC_SETTINGS,
  updateCategory: ASYNC_SETTINGS,
  publishCategory: ASYNC_SETTINGS,
  archiveCategory: ASYNC_SETTINGS,
})

/* Feedback Actions
===============================*/
OntologyActions.loadSkills.listenAndPromise(() =>
  extractResponseKey("skills", OntologyItem.of, agent.get(ONTOLOGY_URL))
)

OntologyActions.loadDefaultSkills.listenAndPromise(() =>
  extractResponseKey("skills", OntologyItem.of, agent.get(SKILLS_DEFAULT_URL))
)

function dashboardApiPromise(method, subPath, skill, loadingState = false) {
  UIActions.setLoadingState(loadingState)

  return new Promise((resolve, reject) => {
    agent[method.toLowerCase()](`${ADMIN_SKILLS_URL}${subPath}`)
      .send(skill ? { skill } : "")
      .end((err, res) => {
        UIActions.setLoadingState(false)

        const [skill, skills] = _.map(
          ["body.skill", "body.skills"],
          _.propertyOf(res)
        )

        if (err || !(skill || skills)) {
          UIActions.error(
            _.get(res, "body.error") || strings.toast.genericErrorMessage
          )

          reject([res && res.body, err])
        } else {
          resolve(skill ? OntologyItem.of(skill) : skills.map(OntologyItem.of))
        }
      })
  })
}

function dashboardApiAction(
  actionName,
  method,
  subPath,
  skill,
  loadingState = false
) {
  dashboardApiPromise(method, subPath, skill, loadingState).then(
    OntologyActions[actionName].completed,
    ([body, err]) => OntologyActions[actionName].failed(body, err)
  )
}

OntologyActions.loadStagedSkill.preEmit = ({ id }) =>
  dashboardApiAction("loadStagedSkill", "GET", `/${id}`, /* Skill */ null)

OntologyActions.loadStagedSkills.preEmit = () =>
  dashboardApiAction(
    "loadStagedSkills",
    "GET",
    "",
    /* Skill */ null,
    /* Param: loadingState */ true
  )

OntologyActions.createSkill.preEmit = (skill) =>
  dashboardApiAction("createSkill", "POST", "", skill)

OntologyActions.updateSkill.preEmit = (skill, newParams) =>
  dashboardApiAction("updateSkill", "PUT", `/${skill.id}`, newParams)

OntologyActions.createCategory.preEmit = (cat) =>
  dashboardApiAction("createCategory", "POST", "", cat)

OntologyActions.updateCategory.preEmit = (cat, newParams) =>
  dashboardApiAction("updateCategory", "PUT", `/${cat.id}`, newParams)

OntologyActions.publishCategory.preEmit = function (cat) {
  const isNew = !cat.name

  dashboardApiPromise("POST", `/${cat.id}/publish`).then(
    (resCategory) =>
      OntologyActions.publishCategory.completed(resCategory, isNew),
    ([body, err]) => OntologyActions.publishCategory.failed(body, err)
  )
}

OntologyActions.archiveCategory.preEmit = (cat) =>
  dashboardApiAction("archiveCategory", "POST", `/${cat.id}/deactivate`)

export default OntologyActions
