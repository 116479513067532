import React from "react"
import { LoadingPlaceholder } from "@kaizen/draft-loading-placeholder"
import { CheckboxField } from "@kaizen/draft-form"
import { Divider } from "@kaizen/draft-divider"
import styles from "./FilterSelectDropdown.scss"

const NUM_OF_LOADING_COLUMNS = 10

const loadingRows = [...Array(NUM_OF_LOADING_COLUMNS)].map((i) => (
  <div className={styles.checkboxRow}>
    <CheckboxField
      id={`checkbox-loading-${i}`}
      labelText={
        <div className={styles.loadingPlaceholder}>
          <LoadingPlaceholder width={140} />
        </div>
      }
    />
  </div>
))

export default function FilterSelectDropdownLoading() {
  return (
    <>
      <div className={styles.selectAction}>
        <CheckboxField
          id="checkbox-all-placeholder"
          labelText={
            <div className={styles.loadingPlaceholder}>
              <LoadingPlaceholder width={140} />
            </div>
          }
        />
      </div>
      <Divider variant="content" />
      <div className={styles.content}>
        <div className={styles.loading}>{loadingRows}</div>
      </div>
    </>
  )
}
