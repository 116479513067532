import { CurrentUser } from "@Types/User"
import React, { useContext, FunctionComponent } from "react"
import { CurrentUserContext } from "../../reduxReducers/currentUser"

export const useCurrentUser = () => {
  const currentUser = useContext(CurrentUserContext)
  if (currentUser) {
    return currentUser
  } else {
    throw new Error(
      "Current user should not be null - OR - you should use graphql for loading the 1-1 current user"
    )
  }
}

export function withCurrentUser<P>(
  WrappedComponent: React.ComponentType<
    P & { currentUser: CurrentUser | null | undefined }
  >
): React.ComponentType<P> {
  const WithCurrentUser: FunctionComponent<P> = (props: P) => {
    const user = useCurrentUser()
    return <WrappedComponent currentUser={user} {...props} />
  }
  return WithCurrentUser
}

export default useCurrentUser
