import React from "react"
import { toggleMobileNavigation } from "@cultureamp/unified-navigation-ui"
import { TitleBlockZen } from "@kaizen/draft-title-block-zen"
import { useTheme } from "@kaizen/design-tokens"
import "./legacy-hide-navigation-trigger.scss"

/* This component is designed to achieve 2 things,
 * - Removes the need for PageLayout to inject handleHambergerClick using CloneElement
 * - Imports a legacy stylesheet to hide the older navigation bars hamburger menu
 * This legacy requirement will go away after we roll-out the unified navigation bar.
 * After this is used everywhere we will be able to export a version of the TitleBlock
 * with the click handler built in, or remove the requirement completely.
 */

export type SurveyStatus = {
  text: string
  status: "draft" | "live" | "default"
}

type TitleBlockProps = Omit<
  React.ComponentProps<typeof TitleBlockZen>,
  "handleHamburgerClick"
>
export const TitleBlock: React.FunctionComponent<TitleBlockProps> = (props) => {
  const { themeKey } = useTheme()
  return (
    <TitleBlockZen
      {...props}
      variant={themeKey === "heart" ? undefined : props.variant}
      handleHamburgerClick={() => toggleMobileNavigation("open")}
    />
  )
}
