import React from "react"

import cx from "classnames"

import { mainContentId } from "@Constants/elementIds"
import { Skirt } from "@kaizen/draft-page-layout"
import { Dossier } from "@Components/layout/Dossier/Dossier"
import ErrorBoundary from "@Containers/ErrorBoundary/ErrorBoundary"
import styles from "./SkirtPageLayout.scss"

type DossierSettings = {
  isOpen: boolean
  content: React.ReactElement
  onClose?: () => void
  size?: "small" | "default"
}

type SkirtPageLayoutProps = {
  children?: React.ReactNode
  dossierSettings: DossierSettings
  headingContent?: React.ReactElement
  legacyClassnames?: string
}

export const SkirtPageLayout: React.FunctionComponent<SkirtPageLayoutProps> = ({
  children,
  headingContent,
  dossierSettings,
  legacyClassnames,
}) => {
  return (
    <main
      className={cx([styles.root, legacyClassnames], {
        [styles.dossierOpen]: dossierSettings?.isOpen,
        [styles.dossierIsSmall]: dossierSettings?.size === "small",
      })}
      id={mainContentId}
    >
      {headingContent && (
        <div className={cx(styles.headingWrapper)}>{headingContent}</div>
      )}
      <ErrorBoundary>
        <Skirt className={cx([styles.skirtRoot])}>
          {!!children && (
            <div className={cx(styles.container, styles.skirtContent)}>
              {children}
            </div>
          )}
          <Dossier
            isOpen={dossierSettings.isOpen}
            onClose={dossierSettings.onClose}
            size={dossierSettings.size}
          >
            {dossierSettings.content}
          </Dossier>
        </Skirt>
      </ErrorBoundary>
    </main>
  )
}
