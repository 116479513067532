import { Icon } from "@kaizen/component-library"
import cx from "classnames"
import spinner from "@kaizen/component-library/icons/spinner.icon.svg"
import * as React from "react"
import styles from "./Loading.scss"

type Props = {
  // compact - A small 20 x 20 loading spinner
  // paddedHeight - Sets the wrapper height to 200px.
  //                This is a quick and dirty way to ensure that the loader
  //                isn't wedged up against its surrounding content.
  mode?: "compact" | "paddedHeight"
}

const Loading = ({ mode = "compact" }: Props) => (
  <div
    className={cx(
      styles.wrapper,
      mode === "paddedHeight" && styles.paddedHeight
    )}
  >
    <Icon icon={spinner} title="Loading" />
  </div>
)

export default Loading
