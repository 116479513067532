import React, { useMemo } from "react"
import cx from "classnames"
import { TreeGoalCard } from "@Goals/components"
import { GoalForTree, GoalTreeNode } from "@Goals/types"
import { useGoalForTree } from "@Goals/hooks/useGoalForTree"
import { useGoalTreeState } from "@Goals/context/goalTreeState"
import moment from "moment"
import { NavigationTreeNodePlaceholder } from "./NavigationTreeNodePlaceholder"

import styles from "./NavigationTreeNode.scss"

export type NavigationTreeNodeProps = {
  node: GoalTreeNode
  isFirstLevel?: boolean
  onClick: (id: number) => void
  onCollapse: (id: string) => void
  onExpand: (id: string) => void
}

interface ChildNode {
  id: string
  data: GoalForTree
}

export const NavigationTreeNode = ({
  node,
  isFirstLevel,
  onClick,
  onCollapse,
  onExpand,
}: NavigationTreeNodeProps) => {
  const { id, data } = node
  const { expandedNodes } = useGoalTreeState()
  const isExpanded =
    expandedNodes && expandedNodes.some((nodeId: string) => nodeId === id)
  const { data: childNodeData, isLoading } = useGoalForTree(
    isExpanded ? data.uuid : ""
  )

  const childNodes: ChildNode[] = useMemo(() => {
    if (!childNodeData) {
      return []
    }

    const identifyNode = (nodeData: GoalForTree) => ({
      // Use combination of child goal id and parent goal id so that we can differentiate
      // if there's duplicate goal (as child node) align to different parent goal
      id: `${nodeData.uuid}-${id}`,
      data: { ...nodeData },
    })

    const sortNodes = (a: ChildNode, b: ChildNode) => {
      // if goals are of the type we sort by due date
      if (a.data.goalType === b.data.goalType) {
        const aDueAt = moment(a.data.dueAt)
        const bDueAt = moment(b.data.dueAt)

        return aDueAt.diff(bDueAt)

        // if goals are of different types, we sort according to order below
      } else {
        const order = ["department", "team", "individual"]

        return order.indexOf(a.data.goalType) - order.indexOf(b.data.goalType)
      }
    }

    return childNodeData.map(identifyNode).sort(sortNodes)
  }, [childNodeData, id])

  const handleOnToggleExpanded = () => {
    if (!isExpanded) {
      onExpand && onExpand(id)
      return
    }

    onCollapse && onCollapse(id)
  }

  return (
    <div
      className={cx(
        isFirstLevel ? styles.firstLevelNodeContainer : styles.nodeContainer
      )}
    >
      <div className={styles.node}>
        <TreeGoalCard
          goal={data}
          isExpanded={isExpanded}
          onClick={onClick}
          onToggleExpanded={handleOnToggleExpanded}
        />
      </div>
      {isExpanded && (
        <div className={cx(styles.childNodes)}>
          {isLoading ? (
            <NavigationTreeNodePlaceholder />
          ) : (
            childNodes &&
            childNodes.map((childNode) => (
              <NavigationTreeNode
                key={childNode.id}
                node={childNode}
                onClick={onClick}
                onCollapse={onCollapse}
                onExpand={onExpand}
              />
            ))
          )}
        </div>
      )}
    </div>
  )
}
