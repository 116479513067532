import * as React from "react"
import { Heading } from "@kaizen/typography"
import { CheckboxGroup, CheckboxField } from "@kaizen/draft-form"
import { DropdownOption } from "./FilterSelectDropdown"
import styles from "./FilterSelectDropdown.scss"
import { sortByName, sortByPosition } from "./utils"

export type GroupDropdownOption<T> = {
  label: string
  options: DropdownOption<T>[]
  position: number
}

type Props<T> = {
  savedFilterOptions: Array<DropdownOption<T>>
  onCheckboxChange: (option: DropdownOption<T>) => void
  selectedFilterOption: Array<DropdownOption<T>>
  groupOptions: Array<GroupDropdownOption<T>>
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const GroupSelectOptions = <T extends any>({
  groupOptions,
  savedFilterOptions,
  onCheckboxChange,
  selectedFilterOption,
}: Props<T>) => {
  const checkedStatus = (option: DropdownOption<T>) => {
    return selectedFilterOption.filter(
      (filter) => filter.value === option.value
    ).length > 0
      ? "on"
      : "off"
  }

  const renderCheckboxOptions = (options: Array<DropdownOption<T>>) => {
    // We need to determine first which options have been selected.
    // These selected options will be rendered first then the unselected options
    const savedFilterOptionsSet = new Set(
      savedFilterOptions.map(({ value }) => value)
    )
    const savedOptions = options.filter((option) =>
      savedFilterOptionsSet.has(option.value)
    )
    const unsavedOptions = options.filter(
      (option) => !savedFilterOptionsSet.has(option.value)
    )

    return (
      <CheckboxGroup labelText="">
        {savedOptions.sort(sortByName).map((option) => (
          <div
            className={styles.checkboxRow}
            key={`checkbox-row-${option.value}`}
          >
            <CheckboxField
              onCheck={() => {
                onCheckboxChange(option)
              }}
              id={`checkbox-${option.value}`}
              checkedStatus={checkedStatus(option)}
              labelText={option.label}
            />
          </div>
        ))}
        {unsavedOptions.sort(sortByName).map((option) => (
          <div
            className={styles.checkboxRow}
            key={`checkbox-row-${option.value}`}
          >
            <CheckboxField
              onCheck={() => {
                onCheckboxChange(option)
              }}
              id={`checkbox-${option.value}`}
              checkedStatus={checkedStatus(option)}
              labelText={option.label}
            />
          </div>
        ))}
      </CheckboxGroup>
    )
  }

  return (
    <>
      {groupOptions.length > 0
        ? groupOptions.sort(sortByPosition).map((groupOption) => {
            return (
              <React.Fragment key={groupOption.position}>
                <Heading
                  color="dark-reduced-opacity"
                  variant="heading-6"
                  tag="div"
                >
                  {groupOption.label}
                </Heading>
                {renderCheckboxOptions(groupOption.options)}
              </React.Fragment>
            )
          })
        : null}
    </>
  )
}
