import { CurrentUser } from "@Types/User"
import strings from "@Locale/strings"
import {
  getManagedDepartments,
  hasConfigOption,
  isAdmin,
  isHrbp,
} from "@Domain/user/user"
import { ConfigurationOptions } from "@Constants/configurationOptions"

const goalText = strings.goalsPage.goalType

export type Goal = {
  type: string
  path: string
  tab: {
    label: string
    visible: () => true
  }
  create: {
    title: string
    label: string
    path: string
    disabled: () => true
  }
  edit: {
    label: string
  }
}

export const Goals = {
  personal: {
    type: "personal",
    path: "/new_goals/personal",
    section: goalText.individual.sectionName,
    tab: {
      label: goalText.individual.tabLabel,
      visible: () => true,
    },
    create: {
      title: strings.newIndividualGoal.title,
      label: goalText.individual.createGoalButton,
      path: "/new_goals/personal/new",
      disabled: () => false,
    },
    edit: {
      title: strings.newIndividualGoal.edit,
    },
  },
  directReport: {
    type: "directReport",
    path: "/new_goals/directReport",
    section: goalText.directReports.sectionName,
    tab: {
      label: goalText.directReports.tabLabel,
      visible: () => true,
    },
  },
  team: {
    type: "team",
    path: "/new_goals/team",
    section: strings.goalsPage.goalType.team.sectionName,
    tab: {
      label: goalText.team.tabLabel,
      visible: (user: CurrentUser) =>
        hasConfigOption(user, ConfigurationOptions.teamGoals),
    },
    create: {
      title: strings.newTeamGoal.title,
      label: goalText.team.createGoalButton,
      path: "/new_goals/team/new",
      disabled: (user: CurrentUser, hasTeam: boolean) =>
        !(hasConfigOption(user, ConfigurationOptions.teamGoals) && hasTeam),
    },
    edit: {
      title: strings.newTeamGoal.edit,
    },
  },
  department: {
    type: "department",
    path: "/new_goals/department",
    section: goalText.department.sectionName,
    tab: {
      label: goalText.department.tabLabel,
      visible: () => true,
    },
    create: {
      title: strings.newDepartmentGoal.title,
      label: goalText.department.createGoalButton,
      path: "/new_goals/department/new",
      disabled: (user: CurrentUser) =>
        !(
          isAdmin(user) ||
          isHrbp(user) ||
          getManagedDepartments(user).length > 0
        ),
    },
    edit: {
      title: strings.newDepartmentGoal.edit,
    },
  },
  company: {
    type: "company",
    path: "/new_goals/company",
    section: goalText.company.sectionName,
    tab: {
      label: goalText.company.tabLabel,
      visible: () => true,
    },
    create: {
      title: strings.newCompanyGoal.title,
      label: goalText.company.createGoalButton,
      path: "/new_goals/company/new",
      disabled: (user: CurrentUser) => !isAdmin(user),
    },
    edit: {
      title: strings.newCompanyGoal.edit,
    },
  },
}
