import React from "react"
// eslint-disable-next-line no-restricted-imports
import { Tooltip as KaizenTooltip } from "@kaizen/draft-tooltip"
import { TooltipProps } from "@kaizen/draft-tooltip/KaizenDraft/Tooltip/Tooltip"
import { tooltipPortalId } from "../../constants/portals"

type Props = Omit<TooltipProps, "portalSelector"> & {
  /**
   * Specify where the tooltip should be rendered.
   * "inline" will render the component exactly how you have placed it.
   * "root" will render this at the root document/body level.
   * Set this to "root", if you experience issues such as the tooltip getting
   * cropped.
   */
  renderLocation?: "inline" | "root"
}

/**
 * Wrapper around the kaizen tooltip. So far, it does nothing but add the renderLocation
 * prop, in favour of `portalSelector`.
 */
export const Tooltip = ({ renderLocation = "inline", ...rest }: Props) => {
  const portalSelector =
    renderLocation === "root" ? `#${tooltipPortalId}` : undefined

  return <KaizenTooltip portalSelector={portalSelector} {...rest} />
}
