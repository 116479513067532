import { compile, Key, parse } from "path-to-regexp"
import * as qs from "query-string"
import { parseUrl } from "query-string"
import { QueryParamConfigMap } from "serialize-query-params/lib/types"
import _ from "lodash"
import { QueryParamConfig, withDefault } from "use-query-params"
import { QueryStringParams } from "@OneOnOnes/types/url"

export const stringifyQueryParams = (params: QueryStringParams) => {
  if (!params) return ""

  // Remove any null or undefined param values
  const filteredParams = Object.fromEntries(
    Object.entries(params).filter(([key, val]) => val != null)
  )
  return qs.stringify(filteredParams, { sort: (a, b) => (a < b ? -1 : 1) })
}

const getParamNames = (pattern: string): string[] => {
  return parse(pattern)
    .filter((m) => typeof m !== "string")
    .map((m) => `${(m as Key).name}`)
}

/**
 * Builds a url with express-style pattern matching. eg. /user/:name
 * @param url - the url
 * @param params - The params to be added. Any param that cannot be added
 * will be added as a query string value instead. Array values are supported.
 */
export const buildUrl = (url: string, params: QueryStringParams = {}) => {
  const formattedUrl = compile(url)(params)

  if (!params || !Object.keys(params).length) {
    return formattedUrl
  }

  const paramsInUrl = getParamNames(url)

  let queryStringParams = Object.fromEntries(
    Object.entries(params).filter(([key]) => !paramsInUrl.includes(key))
  )

  queryStringParams = {
    ...parseUrl(formattedUrl).query,
    ...queryStringParams,
  }

  const queryString = stringifyQueryParams(queryStringParams)

  return !queryString.length
    ? formattedUrl
    : `${parseUrl(formattedUrl).url}?${queryString}`
}

/**
 * There is no direct update option for withDefault if already defaults applied in `use-query-params`
 * Using this as the work around to get filter configs with latest default values
 * Issue: https://github.com/pbeshai/use-query-params/issues/191
 */
export const getQueryConfigWithDefaultValues = (
  configs: QueryParamConfigMap,
  defaultValues: {}
) => {
  return _.mapValues(configs, (paramConfig, paramName) => {
    // @ts-expect-error - This should be typed
    const defaultValue = defaultValues[paramName]
    return defaultValue
      ? withClearableDefaultValue(paramConfig, defaultValue)
      : { ...paramConfig }
  })
}

// Wrapper function for *withDefault*, allows us to clears the default value in query params
// Use when there is a need to clear the default filters
export function withClearableDefaultValue<D, DefaultType extends D2, D2 = D>(
  param: QueryParamConfig<D, D2>,
  defaultValue: DefaultType
): QueryParamConfig<D, Exclude<D2, undefined> | DefaultType> {
  return withDefault(param, defaultValue, false)
}
