import React from "react"
import { Card } from "@kaizen/draft-card"
import { Box } from "@kaizen/component-library"
import { Paragraph } from "@kaizen/typography"
import { LoadingGraphic, LoadingHeading } from "@kaizen/loading-skeleton"

import styles from "../TreeGoalCard/TreeGoalCard.scss"

export const TreeGoalCardPlaceholder = () => {
  return (
    <div className={styles.container}>
      <Card classNameOverride={styles.card}>
        <div className={styles.bodyContainer}>
          <Box mb={0.25}>
            <LoadingHeading variant="heading-4" width={95} isAnimated />
            <LoadingHeading variant="heading-4" width={95} isAnimated />
          </Box>
          <Paragraph tag="div" variant="small" color="dark-reduced-opacity">
            <LoadingHeading variant="heading-6" width={80} isAnimated />
          </Paragraph>
          <div className={styles.cardFooterContainer}>
            <LoadingGraphic size="small" isAnimated />
          </div>
        </div>
      </Card>
    </div>
  )
}
