import Reflux from "reflux-core"
import Settings from "../settings"
import endpoints from "../constants/endpointsDeprecated"
import SteadyfootAgent from "./lib/SteadyfootAgent"
import SearchAgent from "./lib/SearchAgent"
import { addLoadingState, extractResponseKey } from "./lib/apiActionHelpers"

const { GENERAL_URLS, NOTIFICATIONS_URLS } = endpoints
const { BASE_URL } = GENERAL_URLS
const { NOTIFICATION_URL, PENDING_NOTIFICATION_URL, TO_DO_URL } =
  NOTIFICATIONS_URLS

const agent = SteadyfootAgent.defaultInstance

const resourceName = "notifications"

const searchAgent = new SearchAgent({
  requestAgent: agent,
  resourcePath: `${BASE_URL}/${resourceName}`,
  resourceName,
  pageSize: 25,
})

let pollNotifsTimer = null

const NotificationActions = Reflux.createActions({
  searchNotifications: { asyncResult: true },
  pageNotifications: { asyncResult: true },
  loadNotification: { asyncResult: true },
  markAsSeen: { asyncResult: true },
  markAsRead: { asyncResult: true },
  getUnseenCount: { asyncResult: true },
  loadTasks: { asyncResult: true },
  startPolling: {},
  stopPolling: {},
  localMarkAsSeen: {},
  localClear: {},
})

NotificationActions.searchNotifications.listenAndPromise(
  ({ showLoadingState, categoryFilter, incomplete, complete, query } = {}) =>
    addLoadingState(
      showLoadingState,
      searchAgent.search({
        searchParams: {
          notification_category: categoryFilter || undefined,
          incomplete,
          complete,
          q: query || undefined,
        },
      })
    )
)

NotificationActions.pageNotifications.listenAndPromise(
  ({ page, categoryFilter, incomplete, complete, query } = {}) =>
    searchAgent.search({
      page,
      searchParams: {
        notification_category: categoryFilter || undefined,
        incomplete,
        complete,
        q: query || undefined,
      },
    })
)

NotificationActions.loadNotification.listenAndPromise(({ notificationId }) =>
  addLoadingState(
    true,
    extractResponseKey(
      "notification",
      agent.get(`${NOTIFICATION_URL}/${notificationId}`)
    )
  )
)

NotificationActions.loadTasks.listenAndPromise(({ startDate, endDate }) =>
  extractResponseKey(
    "to_do_tasks",
    agent.get(TO_DO_URL).query({
      start_date: startDate,
      end_date: endDate,
    })
  )
)

NotificationActions.markAsSeen.listenAndPromise((notificationId) =>
  extractResponseKey(
    "notification",
    agent
      .put(`${NOTIFICATION_URL}/${notificationId}`)
      .send({ notification: { seen_at: new Date() } })
  )
)

NotificationActions.markAsRead.listenAndPromise((notificationId) =>
  extractResponseKey(
    "notification",
    agent
      .put(`${NOTIFICATION_URL}/${notificationId}`)
      .send({ notification: { read: true } })
  )
)

NotificationActions.startPolling = function () {
  NotificationActions.getUnseenCount()

  clearInterval(pollNotifsTimer)
  pollNotifsTimer = setInterval(() => {
    NotificationActions.getUnseenCount()
  }, Settings.NOTIFICATION_POLL_INTERVAL)
}

NotificationActions.stopPolling = function () {
  clearInterval(pollNotifsTimer)
}

NotificationActions.getUnseenCount.listenAndPromise(() =>
  extractResponseKey(
    "pending_notifications_count",
    agent.get(PENDING_NOTIFICATION_URL)
  )
)

export default NotificationActions
