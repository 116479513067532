import Reflux from "reflux-core"
import FeedbackActions from "../refluxActions/FeedbackActions"
import ReviewActions from "../refluxActions/ReviewActions"
import observeSearchActions from "./lib/observeSearchActions"

const GivenFeedbackStore = Reflux.createStore({
  init() {
    this.data = {
      reviewsGivenByRevieweeId: {},
      reviewsGivenSearchResults: null,
      reviewsGivenPagination: null,
      waitingForResponse: false,
    }

    this.listenTo(
      FeedbackActions.localClearReviewsGivenByRevieweeId,
      this.onLocalClearReviewsGivenByRevieweeId
    )

    this.listenTo(FeedbackActions.loadReviewsGivenByRevieweeId, () => {
      this.data = { ...this.data, waitingForResponse: true }
      this.trigger(this.data)
    })

    this.listenTo(
      FeedbackActions.loadReviewsGivenByRevieweeId.completed,
      this.onLoadReviewsGivenByRevieweeId
    )

    this.listenTo(ReviewActions.complete.completed, this.onReviewCompleted)

    observeSearchActions(this, {
      getResults: () => this.data.reviewsGivenSearchResults,
      searchAction: FeedbackActions.loadReviewsGiven,
      pageAction: FeedbackActions.pageReviewsGiven,
      onChange: (reviewsGivenSearchResults, { pagination }) => {
        this.data = {
          ...this.data,
          reviewsGivenSearchResults,
          reviewsGivenPagination: pagination,
        }
        this.trigger(this.data)
      },
    })
  },

  getInitialState() {
    return this.data
  },

  appendReviewsToReviewsGivenByRevieweeId({
    currentReviewData,
    reviewsToAppend,
    pageLimit,
  }) {
    const reviews = (currentReviewData && currentReviewData.reviews) || []
    return {
      reviews: [...reviews, ...reviewsToAppend],
      hasMore: reviewsToAppend.length === pageLimit,
    }
  },

  _replaceReviewsGivenByRevieweeId({ revieweeId, reviews, pageLimit }) {
    this.data = {
      ...this.data,
      waitingForResponse: false,
      reviewsGivenByRevieweeId: {
        ...this.data.reviewsGivenByRevieweeId,
        [revieweeId]: this.appendReviewsToReviewsGivenByRevieweeId({
          currentReviewData: this.data.reviewsGivenByRevieweeId[revieweeId],
          reviewsToAppend: reviews,
          pageLimit,
        }),
      },
    }
  },

  onLoadReviewsGivenByRevieweeId({ revieweeId, reviews, pageLimit }) {
    this._replaceReviewsGivenByRevieweeId({ revieweeId, reviews, pageLimit })
    this.trigger(this.data)
  },

  onLocalClearReviewsGivenByRevieweeId({ revieweeId }) {
    this.data = {
      ...this.data,
      reviewsGivenByRevieweeId: {
        ...this.data.reviewsGivenByRevieweeId,
        [revieweeId]: null,
      },
    }
    this.trigger(this.data)
  },

  onReviewCompleted(review) {
    const revieweeId = review.reviewee.id

    this._replaceReviewsGivenByRevieweeId({
      revieweeId,
      reviews: [{ source_obj: { review, type: "review" } }],
    })

    this.trigger(this.data)
  },
})

export default GivenFeedbackStore
