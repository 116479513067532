import React from "react"
import styles from "./ContentPadding.scss"

type Props = {
  children: React.ReactNode
}

/**
 * The css padding that wraps around body content.
 * Note, for larger breakpoints, there is a max-width also set to the main content.
 * This is not included in this component, it is included in PageLayout.
 */
export const ContentPadding = ({ children }: Props) => {
  return <div className={styles.contentPadding}>{children}</div>
}
