/**
 * DEPRECATED: we are no longer using decoders to validate server responses.
 * Please do not use them in new code.
 *
 * See src/api/decoders/README.md for more info.
 */
import { JsonDecoder } from "ts.data.json"
import { Pagination } from "../../../types/Pagination"
import paginationDecoder from "../PaginationDecoder/paginationDecoder"
import { teamSummaryDecoder } from "../TeamSummary/teamSummary"
import { TeamSummary } from "../../../types/reduxState/teamSummaries"

type ResponseType = {
  teams: TeamSummary[]
  meta: {
    pagination: Pagination
  }
}

export const getTeamSummariesResponseDecoder = JsonDecoder.object<ResponseType>(
  {
    teams: JsonDecoder.array(teamSummaryDecoder, "teamSummaries"),
    meta: JsonDecoder.object(
      {
        pagination: paginationDecoder,
      },
      "meta"
    ),
  },
  "getTeamSummariesResponse"
).map(({ teams, meta: { pagination } }) => ({
  teamSummaries: teams,
  pagination,
}))
