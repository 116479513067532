import { Box } from "@kaizen/component-library"
import { Paragraph } from "@kaizen/typography"
import { Button } from "@kaizen/button"
import addIcon from "@kaizen/component-library/icons/add.icon.svg"
import * as React from "react"
import { injectIntl, IntlShape } from "react-intl"
import { useHistory } from "react-router-dom"
import { buildUrl } from "@Utils/url"
import { parse } from "query-string"
import strings from "../../locale/strings"
import styles from "./OutgoingAlignedGoals.scss"
import { Goal } from "../../types/Goals"
import AlignedGoalsSection from "./AlignedGoalsSection"
import Aid from "../../constants/automationId"

interface OutgoingAlignedGoals {
  goal: Goal
  intl: IntlShape
  hasTeamGoalsEnabled: boolean
  readOnly?: boolean
  addAlignedGoalHandler?: () => void
}

const OutgoingAlignedGoals = ({
  goal,
  intl,
  hasTeamGoalsEnabled,
  readOnly,
  addAlignedGoalHandler,
}: OutgoingAlignedGoals) => {
  const { formatMessage } = intl

  const getAlignedGoals = (goalType: string) =>
    goal.outgoingAlignedGoals
      ? goal.outgoingAlignedGoals.filter(
          (alignedGoal) => alignedGoal.goalType === goalType
        )
      : []

  const history = useHistory()
  const { location } = history
  const query = parse(location.search)

  const alignedTeamGoals = getAlignedGoals("team")
  const alignedDepartmentGoals = getAlignedGoals("department")
  const alignedCompanyGoals = getAlignedGoals("company")

  const onGoalClick = (goalId: number) => {
    history.push(
      buildUrl(location.pathname, {
        ...query,
        goalId: goalId,
      })
    )
  }

  return (
    <div data-automation-id={Aid.outgoingGoalAlignments}>
      {!!(alignedTeamGoals.length && hasTeamGoalsEnabled) ||
      !!alignedDepartmentGoals.length ||
      !!alignedCompanyGoals.length ? (
        <>
          <div className={styles.outgoingAlignmentsLabel}>
            <Box mt={1}>
              <Paragraph variant="body">
                <strong>
                  {formatMessage(
                    strings.goalsPage.goalsDossier.goalAlignments.alignedTo
                  )}
                </strong>
              </Paragraph>
            </Box>
          </div>
          <div>
            {!!(alignedTeamGoals.length && hasTeamGoalsEnabled) && (
              <AlignedGoalsSection
                alignedGoals={alignedTeamGoals}
                goalType="team"
                onGoalClick={onGoalClick}
              />
            )}
            {alignedDepartmentGoals.length > 0 && (
              <AlignedGoalsSection
                alignedGoals={alignedDepartmentGoals}
                goalType="department"
                onGoalClick={onGoalClick}
              />
            )}
            {alignedCompanyGoals.length > 0 && (
              <AlignedGoalsSection
                alignedGoals={alignedCompanyGoals}
                goalType="company"
                onGoalClick={onGoalClick}
              />
            )}
          </div>
        </>
      ) : (
        <div className={styles.emptyState}>
          <div className={styles.emptyStateTitle}>
            <Paragraph variant="body">
              {formatMessage(
                strings.goalsPage.goalsDossier.goalAlignments.emptyState
              )}
            </Paragraph>
          </div>
          {!readOnly ? (
            <div>
              <Button
                iconPosition="start"
                icon={addIcon}
                label={formatMessage(
                  strings.goalsPage.goalsDossier.goalAlignments
                    .addGoalAlignmentButtonText
                )}
                primary={false}
                secondary
                onClick={addAlignedGoalHandler}
                reversed={false}
              />
            </div>
          ) : null}
        </div>
      )}
    </div>
  )
}

export default injectIntl(OutgoingAlignedGoals)
