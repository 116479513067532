import Reflux from "reflux-core"
import SavedViewActions from "../refluxActions/SavedViewActions"
import BaseSavedViewStore from "./lib/BaseSavedViewStore"

export default Reflux.createStore({
  ...BaseSavedViewStore,

  init() {
    BaseSavedViewStore.init.call(this, { actions: SavedViewActions })
  },
})
