import {
  GoalType,
  VisibilityOption,
  VisibilityOptionEveryone,
  VisibilityOptionIndividuals,
  VisibilityOptionManager,
  VisibilityOptionOwner,
  VisibilityOptionTeam,
} from "@Goals/types"
import { Goal } from "@Types/Goals"
import { CurrentUser } from "@Types/User"
import { getGoalCycle } from "@Domain/user/user"
import moment from "moment/moment"

export const DEVELOPMENT_MANAGER_ACCESS_PERMISSION = {
  notes: "development_manager",
}

export const isDevelopModuleGoal = (goal: Goal) => {
  return !!goal.access_permissions?.some(
    (permission) =>
      permission.notes === DEVELOPMENT_MANAGER_ACCESS_PERMISSION.notes
  )
}

const VisibilityOptionsByGoalType: { [K in GoalType]: VisibilityOption[] } = {
  personal: [
    VisibilityOptionEveryone,
    VisibilityOptionManager,
    VisibilityOptionOwner,
    VisibilityOptionIndividuals,
  ],
  team: [VisibilityOptionEveryone, VisibilityOptionTeam],
  department: [VisibilityOptionEveryone],
  company: [VisibilityOptionEveryone],
}

export function getVisibilityOptions(
  type: GoalType,
  isDevelopModuleGoal: boolean
): VisibilityOption[] {
  if (isDevelopModuleGoal) {
    return [
      VisibilityOptionEveryone,
      VisibilityOptionOwner,
      VisibilityOptionIndividuals,
    ]
  }

  return VisibilityOptionsByGoalType[type]
}

export const getDueDateForCopyGoal = (
  currentUser: CurrentUser,
  baseGoalDueDate: Date
) => {
  const currentCycleEndDate = getGoalCycle(currentUser).end
  if (moment(baseGoalDueDate).isAfter(currentCycleEndDate)) {
    return baseGoalDueDate
  }
  return currentCycleEndDate
}
