import Reflux from "reflux-core"
import { Map } from "immutable"
import CompanyActions from "../refluxActions/CompanyActions"

export const createDirectoryStore = () =>
  Reflux.createStore({
    init: function () {
      this.data = {
        updates: null,
        users: [],
        meta: null,
        usersById: Map(),
      }

      CompanyActions.loadById.completed.listen((user) => {
        this.data = {
          ...this.data,
          usersById: this.data.usersById.set(user.id, user),
        }

        this.trigger(this.data)
      })

      this.listenTo(CompanyActions.listUsers.completed, this.onDirectoryList)

      this.listenTo(
        CompanyActions.deactivateUser.completed,
        this.onDirectoryUpdate
      )

      this.listenTo(
        CompanyActions.reactivateUser.completed,
        this.onDirectoryUpdate
      )

      this.listenTo(CompanyActions.updateUser.completed, this.onDirectoryUpdate)

      this.listenTo(CompanyActions.deleteUser.completed, this.onDirectoryUpdate)
    },

    getInitialState: function () {
      return this.data
    },

    onDirectoryList: function ({ users, meta = {} }) {
      if (meta.pagination && meta.pagination.current_page > 1) {
        users = [...this.data.users, ...users]
      }
      this.data = {
        updates: null,
        users,
        meta,
        usersById: this.data.usersById,
      }

      this.trigger(this.data)
    },

    onDirectoryUpdate(updatedUser) {
      this.data = {
        ...this.data,
        updates: updatedUser, // LEGACY - used by Directory.jsx
        usersById: this.data.usersById.set(updatedUser.id, updatedUser),
      }

      this.trigger(this.data)
    },
  })

export default createDirectoryStore()
