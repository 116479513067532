import "./tailwind.css"
import "./static/fonts/fonts.css"
// Prevent scroll bounce
// import iNoBounce from 'inobounce';
// if (/iPad/i.test(navigator.userAgent)) {
//   iNoBounce.disable(); // TODO enable and test -webkit scroll on body
// }
import * as Sentry from "@sentry/browser"
import "flatpickr/dist/themes/material_green.css" // flatpickr is installed via react-flatpickr, but we have to import the CSS ourselves
import "./styles/lib/flatpickr-overrides.scss"
import "rc-slider/assets/index.css"
import "rc-tooltip/assets/bootstrap_white.css"
import "react-select/dist/react-select.css"
import React from "react"
import ReactDOM from "react-dom"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import { ApolloProvider } from "@apollo/client"
import { ThemeManager, ThemeProvider, heartTheme } from "@kaizen/design-tokens"
import { QueryClient, QueryClientProvider } from "react-query"
import { QueryParamProvider } from "use-query-params"
import { FlatPickrLocaleProvider } from "@Common/components/FlatPickrLocaleProvider"
import { StringsProvider } from "@Common/components/StringsProvider"
import { LaunchDarklyProviderWrapper } from "@Common/components/LaunchDarklyProvider/LaunchDarklyProviderWrapper"
import localeBundles from "@Locale/LocaleBundles"
import { initRefluxActionLogger } from "./utils/reflux"
import { CurrentUserProvider } from "./reduxReducers/currentUser"
import { refluxStores } from "./refluxStores"
import refluxActions from "./refluxActions/index" // need to import index to do impl setup
import "./index.less"
import { RoutesApp, PublicRoutesApp as PublicApp } from "./routes"
import Settings from "./settings"
import { isLogTagEnabled } from "./utils/logging"
import { SENTRY_EVENTS } from "./constants/logging"
import { client } from "./apollo"
import { basePath } from "./constants/routes"
import Loader from "./components/Loader/Loader"

// Create a react-query client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

import { rootAppId } from "./constants/portals"
import { BrowserHistoryProvider } from "./reduxReducers/browserHistory"

// Init Kaizen ThemeManager
// This should ideally be `defaultTheme` instead of `heartTheme`,
// but this can't be done until design-tokens is >=3.1
const themeManager = new ThemeManager(heartTheme)

// Gives us the ability to see our Reflux store in any environment.
window._DEBUG = { refluxActions, refluxStores }

initRefluxActionLogger(refluxActions, refluxStores)

if (Settings.SENTRY_ENABLED && window.location.protocol !== "file:") {
  Sentry.init({
    dsn: Settings.SENTRY_URL,
    environment: Settings.SENTRY_ENVIRONMENT,
    release: Settings.RELEASE,
    autoSessionTracking: false,
    beforeSend(event, hint) {
      /**
       * The following two blocks are an interim prevention for chunk errors. So far, no customer complaints,
       * but that is not enough justification. The aim here is to log to the console for
       * now in the sandbox where errors have been shown before, and in the interim stop
       * chunking errors from clogging up Sentry. All the chunking errors are currently handled.
       */
      if (event?.environment === "sandbox") {
        if (isLogTagEnabled(SENTRY_EVENTS)) {
          // eslint-disable-next-line no-console
          console.log("@@@ Sentry event", event)
        }
        if (isLogTagEnabled(SENTRY_EVENTS)) {
          // eslint-disable-next-line no-console
          console.log("@@@ Sentry hint", hint)
        }
      }
      if (/loading.+chunk/i.test(hint?.originalException)) {
        return null
      }

      return event
    },
  })
}

const PrivateApp = (
  <ThemeProvider themeManager={themeManager}>
    <QueryClientProvider client={queryClient}>
      <CurrentUserProvider>
        <LaunchDarklyProviderWrapper>
          <FlatPickrLocaleProvider>
            <StringsProvider loaders={localeBundles}>
              <ApolloProvider client={client}>
                <BrowserHistoryProvider>
                  <QueryParamProvider ReactRouterRoute={Route}>
                    <Route path="*">{RoutesApp}</Route>
                  </QueryParamProvider>
                </BrowserHistoryProvider>
                {/* /* There's also the ModalRouter, which can be found in ./components/App.jsx */}
              </ApolloProvider>
            </StringsProvider>
          </FlatPickrLocaleProvider>
        </LaunchDarklyProviderWrapper>
      </CurrentUserProvider>
    </QueryClientProvider>
  </ThemeProvider>
)

function init() {
  ReactDOM.render(
    <Router basename={basePath}>
      <React.Suspense fallback={<Loader />}>
        <Switch>
          {PublicApp}
          {PrivateApp}
        </Switch>
      </React.Suspense>
    </Router>,
    document.getElementById(rootAppId)
  )
}

init()
