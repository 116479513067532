import React from "react"
import { update } from "ramda"
import { Paragraph } from "@kaizen/typography"
import { KeyResultItem } from "@Goals/components"
import { KeyResult } from "@Goals/types"
import ProgressBar from "@Components/ProgressBar/ProgressBar"
import styles from "./KeyResultListFacade.scss"

export type KeyResultListProps = {
  keyResults: KeyResult[]
  readOnly: boolean
  onChange: (keyResults: KeyResult[]) => void
}

const ReadOnlyKeyResultList = ({ keyResults }: KeyResultListProps) => {
  return (
    <>
      {keyResults.map((keyResult, i) => (
        <div key={keyResult.id} className={styles.container}>
          <div className={styles.title}>
            <Paragraph variant="small">{keyResult.title}</Paragraph>
          </div>
          <div
            className={styles.completion}
            data-automation-id="goal-key-result-progress-bar"
          >
            <ProgressBar value={keyResult.completion} />
          </div>
        </div>
      ))}
    </>
  )
}

const KeyResultList = ({ keyResults, onChange }: KeyResultListProps) => {
  return (
    <>
      {keyResults.map((keyResult, i) => {
        return (
          <KeyResultItem
            key={keyResult.id}
            keyResult={keyResult}
            onChange={(updatedKeyResult) =>
              onChange(update(i, updatedKeyResult, keyResults))
            }
          />
        )
      })}
    </>
  )
}

export const KeyResultListFacade = (props: KeyResultListProps) => {
  if (props.readOnly) {
    return <ReadOnlyKeyResultList {...props} />
  }

  return <KeyResultList {...props} />
}
