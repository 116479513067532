import * as React from "react"
import { CheckboxGroup, CheckboxField } from "@kaizen/draft-form"
import _ from "lodash"
import { DropdownOption } from "./FilterSelectDropdown"
import styles from "./FilterSelectDropdown.scss"

export type NestedDropdownOption<T> = {
  options?: Array<DropdownOption<T>>
} & DropdownOption<T>

type Props<T> = {
  savedFilterOptions: Array<DropdownOption<T>>
  onCheckboxChange: (options: Array<DropdownOption<T>>) => void
  selectedFilterOption: Array<DropdownOption<T>>
  options: Array<NestedDropdownOption<T>>
  valueToString?: (value: T) => string
}

type CheckboxState = "off" | "on" | "mixed"

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const NestedSelectOptions = <T extends any>({
  options,
  savedFilterOptions,
  onCheckboxChange,
  selectedFilterOption,
  valueToString = (val) => JSON.stringify(val),
}: Props<T>) => {
  const checkedStatus = (option: NestedDropdownOption<T>): CheckboxState => {
    if (option.options?.length) {
      const childIds = option.options.map((val) => valueToString(val.value))
      const selectedChild = selectedFilterOption.filter((val) =>
        childIds.includes(valueToString(val.value))
      ).length
      if (selectedChild === childIds.length) {
        return "on"
      }
      if (selectedChild > 0 && selectedChild !== childIds.length) {
        return "mixed"
      }
      return "off"
    }

    return selectedFilterOption.filter(
      (filter) => filter.value === option.value
    ).length > 0
      ? "on"
      : "off"
  }

  const handleNestedOptionCheckboxChange = (
    checkboxState: CheckboxState,
    selectedOption: DropdownOption<T>,
    parentLabel: string
  ) => {
    if (checkboxState === "off") {
      onCheckboxChange([
        ...selectedFilterOption,
        {
          label: `${parentLabel}: ${selectedOption.label}`,
          value: selectedOption.value,
        },
      ])
    } else {
      onCheckboxChange(
        selectedFilterOption.filter((val) => val.value !== selectedOption.value)
      )
    }
  }

  const handleCheckboxOnChange = (
    checkboxState: string,
    selectedOption: NestedDropdownOption<T>
  ) => {
    // Handle the state where the option has child, we need to structure the label to be in
    // this format parent.label: child.label
    if (selectedOption.options?.length) {
      const selectedOptions = selectedOption.options.map((val) => {
        return {
          label: `${selectedOption.label}: ${val.label}`,
          value: val.value,
        }
      })
      // When it previously from unchecked / mix state, make all child be selected
      if (checkboxState === "off" || checkboxState === "mixed") {
        // Check all of the child from this parent option. Use `uniq`
        // to make sure there's not duplicate by value within the selectedOptions array
        onCheckboxChange(
          _.uniqBy(
            [...selectedFilterOption, ...selectedOptions],
            (val) => val.value
          )
        )
      } else {
        const filteredOptions = selectedFilterOption.filter((val) => {
          return !selectedOptions.some(
            (selectedOption) => selectedOption.value === val.value
          )
        })
        onCheckboxChange(filteredOptions)
      }
    } else {
      // For the option without child there will be only "off" / "on" state
      if (checkboxState === "off") {
        onCheckboxChange([...selectedFilterOption, selectedOption])
      } else {
        onCheckboxChange(
          selectedFilterOption.filter(
            (val) => val.value !== selectedOption.value
          )
        )
      }
    }
  }

  const renderNestedOption = (parentOption: NestedDropdownOption<T>) => {
    return (
      <div className={styles.nestedRow}>
        {parentOption.options?.map((option) => {
          return (
            <CheckboxField
              key={`checkbox-${option.value}`}
              onCheck={(e) => {
                handleNestedOptionCheckboxChange(
                  e.currentTarget.value as CheckboxState,
                  option,
                  parentOption.label
                )
              }}
              id={`checkbox-${option.value}`}
              checkedStatus={checkedStatus(option)}
              labelText={option.label}
            />
          )
        })}
      </div>
    )
  }

  return (
    <CheckboxGroup labelText="">
      {options.map((option) => {
        return (
          <>
            <div
              className={styles.checkboxRow}
              key={`checkbox-row-${option.value}`}
            >
              <CheckboxField
                onCheck={(e) => {
                  handleCheckboxOnChange(e.currentTarget.value, option)
                }}
                id={`checkbox-${option.value}`}
                checkedStatus={checkedStatus(option)}
                labelText={option.label}
              />
            </div>
            {option.options &&
              option.options.length > 0 &&
              renderNestedOption(option)}
          </>
        )
      })}
    </CheckboxGroup>
  )
}
