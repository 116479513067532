import { useCallback, useContext, useEffect } from "react"
import saveDraftDecoder from "../../api/decoders/SaveDraftDecoder/saveDraftDecoder"
import saveDraftEncoder from "../../api/encoders/saveDraftEncoder"
import { DraftsContext } from "../../reduxReducers/drafts"
import useDataAPI from "../performanceApi/useDataAPI"
import { DraftSourceType } from "../../types/reduxState/drafts"

const useSaveDraft = (
  sourceId: number,
  sourceType: DraftSourceType,
  recipientId?: number
) => {
  const { fire, loading, data, error } = useDataAPI("/drafts", "POST")
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { state, dispatch } = useContext(DraftsContext)

  useEffect(() => {
    if (data) {
      const draft = saveDraftDecoder(data)

      dispatch({
        type: "FETCH_DRAFT",
        payload: {
          sourceType,
          sourceId,
          recipientId,
          draft,
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const saveDraft = useCallback(
    (body: string) => {
      const draftData = saveDraftEncoder(
        sourceId,
        sourceType,
        body,
        recipientId
      )
      fire(draftData)
    },
    [fire, recipientId, sourceId, sourceType]
  )

  const succeeded = data && !error && !loading

  return { loading, saveDraft, succeeded }
}

export default useSaveDraft
