/**
 * DEPRECATED: we are no longer using decoders to validate server responses.
 * Please do not use them in new code.
 *
 * See src/api/decoders/README.md for more info.
 */
import { JsonDecoder } from "ts.data.json"
import { GoalOwner, TeamGoalPreview } from "../../../types/Goals"
import { outgoingAlignedGoalDecoder } from "../AlignedGoalDecoder/alignedGoalDecoder"
import {
  goalPriorityDecoder,
  goalKeyResultDecoder,
} from "../BaseGoalDecoder/baseGoalDecoder"

export const goalOwnerDecoder = JsonDecoder.object<GoalOwner>(
  // @ts-expect-error Typescript is askig for the other props from the CurrentUser
  {
    aggregateId: JsonDecoder.string,
    id: JsonDecoder.number,
    name: JsonDecoder.string,
    avatar: JsonDecoder.object(
      {
        thumb_url: JsonDecoder.string,
      },
      "avatar"
    ).map((avatarObj) => avatarObj.thumb_url),
    profileImage: JsonDecoder.failover("", JsonDecoder.string),
    role: JsonDecoder.failover(
      "",
      JsonDecoder.oneOf(
        [
          JsonDecoder.string,
          JsonDecoder.object(
            {
              title: JsonDecoder.string,
            },
            "role"
          ).map(({ title }) => title),
        ],
        "role"
      )
    ),
  },
  "goalOwner",
  {
    name: "user_best_name",
    avatar: "user_avatar_image",
    profileImage: "user_profile_image_url",
    aggregateId: "employee_aggregate_id",
    role: "job_title",
  }
)

export const teamGoalPreviewDecoder: JsonDecoder.Decoder<TeamGoalPreview> =
  JsonDecoder.object<TeamGoalPreview>(
    {
      owners: JsonDecoder.array(goalOwnerDecoder, "owners"),
      type: JsonDecoder.isExactly("team_goal").map((): "team" => "team"),
      id: JsonDecoder.number,
      name: JsonDecoder.string,
      completion: JsonDecoder.number,
      commentsCount: JsonDecoder.number,
      dueDate: JsonDecoder.string.map((dateString) => new Date(dateString)),
      priority: goalPriorityDecoder,
      status: JsonDecoder.string,
      outgoingAlignedGoals: JsonDecoder.failover(
        [],
        JsonDecoder.array(outgoingAlignedGoalDecoder, "outgoingAlignedGoals")
      ),
      teamName: JsonDecoder.failover("", JsonDecoder.string),
      keyResults: JsonDecoder.failover(
        [],
        JsonDecoder.array(goalKeyResultDecoder, "key_results")
      ),
    },
    "teamGoalPreview",
    {
      owners: "goal_owners",
      commentsCount: "number_of_comments",
      dueDate: "due_at",
      priority: "priority",
      type: "goal_type",
      teamName: "team_name",
    }
  )
