import { perfApiFetch, InitBodyFetch } from "@API/utils"

export class SupremeAgentRequest {
  private baseUrl: string
  private url: string
  private request: InitBodyFetch
  private controller: AbortController
  private promise: Promise<unknown>

  constructor(method: string, url: string, baseUrl: string) {
    this.baseUrl = baseUrl
    this.controller = new AbortController()
    this.url = url
    this.request = {
      method,
      signal: this.controller.signal,
    }
  }

  end(callback?: (err: Error | null, result?: unknown) => void) {
    this.promise = perfApiFetch(this.url, this.request, this.baseUrl)
      .then((val) => {
        const resp = { body: val.data }
        callback && callback(null, resp)
        return resp
      })
      .catch((err) => {
        callback && callback(err)
        throw err
      })
    return this
  }

  query(data: InitBodyFetch["params"]) {
    this.request.params = data
    return this
  }

  send(data: InitBodyFetch["body"]) {
    // superagent supports multiple calls to .send for merging JSON
    if (
      this.request.body === undefined ||
      typeof this.request.body === "string" ||
      typeof data === "string"
    ) {
      if (data) {
        this.request.body = data
      }
    } else {
      this.request.body = { ...(this.request.body as object), ...data }
    }
    return this
  }

  attach({ name, file }: { name: string; file: File }) {
    const fd = new FormData()
    fd.append(name, file)
    this.request.body = fd
    return this
  }

  abort() {
    this.controller.abort()
    return this
  }

  then(onSuccess: (data: unknown) => void, onFailure?: (err: Error) => void) {
    if (!this.promise) {
      this.end()
    }
    return this.promise.then(onSuccess).catch(onFailure)
  }

  catch(onFailure: (err: Error) => void) {
    if (!this.promise) {
      this.end()
    }
    return this.promise.catch(onFailure)
  }
}

export default class SupremeAgent {
  base: string
  constructor(opts: { base: string }) {
    if (!opts.base || typeof opts.base !== "string") {
      throw new Error(
        "No API URL provided to constructor. " +
          "Pass in a string under the 'base' attribute."
      )
    }
    this.base = opts.base
  }

  /**
   * Creates a new `Request` object, returned from the `get` / `post` / ...
   * methods. May be overridden by subclasses, allowing them to, for example,
   * return a custom `Request` class. The return value must be an instance
   * of `Request` or a subclass.
   */
  createRequest(method: string, url: string) {
    return new SupremeAgentRequest(method, url, this.base)
  }

  /**
   * Plumbing associated with creating a new request. The `createRequest` method
   * is designed to be overridable by subclasses, so is only expected to create
   * and return a new request instance.
   */
  _createAndConfigureRequest(method: string, url: string) {
    return this.createRequest(method, url)
  }

  /**
   * REST METHODS
   */

  head(url: string) {
    return this._createAndConfigureRequest("head", url)
  }

  get(url: string) {
    return this._createAndConfigureRequest("get", url)
  }

  post(url: string) {
    return this._createAndConfigureRequest("post", url)
  }

  put(url: string) {
    return this._createAndConfigureRequest("put", url)
  }

  del(url: string) {
    return this._createAndConfigureRequest("delete", url)
  }
}
