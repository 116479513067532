import { Location } from "history"
import { AUTOMATIC_REDIRECTS } from "../constants/logging"
import { isLogTagEnabled } from "./logging"
import { basePath } from "../constants/routes"

export const prependBasePath = (pathName: string) => {
  return basePath + pathName
}

export const getPathQueryHash = (location: Location) => {
  return location.pathname + location.search + location.hash
}

export const getBasedPathQueryHash = (location: Location) => {
  // @ts-expect-error: I'm not sure why the basename isn't showing here
  return location.basename + location.pathname + location.search + location.hash
}

export const getPathQuery = (location: Location) => {
  return location.pathname + location.search
}

// Provides a console warning, letting developers know the reason why the
// page redirected.
export const logRedirectWarning = (
  from: string,
  to: string,
  reason: string
) => {
  if (isLogTagEnabled(AUTOMATIC_REDIRECTS)) {
    // eslint-disable-next-line no-console
    console.warn(
      `AUTOMATIC REDIRECT\nFrom: ${from}\nTo: ${to}\nReason: ${reason}`
    )
  }
}
