import cx from "classnames"
import React from "react"
import { Paragraph } from "@kaizen/typography"
import { useIntl } from "@Common/locale/useIntl"
import ButtonizedDiv from "@Components/ButtonizedDiv"
import { KeyResultForm } from "@Goals/components"
import styles from "@Goals/components/KeyResultFormItem/KeyResultFormItem.scss"
import { KeyResult } from "@Goals/types"
import strings from "@Locale/strings"

type EmptyKeyResultFormItemProps = {
  automationId?: string
  isEditing: boolean
  onSubmit: (keyResult: KeyResult) => void
  onSelect: () => void
  onCancelSelect: () => void
}

export const EmptyKeyResultFormItem = ({
  automationId,
  isEditing,
  onSubmit,
  onSelect,
  onCancelSelect,
}: EmptyKeyResultFormItemProps) => {
  const { formatMessage } = useIntl()

  const handleOnSubmit = (kr: KeyResult) => {
    onSubmit(kr)
    onCancelSelect()
  }

  if (isEditing) {
    return (
      <KeyResultForm
        id={`empty-key-result-form`}
        onSubmit={handleOnSubmit}
        onCancel={onCancelSelect}
      />
    )
  }

  return (
    <div className={styles.item} data-automation-id={automationId}>
      <ButtonizedDiv
        className={cx(
          styles.textContainer,
          styles.textContainerEditable,
          styles.textContainerEmpty
        )}
        onClick={onSelect}
      >
        <Paragraph variant="body" tag="p">
          {formatMessage(strings.goalKeyResultsItem.addKeyResultText)}
        </Paragraph>
      </ButtonizedDiv>
    </div>
  )
}
