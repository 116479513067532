import { useCallback, useContext, useEffect } from "react"
import teamsDecoder from "@API/decoders/TeamsDecoder/teamsDecoder"
import { TeamsContext } from "@Redux/reducers/teams"
import useDataAPI from "@Domain/performanceApi/useDataAPI"
import { captureDecoderException } from "@API/decoders/utils"
import { Team } from "@Types/reduxState/teams"

interface Filters {
  all_teams: boolean
  q?: string
  archived_teams?: boolean
}

const perPage = 20

const useTeams = (
  filters: Filters = { all_teams: false, archived_teams: false }
) => {
  const { state, dispatch } = useContext(TeamsContext)
  const fetchAPI = useDataAPI("/teams", "GET")
  const hasSearchFilter = filters.q && filters.q.length > 0
  useEffect(() => {
    fetchAPI.fire({
      ...filters,
      page: 1,
      per_page: perPage,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.all_teams, filters.q])

  useEffect(() => {
    if (fetchAPI.data) {
      teamsDecoder
        .decodePromise(fetchAPI.data)
        .then((response) => {
          if (hasSearchFilter) {
            if (filters.all_teams) {
              dispatch({
                type: "FETCH_ALL_TEAMS_SEARCH",
                payload: response,
              })
            } else {
              dispatch({
                type: "FETCH_MY_TEAMS_SEARCH",
                payload: response,
              })
            }
          } else {
            if (filters.all_teams) {
              dispatch({
                type: "FETCH_ALL_TEAMS",
                payload: response,
              })
            } else {
              dispatch({
                type: "FETCH_MY_TEAMS",
                payload: response,
              })
            }
          }
        })
        .catch((error) => {
          captureDecoderException(error)
          throw error
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchAPI.data])

  const filteredIds = hasSearchFilter
    ? state[
        filters.all_teams ? "allTeamsSearchResults" : "myTeamsSearchResults"
      ]
    : state[filters.all_teams ? "allTeams" : "myTeams"]

  const fetchMore = useCallback(() => {
    const { nextPage } = filteredIds
    if (nextPage) {
      fetchAPI.fire({ ...filters, page: nextPage, per_page: perPage })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredIds.nextPage, filters.all_teams, filters.q])

  const teams = Object.values(filteredIds.pages)
    .reduce((res, teams) => [...res, ...teams], [])
    .map((goalId) => state.teamsById[goalId] as Team)

  const hasMore = filteredIds.totalCount > teams.length

  return {
    hasMore,
    fetchMore,
    teams,
    loading: fetchAPI.loading,
  }
}

export default useTeams
