import Reflux from "reflux-core"
import { Map, Record } from "immutable"
import DraftActions from "../refluxActions/DraftActions"

export const DraftSource = Record({
  parentObjType: null,
  parentObjId: null,
  recipientId: null,
})

export default Reflux.createStore({
  init() {
    this.data = {
      // NB: each source has at most one draft
      draftsBySource: Map(), // { DraftSource -> {body, saveState} }
    }

    this.listenTo(
      DraftActions.notifyEditing,
      ({ parentObjType, parentObjId, recipientId }) => {
        const draftSource = DraftSource({
          parentObjType,
          parentObjId,
          recipientId,
        })
        const draft = this.data.draftsBySource.get(draftSource)

        if (draft && draft.saving) {
          return
        }

        this.updateDraft(
          { parentObjType, parentObjId, recipientId },
          (draft) => ({ ...draft, saveState: "saving" })
        )

        this.trigger(this.data)
      }
    )

    this.listenTo(
      DraftActions.save.failed,
      ({ parentObjType, parentObjId, recipientId }) => {
        this.updateDraft(
          { parentObjType, parentObjId, recipientId },
          (draft) => ({ ...draft, saveState: "failed" })
        )

        this.trigger(this.data)
      }
    )

    this.listenTo(
      DraftActions.save.completed,
      ({ draft, parentObjType, parentObjId, recipientId }) => {
        this.updateDraft({ parentObjType, parentObjId, recipientId }, () => ({
          ...draft,
          saveState: "saved",
        }))

        this.trigger(this.data)
      }
    )

    this.listenTo(
      DraftActions.get.completed,
      ({ draft, parentObjType, parentObjId, recipientId }) => {
        this.updateDraft(
          { parentObjType, parentObjId, recipientId },
          (origDraft) => ({ ...origDraft, ...draft })
        )

        this.trigger(this.data)
      }
    )
  },

  getInitialState() {
    return this.data
  },

  updateDraft({ parentObjType, parentObjId, recipientId }, updater) {
    const draftSource = DraftSource({ parentObjType, parentObjId, recipientId })

    this.data = {
      ...this.data,
      draftsBySource: this.data.draftsBySource.update(draftSource, updater),
    }
  },
})
