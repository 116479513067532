import React, { useCallback, useContext, useMemo } from "react"
import { ConfigurationOptions } from "@Constants/configurationOptions"
import R from "@Constants/routes"
import { CurrentUserContext } from "@Redux/reducers/currentUser"
import {
  RouteProps,
  Route,
  RouteComponentProps,
  Redirect,
} from "react-router-dom"
import { RedirectTo403 } from "./RedirectTo403"

import { CurrentSession, sessionDetailsFromUser, UserRoles } from "./session"

export type AuthenticateParams = {}

type ConfigurationOptionsExtended =
  | ConfigurationOptions
  | "allow_goals"
  | "allow_new_goals_interface"
  | "allow_praise"
  | "allow_self_reflections"
  | "allow_performance_cycles"
  | "allow_reviews"

type AuthenticationCheck = {
  authorisedRoles?: UserRoles[]
  hasConfigurationOption?: ConfigurationOptionsExtended
}

export const withPerformanceRoute = <P extends {}>(
  Component: React.ComponentType<P>,
  { authorisedRoles, hasConfigurationOption }: AuthenticationCheck = {}
): React.FunctionComponent<P> => {
  const authCheck = ({ roles, configuration }: CurrentSession) => {
    // access denined
    if (
      // role check
      (authorisedRoles !== undefined &&
        // session roles does not include any of the authorised list
        !authorisedRoles.some((role) => roles.includes(role))) ||
      // config check
      (hasConfigurationOption !== undefined &&
        // the enabled configuration does not include the required configuration
        !configuration.includes(hasConfigurationOption))
    ) {
      return false
    }
    return true
  }

  const ProtectedRoute: React.FunctionComponent<P> = (props) => {
    const user = useContext(CurrentUserContext)

    const isAllowed = useMemo<boolean>(() => {
      const data = sessionDetailsFromUser(user)
      return data !== undefined && authCheck(data)
    }, [user])

    return !isAllowed ? <RedirectTo403 /> : <Component {...props} />
  }
  return ProtectedRoute
}

const NoOp: React.FunctionComponent = ({ children }) => <>{children}</>

/**
 * For protecting a route without adding a visual component to the tree
 */
export const protectedPerformanceRoute = (
  authenticationCheck: AuthenticationCheck = {}
) => withPerformanceRoute(NoOp, authenticationCheck)

export const PrivateRoute: React.FC<
  {
    authorisedRoles?: UserRoles[]
    hasConfigurationOption?: ConfigurationOptionsExtended
  } & RouteProps
> = ({ children, authorisedRoles, hasConfigurationOption, ...rest }) => {
  const user = useContext(CurrentUserContext)
  const authCheck = useCallback(
    ({ roles, configuration }: CurrentSession) => {
      // access denined
      if (
        // role check
        (authorisedRoles !== undefined &&
          // session roles does not include any of the authorised list
          !authorisedRoles.some((role) => roles.includes(role))) ||
        // config check
        (hasConfigurationOption !== undefined &&
          // the enabled configuration does not include the required configuration
          !configuration.includes(hasConfigurationOption))
      ) {
        return false
      }
      return true
    },
    [authorisedRoles, hasConfigurationOption]
  )

  const isAllowed = useMemo<boolean>(() => {
    const data = sessionDetailsFromUser(user)
    return data !== undefined && authCheck(data)
  }, [user, authCheck])

  return isAllowed === false ? (
    <RedirectTo403 />
  ) : (
    <Route {...rest}>{children}</Route>
  )
}

type ParamValidators = Record<
  string,
  RegExp | ((paramValue: string) => boolean)
>

/**
 * For use when needing to validate url parameters on a route. For
 * example, ensuring that the :cycleId parameter in the path is a number
 * you would do:
 * <ValidatedRoute path="/evaluation_cycles/:cycleId" component={SharedEcDetailPage} validators={{ cycleId: /^\d+$/ }} />
 *
 */
export const ValidatedRoute = (
  routeProps: RouteProps & {
    validators: ParamValidators
    authorisedRoles?: UserRoles[]
  }
) => {
  const { validators, component, authorisedRoles, ...restRoute } = routeProps

  return (
    <Route
      {...restRoute}
      render={(componentProps: RouteComponentProps) => (
        <CheckRoute
          validators={validators}
          routeProps={{ component, ...restRoute }}
          componentProps={componentProps}
          authorisedRoles={authorisedRoles}
        />
      )}
    />
  )
}

interface ICheckRoute {
  validators: ParamValidators
  routeProps: RouteProps
  componentProps: RouteComponentProps<
    Record<Partial<keyof ParamValidators>, string>
  >
  authorisedRoles?: UserRoles[]
}

const CheckRoute = ({
  validators,
  routeProps,
  componentProps,
  authorisedRoles,
}: ICheckRoute) => {
  const errors = Object.entries(validators).some(([param, validator]) => {
    const paramValue = componentProps.match.params[param]
    if (!paramValue) {
      return true
    }

    if (validator instanceof RegExp) {
      return !paramValue.match(validator)
    }

    return !validator(paramValue)
  })

  return errors ? (
    <Redirect to={R.error404} />
  ) : authorisedRoles?.length ? (
    <PrivateRoute authorisedRoles={authorisedRoles} {...routeProps} />
  ) : (
    <Route {...routeProps} />
  )
}
