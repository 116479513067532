import React from "react"
import cx from "classnames"
import { TreeGoalCardPlaceholder } from "@Goals/components"

import styles from "./NavigationTreeNode.scss"

export const NavigationTreeNodePlaceholder = ({
  isFirstLevel,
}: {
  isFirstLevel?: boolean
}) => {
  return (
    <div
      className={cx(
        isFirstLevel ? styles.firstLevelNodeContainer : styles.nodeContainer
      )}
    >
      <div className={styles.node}>
        <TreeGoalCardPlaceholder />
      </div>
    </div>
  )
}
