import React from "react"
import { Link } from "react-router-dom"
import { useIntl } from "@Common/locale/useIntl"
import R from "@Constants/routes"
import ResponsiveWrapper from "../../components/ResponsiveWrapper/ResponsiveWrapper"
import EmptyState from "../../components/EmptyState/EmptyState"
import strings from "../../locale/strings"
import Aid from "../../constants/automationId"

type Props = {
  // You can supply either an error object, or an errorMessage string for convenience.
  // It's up to you.
  error?: Error
  errorMessage?: string
  ctaText?: string
  ctaOnClick?: () => void
}

const ErrorState = ({ errorMessage, error, ctaText, ctaOnClick }: Props) => {
  const { formatMessage } = useIntl()

  return (
    <ResponsiveWrapper className="ErrorPage">
      <div
        data-automation-id={Aid.errorState}
        className="ErrorPage--notification-container flex basis-auto flex-col items-center justify-center"
      >
        <EmptyState
          illustrationType="negative"
          header={formatMessage(strings.errorPage.header)}
          body={
            errorMessage ||
            error?.message ||
            formatMessage(strings.errorPage.body, {
              Link: (chunks: React.ReactNode) => (
                <Link to={R.home}>{chunks}</Link>
              ),
              lineBreak: <br />,
            })
          }
          ctaText={ctaText}
          ctaProps={ctaOnClick ? { onClick: ctaOnClick } : undefined}
        />
      </div>
    </ResponsiveWrapper>
  )
}

export default ErrorState
