export default {
  init({
    actions: {
      updateSurveyItemLocally,
      updateSurveyItemRemotely,
      getIncompleteSurveyResponse,
      acceptIncompleteSurveyResponse,
      submitSurveyResponse,
    },
  }) {
    this.data = {
      incompleteSurveyResponse: null,
    }

    this.listenTo(updateSurveyItemLocally, (updatedSurveyItem) =>
      this.handleSurveyItemUpdated({
        ...updatedSurveyItem,
        saveState: "saving",
      })
    )

    this.listenTo(updateSurveyItemRemotely.completed, (updatedSurveyItem) =>
      this.handleSurveyItemUpdated({ ...updatedSurveyItem, saveState: "saved" })
    )

    this.listenTo(
      updateSurveyItemRemotely.failed,
      this.handleSurveyItemUpdateFailed
    )
    this.listenTo(
      getIncompleteSurveyResponse.completed,
      this.handleIncompleteSurveyResponse
    )
    this.listenTo(
      acceptIncompleteSurveyResponse.completed,
      this.handleIncompleteSurveyResponse
    )
    this.listenTo(
      submitSurveyResponse.completed,
      this.handleIncompleteSurveyResponse
    )
  },

  getInitialState() {
    return this.data
  },

  handleSurveyItemUpdateFailed(id) {
    this.data = {
      ...this.data,
      incompleteSurveyResponse: this.data.incompleteSurveyResponse.with({
        survey_items: this.data.incompleteSurveyResponse.survey_items.map(
          (surveyItem) =>
            id === surveyItem.id
              ? { ...surveyItem, saveState: "failed" }
              : surveyItem
        ),
      }),
    }
    this.trigger(this.data)
  },

  handleSurveyItemUpdated(updatedSurveyItem) {
    this.data = {
      ...this.data,
      incompleteSurveyResponse: this.data.incompleteSurveyResponse.with({
        survey_items: this.data.incompleteSurveyResponse.survey_items.map(
          (surveyItem) =>
            updatedSurveyItem.id === surveyItem.id
              ? updatedSurveyItem
              : surveyItem
        ),
      }),
    }
    this.trigger(this.data)
  },

  handleIncompleteSurveyResponse(surveyResponse) {
    this.data = {
      ...this.data,
      incompleteSurveyResponse: surveyResponse,
    }
    this.trigger(this.data)
  },
}
