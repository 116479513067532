export const RoutePaths = {
  companyGoalsList: "/new_goals/company",
  goalsTree: "/new_goals/company/tree",
  departmentGoals: "/new_goals/department",
  teamGoals: "/new_goals/team",
  individualGoals: "/new_goals/personal",
} as const

export type RoutePathKey = keyof typeof RoutePaths
export type RoutePathValue = typeof RoutePaths[RoutePathKey]

export const RouteNames: { [Property in RoutePathValue]: string } = {
  [RoutePaths.companyGoalsList]: "Company Goals (List)",
  [RoutePaths.goalsTree]: "Company Goals (Tree)",
  [RoutePaths.departmentGoals]: "Department Goals",
  [RoutePaths.teamGoals]: "Team Goals",
  [RoutePaths.individualGoals]: "Individual Goals",
}
