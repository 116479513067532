/**
 * DEPRECATED: we are no longer using decoders to validate server responses.
 * Please do not use them in new code.
 *
 * See src/api/decoders/README.md for more info.
 */
import { JsonDecoder } from "ts.data.json"
import { TeamSummary } from "../../../types/reduxState/teamSummaries"

export const teamSummaryDecoder = JsonDecoder.object<TeamSummary>(
  {
    id: JsonDecoder.string,
    name: JsonDecoder.string,
    overallProgress: JsonDecoder.failover(0, JsonDecoder.number),
    goalsCount: JsonDecoder.failover(0, JsonDecoder.number),
  },
  "TeamSummary",
  {
    id: "team_id",
    name: "team_name",
    overallProgress: "team_overall_progress",
    goalsCount: "goal_count",
  }
)

export const teamSummariesResponseDecoder = JsonDecoder.object(
  {
    teams: JsonDecoder.array(teamSummaryDecoder, "TeamPreviews"),
  },
  "teamSummariesResponse"
).map((res) => res.teams)
