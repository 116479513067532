import "./ProgressBar.less"
import React from "react"
import { FormattedNumber } from "react-intl"
import cx from "classnames"

type Props = {
  value: number
  color?: string
  className?: string
  completionClassName?: string
  children?: React.ReactNode
}
export default class ProgressBar extends React.Component<Props> {
  static defaultProps = {
    className: "",
    completionClassName: "",
  }

  render() {
    const { value, color, className, children } = this.props
    const completionWidth = `${Math.round(value * 100)}%`
    const completionClass = cx(
      "ProgressBar--completion",
      this.props.completionClassName,
      {
        "ProgressBar--completion-empty": value === 0,
        "ProgressBar--completion-fill": value > 0 && value <= 0.25,
        "ProgressBar--completion-full": value > 0.25,
      }
    )

    return (
      <div className={`ProgressBar ${className}`}>
        <div className="ProgressBar--completion-wrapper">
          {value > 0.25 ? (
            // Showing the percentage within the bar in white font
            <div
              className={completionClass}
              style={{ backgroundColor: color, width: completionWidth }}
            >
              <span className="ProgressBar--text">
                <FormattedNumber value={value} style="percent" />
              </span>
            </div>
          ) : (
            // Showing the percentage after the bar in dark font
            <div>
              <div
                className={completionClass}
                style={{
                  backgroundColor: color,
                  width: completionWidth,
                  display: "inline-block",
                }}
              >
                &nbsp;
              </div>
              <span className="ProgressBar--text">
                <FormattedNumber value={value} style="percent" />
              </span>
            </div>
          )}
        </div>
        {children}
      </div>
    )
  }
}
