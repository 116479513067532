import { QueryStringParams } from "@OneOnOnes/types/url"
import { buildUrl } from "../url"
import settings from "../../settings"
import { joinPath } from "../path"

const getIsJsonContentType = (contentType: string | null) => {
  if (!contentType) return false

  return !!contentType.split(";").find((t) => {
    const type = t.trim().toLowerCase()
    return type === "application/json"
  })
}

type ResponseErrorType = {
  status: number
}

interface APIErrorResponse {
  data: {
    error: string
    error_id: number
  }
}

export class ResponseError extends Error {
  constructor(
    message: string,
    public response: ResponseErrorType & APIErrorResponse
  ) {
    super(message)
    this.name = "ResponseError"
    this.response = response
  }
}

interface PlainResponse<T> {
  data: T
  status: number
  statusText: string
  headers: Headers
  redirected: boolean
  url: string
}

type ErrorWithResponse<T> = Error & { response: PlainResponse<T> }

export const isErrorWithResponse = <T>(
  error: unknown
): error is ErrorWithResponse<T> => {
  const knownError = error as ErrorWithResponse<T>
  const { response } = knownError
  return !(
    response === undefined ||
    response.data === undefined ||
    typeof response.status !== "number" ||
    typeof response.statusText !== "string" ||
    typeof response.redirected !== "boolean" ||
    typeof response.url !== "string" ||
    !(response.headers instanceof Headers)
  )
}

const isAPIErrorResponse = (
  response: unknown
): response is APIErrorResponse => {
  if (
    typeof (response as APIErrorResponse).data.error !== "string" ||
    typeof (response as APIErrorResponse).data.error_id !== "number"
  ) {
    return false
  }
  return true
}

/**
 * Takes a response object, and returns a plain old javascript object, with the
 * same values.
 */
const adaptResponse = async <T>(
  response: Response
): Promise<PlainResponse<T>> => {
  const contentType = response.headers.get("content-type")
  const isJsonResp = getIsJsonContentType(contentType)

  return {
    data: await (isJsonResp ? response.json() : response.text()),
    status: response.status,
    statusText: response.statusText,
    headers: response.headers,
    redirected: response.redirected,
    url: response.url,
  }
}

type FetchInit = InitBodyFetch & {
  // For simplicity, only accept a plain old javascript object for the headers.
  // No need for this special Headers class.
  headers?: Record<string, string>
}
/**
 * Wraps the `fetch` call, into a promise that automatically calls `.json` or
 * `.text`, and throws an exception if the !response.ok.
 * ie. it abstracts away the lower level functionality that we don't normally
 * need.
 */
const doFetch = async <T>(
  url: string,
  init: FetchInit
): Promise<PlainResponse<T>> => {
  const response = await fetch(url, {
    ...init,
    credentials: "same-origin",
    headers:
      init.method === "GET"
        ? init.headers
        : {
            "Content-Type": "application/json",
            ...init.headers,
          },
    body: JSON.stringify(init.body),
  })

  if (response.ok) {
    return await adaptResponse(response)
  }

  if (response.status === 401) {
    const redirectUrl = encodeURIComponent(window.location.toString())
    const signInUrl = `/session/sign_in?redirect=${redirectUrl}`
    window.location.replace(signInUrl)
    return new Promise((_) => {
      /* We do nothing here so that the requestor remains in a paused state
      while the browser gets around to redirecting ot the sign in flow. */
    })
  }

  throw Object.assign(
    new Error(response.statusText || `Error status: ${response.status}`),
    {
      response: await adaptResponse(response),
    }
  )
}

type Init = Omit<RequestInit, "method" | "url" | "body" | "headers"> & {
  params?: QueryStringParams
  // For simplicity, only accept a plain old javascript object for the headers.
  // No need for this special Headers class.
  headers?: Record<string, string>
}

type InitWithBody = Init & {
  body?: string | object
}

interface InitBodyFetch extends InitWithBody {
  method: string
}

export const perfApiFetch = async <T>(
  url: string,
  init: InitBodyFetch,
  basePath = settings.STEADYFOOT_URL
) => {
  try {
    // This adds the ability to pass in express-style pattern matching into the
    // url. It is recommended to pass parameters this way, rather than using
    // template strings, otherwise the url could be subject to bugs with
    // strings not getting properly escaped. Even if you're just passing down a
    // number, it's good to keep the consistency, as so you'll never forget.
    // eg. instead of:
    //   perfApiGet(`/foo/${bar}?hello=${hello}`)
    // do:
    //   perfApiGet("/foo/:bar", { bar, hello })
    let newUrl = init.params ? buildUrl(url, init.params) : url
    newUrl = joinPath(basePath, newUrl)
    const { params, ...initWithoutParams } = init
    return await doFetch<T>(newUrl, initWithoutParams)
  } catch (ex) {
    // Sample error response from performance-api
    // { "error": "Friendly message (sometimes)", "error_id": 123 }
    if (
      isErrorWithResponse(ex) &&
      isAPIErrorResponse(ex.response) &&
      ex.response.data.error
    ) {
      throw new ResponseError(ex.response.data.error, ex.response)
    }
    throw ex
  }
}

export const perfApiGet = async <T>(url: string, init?: Init) => {
  return await perfApiFetch<T>(url, {
    ...init,
    method: "GET",
  })
}

export const perfApiPost = async <T>(url: string, init?: InitWithBody) => {
  return await perfApiFetch<T>(url, {
    ...init,
    method: "POST",
  })
}

export const perfApiPut = async <T>(url: string, init?: InitWithBody) => {
  return await perfApiFetch<T>(url, {
    ...init,
    method: "PUT",
  })
}

export const perfApiDelete = async <T>(url: string, init?: InitWithBody) => {
  return await perfApiFetch<T>(url, {
    ...init,
    method: "DELETE",
  })
}
