import * as React from "react"
import { useState } from "react"
import { Tag } from "@kaizen/draft-tag"
import { Paragraph } from "@kaizen/typography"
import { usePopover } from "@kaizen/draft-popover"
import { injectIntl, IntlShape } from "react-intl"
import strings from "@Locale/strings"
import useGoalDossier from "../../domainHooks/goals/useGoalDossier"
import styles from "./GoalDetailsReadOnlyMessage.scss"

interface GoalDetailsReadOnlyMessageProps {
  intl: IntlShape
}

const GoalDetailsReadOnlyMessage = ({
  intl,
}: GoalDetailsReadOnlyMessageProps) => {
  const { formatMessage } = intl
  const [popoverReferenceElementRef, Popover] = usePopover()
  const [isPopoverVisible, setIsPopoverVisible] = useState(false)
  const [hasPopoverDisplayed, setHasPopoverDisplayed] = useState(false)
  const { isOpen: isGoalDossierVisible } = useGoalDossier()

  if (isGoalDossierVisible && !hasPopoverDisplayed) {
    setTimeout(() => setIsPopoverVisible(true), 1000)
    setTimeout(() => setIsPopoverVisible(false), 5000)
    setHasPopoverDisplayed(true)
  }

  return (
    <>
      <button
        className={styles.readOnlyButton}
        onClick={() => setIsPopoverVisible(true)}
        ref={popoverReferenceElementRef}
        type="button"
      >
        <Tag size="medium" variant="validationInformative">
          {formatMessage(
            strings.goalsPage.goalsDossier.readOnlyMessage.readOnly
          )}
        </Tag>
      </button>
      {isPopoverVisible && (
        <Popover
          dismissible
          heading={formatMessage(
            strings.goalsPage.goalsDossier.readOnlyMessage.popoverHeading
          )}
          onClose={() => setIsPopoverVisible(false)}
          placement="bottom"
          variant="informative"
        >
          <Paragraph variant="small">
            {formatMessage(
              strings.goalsPage.goalsDossier.readOnlyMessage
                .popoverDescriptionStart
            )}{" "}
            <a href="/app/develop">
              {formatMessage(
                strings.goalsPage.goalsDossier.readOnlyMessage
                  .popoverDescriptionLink
              )}
            </a>
            .
          </Paragraph>
        </Popover>
      )}
    </>
  )
}

export default injectIntl(GoalDetailsReadOnlyMessage)
