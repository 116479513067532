/**
 * DEPRECATED: we are no longer using decoders to validate server responses.
 * Please do not use them in new code.
 *
 * See src/api/decoders/README.md for more info.
 */
import { JsonDecoder } from "ts.data.json"
import { TeamMember, Team } from "../../../types/reduxState/teams"
const { array, failover, object, string, number, nullable } = JsonDecoder

const teamMemberDecoder = object<TeamMember>(
  {
    id: number,
    aggregateId: string,
    name: string,
    role: failover("", string),
    profileImage: failover("", string),
    avatar: object({ thumb_url: string }, "avatar").map((a) => a.thumb_url),
    membershipId: string,
    email: string,
  },
  "teamMember",
  {
    name: "best_name",
    aggregateId: "member_id",
    id: "user_id",
    role: "job_title",
    membershipId: "team_membership_id",
    profileImage: "profile_image_url",
    avatar: "avatar_images",
  }
)

export const teamDecoder = object(
  {
    team: object(
      {
        team_id: string,
        name: string,
        description: string,
        team_leads: array<TeamMember>(teamMemberDecoder, "teamLead"),
        team_members: array<TeamMember>(teamMemberDecoder, "teamMember"),
        archived_at: nullable(string),
      },
      "team"
    ),
  },
  "teamDetail"
).map<Team>(({ team }) => ({
  id: team.team_id,
  name: team.name,
  description: team.description,
  details: {
    teamLeads: team.team_leads,
    teamMembers: team.team_members,
    archivedAt: team.archived_at,
  },
}))

export default teamDecoder
