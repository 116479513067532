import React from "react"
import { Route } from "react-router-dom"

const Development = React.lazy(
  () =>
    import(
      // @ts-expect-error - We are importing a .js file that doesn't have a declaration
      /* webpackChunkName: "DevelopmentResources" */ "@DevelopmentResources/entry/Development/Development"
    )
)

export const DevelopmentResourcesRoutes = (
  <Route path="/development" component={Development} />
)
