import { forEach } from "lodash"

type TranslationDataValue =
  | string
  | string[]
  | TranslationData
  | TranslationData[]
type TranslationData = { [key: string]: TranslationDataValue }
export type Translations = { [key: string]: string | null }
type CustomTerms = {
  [termKey: string]: string
}

function addMessageTranslations(
  messages: Translations,
  id: string,
  data: TranslationDataValue
) {
  if (!(data && typeof data === "object")) {
    messages[id] = data !== null && data !== undefined ? String(data) : null
  } else {
    forEach(data, (value, key) =>
      addMessageTranslations(
        messages,
        id ? `${id}-${key}` : key || "",
        value as TranslationDataValue
      )
    )
  }
}

// We are transitioning from customTerms having names like "skills|lowercase" to "skills__lowercase".
// This change will improve the support of ICU formatted translation strings with our translation provider.
// Until we update all usage and migrate the database, we are supporting both formats side by side.
const termsInBothFormats = (terms: CustomTerms): CustomTerms =>
  Object.keys(terms).reduce((newTerms, termKey) => {
    const [search, replace] =
      termKey.indexOf("|") === -1 ? ["__", "|"] : ["|", "__"]
    const termKeyWithReplacement = termKey.replace(search, replace)
    return {
      ...newTerms,
      [termKey]: terms[termKey],
      [termKeyWithReplacement]: terms[termKey],
    }
  }, {})

export function convertToMessageTranslations(
  data: TranslationData
): Translations {
  const messages: Translations = {}
  addMessageTranslations(messages, "", data)
  return messages
}

export function replaceCustomTerms(
  translations: Translations,
  customTerms: CustomTerms
): Translations {
  const customTermPairs = Object.entries(termsInBothFormats(customTerms))
  return Object.fromEntries(
    Object.entries(translations).map(([key, translation]) => {
      if (translation === null) {
        return [key, translation]
      }
      if (translation.indexOf("{") === -1) {
        return [key, translation]
      }
      const replaced = customTermPairs.reduce((text, [term, replacement]) => {
        return text.replaceAll(`{${term}}`, replacement)
      }, translation)
      return [key, replaced]
    })
  )
}
