import { Input, FieldMessage, Label } from "@kaizen/draft-form"
import { Button } from "@kaizen/button"
import moment, { Moment } from "moment"
import React, { useCallback, useMemo, useRef, useState, useEffect } from "react"
import { IntlShape, injectIntl } from "react-intl"
import { Box, Icon } from "@kaizen/component-library"
import exclamationIcon from "@kaizen/component-library/icons/exclamation.icon.svg"
import dateIcon from "@kaizen/component-library/icons/date-start.icon.svg"
import dateRangeIcon from "@kaizen/component-library/icons/date-range.icon.svg"
import Calendar from "./primitives/Calendar"
import styles from "./styles.scss"
import strings from "../../locale/strings"
import Aid from "../../constants/automationId"
import { usePopover } from "../Popover"

export type DatePickerProps = {
  id: string
  allowDateRange?: boolean
  initialDate?: Date[]
  onChange: (dates: Date[]) => void
  label?: string
  intl: IntlShape
  minimumDate?: Date
  isDisabled?: boolean
  automationId?: Aid
  showError?: boolean
  setFocusOnElement?: boolean
  validationMessage?: string
  onBlur?: (event?: React.FocusEvent<HTMLElement> | undefined) => void
  onFocus?: (event?: React.FocusEvent<HTMLElement> | undefined) => void
  reversed?: boolean
}

type DatePicker = React.FunctionComponent<DatePickerProps>

const DatePicker: DatePicker = ({
  id,
  allowDateRange = false,
  onChange,
  label,
  initialDate = [],
  intl,
  minimumDate,
  isDisabled,
  automationId,
  showError,
  setFocusOnElement,
  validationMessage,
  onBlur,
  onFocus,
  reversed,
}) => {
  const { formatMessage } = intl
  const [calendarOpened, setCalendarOpened] = useState(false)
  const containerRef = useRef<HTMLDivElement>(null)
  const calendarRef = useRef<HTMLDivElement>(null)
  const [selectedDates, setSelectedDates] = useState<Date[]>(initialDate)
  const [savedDates, setSavedDates] = useState<Date[]>(initialDate)
  const dates = selectedDates.map((date) => moment(date))
  const inputRef = useRef<HTMLInputElement>(null)

  const handleClose = useCallback(() => {
    setCalendarOpened(false)
    allowDateRange && setSelectedDates(savedDates)
  }, [setCalendarOpened, setSelectedDates, allowDateRange, savedDates])

  useEffect(() => {
    if (showError) {
      inputRef.current?.focus()
    }
  }, [showError, setFocusOnElement])

  const datesString = useMemo(() => {
    const format = "ll"
    const startDateString = dates[0] && dates[0].format(format)

    const endDateString =
      (dates[1] && dates[1].format(format)) ||
      (dates[0] && formatMessage(strings.filters.datePicker.endDate))
    return allowDateRange
      ? startDateString && `${startDateString} - ${endDateString}`
      : startDateString
  }, [dates, allowDateRange, formatMessage])

  const handleDateChange = useCallback(
    (dates: Moment[]) => {
      if (dates.length === 0) {
        return
      }

      setSelectedDates(
        dates.sort((a, b) => (a.isBefore(b) ? -1 : 1)).map((m) => m.toDate())
      )
      !allowDateRange && onChange(dates.map((m) => m.toDate()))
    },
    [setSelectedDates, allowDateRange, onChange]
  )

  const [referenceElementRef, Popover] = usePopover()

  return (
    // Disabled for now, as a quick fix. We will have a kaizen
    // calendar available soon, so no point fixing this up.
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div
      className={styles.container}
      ref={containerRef}
      onClick={() => {
        !isDisabled && setCalendarOpened(true)
      }}
      data-automation-id={automationId}
    >
      <Label htmlFor={id} reversed={reversed}>
        {label}
      </Label>
      <div>
        <Box>
          <Input
            id={id}
            inputType="text"
            inputRef={inputRef}
            inputValue={
              datesString ||
              (allowDateRange
                ? formatMessage(strings.filters.datePicker.placeholder)
                : formatMessage(strings.datePicker.placeholder))
            }
            disabled={isDisabled}
            onBlur={onBlur}
            onFocus={onFocus}
            status={showError || validationMessage ? "error" : "default"}
            startIconAdornment={
              allowDateRange ? (
                <Icon icon={dateRangeIcon} role="presentation" />
              ) : (
                <Icon icon={dateIcon} role="presentation" />
              )
            }
            endIconAdornment={
              showError || validationMessage ? (
                <div className={styles.error}>
                  <Icon icon={exclamationIcon} role="presentation" />
                </div>
              ) : null
            }
            onChange={() => {
              /* Do nothing. Effectively sets readonly=true on the field, which
               * is what we want, but Input doesn't support this. Without this
               * handler, React emits a console warning about the field being
               * read-only. */
            }}
          />
          <div ref={referenceElementRef}></div>
          {(showError || validationMessage) && (
            <FieldMessage
              id={`${id}-field-message`}
              automationId={`${id}-field-validation-message`}
              message={
                validationMessage ||
                formatMessage(strings.datePicker.startDateErrorMessage)
              }
              status={"error"}
            />
          )}
        </Box>
      </div>
      <Popover
        classNameOverride={styles.popover}
        placement="bottom"
        size="large"
        onClose={handleClose}
        isVisible={calendarOpened}
      >
        <div ref={calendarRef}>
          <Calendar
            minimumDate={minimumDate}
            selectedDates={dates}
            onChange={handleDateChange}
            allowDateRange={allowDateRange}
          />
        </div>
        <div className={styles.actions}>
          <Button
            label={formatMessage(strings.filters.datePicker.clear)}
            onClick={(e: React.MouseEvent) => {
              e.stopPropagation()
              setCalendarOpened(false)
              setSelectedDates([])
              setSavedDates([])
              onChange([])
            }}
            disabled={selectedDates.length === 0}
            secondary
          />
          {allowDateRange && (
            <Button
              label={formatMessage(strings.filters.datePicker.save)}
              onClick={(e: React.MouseEvent) => {
                e.stopPropagation()
                setCalendarOpened(false)
                selectedDates[1].setHours(23, 59, 59, 999)
                setSavedDates(selectedDates)
                onChange(selectedDates)
              }}
              disabled={selectedDates.length < 2}
              secondary
            />
          )}
        </div>
      </Popover>
    </div>
  )
}

export default injectIntl(DatePicker)
