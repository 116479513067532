import React from "react"

interface GoalTreeState {
  expandedNodes: string[]
  updateExpandedNodes: (newExpandedNodes: string[]) => void
}

const GoalTreeStateContext = React.createContext<GoalTreeState | undefined>(
  undefined
)

interface GoalTreeStateProps {
  children: React.ReactNode
}

export function GoalTreeStateProvider({ children }: GoalTreeStateProps) {
  const [expandedNodes, setExpandedNodes] = React.useState<string[]>([])

  const updateExpandedNodes = (newExpandedNodes: string[]) => {
    setExpandedNodes(newExpandedNodes)
  }

  return (
    <GoalTreeStateContext.Provider
      value={{ expandedNodes, updateExpandedNodes }}
    >
      {children}
    </GoalTreeStateContext.Provider>
  )
}

export function useGoalTreeState() {
  const context = React.useContext(GoalTreeStateContext)

  if (context === undefined) {
    throw new Error(
      "useGoalTreeState must be used within GoalTreeStateProvider"
    )
  }

  return context
}
