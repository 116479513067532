import Reflux from "./ActionsInitializer"
import SteadyfootAgent from "./lib/SteadyfootAgent"
import {
  addLoadingState,
  addToasts,
  extractResponseBody,
  extractResponseKey,
} from "./lib/apiActionHelpers"
import { adaptSurveyResponseSearchParams } from "./lib/surveyHelpers"
import { loadUserFilterOptions } from "./lib/userFilterHelpers"
import SearchAgent from "./lib/SearchAgent"
import SurveyResponse from "../models/SurveyResponse"
import endpoints from "../constants/endpointsDeprecated"
import { SurveyKind } from "../constants/survey"
import strings from "../locale/strings"

const {
  SURVEYS_URLS: { SURVEY_RESPONSES_URL },
  SURVEY_RESPONSE_FILTER_URLS,
  SAVED_VIEW_URLS: { SAVED_VIEW_URL },
} = endpoints
const agent = SteadyfootAgent.defaultInstance

const searchAgent = new SearchAgent({
  requestAgent: agent,
  resourcePath: SURVEY_RESPONSES_URL,
  resourceName: "survey_responses",
  pageSize: 25,
  modelWrapper: SurveyResponse.of,
})

const SurveyResponseActions = Reflux.createActions({
  createSurveyResponse: { asyncResult: true },
  searchSurveyResponses: { asyncResult: true },
  pageSurveyResponses: { asyncResult: true },
  clearSurveyResponses: {},
  loadCompletedSelfReflectionByUserId: { asyncResult: true },
  loadFilterOptions: { asyncResult: true },
  getSurveyResponse: { asyncResult: true },
  markAsRecipientSeen: { asyncResult: true },
})

SurveyResponseActions.createSurveyResponse.listenAndPromise(
  (
    surveyResponse,
    numEmployees,
    subjectFirstName,
    toast = strings.survey.MRF.createSurveyResponseSuccess
  ) =>
    addLoadingState(
      [true, { light: true }],
      addToasts(
        { defaultError: strings.toast.genericErrorMessage },
        extractResponseBody(
          agent.post(SURVEY_RESPONSES_URL).send({
            survey_response: {
              kind: surveyResponse.kind,
              subject_id: surveyResponse.subjectId,
              questions: surveyResponse.questions,
              author_ids: surveyResponse.authorIds,
              answers: surveyResponse.answers || undefined,
              shared_with_subject: surveyResponse.sharedWithSubject,
              team_id: surveyResponse.teamId,
              quick_comment: surveyResponse.quickComment,
            },
          })
        ).then((newSurveyResponse) => ({
          newSurveyResponse,
          numEmployees,
          subjectFirstName,
        }))
      )
    ).then(({ newSurveyResponse }) => {
      return newSurveyResponse
    })
)

/**
 * @param {SurveyKind} kind
 * @param {number=} surveyId
 * @param {{departmentIds, managerIds, groupTypes, jobTitleIds, userIds}=} filters
 * @param {boolean=} mine - return only current user's survey responses
 */
SurveyResponseActions.searchSurveyResponses.listenAndPromise(
  ({ kind, surveyId = null, filters = {}, mine = false, completed }) => {
    const searchParams = {
      kind: Array.isArray(kind) ? kind.join(",") : kind,
      survey_id: surveyId,
      ...adaptSurveyResponseSearchParams(filters),
    }
    if (completed !== undefined) {
      searchParams.completed = completed
    }
    return addLoadingState(
      [true, { light: true }],
      searchAgent.search({
        searchParams,
        path: mine ? "mine" : undefined,
      })
    )
  }
)

/**
 * @param {number} page
 * @param {SurveyKind} kind
 * @param {number=} surveyId
 * @param {{departmentIds, managerIds, groupTypes, jobTitleIds, userIds}=} filters
 * @param {boolean=} mine - return only current user's survey responses
 */
SurveyResponseActions.pageSurveyResponses.listenAndPromise(
  ({ page, kind, surveyId = null, filters = {}, mine = false }) =>
    searchAgent.search({
      page,
      searchParams: {
        kind,
        survey_id: surveyId,
        ...adaptSurveyResponseSearchParams(filters),
      },
      path: mine ? "mine" : undefined,
    })
)

SurveyResponseActions.loadCompletedSelfReflectionByUserId.listenAndPromise(
  ({ savedViewId = null, userId, currentUser = null }) =>
    extractResponseKey(
      "survey_responses",
      SurveyResponse.of,
      agent
        .get(
          !savedViewId
            ? currentUser && currentUser.id === userId
              ? `${SURVEY_RESPONSES_URL}/mine`
              : SURVEY_RESPONSES_URL
            : `${SAVED_VIEW_URL}/${savedViewId}/survey_responses`
        )
        .query({
          user_ids: currentUser.id === userId ? undefined : userId,
          kind: SurveyKind.SelfEval,
          completed: true,
        })
    ).then((result) => ({ selfReflections: result, userId }))
)

/**
 * @param {UserFilterType} userFilterType
 * @param {Array<number>=} surveyIds
 * @param {{departmentIds, managerIds, groupTypes, jobTitleIds}} currentFilters - current filter
 *    selections
 * @param {string=} query - textual query
 * @param {Array<number>=} ids - specific ids to return
 * @param {number=} page - Page of results to return
 */
SurveyResponseActions.loadFilterOptions.listenAndPromise(
  ({
    userFilterType,
    surveyIds,
    currentFilters,
    query,
    ids,
    page = 1,
    distinct_by = "title,parent_job_title_id",
  }) =>
    loadUserFilterOptions({
      urls: SURVEY_RESPONSE_FILTER_URLS,
      userFilterType,
      cycleIds: surveyIds,
      cycleParamName: "survey_ids",
      currentFilters,
      query,
      ids,
      page,
      distinct_by,
    })
)

SurveyResponseActions.getSurveyResponse.listenAndPromise((id) =>
  extractResponseKey(
    "survey_response",
    SurveyResponse.of,
    agent.get(`${SURVEY_RESPONSES_URL}/${id}`)
  )
)

SurveyResponseActions.markAsRecipientSeen.listenAndPromise((id) =>
  extractResponseKey(
    "survey_response",
    SurveyResponse.of,
    agent
      .put(`${SURVEY_RESPONSES_URL}/${id}`)
      .send({ survey_response: { recipient_seen: true } })
  )
)

export default SurveyResponseActions
