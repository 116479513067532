import "./EmptyState.less"
import React from "react"
import { EmptyState } from "@kaizen/draft-empty-state"
import { Paragraph, Heading } from "@kaizen/typography"
import ButtonDeprecated, {
  Props as ButtonProps,
} from "../ButtonDeprecated/ButtonDeprecated"
import Aid from "../../constants/automationId"
import teamSkillSummaryImg from "../../static/images-2017/screenshots/team-skill-summary.png"

declare type IllustrationType =
  | "team-summary"
  | "positive"
  | "neutral"
  | "negative"
  | "informative"
  | "action"

type Props = {
  illustrationType: IllustrationType
  header: React.ReactNode
  body: React.ReactNode
  ctaProps?: Partial<ButtonProps>
  ctaText?: string
  automationId?: Aid
}

const legacyTypes: Map<IllustrationType, string> = new Map([
  ["team-summary", teamSkillSummaryImg],
])

const EvalsEmptyState = ({
  imageSrc,
  body,
  ctaText,
  ctaProps,
  header,
  automationId,
}: Omit<Props, "illustrationType"> & { imageSrc: string }) => (
  <div className="EmptyState" data-automation-id={automationId}>
    <div className="EmptyState--image-container">
      <img alt="" className="EmptyState--img" src={imageSrc} />
    </div>
    <div className="EmptyState--text-container">
      <Heading variant="heading-2">{header}</Heading>
      {body && <Paragraph variant="body">{body}</Paragraph>}
      {ctaText && (
        <ButtonDeprecated actionType="primary" {...ctaProps}>
          {ctaText}
        </ButtonDeprecated>
      )}
    </div>
  </div>
)

const EmptyStateWrapper = ({
  illustrationType,
  body,
  ctaText,
  ctaProps,
  header,
  automationId,
}: Props) => {
  return illustrationType === "team-summary" ? (
    <EvalsEmptyState
      imageSrc={legacyTypes.get(illustrationType) || ""}
      header={header}
      body={body}
      ctaText={ctaText}
      ctaProps={ctaProps}
      automationId={automationId}
    />
  ) : (
    <EmptyState
      headingProps={{ variant: "heading-3", children: header }}
      bodyText={body}
      illustrationType={illustrationType}
      automationId={automationId}
    >
      {ctaText && (
        <ButtonDeprecated actionType="primary" {...ctaProps}>
          {ctaText}
        </ButtonDeprecated>
      )}
    </EmptyState>
  )
}

export default EmptyStateWrapper
