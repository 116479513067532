import * as React from "react"
import { Heading } from "@kaizen/typography"
import { Moment } from "moment"
import classnames from "classnames"
import styles from "./SmartDefaultSection.scss"

export type SmartDefaultOption = {
  label: string
  values: Moment[]
}

type Props = {
  options: SmartDefaultOption[]
  optionClickHandler: (option?: SmartDefaultOption) => void
  label?: React.ReactNode | string
  customRangeLabel: string
  selectedDates?: Moment[]
}

export const SmartDefaultSection = ({
  options,
  optionClickHandler,
  label,
  customRangeLabel,
  selectedDates,
}: Props) => {
  if (!options) {
    return null
  }

  const renderLabel = (labelElement: React.ReactNode | string) => {
    if (React.isValidElement(labelElement)) {
      return <>{labelElement}</>
    }
    return (
      <div className={styles.stringLabel}>
        <Heading variant="heading-6" color="dark-reduced-opacity">
          {labelElement}
        </Heading>
      </div>
    )
  }

  const renderDateRangeOptions = (options: SmartDefaultOption[]) => {
    return options.map((option) => {
      const selected =
        selectedDates &&
        selectedDates.length > 1 &&
        selectedDates[0].isSame(option.values[0], "date") &&
        selectedDates[1].isSame(option.values[1], "date")
      return (
        <button
          key={`option-${option.label}`}
          type="button"
          className={classnames({
            [styles.button]: true,
            [styles.selected]: selected,
          })}
          onClick={(e: React.MouseEvent) => {
            e.stopPropagation()
            optionClickHandler(option)
          }}
        >
          {option.label}
        </button>
      )
    })
  }

  // If the selectedDates is not match with any of the value in options then
  // user must select the Custom date range option
  const isCustomDateRangeSelected =
    selectedDates &&
    options.filter((option) => {
      return (
        selectedDates.length > 1 &&
        selectedDates[0].isSame(option.values[0], "date") &&
        selectedDates[1].isSame(option.values[1], "date")
      )
    }).length === 0

  return (
    <div className={styles.container}>
      {label ? <div>{renderLabel(label)}</div> : null}
      {renderDateRangeOptions(options)}
      <button
        onClick={(e: React.MouseEvent) => {
          e.stopPropagation()
          optionClickHandler()
        }}
        type="button"
        className={classnames({
          [styles.button]: true,
          [styles.selected]: isCustomDateRangeSelected,
        })}
      >
        <span>{customRangeLabel}</span>
      </button>
    </div>
  )
}
