import moment from "moment"
import React from "react"
import { Card } from "@kaizen/draft-card"
import { Box, Icon } from "@kaizen/component-library"
import { Paragraph } from "@kaizen/typography"
import arrowUp from "@kaizen/component-library/icons/arrow-up.icon.svg"
import arrowDown from "@kaizen/component-library/icons/arrow-down.icon.svg"
import { Tag } from "@kaizen/draft-tag"
import { AvatarGroup } from "@Goals/components"
import { GoalForTree } from "@Goals/types"

import styles from "./TreeGoalCard.scss"

type ExpandCollapseButtonProps = {
  isExpanded: boolean
  onToggleExpanded: () => void
  CollapsedComponent: React.ReactNode
  ExpandedComponent: React.ReactNode
  automationId?: string
}

const ExpandCollapseButton = ({
  isExpanded,
  onToggleExpanded,
  CollapsedComponent,
  ExpandedComponent,
  automationId,
}: ExpandCollapseButtonProps) => {
  const label = isExpanded ? "collapse" : "expand"
  const content = isExpanded ? CollapsedComponent : ExpandedComponent

  const handleOnClick = (
    e: React.MouseEvent<HTMLButtonElement> | React.TouchEvent<HTMLButtonElement>
  ) => {
    if (!e.defaultPrevented) {
      onToggleExpanded()
    }
  }

  return (
    <button
      data-automation-id={automationId}
      className={styles.expandCollapseButton}
      type="button"
      onClick={handleOnClick}
      onTouchEnd={handleOnClick}
      aria-label={label}
    >
      {content}
    </button>
  )
}

export type TreeGoalCardProps = {
  goal: GoalForTree
  isExpanded: boolean
  onClick: (id: number) => void
  onToggleExpanded: () => void
}

export const TreeGoalCard = ({
  goal: { id, name, subTitle, dueAt, owners, status, incomingCount, uuid },
  isExpanded,
  onClick,
  onToggleExpanded,
}: TreeGoalCardProps) => {
  const showBlockedTag = status === "Blocked"

  const handleOnClick = (
    e: React.MouseEvent<HTMLButtonElement> | React.TouchEvent<HTMLButtonElement>
  ) => {
    if (!e.defaultPrevented) {
      onClick(id)
    }
  }

  return (
    <div className={styles.container}>
      <Card classNameOverride={styles.card}>
        <div className={styles.bodyContainer}>
          <Box mb={0.25}>
            <button
              className={styles.goalName}
              type={"button"}
              onClick={handleOnClick}
              onTouchEnd={handleOnClick}
            >
              <Paragraph variant="body">
                <strong>{name}</strong>
              </Paragraph>
            </button>
          </Box>
          <Paragraph variant="small" color="dark-reduced-opacity">
            <span className={styles.subtitle}>{subTitle}</span>
            <span>{moment(dueAt).format("MMM DD, YYYY")}</span>
          </Paragraph>
          <div className={styles.cardFooterContainer}>
            <AvatarGroup goalOwners={owners} />
            {showBlockedTag && (
              <Tag variant="validationCautionary" size={"small"} inline>
                {status}
              </Tag>
            )}
          </div>
        </div>
        {incomingCount > 0 && (
          <div className={styles.expandCollapseContainer}>
            <ExpandCollapseButton
              automationId={`expand-collapse-button-${uuid}`}
              isExpanded={isExpanded}
              onToggleExpanded={onToggleExpanded}
              CollapsedComponent={<Icon icon={arrowUp} role={"presentation"} />}
              ExpandedComponent={
                <div className={styles.arrowDown}>
                  <Icon icon={arrowDown} role={"presentation"} />
                </div>
              }
            />
            <div className={styles.incomingCount}>
              <Paragraph variant="extra-small">
                {incomingCount > 99 ? "99+" : incomingCount}
              </Paragraph>
            </div>
          </div>
        )}
      </Card>
    </div>
  )
}
