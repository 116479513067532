import { SupremeAgentRequest } from "@Utils/supremeagent"
// @ts-expect-error no declaration file
import promiseFinally from "../../utils/promiseFinally"
// @ts-expect-error no declaration file
import UIActions from "../UIActions"

/**
 * Utility for ensuring that at most one superagent request is running at a time.
 */
export default class RequestSupplanter {
  _ongoingRequest: SupremeAgentRequest | null = null

  /**
   * Sets `request` as the current request, canceling the previous request, if
   * any.
   *
   * @param {SupremeAgentRequest} request - the request to adopt
   * @return a promise that will resolve when the given request succeeds and
   *    the RequestSupplanter cleans up its tracking of the request.
   */
  replaceRequest(request: SupremeAgentRequest) {
    if (this._ongoingRequest) {
      this._ongoingRequest.abort()
      this._ongoingRequest = null
      UIActions.setLoadingState(false)
    }

    this._ongoingRequest = request

    return promiseFinally(request, () => {
      this._ongoingRequest = null
    })
  }
}
