import React, { forwardRef, memo, Ref, useCallback } from "react"
import cx from "classnames"
import styles from "./styles.scss"

type Props = React.HTMLAttributes<HTMLDivElement> & {
  onClick?: (e: React.MouseEvent) => void
  disabled?: boolean
  onKeyPress?: (e: React.KeyboardEvent) => void
  className?: string
  focusStyling?: "rectangle" | "rounded" | "none"
}

/**
 * Renders a `div` element, but with the behaviours of a `button`.
 * Avoid using this component when unnecessary - there's no guarantee that
 * I've thought of every possible edge case. The preference will always be to
 * simply use a `button` element (or a `Link`/`a`nchor if it links to a route).
 *
 * The only use case that I know of for using this component is when you would like to
 * display an element with `display: table-row`, but would also like to make it
 * clickable. For some reason buttons don't play well as being table rows.
 * See [here](https://codepen.io/jeremy8883/pen/OJMEYoQ) for an example.
 *
 * Note that this component will replicate button behaviour only, the
 * hover cursor, and the focus outline. If you'd like to role out your
 * own focus state, set `focusStyling="none"`.
 */
const ButtonizedDiv = (
  {
    onClick,
    onKeyPress,
    className,
    // Using "rectangle" as the default, because this component will typically be used
    // for table rows, where a rounded focus state wouldn't look too clean.
    focusStyling = "rectangle",
    disabled = false,
    ...rest
  }: Props,
  ref: Ref<HTMLDivElement>
) => {
  const handleSelected = useCallback(
    (e) => {
      !disabled && onClick && onClick(e)
    },
    [onClick, disabled]
  )
  // A regular `button` element would do a `onClick` callback
  // when pressing "enter". Here we replicate this behaviour.
  // Note that I have intentionally chosen to use "key press", not
  // "key down". This is because the "click" happens right after the
  // "key press". ie. the order of events is "key press", "key down", "click"
  const handleKeyPress = useCallback(
    (e) => {
      if (onKeyPress) {
        onKeyPress(e)
      }

      if (!e.defaultPrevented && e.charCode === 13) {
        handleSelected(e)
      }
    },
    [handleSelected, onKeyPress]
  )

  return (
    <div
      onKeyPress={handleKeyPress}
      role="button"
      onClick={handleSelected}
      tabIndex={0}
      ref={ref}
      className={cx(
        styles.root,
        focusStyling === "rounded" && styles.focusRounded,
        focusStyling === "rectangle" && styles.focusRectangle,
        className
      )}
      {...rest}
    />
  )
}

export default memo(forwardRef(ButtonizedDiv))
