import * as React from "react"
import { createContext, useContext, useReducer } from "react"
import { CurrentUser } from "@Types/User"
import { getPaginationState } from "@Redux/reducers/shared/pagination"
import { TeamsState, Team } from "@Types/reduxState/teams"
import { TeamsAction } from "@Types/reduxActions/teams"

const INITIAL_STATE: TeamsState = {
  teamsById: {},
  myTeams: {
    pages: {},
    totalCount: 0,
    totalPages: 0,
  },
  allTeams: {
    pages: {},
    totalCount: 0,
    totalPages: 0,
  },
  myTeamsSearchResults: {
    pages: {},
    totalCount: 0,
    totalPages: 0,
  },
  allTeamsSearchResults: {
    pages: {},
    totalCount: 0,
    totalPages: 0,
  },
}

export const teamsReducer = (state: TeamsState, action: TeamsAction) => {
  switch (action.type) {
    case "FETCH_MY_TEAMS": {
      const teamsById: Record<string, Team> = {}
      const myTeams: string[] = []
      const { teams, pagination } = action.payload
      teams.map((team) => {
        teamsById[team.id] = team
        myTeams.push(team.id)
      })
      return {
        ...state,
        teamsById: {
          ...state.teamsById,
          ...teamsById,
        },
        myTeams: getPaginationState(state.myTeams, pagination, myTeams),
      }
    }

    case "FETCH_ALL_TEAMS": {
      const teamsById: Record<string, Team> = {}
      const allTeams: string[] = []
      const { teams, pagination } = action.payload
      teams.map((team) => {
        teamsById[team.id] = team
        allTeams.push(team.id)
      })
      return {
        ...state,
        teamsById: {
          ...state.teamsById,
          ...teamsById,
        },
        allTeams: getPaginationState(state.allTeams, pagination, allTeams),
      }
    }

    case "FETCH_ALL_TEAMS_SEARCH": {
      const teamsById: Record<string, Team> = {}
      const searchResults: string[] = []
      const { teams, pagination } = action.payload
      teams.map((team) => {
        teamsById[team.id] = team
        searchResults.push(team.id)
      })
      return {
        ...state,
        teamsById: {
          ...state.teamsById,
          ...teamsById,
        },
        allTeamsSearchResults: getPaginationState(
          state.allTeamsSearchResults,
          pagination,
          searchResults
        ),
      }
    }

    case "FETCH_MY_TEAMS_SEARCH": {
      const teamsById: Record<string, Team> = {}
      const searchResults: string[] = []
      const { teams, pagination } = action.payload
      teams.map((team) => {
        teamsById[team.id] = team
        searchResults.push(team.id)
      })
      return {
        ...state,
        teamsById: {
          ...state.teamsById,
          ...teamsById,
        },
        myTeamsSearchResults: getPaginationState(
          state.myTeamsSearchResults,
          pagination,
          searchResults
        ),
      }
    }

    case "FETCH_TEAM": {
      const team = action.payload
      return {
        ...state,
        selectedTeam: team,
      }
    }
  }
}

export const TeamsContext = createContext<{
  state: TeamsState
  dispatch: React.Dispatch<TeamsAction>
}>({
  state: INITIAL_STATE,
  dispatch: (state) => state,
})

export const TeamsProvider = ({ children }: { children: React.ReactNode }) => {
  const teamsContext = useContext(TeamsContext)
  const [state, dispatch] = useReducer(teamsReducer, teamsContext.state)

  return (
    <TeamsContext.Provider value={{ state, dispatch }}>
      {children}
    </TeamsContext.Provider>
  )
}

export const userBelongsToTeam = (user: CurrentUser, team: Team) =>
  team.details &&
  [...team.details.teamMembers, ...team.details.teamLeads].some(
    (m) => m.aggregateId === user.employee_aggregate_id
  )
