import { useCallback, useContext, useEffect } from "react"
import { logGoalDeleted } from "@Goals/analytics"
import { GoalsContext } from "../../reduxReducers/goals"
import useDataAPI from "../performanceApi/useDataAPI"

const useDeleteGoal = (goalId: number) => {
  const deleteAPI = useDataAPI(`/goals/${goalId}`, "DELETE")
  const { state, dispatch } = useContext(GoalsContext)

  const deleteGoal = useCallback(async () => {
    await deleteAPI.fire()
    logGoalDeleted({
      goalID: `${goalId}`,
    })
  }, [deleteAPI, goalId])

  useEffect(() => {
    if (deleteAPI.data) {
      const goal = state.goalsById[goalId]
      if (goal) {
        dispatch({ type: "DELETE", payload: goal })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteAPI.data])

  return {
    deleteGoal,
    loading: deleteAPI.loading,
    success: !!deleteAPI.data && !deleteAPI.error,
  }
}

export default useDeleteGoal
