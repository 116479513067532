import Reflux from "reflux-core"
import ContactActions from "../refluxActions/ContactActions"

const ContactStore = Reflux.createStore({
  // Use explicit "init" notation instead of "listenables" to
  // catch Action naming errors
  init: function () {
    this.listenTo(ContactActions.loadTop.completed, this.onLoadCompleted)
  },

  getInitialState: function () {
    return {}
  },

  onLoadCompleted: function (resp) {
    this.trigger(resp)
  },
})

export default ContactStore
