import React from "react"
import R from "@Constants/routes"
import Loader from "@Components/Loader/Loader"
import { joinPath } from "@Utils/path"
import { useHistory } from "react-router-dom"

export const RedirectTo403: React.FunctionComponent = () => {
  const history = useHistory()
  if (history) {
    history.replace({ pathname: R.error403 })
  } else {
    window.location.replace(joinPath("/performance", R.error403))
  }

  return <Loader />
}
