import Reflux from "reflux-core"
import SurveyResponseActions from "../refluxActions/SurveyResponseActions"
import IncompleteSurveyResponseActions from "../refluxActions/IncompleteSurveyResponseActions"
import BaseIncompleteSurveyResponseStore from "./BaseIncompleteSurveyResponseStore"
import { SurveyKind } from "../constants/survey"

const IncompleteSurveyResponseStore = Reflux.createStore({
  ...BaseIncompleteSurveyResponseStore,

  init() {
    BaseIncompleteSurveyResponseStore.init.call(this, {
      actions: IncompleteSurveyResponseActions,
    })

    this.data = {
      ...this.data,
      incompleteMRF: null,
    }

    this.listenTo(
      SurveyResponseActions.searchSurveyResponses.completed,
      this.onSearchSurveys
    )
    this.listenTo(
      IncompleteSurveyResponseActions.submitSurveyResponse.completed,
      this.onSurveyFinished
    )
    this.listenTo(
      IncompleteSurveyResponseActions.rejectIncompleteSurveyResponse.completed,
      this.onSurveyFinished
    )
  },

  onSearchSurveys({ results: surveyResponses }) {
    this.data = {
      ...this.data,
      incompleteMRF: [
        ...surveyResponses.filter((survey) =>
          new Set([
            SurveyKind.MRF,
            SurveyKind.UpwardFeedback,
            SurveyKind.PeerFeedback,
          ]).has(survey.kind)
        ),
      ],
    }

    this.trigger(this.data)
  },

  onSurveyFinished(surveyResponse) {
    this.data = {
      ...this.data,
      incompleteMRF: this.data.incompleteMRF.filter(
        (survey) => survey.id !== surveyResponse.id
      ),
    }

    this.trigger(this.data)
  },
})

export default IncompleteSurveyResponseStore
