import React, { useMemo } from "react"
import { Paragraph } from "@kaizen/typography"
import { ShowIf } from "@Common/components/ShowIf"
import Aid from "@Constants/automationId"
import styles from "./ProgressSlider.scss"

export type ProgressSliderProps = {
  progress: number
  min: number
  max: number
  onProgressChange: (progress: number) => void
  showSpokes?: boolean
  showThumbValue?: boolean
  thumbValueUnits?: string
}

export const ProgressSlider = ({
  progress,
  min = 0,
  max = 100,
  showSpokes = true,
  showThumbValue = false,
  thumbValueUnits = "",
  onProgressChange,
}: ProgressSliderProps) => {
  const progressPercentage = useMemo(
    () => ((progress - min) / (max - min)) * 100,
    [max, min, progress]
  )

  const progressBarWidth = useMemo(() => {
    return `${progressPercentage}%`
  }, [progressPercentage])

  const thumbValueLeftPosition = useMemo(() => {
    // To get the value vertically centered with the center of the thumb,
    // we need to apply an offset proportional to the width of the thumb.
    // 34px is the thumb width including the border, as defined in the
    // SCSS file.

    // At a progress of 0, half the thumb width needs to be added.
    // At a progress of 50, we don't need any offset.
    // At a progress of 100, half the thumb width needs to be subtracted.
    const thumbWidthWithBorder = 34
    const offset = ((50 - progressPercentage) / 50) * (thumbWidthWithBorder / 2)

    return `calc(${progressPercentage}% + ${offset}px)`
  }, [progressPercentage])

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onProgressChange(Number(e.target.value))
  }

  return (
    <div
      className={styles.container}
      data-automation-id={Aid.goalsProgressSlider}
    >
      <ShowIf condition={!!showThumbValue}>
        <div className={styles.thumbValueContainer}>
          <div
            className={styles.thumbValue}
            style={{ left: thumbValueLeftPosition }}
          >
            <Paragraph variant={"extra-small"}>
              {progress}
              {thumbValueUnits}
            </Paragraph>
          </div>
        </div>
      </ShowIf>
      <div className={styles.pseudoTrackContainer}>
        <div className={styles.pseudoTrack} />
        <div
          className={styles.progressBar}
          style={{ width: progressBarWidth }}
        />
      </div>
      <div className={styles.rangeInputContainer}>
        <input
          className={styles.rangeInput}
          type="range"
          onChange={handleOnChange}
          min={min}
          value={progress}
          max={max}
          aria-label={"progress-slider"}
          aria-valuenow={progress}
          aria-valuemin={min}
          aria-valuemax={max}
        />
      </div>
      <ShowIf condition={!!showSpokes}>
        <div className={styles.spokes}>
          <div className={styles.spokeContainer}>
            <div className={styles.spoke} />
            <Paragraph variant="small" color="dark-reduced-opacity" tag="span">
              {min}
            </Paragraph>
          </div>
          <div className={styles.spokeContainer}>
            <div className={styles.spoke} />
            <Paragraph variant="small" color="dark-reduced-opacity" tag="span">
              {max}
            </Paragraph>
          </div>
        </div>
      </ShowIf>
    </div>
  )
}
