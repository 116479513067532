import Reflux from "reflux-core"
import _ from "lodash"
import PerformanceReviewActions from "../refluxActions/PerformanceReviewActions"
import BaseCycleStore from "./lib/BaseCycleStore"

const PerformanceReviewStore = Reflux.createStore(
  _.assign({}, BaseCycleStore, {
    init() {
      BaseCycleStore.init.call(this, { actions: PerformanceReviewActions })

      this.data = {
        ...this.data,
        collaboratorReview: null,
        pastReviewsByUserId: {},
        sharedReviews: null,
        currentSharedPerformanceReview: null,
        savingState: false,
      }

      this.listenTo(
        PerformanceReviewActions.getTeamReviews.completed,
        this.onGetTeamReviews
      )

      this.listenTo(
        PerformanceReviewActions.getCycles.completed,
        this.setCycles
      )

      this.listenTo(
        PerformanceReviewActions.getCycle.completed,
        this.addOrReplaceCycle
      )

      this.listenTo(
        PerformanceReviewActions.getLocalisedCycle.completed,
        this.addOrReplaceCycle
      )

      this.listenTo(
        PerformanceReviewActions.getReview.completed,
        this.onGetReview
      )

      this.listenTo(
        PerformanceReviewActions.updateReview.completed,
        this.onGetReview
      )
      this.listenTo(
        PerformanceReviewActions.shareReviewInCycle.completed,
        this.onShareReview
      )
      this.listenTo(
        PerformanceReviewActions.setNextReview,
        this.onSetNextReview
      )
      this.listenTo(
        PerformanceReviewActions.addBucketAssignment.completed,
        this.onAddBucketAssignment
      )

      this.listenTo(
        PerformanceReviewActions.notifyAnswerEditing,
        this.onAnswerEdit
      )
      this.listenTo(
        PerformanceReviewActions.updateAnswer.completed,
        this.onUpdateAnswer.bind(this, "saved")
      )
      this.listenTo(
        PerformanceReviewActions.updateAnswer.failed,
        this.onUpdateAnswer.bind(this, "failed")
      )

      // The next three listeneres handle editing and locking of sidebar
      // for evaluations.
      this.listenTo(PerformanceReviewActions.notifyAnswerEditing, () => {
        this.data = {
          ...this.data,
          savingState: true,
        }
        this.trigger(this.data)
      })
      this.listenTo(PerformanceReviewActions.updateAnswer.completed, () => {
        this.data = {
          ...this.data,
          savingState: false,
        }
        this.trigger(this.data)
      })
      this.listenTo(PerformanceReviewActions.updateAnswer.failed, () => {
        this.data = {
          ...this.data,
          savingState: false,
        }
        this.trigger(this.data)
      })

      this.listenTo(PerformanceReviewActions.clearReviews, this.clearReviews)

      this.listenTo(
        PerformanceReviewActions.getPastReviewsByUserId.completed,
        ({ results, clientMeta: { userId } }) => {
          this.data = {
            ...this.data,
            pastReviewsByUserId: {
              ...this.data.pastReviewsByUserId,
              [userId]: results,
            },
          }

          this.trigger(this.data)
        }
      )

      this.listenTo(
        PerformanceReviewActions.loadSharedReviews.completed,
        ({ shared_performance_reviews: sharedReviews }) => {
          this.data = {
            ...this.data,
            sharedReviews,
          }
          this.trigger(this.data)
        }
      )

      this.listenTo(
        PerformanceReviewActions.loadSharedReviewById.completed,
        (review) => {
          this.data = {
            ...this.data,
            currentSharedPerformanceReview: review,
          }
          this.trigger(this.data)
        }
      )

      this.listenTo(
        PerformanceReviewActions.getCollaboratorReview.completed,
        (review) => {
          this.data = {
            ...this.data,
            collaboratorReview: review,
          }
          this.trigger(this.data)
        }
      )
    },

    onGetReview(updatedReview) {
      this.data = {
        ...this.data,
        pastReviewsByUserId: _.mapValues(
          this.data.pastReviewsByUserId,
          (reviews) =>
            reviews.map((review) =>
              // Past reviews have additional fields, so merge in the updates rather than replace
              review.id === updatedReview.id
                ? review.with(updatedReview)
                : review
            )
        ),
      }

      BaseCycleStore.onGetReview.call(this, updatedReview)
    },
  })
)

export default PerformanceReviewStore
