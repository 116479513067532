import React from "react"
import { ErrorBoundary } from "react-error-boundary"
import { sendErrorToDatadog } from "@Common/utils/datadog"

type ErrorBoundaryProps = ConstructorParameters<typeof ErrorBoundary>[0]

function onError(
  original: ErrorBoundaryProps["onError"]
): NonNullable<ErrorBoundaryProps["onError"]> {
  return (error, context) => {
    if (original) {
      original(error, context)
    }
    sendErrorToDatadog(error, context)
  }
}

const ErrorBoundaryWithLogging: React.FunctionComponent<ErrorBoundaryProps> = (
  props
) => {
  const wrappedProps = {
    ...props,
    onError: onError(props.onError),
  }
  return <ErrorBoundary {...wrappedProps} />
}

export default ErrorBoundaryWithLogging
