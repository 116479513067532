import React, { CSSProperties, FunctionComponent } from "react"
import "./Loader.less"
import Icon from "../Icon/Icon"
import Aid from "../../constants/automationId"

const Loader: FunctionComponent<{
  className?: string
  style?: CSSProperties
}> = ({ style, className }) => (
  <div
    className={`Loader ${className}`}
    style={style}
    data-automation-id={Aid.loader}
  >
    <Icon iconName="spinner" inheritSize={true} />
  </div>
)

export default Loader
