import Reflux from "reflux-core"
import _ from "lodash"
import moment from "moment"
import { List, Map, Record } from "immutable"
import GoalActions from "../refluxActions/GoalActions"
import Company from "../models/Company"
import observeSearchActions from "./lib/observeSearchActions"
import UIActions from "../refluxActions/UIActions"
import strings from "../locale/strings"

/**
 * A GoalSource identifies a unique entity that has goals, for instance, a particular department
 * or a particular user. It can be identified by the goal type and the id of that entity.
 */
export const GoalSource = Record({ goalType: "my_goal", sourceId: -1 })

export const sourceForGoal = (goal) => {
  return GoalSource({
    goalType: goal.goal_type,
    sourceId:
      goal.goal_type === "company_goal"
        ? Company.CURRENT_COMPANY_ID
        : goal.department_id || goal.user_id || null,
  })
}

export default Reflux.createStore({
  init() {
    this.data = this.getInitialState()

    this.listenTo(
      GoalActions.list.completed,
      ({ goals, departmentId, userId, couldIncludeCompanyGoals }) => {
        this.setGoalsForSources({
          goals,
          sources: _.compact([
            userId && GoalSource({ goalType: "my_goal", sourceId: userId }),

            departmentId &&
              GoalSource({
                goalType: "department_goal",
                sourceId: departmentId,
              }),

            couldIncludeCompanyGoals &&
              GoalSource({
                goalType: "company_goal",
                sourceId: Company.CURRENT_COMPANY_ID,
              }),
          ]),
        })
        this.trigger(this.data)
      }
    )

    this.listenTo(GoalActions.listOwned.completed, ({ goals, userId }) => {
      if (!goals || !userId) {
        UIActions.error(strings.goals.errorMessages.loadingGoals)
        throw new Error(
          "invalid payload for GoalActions.listOwned.completed action"
        )
      }

      this.data = {
        ...this.data,
        goalsByOwner: this.data.goalsByOwner.set(userId, List(goals)),
      }
      this.trigger(this.data)
    })

    this.listenTo(GoalActions.load.completed, (updatedGoal) => {
      this.updateGoal(updatedGoal)
    })

    this.listenTo(GoalActions.loadAccomplishedGoals.completed, ({ goals }) => {
      this.data = {
        ...this.data,
        accomplishedGoals: goals,
      }
      this.trigger(this.data)
    })

    observeSearchActions(this, {
      getResults: () => this.data.teamGoalSearchResults,
      searchAction: GoalActions.searchTeamGoals,
      pageAction: GoalActions.pageTeamGoals,
      onChange: (teamGoalSearchResults, { pagination, stats }) => {
        this.data = {
          ...this.data,
          teamGoalSearchResults,
          teamGoalPagination: pagination,
          teamGoalStats: stats,
        }

        this.trigger(this.data)
      },
    })

    this.listenTo(GoalActions.create.completed, ({ newGoal, targetRange }) => {
      if (
        targetRange &&
        _.isArray(targetRange) &&
        moment(newGoal.due_at).isBetween(targetRange[0], targetRange[1])
      ) {
        this.data = {
          ...this.data,
          goalsBySource: this.data.goalsBySource.update(
            sourceForGoal(newGoal),
            (goals) =>
              goals && goals.push(newGoal).sortBy(({ due_at: dueAt }) => dueAt)
          ),
        }

        this.trigger(this.data)
      }
    })

    this.listenTo(GoalActions.update.completed, (updatedGoal) => {
      this.updateGoal(updatedGoal)
    })

    this.listenTo(GoalActions.markAsManagerSeen.completed, (updatedGoal) => {
      this.updateGoal(updatedGoal)
    })

    this.listenTo(GoalActions.delete.completed, (deletedGoal) => {
      this.data = {
        ...this.data,
        goalsBySource: this.data.goalsBySource.update(
          sourceForGoal(deletedGoal),
          (goals) => goals.filterNot((g) => g.id === deletedGoal.id)
        ),
      }

      this.trigger(this.data)
    })

    this.listenTo(GoalActions.setLastSelectedDeptId, (lastSelectedDeptId) => {
      this.data = {
        ...this.data,
        lastSelectedDeptId,
      }

      this.trigger(this.data)
    })

    this.listenTo(GoalActions.clearGoals, () => {
      const initialState = this.getInitialState()
      this.data = {
        ...this.data,
        goalsBySource: initialState.goalsBySource,
        goalsByOwner: initialState.goalsByOwner,
      }
      this.trigger(this.data)
    })
  },

  initializeState() {
    this.data = this.getInitialState()
  },

  getInitialState() {
    return {
      goalsBySource: Map(), // { GoalSource -> List<Goal> }
      goalsByOwner: Map(), // { userId -> List<Goal> }
      accomplishedGoals: null,
      teamGoalSearchResults: null,
      teamGoalPagination: null,
      lastSelectedDeptId: null,
    }
  },

  /**
   * @param {Array<Object>} goals - the list of goals to set in goalsBySource. Should contain all
   *                                the goals for the goal sources listed in `sources`.
   *
   * @param {Array<GoalSource>} sources - the sources represented in the `goals` array. If a given
   *                                      source has no goals in the `goals` array, it will be
   *                                      understood that this source has no goals (an empty array
   *                                      will be assigned to it in the goalsBySource Map).
   */
  setGoalsForSources({ goals, sources }) {
    const newGoalsBySourceEntries = sources.map((source) => [
      source,
      List(
        // Here, we're doing two things.
        // 1. Taking the goals, and grouping them up into tuples.
        // 2. Filter out and ensure ensure that the goal itself should be visible
        //    to that user, whether it be because they created the goal, or they
        //    are part of the team. No company goals get filtered out, of course.
        //    I am unsure why we are filtering this data in the frontend, and not in
        //    perf-api.
        goals.filter((g) => {
          const goalAsSource = sourceForGoal(g)
          if (source.get("goalType") !== goalAsSource.get("goalType")) {
            return false
          }

          return (
            // The "sourceId" would be either the creator id for individual goals,
            // the department id for team goals, or -1 for company goals (I think).
            source.get("sourceId") === goalAsSource.get("sourceId") ||
            // We recently allowed the ability to include multiple
            // goal owners to individual goals. The existing `sourceId` is singular, hence,
            // it does not work for us. So we need to check the goal_owners value as well.
            (g.goal_owners &&
              g.goal_owners.find((go) => go.id === source.get("sourceId")))
          )
        })
      ),
    ])

    this.data = {
      ...this.data,
      goalsBySource: this.data.goalsBySource.merge(newGoalsBySourceEntries),
    }
  },

  updateGoal(updatedGoal) {
    const { goalsBySource, teamGoalSearchResults } = this.data

    this.data = {
      ...this.data,
      goalsBySource:
        goalsBySource &&
        goalsBySource.update(sourceForGoal(updatedGoal), (goals) => {
          const goalIndex = goals
            ? goals.findIndex((g) => g.id === updatedGoal.id)
            : -1
          return goalIndex > -1
            ? goals.set(goalIndex, updatedGoal)
            : goals
            ? goals.push(updatedGoal)
            : List.of(updatedGoal)
        }),
      teamGoalSearchResults:
        teamGoalSearchResults &&
        teamGoalSearchResults.map((goal) =>
          goal.id === updatedGoal.id ? updatedGoal : goal
        ),
    }

    this.trigger(this.data)
  },
})
