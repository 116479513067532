/**
 * DEPRECATED: we are no longer using decoders to validate server responses.
 * Please do not use them in new code.
 *
 * See src/api/decoders/README.md for more info.
 */
import { JsonDecoder } from "ts.data.json"
import { TeamGoal } from "../../../types/Goals"
import {
  outgoingAlignedGoalDecoder,
  incomingAlignedGoalsCountDecoder,
} from "../AlignedGoalDecoder/alignedGoalDecoder"
import {
  accessPermissionsDecoder,
  goalKeyResultDecoder,
  goalPriorityDecoder,
} from "../BaseGoalDecoder/baseGoalDecoder"
import { goalOwnerDecoder } from "../TeamGoalPreviewDecoder/teamGoalPreviewDecoder"

export const teamGoalDecoder: JsonDecoder.Decoder<TeamGoal> =
  JsonDecoder.object<TeamGoal>(
    {
      id: JsonDecoder.number,
      name: JsonDecoder.string,
      completion: JsonDecoder.number,
      commentsCount: JsonDecoder.number,
      dueDate: JsonDecoder.string.map((dateString) => new Date(dateString)),
      priority: goalPriorityDecoder,
      type: JsonDecoder.isExactly("team_goal").map((): "team" => "team"),
      owners: JsonDecoder.failover(
        [],
        JsonDecoder.array(goalOwnerDecoder, "goalOwners")
      ),
      teamId: JsonDecoder.failover("", JsonDecoder.string),
      teamName: JsonDecoder.failover("", JsonDecoder.string),
      description: JsonDecoder.failover(undefined, JsonDecoder.string),
      status: JsonDecoder.string,
      keyResults: JsonDecoder.failover(
        [],
        JsonDecoder.array(goalKeyResultDecoder, "keyResults")
      ),
      visibility: JsonDecoder.string,
      incomingAlignedGoalsCount: JsonDecoder.failover(
        {},
        incomingAlignedGoalsCountDecoder
      ),
      outgoingAlignedGoals: JsonDecoder.failover(
        [],
        JsonDecoder.array(outgoingAlignedGoalDecoder, "outgoingAlignedGoals")
      ),
      outgoingAlignmentAssociations: JsonDecoder.failover(
        undefined,
        JsonDecoder.array(
          JsonDecoder.object<{
            id: number
            alignedGoalId: number
          }>(
            {
              id: JsonDecoder.number,
              alignedGoalId: JsonDecoder.number,
            },
            "outgoing alignment association",
            {
              alignedGoalId: "incoming_alignment_goal_id",
            }
          ),
          "outgoing alignment associations "
        )
      ),
      editLevel: JsonDecoder.failover(undefined, JsonDecoder.string),
      access_permissions: accessPermissionsDecoder,
    },
    "teamGoal",
    {
      commentsCount: "number_of_comments",
      dueDate: "due_at",
      priority: "priority",
      type: "goal_type",
      owners: "goal_owners",
      teamId: "team_id",
      teamName: "team_name",
      keyResults: "key_results",
      outgoingAlignedGoals: "outgoing_aligned_goals",
      incomingAlignedGoalsCount: "incoming_aligned_goals_counts",
      outgoingAlignmentAssociations: "outgoing_alignment_associations",
      editLevel: "edit_level",
    }
  )
