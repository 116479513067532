import React, { createContext, useEffect } from "react"
import * as Sentry from "@sentry/browser"
import { EmptyState } from "@kaizen/draft-empty-state"
import { setUserProperty } from "@cultureamp/amplitude"
import { useCurrentPerformanceUser } from "@Common/api/user.queries"
import { sendErrorToDatadog } from "@Common/utils"
import { createIntl, createIntlCache, RawIntlProvider } from "react-intl"
import R from "@Constants/routes"
import Loading from "../components/Loading/Loading"
import { CurrentUser } from "../types/User"
import FeatureFlags from "../constants/featureFlags"
import strings from "../locale/strings"

type Flags = Partial<{ [key in FeatureFlags]: boolean }>

const setUserFeatureFlagsInAmplitude = (flags: Flags) => {
  const enabledFlags = Object.values(FeatureFlags).filter(
    (value) => flags[value]
  )
  setUserProperty("User feature flag enabled", enabledFlags)
}

export const CurrentUserContext = createContext<CurrentUser | null | undefined>(
  undefined
)
const intlCache = createIntlCache()

export const CurrentUserProvider: React.FunctionComponent = ({ children }) => {
  const { data, isLoading, isError, error } = useCurrentPerformanceUser()
  useEffect(() => {
    if (data) {
      setUserFeatureFlagsInAmplitude({
        ...data.flags,
      })
    }
  }, [data])

  if (isError) {
    const intl = createIntl(
      {
        locale: "en",
      },
      intlCache
    )

    // Add a tag for the component so that we can alert on this failure
    sendErrorToDatadog(error, { component: "CurrentUserProvider" })
    Sentry.captureException(error)
    return (
      // This uses the default english strings as we have not loaded the user
      // profile and hence the locale data at this point.
      <RawIntlProvider value={intl}>
        <EmptyState
          illustrationType="negative"
          headingProps={{
            variant: "heading-3",
            children: strings.errorPage.header.defaultMessage,
          }}
          bodyText={intl.formatMessage(strings.errorPage.body, {
            Link: (chunks: React.ReactNode) => <a href={R.home}>{chunks}</a>,
            lineBreak: <br />,
          })}
        />
      </RawIntlProvider>
    )
  }

  return isLoading || data === undefined ? (
    <Loading />
  ) : (
    <CurrentUserContext.Provider value={data}>
      {children}
    </CurrentUserContext.Provider>
  )
}
