import { Icon } from "@kaizen/component-library"
import { Paragraph } from "@kaizen/typography"
import chevronRightIcon from "@kaizen/component-library/icons/chevron-right.icon.svg"
import * as React from "react"
import { injectIntl, IntlShape } from "react-intl"
import { OutgoingAlignedGoal } from "../../types/Goals"
import strings from "../../locale/strings"
import styles from "./AlignedGoalsSection.scss"

type AlignedGoalsSection = {
  alignedGoals: OutgoingAlignedGoal[]
  goalType: "team" | "department" | "company"
  onGoalClick: (goalId: number) => void
  intl: IntlShape
}

const AlignedGoalsSection = ({
  alignedGoals,
  goalType,
  onGoalClick,
  intl,
}: AlignedGoalsSection) => {
  const { formatMessage } = intl

  return (
    <div className={styles.outgoingGoalAlignments}>
      <Paragraph variant="small">
        <strong>
          {formatMessage(
            strings.goalsPage.goalsDossier.goalAlignments[goalType]
          )}
        </strong>
      </Paragraph>
      {alignedGoals.map((goal) => (
        <button
          type="button"
          className={styles.alignedGoal}
          key={goal.id}
          onClick={() => onGoalClick(goal.id)}
        >
          <div>
            <Paragraph variant="small">
              <strong>
                {goalType === "team"
                  ? goal.teamName.concat(": ")
                  : goalType === "department"
                  ? goal.departmentName.concat(": ")
                  : ""}
              </strong>
              {goal.name}
            </Paragraph>
          </div>
          <Icon icon={chevronRightIcon} role="presentation" />
        </button>
      ))}
    </div>
  )
}

export default injectIntl(AlignedGoalsSection)
