/**
 * DEPRECATED: we are no longer using decoders to validate server responses.
 * Please do not use them in new code.
 *
 * See src/api/decoders/README.md for more info.
 */
import { JsonDecoder } from "ts.data.json"
import { CompanyGoalPreview, CompanyGoal } from "../../../types/Goals"
import {
  accessPermissionsDecoder,
  goalKeyResultDecoder,
  goalPriorityDecoder,
} from "../BaseGoalDecoder/baseGoalDecoder"
import { goalOwnerDecoder } from "../TeamGoalPreviewDecoder/teamGoalPreviewDecoder"
import {
  outgoingAlignedGoalDecoder,
  incomingAlignedGoalsCountDecoder,
} from "../AlignedGoalDecoder/alignedGoalDecoder"

export const companyGoalDecoder: JsonDecoder.Decoder<CompanyGoal> =
  JsonDecoder.object<CompanyGoal>(
    {
      id: JsonDecoder.number,
      name: JsonDecoder.string,
      completion: JsonDecoder.number,
      commentsCount: JsonDecoder.number,
      dueDate: JsonDecoder.string.map((dateString) => new Date(dateString)),
      priority: goalPriorityDecoder,
      type: JsonDecoder.isExactly("company_goal").map(
        (): "company" => "company"
      ),
      owners: JsonDecoder.failover(
        [],
        JsonDecoder.array(goalOwnerDecoder, "goalOwners")
      ),
      description: JsonDecoder.failover(undefined, JsonDecoder.string),
      status: JsonDecoder.string,
      keyResults: JsonDecoder.failover(
        [],
        JsonDecoder.array(goalKeyResultDecoder, "keyResults")
      ),
      visibility: JsonDecoder.string,
      incomingAlignedGoalsCount: JsonDecoder.failover(
        {},
        incomingAlignedGoalsCountDecoder
      ),
      outgoingAlignedGoals: JsonDecoder.failover(
        [],
        JsonDecoder.array(outgoingAlignedGoalDecoder, "outgoingAlignedGoals")
      ),
      outgoingAlignmentAssociations: JsonDecoder.failover(
        undefined,
        JsonDecoder.array(
          JsonDecoder.object<{
            id: number
            alignedGoalId: number
          }>(
            {
              id: JsonDecoder.number,
              alignedGoalId: JsonDecoder.number,
            },
            "outgoing alignment association",
            {
              alignedGoalId: "incoming_alignment_goal_id",
            }
          ),
          "outgoing alignment associations "
        )
      ),
      editLevel: JsonDecoder.failover(undefined, JsonDecoder.string),
      access_permissions: accessPermissionsDecoder,
    },
    "companyGoal",
    {
      commentsCount: "number_of_comments",
      dueDate: "due_at",
      priority: "priority",
      type: "goal_type",
      owners: "goal_owners",
      keyResults: "key_results",
      outgoingAlignedGoals: "outgoing_aligned_goals",
      incomingAlignedGoalsCount: "incoming_aligned_goals_counts",
      outgoingAlignmentAssociations: "outgoing_alignment_associations",
      editLevel: "edit_level",
    }
  )

export const companyGoalPreviewDecoder: JsonDecoder.Decoder<CompanyGoalPreview> =
  JsonDecoder.object<CompanyGoalPreview>(
    {
      owners: JsonDecoder.array(goalOwnerDecoder, "owners"),
      type: JsonDecoder.isExactly("company_goal").map(
        (): "company" => "company"
      ),
      id: JsonDecoder.number,
      name: JsonDecoder.string,
      completion: JsonDecoder.number,
      commentsCount: JsonDecoder.number,
      dueDate: JsonDecoder.string.map((dateString) => new Date(dateString)),
      priority: goalPriorityDecoder,
      status: JsonDecoder.string,
      outgoingAlignedGoals: JsonDecoder.failover(
        [],
        JsonDecoder.array(outgoingAlignedGoalDecoder, "outgoingAlignedGoals")
      ),
      keyResults: JsonDecoder.failover(
        [],
        JsonDecoder.array(goalKeyResultDecoder, "key_results")
      ),
    },
    "companyGoalPreview",
    {
      commentsCount: "number_of_comments",
      dueDate: "due_at",
      priority: "priority",
      type: "goal_type",
      owners: "goal_owners",
    }
  )

type ResponseType = {
  goals: CompanyGoalPreview[]
}

export const getCompanyGoalsResponseDecoder = JsonDecoder.object(
  {
    company_goals: JsonDecoder.array(
      JsonDecoder.object<ResponseType>(
        {
          goals: JsonDecoder.array(companyGoalPreviewDecoder, "companyGoals"),
        },
        "companyGoal"
      ),
      "companyGoals"
    ),
  },
  "getCompanyGoalsResponse"
).map(({ company_goals }) =>
  company_goals[0] ? company_goals[0] : { goals: [] }
)
