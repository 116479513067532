import moment from "moment"
import strings from "../locale/strings"
import { Department, CurrentUser } from "../types/User"
import FeatureFlags from "../constants/featureFlags"
import { ConfigurationOptions } from "../constants/configurationOptions"

/**
 * DEPRECATED
 * Please use the pure functions found under domain/user.ts
 */

export default class User {
  static getDisplayName(user: {
    best_name?: string
    full_name?: string
    email?: string
  }) {
    return user.best_name || user.full_name || user.email
  }

  static getFirstName(
    user: { best_name?: string; full_name?: string; email?: string },
    { emailOk = true } = {}
  ) {
    return (
      user.best_name ||
      (user.full_name && user.full_name.trim().split(" ")[0]) ||
      (emailOk ? user.email : null)
    )
  }

  static getFullName(user: CurrentUser) {
    return user.full_name || user.email
  }

  // https://zugata.atlassian.net/browse/ZUG-8015
  // This should just be job_title_name after SF addresses the issue
  static getJobTitle(user: CurrentUser) {
    return (
      user.job_title_name ||
      (typeof user.job_title === "string" ? user.job_title : null)
    )
  }

  static getDomain(user: CurrentUser) {
    return user.email?.split("@")[1]
  }

  static isAdmin(user: CurrentUser | null) {
    return Boolean(
      user && user.role_names && user.role_names.indexOf("admin") > -1
    )
  }

  static isHrbp(user: CurrentUser | null) {
    return Boolean(
      user && user.hr_business_partners && user.hr_business_partners.length > 0
    )
  }

  static isDeptManager(user: CurrentUser) {
    return user.department_managers
      ? user.department_managers.length > 0
      : false
  }

  static managesDepartment(user: CurrentUser, department: Department) {
    const { department_managers: departmentManagers } = user

    return (
      !!departmentManagers &&
      departmentManagers.some(
        (departmentManager) => departmentManager.department_id === department.id
      )
    )
  }

  static equals(a: CurrentUser, b: CurrentUser) {
    return a.id === b.id
  }

  static isManagerOf(manager: CurrentUser, user: CurrentUser) {
    return user.manager_email && user.manager_email === manager.email
  }

  static getDisplayAccountType(user: CurrentUser) {
    return User.isAdmin(user)
      ? strings.general.admin
      : User.isHrbp(user)
      ? strings.general.hrbp
      : User.isDeptManager(user)
      ? strings.general.deptManager
      : strings.general.employee
  }

  static isDeactivated(
    user: Pick<CurrentUser, "deleted_at" | "deactivated_at">
  ) {
    return !!user.deleted_at || moment().isAfter(user.deactivated_at || "")
  }

  /**
   * Note: CurrentUser and CurrentUserDeprecated both have flags.
   * Probably would be to convert these static methods
   * to functions in order to save on transpilation cost.
   *
   * More info: docs/featureFlags.md
   *
   * @static
   * @param {(CurrentUser | CurrentUserDeprecated)} user
   * @param {FeatureFlags} flag
   * @returns {Boolean} User has requested flag
   * @memberof User
   */
  static hasFlag(user: CurrentUser, flag: FeatureFlags) {
    return Boolean(user?.flags?.[flag])
  }

  static hasConfigOption(user: CurrentUser, option: ConfigurationOptions) {
    return Boolean(
      user?.configuration_options_obj?.map((o) => o.name).includes(option)
    )
  }
}
