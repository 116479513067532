import * as React from "react"
import { hot } from "react-hot-loader/root"
import { Route, Switch, withRouter } from "react-router-dom"
import { PrivateRoute } from "../../common/routing"
import { SurveyResponsesProvider } from "../../reduxReducers/survey"
import { ConfigurationOptions } from "../../constants/configurationOptions"

const IncompleteTBFPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "tbf" */ "../../entry/IncompleteTBFPage/IncompleteTBFPage"
    )
)

const TeamFeedbackRequest = React.lazy(
  () =>
    import(
      /* webpackChunkName: "tbf" */ "../../entry/TeamFeedbackRequest/TeamFeedbackRequest"
    )
)

type SurveysResponsesRoutes = React.FunctionComponent

const SurveysResponsesRoutes: SurveysResponsesRoutes = ({ children }) => (
  <SurveyResponsesProvider>{children}</SurveyResponsesProvider>
)

export const routes = (
  <PrivateRoute
    path="/team_based_feedback"
    hasConfigurationOption={ConfigurationOptions.teamBasedFeedback}
  >
    <SurveysResponsesRoutes>
      <Switch>
        <Route
          exact
          path="/team_based_feedback"
          component={hot(withRouter(IncompleteTBFPage))}
        />
        <PrivateRoute
          path="/team_based_feedback/team_based_feedback/:teamId"
          hasConfigurationOption={ConfigurationOptions.teamBasedFeedback}
          component={hot(withRouter(TeamFeedbackRequest))}
          /* TODO - add team lead authentication*/
        />
        <PrivateRoute
          path="/team_based_feedback/:teamId"
          hasConfigurationOption={ConfigurationOptions.teamBasedFeedback}
          component={hot(withRouter(TeamFeedbackRequest))}
          /* TODO - add team lead authentication*/
        />
      </Switch>
    </SurveysResponsesRoutes>
  </PrivateRoute>
)
