import Reflux from "reflux-core"
import _ from "lodash"
import CompanyActions from "../refluxActions/CompanyActions"
import BaseEmployeeGroupingStore from "../../refluxStores/BaseEmployeeGroupingStore"

export default Reflux.createStore(
  _.assign({}, BaseEmployeeGroupingStore, {
    init() {
      BaseEmployeeGroupingStore.init.call(this)

      this.listenTo(
        CompanyActions.listEmployeeGroupings.completed,
        this.onListEmployeeGroupings
      )

      this.listenTo(CompanyActions.createEmployeeGrouping.completed, () =>
        CompanyActions.listEmployeeGroupings()
      )

      this.listenTo(
        CompanyActions.deleteEmployeeGrouping.completed,
        (employeeGrouping) => {
          const { id } = employeeGrouping
          const { departments, jobTitlesByParentId } = this.data

          this.data = {
            ...this.data,
            departments: _.reject(departments, { id }),
            jobTitlesByParentId: _.mapValues(jobTitlesByParentId, (jobTitles) =>
              _.reject(jobTitles, { id })
            ),
          }

          this.trigger(this.data)
        }
      )
    },
  })
)
