import React, { useEffect, useState } from "react"

import { parse, ParsedQuery } from "query-string"
import {
  HelpCenter,
  HelpCenterEventListener,
  SuggestedArticle,
  toggleHelpCenter,
} from "@cultureamp/help-center"
import { useLocation } from "react-router-dom"
import { getSuggestedArticles } from "./getSuggestedArticles"

export const HELP_REDIRECT_QUERY_KEY = "helpRedirect"

const isLegacyRedirect = (search: string): boolean => {
  const parsedSearchObject: ParsedQuery = parse(search)
  return (
    !!parsedSearchObject[HELP_REDIRECT_QUERY_KEY] &&
    parsedSearchObject[HELP_REDIRECT_QUERY_KEY] === "true"
  )
}

export const HelpCenterWrapper = () => {
  const [suggestedArticles, setSuggestedArticles] = useState<
    Array<SuggestedArticle>
  >([])
  const location = useLocation()

  useEffect(() => {
    if (isLegacyRedirect(location.search)) toggleHelpCenter("open")
  }, [location])

  useEffect(() => {
    getSuggestedArticles(location.pathname).then((result) =>
      setSuggestedArticles(result.suggested_articles)
    )
  }, [location.pathname, setSuggestedArticles])

  return (
    <HelpCenterEventListener>
      {(props) => (
        <HelpCenter {...props} suggestedArticles={suggestedArticles} />
      )}
    </HelpCenterEventListener>
  )
}

export default HelpCenterWrapper
