/**
 * DEPRECATED: we are no longer using decoders to validate server responses.
 * Please do not use them in new code.
 *
 * See src/api/decoders/README.md for more info.
 */
import { JsonDecoder } from "ts.data.json"
import { CurrentUser, JobTitle, Manager } from "../../../types/User"
import { Company } from "../../../types/Company"
import { ConfigurationOption } from "../../../constants/configurationOptions"
import { allowListFilter } from "../utils"

const {
  object,
  number,
  failover,
  array,
  string,
  dictionary,
  boolean,
  lazy,
  nullable,
  optional,
} = JsonDecoder

const configurationOptionsDecoder = object<ConfigurationOption>(
  {
    name: string,
    value: failover(null, string),
  },
  "Configuration Options Decoder"
)

export const departmentDecoder = object(
  {
    id: number,
    title: string,
    job_title_type: string,
  },
  "Department Decoder"
)

export const departmentManagersDecoder = object(
  {
    id: number,
    user_id: number,
    department_id: number,
    created_at: string,
    updated_at: string,
    department: failover(null, departmentDecoder),
  },
  "Department Managers Decoder"
)

export const jobTitleDecoder: JsonDecoder.Decoder<JobTitle> = object<JobTitle>(
  {
    id: number,
    title: string,
    job_title_type: string,
    job_titles_count: nullable(number),
    department_managers: failover(
      [],
      array(departmentManagersDecoder, "Jobtitle decoder department_managers")
    ),
    hr_business_partners: failover(
      [],
      array(
        lazy(() => jobTitleDecoder),
        "JobTitle decoder hr_business_partners"
      )
    ),
    parent_job_title: nullable(lazy(() => jobTitleDecoder)),
  },
  "Jobtitle decoder"
)

export const managerDecoder = object<Manager>(
  {
    ancestor_ids: failover([], array(number, "Manager decoder ancestor ids")),
    avatar_images: object(
      {
        thumb_url: failover("", string),
        small_url: failover("", string),
        medium_url: failover("", string),
        large_url: failover("", string),
      },
      "Manager decoder avatar_images"
    ),
    best_name: string,
    deactivated_at: failover(null, string),
    department_id: failover(null, number),
    department_name: failover(null, string),
    email: string,
    employee_aggregate_id: string,
    employee_id: nullable(string),

    full_name: nullable(string),

    id: number,
    in_managed_department: failover(false, boolean),
    is_managed: failover(false, boolean),
    job_title_id: failover(null, number),
    job_title_name: failover(null, string),
    manager_avatar: nullable(
      object(
        {
          thumb_url: failover("", string),
          small_url: failover("", string),
          medium_url: failover("", string),
          large_url: failover("", string),
        },
        "Manager decoder avatar_images"
      )
    ),
    manager_email: nullable(string),
    manager_id: nullable(number),
    manager_name: nullable(string),
    manager_profile_image: nullable(string),
    on_team: failover(false, boolean),
    preferred_name: failover(null, string),
    profile_image_url: string,
    zuid: string,
  },
  "Manager decoder"
)

export const companyDecoder = object<Company>(
  {
    avatar_images: object(
      {
        thumb_url: failover("", string),
        small_url: failover("", string),
        medium_url: failover("", string),
        large_url: failover("", string),
      },
      "Company decoder avatar_images"
    ),
    account_id: string,
    named_reviewer: boolean,
    anonymous_avatar_url: failover("", string),
    admin_emails: failover([], array(string, "Company decoder admin_emails")),
    up_vote_name: string,
    down_vote_name: string,
    goals_included: failover(
      [],
      array(string, "Company decoder goals_included")
    ),
    allow_development_resources: boolean,
    allow_everyone_to_see_department_goals: failover(false, boolean),
    allow_goals: boolean,
    allow_new_goals_interface: boolean,
    authentication_integrations: failover(
      [],
      array(string, "Company decoder authentication_integrations")
    ),
    allow_peer_feedback_invites: boolean,
    allow_performance_cycles: boolean,
    allow_praise: boolean,
    allow_reviews: boolean,
    allow_self_reflections: boolean,
    goal_cycle_day: number,
    goal_cycle_length: number,
    goal_cycle_month: number,
    allow_top_nav: optional(boolean), // Here until we discard from BE
    id: number,
    name: failover("", string),
    subdomain: failover("", string),
  },
  "Current User Decoder Company decoder"
)

const userDecoder: JsonDecoder.Decoder<CurrentUser> = object<CurrentUser>(
  {
    id: number,
    zuid: string,
    preferred_name: failover(null, string),
    full_name: nullable(string),
    employee_id: nullable(string),
    hris_id: failover(null, number),
    flags: failover({}, dictionary(boolean, "feature flags")),
    launchdarkly_flags: JsonDecoder.succeed,
    email: string,
    employee_aggregate_id: string,
    best_name: string,
    is_a_manager: failover(false, boolean),
    is_masquerading: failover(false, boolean),
    job_title_name: nullable(string),
    avatar_images: object(
      {
        thumb_url: string,
        small_url: string,
        medium_url: string,
        large_url: string,
      },
      "currentUserDecoder avatar_images"
    ),
    manager_id: nullable(number),
    manager_email: nullable(string),
    manager_name: nullable(string),
    time_zone: nullable(string),
    review_stats: object(
      {
        given_count: number,
        received_count: number,
      },
      "Current User Decoder review stats"
    ),
    job_title_options: failover(
      [],
      array(string, "Current User Decoder job title options")
    ),
    role_names: allowListFilter([
      "admin",
      "staff",
      "super_staff",
      "department_manager",
      "hr_business_partner",
      "data_export",
    ]),
    pending_notifications_count: failover(0, number),
    has_performance_cycles: boolean,
    can_email_contacts: boolean,
    is_managed_by_import: boolean,
    show_my_summary_at: nullable(string),
    intercom_key: nullable(string),
    country: nullable(string),
    location: nullable(string),
    locale: failover("en", string),
    ancestor_ids: failover(
      [],
      array(number, "Current User Decoder ancestor ids")
    ),
    profile_image_url: string,
    devices: failover([], array(string, "Current User Decoder devices")),
    deleted_at: failover(null, string),
    deactivated_at: failover(null, string),
    company: companyDecoder,
    custom_terms: dictionary(string, "Current User Decoder custom_terms"),
    job_title: nullable(jobTitleDecoder),
    hr_business_partners: failover(
      [],
      array(
        departmentManagersDecoder,
        "Current User Decoder hr_business_partners"
      )
    ),
    department_managers: failover(
      [],
      array(
        departmentManagersDecoder,
        "Current User Decoder department_managers"
      )
    ),
    configuration_options_obj: failover(
      [],
      array(
        configurationOptionsDecoder,
        "Current User Decoder configuration_options_objects"
      )
    ),
    manager: nullable(managerDecoder),
  },
  "currentUserDecoder user"
)

const currentUserDecoder = object(
  {
    user: userDecoder,
  },
  "currentUserDecoder"
).map<CurrentUser>(({ user }) => {
  return user
})

export default currentUserDecoder
