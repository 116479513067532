import Reflux from "reflux-core"
import _ from "lodash"
import TeamActions from "../refluxActions/TeamActions"
import observeSearchActions from "./lib/observeSearchActions"
import handleLazyLoadAction from "./lib/handleLazyLoadAction"

const TeamStore = Reflux.createStore({
  init() {
    this.data = {
      teamSearchResults: null,
      teamSearchState: null,
      teamPreviewResults: null,
      teamNumberOfUsers: null,
      teamCategories: null,
      companyCategories: null,
      teamSkillsBySkillDisplay: {},
      companySkillsBySkillDisplay: {},
      reviewersBySkill: {},
      reviewersBySkillDisplay: {},
      categoryIndex: null,
      topStrengths: null,
      topAreasToImprove: null,
      subManagers: null,
      individualStats: null,
      pagination: null,
      dateRangeId: null,
      invitesByUserId: {},
      invitesById: {},
    }

    observeSearchActions(this, {
      getResults: () => this.data.teamSearchResults,
      searchAction: TeamActions.search,
      pageAction: TeamActions.pageSearchResults,
      onChange: (teamSearchResults, teamSearchState) => {
        this.data = {
          ...this.data,
          teamSearchResults,
          teamSearchState,
        }
        this.trigger(this.data)
      },
    })

    observeSearchActions(this, {
      getResults: () => this.data.individualStats,
      searchAction: TeamActions.getIndividualStats,
      pageAction: TeamActions.pageIndividualStats,
      onChange: (individualStats, { stats, pagination, dateRangeId }) => {
        this.data = {
          ...this.data,
          individualStats,
          pagination: pagination,
          dateRangeId: dateRangeId,
          managerChartStats: stats,
        }
        this.trigger(this.data)
      },
    })

    this.listenTo(
      TeamActions.getMemberPreview.completed,
      ({ users, teamNumberOfUsers }) => {
        this.data = {
          ...this.data,
          teamPreviewResults: users,
          teamNumberOfUsers,
        }
        this.trigger(this.data)
      }
    )

    this.listenTo(TeamActions.resetMemberPreview, () => {
      this.data = {
        ...this.data,
        teamPreviewResults: null,
      }
      this.trigger(this.data)
    })

    this.listenTo(TeamActions.getMemberPreview.failed, () => {
      this.data = {
        ...this.data,
        teamPreviewResults: null,
      }
      this.trigger(this.data)
    })

    this.listenTo(
      TeamActions.getReviewersSuggestions.completed,
      ({ users }) => {
        this.data = {
          ...this.data,
          suggestions: users,
        }
        this.trigger(this.data)
      }
    )

    this.listenTo(TeamActions.getTeamSkills.completed, (skills) => {
      const sortedSkills = this.sortSkills(skills)
      this.data = {
        ...this.data,
        topStrengths: _.filter(
          sortedSkills,
          (skill) => (skill.score > 0 || skill.score === 0) && skill.sum_pos > 0
        ).slice(0, 3),
        topAreasToImprove: _.filter(
          sortedSkills,
          (skill) => (skill.score > 0 || skill.score === 0) && skill.sum_neg > 0
        ).slice(-3),
      }
      this.trigger(this.data)
    })

    this.listenTo(TeamActions.getTeamCategories.completed, (teamCategories) => {
      this.data = {
        ...this.data,
        teamCategories: this.sortScorelessSkillsToBottom(teamCategories),
        categoryIndex: this.data.categoryIndex ? 0 : null,
      }
      this.trigger(this.data)
    })

    this.listenTo(
      TeamActions.getCompanyCategories.completed,
      (companyCategories) => {
        this.data = { ...this.data, companyCategories }
        this.trigger(this.data)
      }
    )

    handleLazyLoadAction(this, TeamActions.getTeamSkillsBySkillDisplay, {
      getLocalData: (skillDisplayId) => {
        const teamSkills = this.data.teamSkillsBySkillDisplay[skillDisplayId]
        return teamSkills ? { skillDisplayId, teamSkills } : null
      },
      handleCompletion: ({ skillDisplayId, teamSkills }) => {
        this.data.teamSkillsBySkillDisplay = {
          ...this.data.teamSkillsBySkillDisplay,
          [skillDisplayId]: this.sortScorelessSkillsToBottom(teamSkills),
        }
        this.trigger(this.data)
      },
    })

    handleLazyLoadAction(this, TeamActions.getCompanySkillsBySkillDisplay, {
      getLocalData: (skillDisplayId) => {
        const companySkills =
          this.data.companySkillsBySkillDisplay[skillDisplayId]
        return companySkills ? { skillDisplayId, companySkills } : null
      },
      handleCompletion: ({ skillDisplayId, companySkills }) => {
        this.data.companySkillsBySkillDisplay = {
          ...this.data.companySkillsBySkillDisplay,
          [skillDisplayId]: companySkills,
        }
        this.trigger(this.data)
      },
    })

    this.listenTo(
      TeamActions.getReviewersBySkill.completed,
      ({ result, skillId }) => {
        this.data = {
          ...this.data,
          reviewersBySkill: {
            ...this.data.reviewersBySkill,
            [skillId]: result,
          },
        }
        this.trigger(this.data)
      }
    )

    this.listenTo(
      TeamActions.getReviewersBySkillDisplay.completed,
      ({ result, skillDisplayId }) => {
        this.data = {
          ...this.data,
          reviewersBySkillDisplay: {
            ...this.data.reviewersBySkillDisplay,
            [skillDisplayId]: result,
          },
        }
        this.trigger(this.data)
      }
    )

    this.listenTo(TeamActions.setCategoryIndex, (categoryIndex) => {
      this.data = { ...this.data, categoryIndex }
      this.trigger(this.data)
    })

    this.listenTo(TeamActions.nextCategory, () => {
      this.data = { ...this.data, categoryIndex: this.data.categoryIndex + 1 }
      this.trigger(this.data)
    })

    this.listenTo(TeamActions.previousCategory, () => {
      this.data = { ...this.data, categoryIndex: this.data.categoryIndex - 1 }
      this.trigger(this.data)
    })

    this.listenTo(TeamActions.getSubManagers.completed, ({ users }) => {
      this.data = { ...this.data, subManagers: users }
      this.trigger(this.data)
    })

    this.listenTo(TeamActions.clearTeamSkills, () => {
      this.data = { ...this.data, teamSkillsBySkillDisplay: {} }
      this.trigger(this.data)
    })

    this.listenTo(
      TeamActions.getInvitationsForUser.completed,
      ({ userId, invites }) => {
        this.data = {
          ...this.data,
          invitesByUserId: {
            ...this.data.invitesByUserId,
            userId,
            [userId]: invites.filter((q) => !q.withdrawn_at),
          },
        }
        this.trigger(this.data)
      }
    )

    this.listenTo(
      TeamActions.withdrawManagerFeedbackRequest.completed,
      ({ id, subject_id }) => {
        const {
          invitesByUserId,
          invitesByUserId: { userId },
          individualStats,
        } = this.data

        this.data = {
          ...this.data,
          invitesByUserId: {
            ...invitesByUserId,
            [userId]: invitesByUserId[invitesByUserId.userId].filter(
              (q) => q.id !== id
            ),
          },
          individualStats: individualStats.map((item) =>
            item.direct.id === subject_id
              ? {
                  ...item,
                  total_invitations_count: item.total_invitations_count - 1,
                }
              : item
          ),
        }
        this.trigger(this.data)
      }
    )

    this.listenTo(TeamActions.getInvitation.completed, (invite) => {
      this.data = {
        ...this.data,
        invitesById: {
          ...this.data.invitesById,
          [invite.id]: invite,
        },
      }
      this.trigger(this.data)
    })
  },

  getInitialState() {
    return this.data
  },

  sortSkills(skills) {
    return _.sortBy(skills, (skill) => -skill.score)
  },

  sortScorelessSkillsToBottom(skills) {
    return _.sortBy(skills, (skill, index) =>
      skill.score || skill.score === 0 ? index : Infinity
    )
  },
})

export default TeamStore
