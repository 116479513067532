// Generated by Avo VERSION 111.45.0. You should never have to make changes to this file.
// If you find yourself in the situation where you have to edit the file please contact us at hi@avo.app.
// If you encounter a git conflict in this file run `avo pull` and it will be resolved automatically.
/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

export enum AvoEnv {
  Prod = "prod",
  Dev = "dev",
}

export interface CustomDestination {
  make?(env: AvoEnv, apiKey: string): void;
  logEvent?: (eventName: string, eventProperties: object) => void;
  setUserProperties?: (userId: string, userProperties: object) => void;
  identify?: (userId: string) => void;
  unidentify?: () => void;
  logPage?: (pageName: string, eventProperties: object) => void;
  revenue?: (amount: number, eventProperties: object) => void;
  setGroupProperties?: (
    groupType: string,
    groupId: string,
    groupProperties: object,
  ) => void;
  addCurrentUserToGroup?: (
    groupType: string,
    groupId: string,
    groupProperties: object,
  ) => void;
  logEventWithGroups?: (
    eventName: string,
    eventProperties: object,
    groupTypesToGroupIds: object,
  ) => void;
}

// @ts-ignore
interface AvoAssertMessage {
  eventName?: string;
  tag?: string;
  propertyId?: string;
  message?: string;
  additionalProperties?: string[],
  shape?: object,
  shapeUserProps?: object,
  actualType?: string
}

let __AVO_ENV__: AvoEnv | null = null;
// @ts-ignore
let __AVO_NOOP__: boolean = false;
// @ts-ignore
let __AVO_LOGGER__: AvoLogger | null = null;
// @ts-ignore
let __STRICT__: boolean | null = null;
// @ts-ignore
let __REPORT_FAILURE_AS__: 'error' | 'warn' | 'log' | null = null;

// @ts-ignore
let __WEB_DEBUGGER__: boolean = true;
export const avoInspectorApiKey = "m9O4mDUeamu53RNxTXXF";
// @ts-ignore
interface AvoInspector {}
let __INSPECTOR__: AvoInspector | null = null;


interface AvoLogger {
  logDebug(env: AvoEnv | null, message: string): boolean;
  logWarn(env: AvoEnv | null, message: string): boolean;
  logError(env: AvoEnv | null, error: string): boolean;
}

enum webDebuggerArea {
  BottomRight = "BottomRight",
  BottomLeft = "BottomLeft",
  TopRight = "TopRight",
  TopLeft = "TopLeft"
}

interface bottomRightParameters {
  bottom: number;
  right: number;
}

interface bottomLeftParameters {
  bottom: number;
  left: number;
}

interface topRightParameters {
  top: number;
  right: number;
}

interface topLeftParameters {
  top: number;
  left: number;
}

interface webDebuggerPosition {
  position: webDebuggerArea;
  top?: number;
  right?: number;
  bottom?: number;
  left?: number;
}

interface webDebuggerOptions {
  position?: webDebuggerPosition;
}

interface WebDebuggerPositionSetter {
  BottomRight(p: bottomRightParameters): webDebuggerPosition;
  BottomLeft(p: bottomLeftParameters): webDebuggerPosition;
  TopRight(p: topRightParameters): webDebuggerPosition;
  TopLeft(p: topLeftParameters): webDebuggerPosition;
}

export const WebDebuggerPosition: WebDebuggerPositionSetter = {
  BottomRight: ({ bottom, right }) => ({
    position: webDebuggerArea.BottomRight,
    bottom,
    right,
  }),
  BottomLeft: ({ bottom, left }) => ({
    position: webDebuggerArea.BottomLeft,
    bottom,
    left,
  }),
  TopRight: ({ top, right }) => ({
    position: webDebuggerArea.TopRight,
    top,
    right,
  }),
  TopLeft: ({ top, left }) => ({
    position: webDebuggerArea.TopLeft,
    top,
    left,
  }),
}


let InternalAvoLogger: any = {
  logEventSent: function logEventSent(eventName: string, eventProperties: any, userProperties: any) {
    const message = "Event Sent:" + eventName + "Event Props:" + JSON.stringify(eventProperties) + "User Props:" + JSON.stringify(userProperties);

    if (__AVO_LOGGER__ && __AVO_LOGGER__.logDebug && __AVO_LOGGER__.logDebug(__AVO_ENV__, message)) {
      return
    }
    typeof console !== 'undefined' && console.log("[avo] Event Sent:", eventName, "Event Props:", eventProperties, "User Props:", userProperties);
  },

  log: function log(message: string) {
    if (__AVO_LOGGER__ && __AVO_LOGGER__.logDebug && __AVO_LOGGER__.logDebug(__AVO_ENV__, message)) {
      return
    }
    typeof console !== 'undefined' && console.log("[avo] " + message);
  },

  warn: function warn(message: string) {
    if (__AVO_LOGGER__ && __AVO_LOGGER__.logWarn && __AVO_LOGGER__.logWarn(__AVO_ENV__, message)) {
      return
    }
    typeof console !== 'undefined' && console.warn("[avo] " + message);
  },

  error: function error(message: string, error: string) {
    if (__AVO_LOGGER__ && __AVO_LOGGER__.logError && __AVO_LOGGER__.logError(__AVO_ENV__, message + error)) {
      return
    }
    typeof console !== 'undefined' && console.error("[avo] " + message, error);
  }
};

// @ts-ignore
let array_difference: any;
// @ts-ignore
let AvoAssert: any;
array_difference = function array_difference(a1: any[], a2: any[]) {
  let result: any[] = [];
  for (let i = 0; i < a1.length; i++) {
    if (a2.indexOf(a1[i]) === -1) {
      result.push(a1[i]);
    }
  }
  return result;
}

AvoAssert = {
  assertObject: function assertObject(propertyId: string, propName: string, obj: object) {
    if (typeof obj !== 'object') {
      let message = propName +
          ' should be of type object but you provided type ' +
          typeof obj +
          ' with value ' +
          JSON.stringify(obj);
      return [{tag: 'expectedObjectType', propertyId, message, actualType: typeof obj}];
    } else {
      return [];
    }
  },

  assertString: function assertString(propertyId: string, propName: string, str: string) {
    if (typeof str !== 'string') {
      let message = propName +
          ' should be of type string but you provided type ' +
          typeof str +
          ' with value ' +
          JSON.stringify(str);
      return [{tag: 'expectedStringType', propertyId, message, actualType: typeof str}];
    } else {
      return [];
    }
  },

  assertInt: function assertInt(propertyId: string, propName: string, int: number) {
    if (typeof int === 'number' && int !== Math.round(int)) {
      let message = propName +
          ' should be of type int but you provided type float with value ' +
          JSON.stringify(int);
      return [{tag: 'expectedIntType', propertyId, message, actualType: 'float'}];
    } else if (typeof int !== 'number') {
      let message = propName +
          ' should be of type int but you provided type ' +
          typeof int +
          ' with value ' +
          JSON.stringify(int);
      return [{tag: 'expectedIntType', propertyId, message, actualType: typeof int}];
    } else {
      return [];
    }
  },

  assertLong: function assertLong(propertyId: string, propName: string, long: number) {
    if (typeof long === 'number' && long !== Math.round(long)) {
      let message = propName +
          ' should be of type long but you provided type float with value ' +
          JSON.stringify(long);
      return [{tag: 'expectedLongType', propertyId, message, actualType: 'float'}];
    } else if (typeof long !== 'number') {
      let message = propName +
          ' should be of type long but you provided type ' +
          typeof long +
          ' with value ' +
          JSON.stringify(long);
      return [{tag: 'expectedLongType', propertyId, message, actualType: typeof long}];
    } else {
      return [];
    }
  },

  assertFloat: function assertFloat(propertyId: string, propName: string, float: number) {
    if (typeof float !== 'number') {
      let message = propName +
          ' should be of type float but you provided type ' +
          typeof float +
          ' with value ' +
          JSON.stringify(float);
      return [{tag: 'expectedFloatType', propertyId, message, actualType: typeof float}];
    } else {
      return [];
    }
  },

  assertBool: function assertBool(propertyId: string, propName: string, bool: boolean) {
    if (typeof bool !== 'boolean') {
      let message = propName +
          ' should be of type boolean but you provided type ' +
          typeof bool +
          ' with value ' +
          JSON.stringify(bool);
      return [{tag: 'expectedBoolType', propertyId, message, actualType: typeof bool}];
    } else {
      return [];
    }
  },

  assertMax: function assertMax(
    propertyId: string,
    propName: string,
    max: number,
    value: number
  ) {
    if (value > max) {
      let message = propName +
        ' has a maximum value of ' +
        max +
        ' but you provided the value ' +
        JSON.stringify(value);
      return [{tag: 'expectedMax', propertyId, message}];
    } else {
      return [];
    }
  },

  assertMin: function assertMin(
    propertyId: string,
    propName: string,
    min: number,
    value: number
  ) {
    if (value < min) {
      let message = propName +
        ' has a minimum value of ' +
        min +
        ' but you provided the value ' +
        JSON.stringify(value);
      return [{tag: 'expectedMin', propertyId, message}];
    } else {
      return [];
    }
  },

  assertList: function assertList(propertyId: string, propName: string, value: any) {
    if (!Array.isArray(value)) {
      let message = propName + ' should be of type list but you provided type ' + typeof value;
      return [{tag: 'expectedList', propertyId, message}];
    } else {
      return [];
    }
  },

  assertNoAdditionalProperties: function assertNoAdditionalProperties(eventName: string, input: string[], spec: string[]) {
    let additionalKeys = array_difference(input, spec);
    if (additionalKeys.length) {
      let message = "Additional properties when sending event " + eventName + ": " + JSON.stringify(additionalKeys);
      return [{tag: 'expectedNoAdditionalProperties', additionalProperties: additionalKeys, message: message}];
    } else {
      return [];
    }
  },

  assertNoAdditionalUserProperties: function assertNoAdditionalProperties(eventName: string, input: string[], spec: string[]) {
    let additionalKeys = array_difference(input, spec);
    if (additionalKeys.length) {
      let message = "Additional user properties when sending event " + eventName + ": " + JSON.stringify(additionalKeys);
      return [{tag: 'expectedNoAdditionalUserProperties', additionalProperties: additionalKeys, message: message}];
    } else {
      return [];
    }
  },
};

let _avo_invoke: any;
let _avo_invoke_meta: any;
let _avo_sampling_rate = 1.0;
_avo_invoke = function _avo_invoke(env: AvoEnv, eventId: string, hash: string, messages: {tag: string, propertyId: string}[], origin: string) {
  // @ts-ignore
  if (typeof (window as any) === 'undefined') { return; }
  if (_avo_sampling_rate > 0) {
    if (Math.random() < _avo_sampling_rate) {
      // @ts-ignore
      fetch("https://api.avo.app/i", {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify({
          "ac": "u2CYeHy32z1OyWPfqscA",
          "br": "master",
          "en": env,
          "ev": eventId,
          "ha": hash,
          "sc": "SyotC3NRBiaKYbijsQS9",
          "se": (new Date()).toISOString(),
          "so": "PMHjuFTxE",
          "va": messages.length === 0,
          "me": messages,
          "or": origin
        })
      }).then(function(res: any) { return res.json(); }).then(function(data: any) { _avo_sampling_rate = data.sa; }).catch(function() {});
    }
  }
}

_avo_invoke_meta = function _avo_invoke_meta(env: AvoEnv, type: string, messages: {tag: string, propertyId: string}[], origin: string) {
  // @ts-ignore
  if (typeof (window as any) === 'undefined') { return; }
  if (_avo_sampling_rate > 0) {
    if (Math.random() < _avo_sampling_rate) {
      // @ts-ignore
      fetch("https://api.avo.app/i", {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify({
          "ac": "u2CYeHy32z1OyWPfqscA",
          "br": "master",
          "en": env,
          "ty": type,
          "sc": "SyotC3NRBiaKYbijsQS9",
          "se": (new Date()).toISOString(),
          "so": "PMHjuFTxE",
          "va": messages.length === 0,
          "me": messages,
          "or": origin
        })
      }).then(function(res: any) { return res.json(); }).then(function(data: any) { _avo_sampling_rate = data.sa; }).catch(function() {});
    }
  }
}


let _avo_debugger_log: any;
let _avo_debugger_events_during_boot: any = [];
let _avo_debugger_ready = false;

if (typeof (window as any) !== 'undefined') {
  window.addEventListener("message", function(event) {
    if (event.origin !== "https://www.avo.app") {
      return;
    }
    let iframe: any = document.getElementById("avo-debugger");
    if (iframe && event && event.data && event.data.type_ === "avo-debugger-update-style") {
      iframe.style = event.data.style;
    } else if (iframe && event && event.data && event.data.type_ === "avo-debugger-ready") {
      let message = {
        type_: "avo-debugger-boot-events",
        schemaId: "SyotC3NRBiaKYbijsQS9",
        href: window.location.href,
        events: _avo_debugger_events_during_boot
      };
      _avo_debugger_events_during_boot = [];
      _avo_debugger_ready = true;
      iframe.contentWindow.postMessage(message, "https://www.avo.app/_debugger")
    }
  });
}

_avo_debugger_log = function _avo_debugger_log(eventId: string, eventName: string, messages: any[], eventProperties: any[], userProperties: any[]) {
  if (typeof (window as any) === 'undefined') { return; }
  let event = {
    eventId: eventId,
    eventName: eventName,
    messages: messages,
    timestamp: Date.now(),
    eventProperties,
    userProperties
  };

  if (_avo_debugger_ready) {
    let message = {type_: "avo-debugger-events", events: [event]};
    (document.getElementById("avo-debugger") as any).contentWindow.postMessage(message, "https://www.avo.app/_debugger")
  } else {
    _avo_debugger_events_during_boot.push(event);
  }
}

function _avo_debugger_send_position(position: webDebuggerPosition) {
  if (typeof window === 'undefined') { return; }
  window.addEventListener("message", function(event) {
    if (event.data.type_ === "avo-debugger-ready" && position !== null) {
      var message = {type_: "avo-debugger-position", position: position};
      document.getElementById("avo-debugger").contentWindow.postMessage(message, "https://www.avo.app/_debugger")
    }
  })
}


export const PerformanceQuestionType = {
  'ASSESSMENT_GROUP': 'Assessment group',
  'RATING': 'Rating',
  'MULTIPLE_CHOICE': 'Multiple-choice',
  'FREE_TEXT': 'Free text',
} as const;
export type PerformanceQuestionTypeType = typeof PerformanceQuestionType;
export type PerformanceQuestionTypeValueType = PerformanceQuestionTypeType[keyof PerformanceQuestionTypeType];

export const GoalPriority = {
  'HIGH': 'High',
  'LOW': 'Low',
  'MEDIUM': 'Medium',
} as const;
export type GoalPriorityType = typeof GoalPriority;
export type GoalPriorityValueType = GoalPriorityType[keyof GoalPriorityType];

export const OneOn1Role = {
  'MANAGER': 'Manager',
  'SUBJECT': 'Subject',
} as const;
export type OneOn1RoleType = typeof OneOn1Role;
export type OneOn1RoleValueType = OneOn1RoleType[keyof OneOn1RoleType];

export const Visibility = {
  'EVERYONE': 'Everyone',
  'ONLY_ME': 'Only me',
  'ONLY_MY_MANAGER': 'Only my manager',
  'SPECIFY_USERS': 'Specify users',
} as const;
export type VisibilityType = typeof Visibility;
export type VisibilityValueType = VisibilityType[keyof VisibilityType];

export const GoalType = {
  'COMPANY': 'Company',
  'DEPARTMENT': 'Department',
  'INDIVIDUAL': 'Individual',
  'TEAM': 'Team',
} as const;
export type GoalTypeType = typeof GoalType;
export type GoalTypeValueType = GoalTypeType[keyof GoalTypeType];

export const UpdateAction = {
  'ADDED': 'Added',
  'UPDATED': 'Updated',
  'DELETED': 'Deleted',
  'REORDERED': 'Reordered',
  'COLLAPSED': 'Collapsed',
  'EXPANDED': 'Expanded',
  'MARKED_COMPLETE': 'Marked complete',
} as const;
export type UpdateActionType = typeof UpdateAction;
export type UpdateActionValueType = UpdateActionType[keyof UpdateActionType];

export const Role = {
  'MANAGER': 'Manager',
  'SUBJECT': 'Subject',
} as const;
export type RoleType = typeof Role;
export type RoleValueType = RoleType[keyof RoleType];

export const IsAlignedTo = {
  'COMPANY': 'Company',
  'DEPARTMENT': 'Department',
  'TEAM': 'Team',
} as const;
export type IsAlignedToType = typeof IsAlignedTo;
export type IsAlignedToValueType = IsAlignedToType[keyof IsAlignedToType];

let AmplitudeCustom: any;

export function initAvo(options: {env: AvoEnv; webDebugger?: boolean;
  webDebuggerOptions?: webDebuggerOptions; strict?: boolean; noop?: boolean;
  reportFailureAs?: 'error' | 'warn' | 'log'; inspector?: AvoInspector;
  avoLogger?: AvoLogger}, destinationOptions: any,
  AmplitudeCustomDestination: CustomDestination) {
  if (__AVO_ENV__ !== null) {
    return;
  }
  __AVO_ENV__ = options.env;
  if (options.avoLogger !== undefined) {
    __AVO_LOGGER__ = options.avoLogger;
  }
  if (options.noop === true) {
    __AVO_NOOP__ = true;
  }
  if (__AVO_NOOP__ && __AVO_ENV__ == AvoEnv.Prod) {
    InternalAvoLogger.warn("[avo] ****************************************************");
    InternalAvoLogger.warn("[avo] WARNING Avo cannot be initialized in noop mode in production:");
    InternalAvoLogger.warn("[avo] - Overwriting configuration with noop=false.");
    InternalAvoLogger.warn("[avo] - Please reach out if you want to be able to run Avo in production mode with noop=true");
    InternalAvoLogger.warn("[avo] ****************************************************");
    __AVO_NOOP__ = false;
  }
  if (__AVO_NOOP__) {
    InternalAvoLogger.log("[avo] ****************************************************");
    InternalAvoLogger.log("[avo] Avo is now initialized in noop mode. This means:");
    InternalAvoLogger.log("[avo] - No events will be sent");
    InternalAvoLogger.log("[avo] - No network requests are made");
    InternalAvoLogger.log("[avo] ****************************************************");
  }
  if (options.strict !== undefined) {
    __STRICT__ = options.strict !== false;
  }
  if (options.reportFailureAs !== undefined) {
    __REPORT_FAILURE_AS__ = options.reportFailureAs;
  }
  __WEB_DEBUGGER__ = !__AVO_NOOP__ && ((typeof window !== 'undefined' && (window as any).location.search.indexOf("avo_debug=1") > -1) || (options.webDebugger !== false && __AVO_ENV__ !== AvoEnv.Prod));
  if (!__AVO_NOOP__ && options.inspector !== undefined) {
    __INSPECTOR__ = options.inspector;
  } else if (__AVO_ENV__ !== 'prod') {
    InternalAvoLogger.warn("[avo] Avo Inspector not provided in initAvo() call");
  }

  destinationOptions = destinationOptions || {};

  if (__WEB_DEBUGGER__ && !__AVO_NOOP__) {
    if (options.webDebuggerOptions?.position) {
      _avo_debugger_send_position(options.webDebuggerOptions.position)
    }

(function() {
  if (typeof (window as any) === 'undefined') { return; }
  let init = function() {
    let iframe: any = document.createElement("iframe");
    document.body.appendChild(iframe);
    iframe.id = "avo-debugger";
    iframe.src = "https://www.avo.app/_debugger";
    iframe.style = "display: none;";
  };

  if (document.body) {
    init();
  } else {
    document.addEventListener('DOMContentLoaded', init);
  }
})();

  }
  if (!__AVO_NOOP__) {
    if (__AVO_ENV__ === AvoEnv.Prod) {
    }
    if (__AVO_ENV__ === AvoEnv.Dev) {
    }

    AmplitudeCustom = AmplitudeCustomDestination;
    if (__AVO_ENV__ === 'prod') {
      AmplitudeCustom && AmplitudeCustom.make && AmplitudeCustom.make(__AVO_ENV__, "6cfe80b109d58db414efa2dbae828525");
    } else if (__AVO_ENV__ === 'dev') {
      AmplitudeCustom && AmplitudeCustom.make && AmplitudeCustom.make(__AVO_ENV__, "ebe34b05f57a2a074057e1c18183de28");
    } else {
      console[__REPORT_FAILURE_AS__ || 'error']("[avo] No staging key is set for Amplitude (custom). Head to destination settings in Avo to set a staging key.");
      AmplitudeCustom && AmplitudeCustom.make && AmplitudeCustom.make(__AVO_ENV__, null);
    }
    if (__AVO_ENV__ === AvoEnv.Dev) {
      // debug console in Avo
      _avo_invoke_meta(__AVO_ENV__, 'init', [], 'init');
    }
  }
}

export function setAvoLogger(avoLogger: AvoLogger | null) {
  __AVO_LOGGER__ = avoLogger;
}

export interface CalibrationViewEditedProperties {
  calibrationViewId: number;
  viewedInFullWidth: boolean;
}
/**
 * Calibration View Edited: An admin edits a calibration view and saves the changes.
 *
 * When to trigger this event:
 * 1. A user edits an existing calibration view and clicks on the 'Save changes' button.
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/events/aRdD7T5ZACCi/trigger/FL3z1D3-B
 *
 * @param properties the properties associatied with this event
 * @param properties.calibrationViewId: ID of the calibration view the event originated from.
 * @param properties.viewedInFullWidth: Is the calibration viewed in full width?
 *
 * @see {@link https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/events/aRdD7T5ZACCi}
 */
export function calibrationViewEdited(
  properties: CalibrationViewEditedProperties) {
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "aRdD7T5ZACCi", "7d5e68019b4d87472faff644ca64e4912ea10c5468fdd289fbec1397afd04f7f", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Calibration View Edited", {
      "Calibration view ID": properties.calibrationViewId,
      "Viewed in full width": properties.viewedInFullWidth,
      }, {});
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("aRdD7T5ZACCi", "Calibration View Edited", messages, [
      {id: "HmZojgQc2T", name: "Calibration view ID", value: properties.calibrationViewId},
      {id: "7mOpOcARk", name: "Viewed in full width", value: properties.viewedInFullWidth},
      ], []);
    }
  }

  // @ts-ignore
  let eventProperties: any = {};
  eventProperties["Calibration view ID"] = properties.calibrationViewId;
  eventProperties["Viewed in full width"] = properties.viewedInFullWidth;

  // @ts-ignore
  let userProperties: any = {};

  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Calibration View Edited", {
        "Calibration view ID": properties.calibrationViewId,
        "Viewed in full width": properties.viewedInFullWidth,
        }, "aRdD7T5ZACCi", "7d5e68019b4d87472faff644ca64e4912ea10c5468fdd289fbec1397afd04f7f");
    }
    // destination AmplitudeCustom
    AmplitudeCustom.logEvent("Calibration View Edited", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface CalibrationViewSharingUpdatedProperties {
  calibrationViewId: number;
  numberOfInvitations: number | null | undefined;
}
/**
 * Calibration View Sharing Updated: An admin updates the list of users that can access a particular calibration view.
 *
 * When to trigger this event:
 * 1. A user selects the 'Share calibration view' button in a calibration view, enters the users to share with in the modal, and selects the 'Share' button.
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/events/_hyFpljfOOUf/trigger/ocAmV48wp
 * 2. An admin clicks on 'Edit sharing' in a calibration view, adds or removes users to the lists, and clicks on 'Update'.
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/events/_hyFpljfOOUf/trigger/K2nW2XVaV
 *
 * @param properties the properties associatied with this event
 * @param properties.calibrationViewId: ID of the calibration view the event originated from.
 * @param properties.numberOfInvitations: The number of users that are invited to fill out a survey, view a calibration, view a report, etc. after this event.
 *
 * @see {@link https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/events/_hyFpljfOOUf}
 */
export function calibrationViewSharingUpdated(
  properties: CalibrationViewSharingUpdatedProperties) {
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "_hyFpljfOOUf", "fc5d567999f197f48479ee4da39d2392210118a11e27a82bbe1541766ce82bc5", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Calibration View Sharing Updated", {
      "Calibration view ID": properties.calibrationViewId,
      "Number of invitations": properties.numberOfInvitations,
      }, {});
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("_hyFpljfOOUf", "Calibration View Sharing Updated", messages, [
      {id: "HmZojgQc2T", name: "Calibration view ID", value: properties.calibrationViewId},
      {id: "i9i7oisOqqe8", name: "Number of invitations", value: properties.numberOfInvitations},
      ], []);
    }
  }

  // @ts-ignore
  let eventProperties: any = {};
  eventProperties["Calibration view ID"] = properties.calibrationViewId;
  if (properties.numberOfInvitations !== undefined && properties.numberOfInvitations !== null) {
    eventProperties["Number of invitations"] = properties.numberOfInvitations;
  }

  // @ts-ignore
  let userProperties: any = {};

  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Calibration View Sharing Updated", {
        "Calibration view ID": properties.calibrationViewId,
        "Number of invitations": properties.numberOfInvitations,
        }, "_hyFpljfOOUf", "fc5d567999f197f48479ee4da39d2392210118a11e27a82bbe1541766ce82bc5");
    }
    // destination AmplitudeCustom
    AmplitudeCustom.logEvent("Calibration View Sharing Updated", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface CalibrationViewViewedProperties {
  context: string | null | undefined;
  calibrationViewId: number;
  viewedInFullWidth: boolean;
}
/**
 * Calibration View Viewed: A user views a calibration view, from either the admin view or a shared calibration link.
 *
 * When to trigger this event:
 * 1. A user views a calibration that has been shared with them.
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/events/DwZO5TZ_XUe1/trigger/hI2x3r-vR
 * 2. An admin views a saved calibration.
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/events/DwZO5TZ_XUe1/trigger/fxpgKhxmL
 * 3. An admin clicks the full-screen toggle on a saved calibration.
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/events/DwZO5TZ_XUe1/trigger/LIHlKOdHg
 * 4. A user clicks the full-screen toggle on a calibration that has been shared with them.
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/events/DwZO5TZ_XUe1/trigger/81xEYvdDX
 *
 * @param properties the properties associatied with this event
 * @param properties.context: In what context was this action performed? For events that can occur from more than one location (e.g. feedback given in Slack or the main app? manager review viewed from the admin view, the manager view, the subject view, the admin calibration view or the shared calibration view?)
 * @param properties.calibrationViewId: ID of the calibration view the event originated from.
 * @param properties.viewedInFullWidth: Is the calibration viewed in full width?
 *
 * @see {@link https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/events/DwZO5TZ_XUe1}
 */
export function calibrationViewViewed(
  properties: CalibrationViewViewedProperties) {
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "DwZO5TZ_XUe1", "bbf4190f4e439f87b226e1dd092efcdeb58de2fdf9c94031c39799c566530b5c", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Calibration View Viewed", {
      "Context": properties.context,
      "Calibration view ID": properties.calibrationViewId,
      "Viewed in full width": properties.viewedInFullWidth,
      }, {});
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("DwZO5TZ_XUe1", "Calibration View Viewed", messages, [
      {id: "B-_x0L8pUXgk", name: "Context", value: properties.context},
      {id: "HmZojgQc2T", name: "Calibration view ID", value: properties.calibrationViewId},
      {id: "7mOpOcARk", name: "Viewed in full width", value: properties.viewedInFullWidth},
      ], []);
    }
  }

  // @ts-ignore
  let eventProperties: any = {};
  if (properties.context !== undefined && properties.context !== null) {
    eventProperties["Context"] = properties.context;
  }
  eventProperties["Calibration view ID"] = properties.calibrationViewId;
  eventProperties["Viewed in full width"] = properties.viewedInFullWidth;

  // @ts-ignore
  let userProperties: any = {};

  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Calibration View Viewed", {
        "Context": properties.context,
        "Calibration view ID": properties.calibrationViewId,
        "Viewed in full width": properties.viewedInFullWidth,
        }, "DwZO5TZ_XUe1", "bbf4190f4e439f87b226e1dd092efcdeb58de2fdf9c94031c39799c566530b5c");
    }
    // destination AmplitudeCustom
    AmplitudeCustom.logEvent("Calibration View Viewed", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface ManagerReviewSubmittedProperties {
  countOfWordsWritten: number;
  numberOfDaysUntilDueDate: number;
  numberOfFreeTextQuestions: number;
  context: string | null | undefined;
  numberOfRatingScaleQuestions: number | null | undefined;
  subjectUserAggregateId: string;
  managerReviewId: number;
  performanceReviewCycleId: number | null | undefined;
  numberOfAssessmentGroupQuestions: number;
  managerReviewCycleId: number;
}
/**
 * Manager Review Submitted: Formal evaluation of a direct report by their manager (or admin) is submitted.
 *
 * When to trigger this event:
 * 1. A manager completes one review for their direct reports.
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/events/0_UaRBEGv_el/trigger/cywUa6pAK
 *
 * @param properties the properties associatied with this event
 * @param properties.countOfWordsWritten: Total number of words written across all questions for submission - set to zero if none
 * @param properties.numberOfDaysUntilDueDate: Difference between submitted date and due date (negative number indicates days past due)
 * @param properties.numberOfFreeTextQuestions: Number of free text questions
 * @param properties.context: In what context was this action performed? For events that can occur from more than one location (e.g. feedback given in Slack or the main app? manager review viewed from the admin view, the manager view, the subject view, the admin calibration view or the shared calibration view?)
 * @param properties.numberOfRatingScaleQuestions: Number of rating scale questions
 * @param properties.subjectUserAggregateId: The aggregate ID of the subject user in this interaction.
 * @param properties.managerReviewId: The ID of the individual manager review for a specific direct report.
 * @param properties.performanceReviewCycleId: The ID of the performance review cycle (also known as evaluation cycle).
 * @param properties.numberOfAssessmentGroupQuestions: Number of assessment group questions. It can be 0 or 1. The assessment group questions are also known as performance buckets and are used to evaluate the performance of an individual overall.
 * @param properties.managerReviewCycleId: The ID of the manager review cycle (also known as the performance cycle) within a performance review cycle (also known as the evaluation cycle).
 *
 * @see {@link https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/events/0_UaRBEGv_el}
 */
export function managerReviewSubmitted(
  properties: ManagerReviewSubmittedProperties) {
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "0_UaRBEGv_el", "d09e6846c0cf736b5a622a408e825cf1e6deb3e2bdd4b1415f99945234171d19", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Manager Review Submitted", {
      "Count of words written": properties.countOfWordsWritten,
      "Number of days until due date": properties.numberOfDaysUntilDueDate,
      "Number of free text questions": properties.numberOfFreeTextQuestions,
      "Context": properties.context,
      "Number of rating scale questions": properties.numberOfRatingScaleQuestions,
      "Subject user aggregate ID": properties.subjectUserAggregateId,
      "Manager review ID": properties.managerReviewId,
      "Performance review cycle ID": properties.performanceReviewCycleId,
      "Number of assessment group questions": properties.numberOfAssessmentGroupQuestions,
      "Manager review cycle ID": properties.managerReviewCycleId,
      }, {});
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("0_UaRBEGv_el", "Manager Review Submitted", messages, [
      {id: "_2mx7sUH5b23", name: "Count of words written", value: properties.countOfWordsWritten},
      {id: "Homm1MS-uRo6", name: "Number of days until due date", value: properties.numberOfDaysUntilDueDate},
      {id: "mPB2a7i1jtGp", name: "Number of free text questions", value: properties.numberOfFreeTextQuestions},
      {id: "B-_x0L8pUXgk", name: "Context", value: properties.context},
      {id: "M23QJ-PjtIPl", name: "Number of rating scale questions", value: properties.numberOfRatingScaleQuestions},
      {id: "hyGVxDxV8awN", name: "Subject user aggregate ID", value: properties.subjectUserAggregateId},
      {id: "8GRMbCCGQp", name: "Manager review ID", value: properties.managerReviewId},
      {id: "1xneEbR4cM", name: "Performance review cycle ID", value: properties.performanceReviewCycleId},
      {id: "Ev14V5R6F", name: "Number of assessment group questions", value: properties.numberOfAssessmentGroupQuestions},
      {id: "CeOT3CL22hYH", name: "Manager review cycle ID", value: properties.managerReviewCycleId},
      ], []);
    }
  }

  // @ts-ignore
  let eventPropertiesAmplitudeCustom: any = {};
  eventPropertiesAmplitudeCustom["Count of words written"] = properties.countOfWordsWritten;
  eventPropertiesAmplitudeCustom["Number of days until due date"] = properties.numberOfDaysUntilDueDate;
  eventPropertiesAmplitudeCustom["Number of free text questions"] = properties.numberOfFreeTextQuestions;
  if (properties.context !== undefined && properties.context !== null) {
    eventPropertiesAmplitudeCustom["Context"] = properties.context;
  }
  if (properties.numberOfRatingScaleQuestions !== undefined && properties.numberOfRatingScaleQuestions !== null) {
    eventPropertiesAmplitudeCustom["Number of rating scale questions"] = properties.numberOfRatingScaleQuestions;
  }
  eventPropertiesAmplitudeCustom["Subject user aggregate ID"] = properties.subjectUserAggregateId;
  eventPropertiesAmplitudeCustom["Manager review ID"] = properties.managerReviewId;
  if (properties.performanceReviewCycleId !== undefined && properties.performanceReviewCycleId !== null) {
    eventPropertiesAmplitudeCustom["Performance review cycle ID"] = properties.performanceReviewCycleId;
  }
  eventPropertiesAmplitudeCustom["Number of assessment group questions"] = properties.numberOfAssessmentGroupQuestions;
  eventPropertiesAmplitudeCustom["Performance cycle ID"] = properties.managerReviewCycleId;

  // @ts-ignore
  let userPropertiesAmplitudeCustom: any = {};

  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Manager Review Submitted", {
        "Count of words written": properties.countOfWordsWritten,
        "Number of days until due date": properties.numberOfDaysUntilDueDate,
        "Number of free text questions": properties.numberOfFreeTextQuestions,
        "Context": properties.context,
        "Number of rating scale questions": properties.numberOfRatingScaleQuestions,
        "Subject user aggregate ID": properties.subjectUserAggregateId,
        "Manager review ID": properties.managerReviewId,
        "Performance review cycle ID": properties.performanceReviewCycleId,
        "Number of assessment group questions": properties.numberOfAssessmentGroupQuestions,
        "Manager review cycle ID": properties.managerReviewCycleId,
        }, "0_UaRBEGv_el", "d09e6846c0cf736b5a622a408e825cf1e6deb3e2bdd4b1415f99945234171d19");
    }
    // destination AmplitudeCustom
    AmplitudeCustom.logEvent("Evaluation Submitted", (Object as any).assign({}, eventPropertiesAmplitudeCustom));
  } else {
    // do nothing
  }
}

export interface CompanyGoalsIndexViewedProperties {
  numberOfGoals: number | null | undefined;
  goalId: string[] | null | undefined;
  filterDateFrom: string | null | undefined;
  filterDateTo: string | null | undefined;
  navigationSource: string | null | undefined;
  filtersApplied: string[] | null | undefined;
  viewMode: string;
}
/**
 * Company Goals Index Viewed: Company Goals Index Viewed
 *
 * When to trigger this event:
 * 1. On page view (Tree)
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/events/Acxhyq57Di1N/trigger/Gf4H6rFpq
 * 2. On page view (List)
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/events/Acxhyq57Di1N/trigger/dXG4D-qTO
 *
 * @param properties the properties associatied with this event
 * @param properties.numberOfGoals: Total of goals on page
 * @param properties.goalId: The system ID of the Performance Goal.
 * @param properties.filterDateFrom: The first date in the goal due date range selected.
 * @param properties.filterDateTo: The last date in the goal due date range selected.
 * @param properties.navigationSource: How did the user navigate to completing this event (e.g. from the task widget, the platform menus or a platform communication?)
 * @param properties.filtersApplied: A list of all filters that were applied to the search. This should be the names of the filters, not the values, as filter values can contain PII, and they are also too numerous to search within Amplitude.
 * @param properties.viewMode: Which mode was the user viewing the page in? e.g. for Continuous Reporting, options are Overview, List view, and Graph.
 *
 * @see {@link https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/events/Acxhyq57Di1N}
 */
export function companyGoalsIndexViewed(
  properties: CompanyGoalsIndexViewedProperties) {
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "Acxhyq57Di1N", "60b45199b9e93b8134108ab9d77fa4122e14ecd673577ea9cda46d249addc4fc", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Company Goals Index Viewed", {
      "Number of goals": properties.numberOfGoals,
      "Goal ID": properties.goalId,
      "Filter date from": properties.filterDateFrom,
      "Filter date to": properties.filterDateTo,
      "Navigation source": properties.navigationSource,
      "Filters applied": properties.filtersApplied,
      "View mode": properties.viewMode,
      }, {});
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("Acxhyq57Di1N", "Company Goals Index Viewed", messages, [
      {id: "YxV_WX8VCPW_", name: "Number of goals", value: properties.numberOfGoals},
      {id: "DvqwsgF7b2N1", name: "Goal ID", value: properties.goalId},
      {id: "8_HE0gSR_", name: "Filter date from", value: properties.filterDateFrom},
      {id: "ykLc24KCa_", name: "Filter date to", value: properties.filterDateTo},
      {id: "wtVE7Zo-ioYV", name: "Navigation source", value: properties.navigationSource},
      {id: "ZjfVsZHrzKhv", name: "Filters applied", value: properties.filtersApplied},
      {id: "Nnuji-llRD", name: "View mode", value: properties.viewMode},
      ], []);
    }
  }

  // @ts-ignore
  let eventProperties: any = {};
  if (properties.numberOfGoals !== undefined && properties.numberOfGoals !== null) {
    eventProperties["Number of goals"] = properties.numberOfGoals;
  }
  if (properties.goalId !== undefined && properties.goalId !== null) {
    eventProperties["Goal ID"] = properties.goalId;
  }
  if (properties.filterDateFrom !== undefined && properties.filterDateFrom !== null) {
    eventProperties["Filter date from"] = properties.filterDateFrom;
  }
  if (properties.filterDateTo !== undefined && properties.filterDateTo !== null) {
    eventProperties["Filter date to"] = properties.filterDateTo;
  }
  if (properties.navigationSource !== undefined && properties.navigationSource !== null) {
    eventProperties["Navigation source"] = properties.navigationSource;
  }
  if (properties.filtersApplied !== undefined && properties.filtersApplied !== null) {
    eventProperties["Filters applied"] = properties.filtersApplied;
  }
  eventProperties["View mode"] = properties.viewMode;

  // @ts-ignore
  let userProperties: any = {};

  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Company Goals Index Viewed", {
        "Number of goals": properties.numberOfGoals,
        "Goal ID": properties.goalId,
        "Filter date from": properties.filterDateFrom,
        "Filter date to": properties.filterDateTo,
        "Navigation source": properties.navigationSource,
        "Filters applied": properties.filtersApplied,
        "View mode": properties.viewMode,
        }, "Acxhyq57Di1N", "60b45199b9e93b8134108ab9d77fa4122e14ecd673577ea9cda46d249addc4fc");
    }
    // destination AmplitudeCustom
    AmplitudeCustom.logEvent("Company Goals Index Viewed", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface IndividualGoalsIndexViewedProperties {
  numberOfGoals: number | null | undefined;
  goalId: string[] | null | undefined;
  filterDateFrom: string | null | undefined;
  filterDateTo: string | null | undefined;
  navigationSource: string | null | undefined;
  filtersApplied: string[] | null | undefined;
}
/**
 * Individual Goals Index Viewed: A user views the index of their individual goals
 *
 * @param properties the properties associatied with this event
 * @param properties.numberOfGoals: Total of goals on page
 * @param properties.goalId: The system ID of the Performance Goal.
 * @param properties.filterDateFrom: The first date in the goal due date range selected.
 * @param properties.filterDateTo: The last date in the goal due date range selected.
 * @param properties.navigationSource: How did the user navigate to completing this event (e.g. from the task widget, the platform menus or a platform communication?)
 * @param properties.filtersApplied: A list of all filters that were applied to the search. This should be the names of the filters, not the values, as filter values can contain PII, and they are also too numerous to search within Amplitude.
 *
 * @see {@link https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/events/vywC_-sJz-Ih}
 */
export function individualGoalsIndexViewed(
  properties: IndividualGoalsIndexViewedProperties) {
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "vywC_-sJz-Ih", "7d4845b4ee293424979c40fbba06ce5cffc5f9d5fea0dfeeb614da375121cb92", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Individual Goals Index Viewed", {
      "Number of goals": properties.numberOfGoals,
      "Goal ID": properties.goalId,
      "Filter date from": properties.filterDateFrom,
      "Filter date to": properties.filterDateTo,
      "Navigation source": properties.navigationSource,
      "Filters applied": properties.filtersApplied,
      }, {});
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("vywC_-sJz-Ih", "Individual Goals Index Viewed", messages, [
      {id: "YxV_WX8VCPW_", name: "Number of goals", value: properties.numberOfGoals},
      {id: "DvqwsgF7b2N1", name: "Goal ID", value: properties.goalId},
      {id: "8_HE0gSR_", name: "Filter date from", value: properties.filterDateFrom},
      {id: "ykLc24KCa_", name: "Filter date to", value: properties.filterDateTo},
      {id: "wtVE7Zo-ioYV", name: "Navigation source", value: properties.navigationSource},
      {id: "ZjfVsZHrzKhv", name: "Filters applied", value: properties.filtersApplied},
      ], []);
    }
  }

  // @ts-ignore
  let eventProperties: any = {};
  if (properties.numberOfGoals !== undefined && properties.numberOfGoals !== null) {
    eventProperties["Number of goals"] = properties.numberOfGoals;
  }
  if (properties.goalId !== undefined && properties.goalId !== null) {
    eventProperties["Goal ID"] = properties.goalId;
  }
  if (properties.filterDateFrom !== undefined && properties.filterDateFrom !== null) {
    eventProperties["Filter date from"] = properties.filterDateFrom;
  }
  if (properties.filterDateTo !== undefined && properties.filterDateTo !== null) {
    eventProperties["Filter date to"] = properties.filterDateTo;
  }
  if (properties.navigationSource !== undefined && properties.navigationSource !== null) {
    eventProperties["Navigation source"] = properties.navigationSource;
  }
  if (properties.filtersApplied !== undefined && properties.filtersApplied !== null) {
    eventProperties["Filters applied"] = properties.filtersApplied;
  }

  // @ts-ignore
  let userProperties: any = {};

  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Individual Goals Index Viewed", {
        "Number of goals": properties.numberOfGoals,
        "Goal ID": properties.goalId,
        "Filter date from": properties.filterDateFrom,
        "Filter date to": properties.filterDateTo,
        "Navigation source": properties.navigationSource,
        "Filters applied": properties.filtersApplied,
        }, "vywC_-sJz-Ih", "7d4845b4ee293424979c40fbba06ce5cffc5f9d5fea0dfeeb614da375121cb92");
    }
    // destination AmplitudeCustom
    AmplitudeCustom.logEvent("Individual Goals Index Viewed", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface PerformanceGoalCreatedProperties {
  goalDueDate: string | null | undefined;
  goalPriority: GoalPriorityValueType | null | undefined;
  goalType: GoalTypeValueType;
  isAligned: any | null | undefined;
  isAlignedTo: IsAlignedToValueType | null | undefined;
  numberOfGoalOwners: number | null | undefined;
  numberOfKeyResultsSet: number | null | undefined;
  visibility: VisibilityValueType | null | undefined;
  goalId: string[];
  context: string | null | undefined;
  navigationSource: string | null | undefined;
  method: string | null | undefined;
  baseGoalId: string | null | undefined;
}
/**
 * Performance Goal Created: A user creates a performance goal (either from scratch or from duplication of an existing goal).
 *
 * @param properties the properties associatied with this event
 * @param properties.goalDueDate: Due date as set by the user
 * @param properties.goalPriority: Priority that the goal was set as
 * @param properties.goalType: Type of goal set e.g. company level goal
 * @param properties.isAligned: Is the goal aligned?
 * @param properties.isAlignedTo: Is the goal aligned to Team, Department, Company
 * @param properties.numberOfGoalOwners: How many people own this goal
 * @param properties.numberOfKeyResultsSet: The number of key results set with this goal
 * @param properties.visibility: Who can see the goal set by user
 * @param properties.goalId: The system ID of the Performance Goal.
 * @param properties.context: In what context was this action performed? For events that can occur from more than one location (e.g. feedback given in Slack or the main app? manager review viewed from the admin view, the manager view, the subject view, the admin calibration view or the shared calibration view?)
 * @param properties.navigationSource: How did the user navigate to completing this event (e.g. from the task widget, the platform menus or a platform communication?)
 * @param properties.method: What method was used to complete the action? This property is applicable wherever there's multiple ways to perform an action (e.g. creating a survey from scratch or by duplicating another)
 * @param properties.baseGoalId: The system ID of the original goal that the new goal was copied from.
 *
 * @see {@link https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/events/1u98xWIRvdNJ}
 */
export function performanceGoalCreated(
  properties: PerformanceGoalCreatedProperties) {
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "1u98xWIRvdNJ", "e4bebb0260d98b42e18f07a9da30f6eaa8c5de5f60c3116005e917aa3cacd6e9", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Performance Goal Created", {
      "Goal due date": properties.goalDueDate,
      "Goal priority": properties.goalPriority,
      "Goal type": properties.goalType,
      "Is aligned": properties.isAligned,
      "Is aligned to": properties.isAlignedTo,
      "Number of goal owners": properties.numberOfGoalOwners,
      "Number of key results set": properties.numberOfKeyResultsSet,
      "Visibility": properties.visibility,
      "Goal ID": properties.goalId,
      "Context": properties.context,
      "Navigation source": properties.navigationSource,
      "Method": properties.method,
      "Base goal ID": properties.baseGoalId,
      }, {});
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("1u98xWIRvdNJ", "Performance Goal Created", messages, [
      {id: "Nk82k048I3sA", name: "Goal due date", value: properties.goalDueDate},
      {id: "3SiTGGj79M72", name: "Goal priority", value: properties.goalPriority},
      {id: "YlrJzTuaYeG7", name: "Goal type", value: properties.goalType},
      {id: "z9VLThrAxUCY", name: "Is aligned", value: properties.isAligned},
      {id: "uSsZ1M_dFpuA", name: "Is aligned to", value: properties.isAlignedTo},
      {id: "9_GqeUOn9vJU", name: "Number of goal owners", value: properties.numberOfGoalOwners},
      {id: "9B7CQem8XSM6", name: "Number of key results set", value: properties.numberOfKeyResultsSet},
      {id: "Pw8EP7vfF71X", name: "Visibility", value: properties.visibility},
      {id: "DvqwsgF7b2N1", name: "Goal ID", value: properties.goalId},
      {id: "B-_x0L8pUXgk", name: "Context", value: properties.context},
      {id: "wtVE7Zo-ioYV", name: "Navigation source", value: properties.navigationSource},
      {id: "z0iUtIO_p7", name: "Method", value: properties.method},
      {id: "ETzfLMS9XI", name: "Base goal ID", value: properties.baseGoalId},
      ], []);
    }
  }

  // @ts-ignore
  let eventProperties: any = {};
  if (properties.goalDueDate !== undefined && properties.goalDueDate !== null) {
    eventProperties["Goal due date"] = properties.goalDueDate;
  }
  if (properties.goalPriority !== undefined && properties.goalPriority !== null) {
    eventProperties["Goal priority"] = properties.goalPriority;
  }
  eventProperties["Goal type"] = properties.goalType;
  if (properties.isAligned !== undefined && properties.isAligned !== null) {
    eventProperties["Is aligned"] = properties.isAligned;
  }
  if (properties.isAlignedTo !== undefined && properties.isAlignedTo !== null) {
    eventProperties["Is aligned to"] = properties.isAlignedTo;
  }
  if (properties.numberOfGoalOwners !== undefined && properties.numberOfGoalOwners !== null) {
    eventProperties["Number of goal owners"] = properties.numberOfGoalOwners;
  }
  if (properties.numberOfKeyResultsSet !== undefined && properties.numberOfKeyResultsSet !== null) {
    eventProperties["Number of key results set"] = properties.numberOfKeyResultsSet;
  }
  if (properties.visibility !== undefined && properties.visibility !== null) {
    eventProperties["Visibility"] = properties.visibility;
  }
  eventProperties["Goal ID"] = properties.goalId;
  if (properties.context !== undefined && properties.context !== null) {
    eventProperties["Context"] = properties.context;
  }
  if (properties.navigationSource !== undefined && properties.navigationSource !== null) {
    eventProperties["Navigation source"] = properties.navigationSource;
  }
  if (properties.method !== undefined && properties.method !== null) {
    eventProperties["Method"] = properties.method;
  }
  if (properties.baseGoalId !== undefined && properties.baseGoalId !== null) {
    eventProperties["Base goal ID"] = properties.baseGoalId;
  }

  // @ts-ignore
  let userProperties: any = {};

  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Performance Goal Created", {
        "Goal due date": properties.goalDueDate,
        "Goal priority": properties.goalPriority,
        "Goal type": properties.goalType,
        "Is aligned": properties.isAligned,
        "Is aligned to": properties.isAlignedTo,
        "Number of goal owners": properties.numberOfGoalOwners,
        "Number of key results set": properties.numberOfKeyResultsSet,
        "Visibility": properties.visibility,
        "Goal ID": properties.goalId,
        "Context": properties.context,
        "Navigation source": properties.navigationSource,
        "Method": properties.method,
        "Base goal ID": properties.baseGoalId,
        }, "1u98xWIRvdNJ", "e4bebb0260d98b42e18f07a9da30f6eaa8c5de5f60c3116005e917aa3cacd6e9");
    }
    // destination AmplitudeCustom
    AmplitudeCustom.logEvent("Performance Goal Created", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface PerformanceGoalUpdatedProperties {
  elementUpdated: string | null | undefined;
  elementValueChangedFrom: string | null | undefined;
  elementValueChangedTo: any | null | undefined;
  goalId: string[];
  goalType: GoalTypeValueType;
}
/**
 * Performance Goal Updated: A goal is updated (be it progress, a comment added etc)
 *
 * @param properties the properties associatied with this event
 * @param properties.elementUpdated: What the user interacted with e.g. progress bar, comment, slider, free text, agenda item
 * @param properties.elementValueChangedFrom: What the element value was before the change (e.g. 50%, In progress)
 * @param properties.elementValueChangedTo: What the element value was after the change (e.g. 70%, Blocked)
 * @param properties.goalId: The system ID of the Performance Goal.
 * @param properties.goalType: Type of goal set e.g. company level goal
 *
 * @see {@link https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/events/G2mqIceLKpyR}
 */
export function performanceGoalUpdated(
  properties: PerformanceGoalUpdatedProperties) {
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "G2mqIceLKpyR", "aaa37b3cf96b1c3568a257be8eccd404a9fc5b1a7fb20f40ab288503e0cf4755", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Performance Goal Updated", {
      "Element updated": properties.elementUpdated,
      "Element value changed from": properties.elementValueChangedFrom,
      "Element value changed to": properties.elementValueChangedTo,
      "Goal ID": properties.goalId,
      "Goal type": properties.goalType,
      }, {});
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("G2mqIceLKpyR", "Performance Goal Updated", messages, [
      {id: "6BWNg4JekUxP", name: "Element updated", value: properties.elementUpdated},
      {id: "Sb3r6Ca9o-3u", name: "Element value changed from", value: properties.elementValueChangedFrom},
      {id: "Q5SrQTREiexD", name: "Element value changed to", value: properties.elementValueChangedTo},
      {id: "DvqwsgF7b2N1", name: "Goal ID", value: properties.goalId},
      {id: "YlrJzTuaYeG7", name: "Goal type", value: properties.goalType},
      ], []);
    }
  }

  // @ts-ignore
  let eventProperties: any = {};
  if (properties.elementUpdated !== undefined && properties.elementUpdated !== null) {
    eventProperties["Element updated"] = properties.elementUpdated;
  }
  if (properties.elementValueChangedFrom !== undefined && properties.elementValueChangedFrom !== null) {
    eventProperties["Element value changed from"] = properties.elementValueChangedFrom;
  }
  if (properties.elementValueChangedTo !== undefined && properties.elementValueChangedTo !== null) {
    eventProperties["Element value changed to"] = properties.elementValueChangedTo;
  }
  eventProperties["Goal ID"] = properties.goalId;
  eventProperties["Goal type"] = properties.goalType;

  // @ts-ignore
  let userProperties: any = {};

  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Performance Goal Updated", {
        "Element updated": properties.elementUpdated,
        "Element value changed from": properties.elementValueChangedFrom,
        "Element value changed to": properties.elementValueChangedTo,
        "Goal ID": properties.goalId,
        "Goal type": properties.goalType,
        }, "G2mqIceLKpyR", "aaa37b3cf96b1c3568a257be8eccd404a9fc5b1a7fb20f40ab288503e0cf4755");
    }
    // destination AmplitudeCustom
    AmplitudeCustom.logEvent("Performance Goal Updated", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface OneOn1CheckMinusinUpdatedProperties {
  oneOn1Id: string;
  oneOn1Role: OneOn1RoleValueType | null | undefined;
  managersUserAggregateId: string;
  scheduleId: string | null | undefined;
  subjectsUserAggregateId: string;
  elementUpdated: string | null | undefined;
  updateAction: UpdateActionValueType | null | undefined;
  formatsApplied: boolean | null | undefined;
}
/**
 * 1 on 1 Check-in Updated: A user interacts with an element on a 1 on 1 check-in
 *
 * When to trigger this event:
 * 1. When the user updates an element in the 1 on 1 check in
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/events/2qrvX-2fveF9/trigger/tAzALltrm
 *
 * @param properties the properties associatied with this event
 * @param properties.oneOn1Id: The unique ID of the 1 on 1
 * @param properties.oneOn1Role: Was this user a manager or subject in this 1 on 1?
 * @param properties.managersUserAggregateId: The user aggregate ID of the manager involved in the 1 on 1
 * @param properties.scheduleId: no description
 * @param properties.subjectsUserAggregateId: The user aggregate ID of the subject/direct report involved in the 1 on 1
 * @param properties.elementUpdated: What the user interacted with e.g. progress bar, comment, slider, free text, agenda item
 * @param properties.updateAction: Was the element added, updated, or deleted?
 * @param properties.formatsApplied: Were any formats applied to the 1 on 1 Checkin via the rich-text editor?
 *
 * @see {@link https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/events/2qrvX-2fveF9}
 */
export function oneOn1CheckInUpdated(
  properties: OneOn1CheckMinusinUpdatedProperties) {
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "2qrvX-2fveF9", "b6c5c85ec16cd43db8da5d4bff4aed1a803ffb1647932db60aac241a5bc4354a", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("1 on 1 Check-in Updated", {
      "1 on 1 ID": properties.oneOn1Id,
      "1 on 1 role": properties.oneOn1Role,
      "Manager's user aggregate ID": properties.managersUserAggregateId,
      "Schedule ID": properties.scheduleId,
      "Subject's user aggregate ID": properties.subjectsUserAggregateId,
      "Element updated": properties.elementUpdated,
      "Update action": properties.updateAction,
      "Formats applied": properties.formatsApplied,
      }, {});
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("2qrvX-2fveF9", "1 on 1 Check-in Updated", messages, [
      {id: "q20XPhSQODmN", name: "1 on 1 ID", value: properties.oneOn1Id},
      {id: "GNy2dQYpw6x_", name: "1 on 1 role", value: properties.oneOn1Role},
      {id: "4P_acBqHwWKq", name: "Manager's user aggregate ID", value: properties.managersUserAggregateId},
      {id: "FA6r6vYeA9s0", name: "Schedule ID", value: properties.scheduleId},
      {id: "fa7n-RfCjkz7", name: "Subject's user aggregate ID", value: properties.subjectsUserAggregateId},
      {id: "6BWNg4JekUxP", name: "Element updated", value: properties.elementUpdated},
      {id: "csgsy7OIlQ", name: "Update action", value: properties.updateAction},
      {id: "il__CF4jB", name: "Formats applied", value: properties.formatsApplied},
      ], []);
    }
  }

  // @ts-ignore
  let eventProperties: any = {};
  eventProperties["1 on 1 ID"] = properties.oneOn1Id;
  if (properties.oneOn1Role !== undefined && properties.oneOn1Role !== null) {
    eventProperties["1 on 1 role"] = properties.oneOn1Role;
  }
  eventProperties["Manager's user aggregate ID"] = properties.managersUserAggregateId;
  if (properties.scheduleId !== undefined && properties.scheduleId !== null) {
    eventProperties["Schedule ID"] = properties.scheduleId;
  }
  eventProperties["Subject's user aggregate ID"] = properties.subjectsUserAggregateId;
  if (properties.elementUpdated !== undefined && properties.elementUpdated !== null) {
    eventProperties["Element updated"] = properties.elementUpdated;
  }
  if (properties.updateAction !== undefined && properties.updateAction !== null) {
    eventProperties["Update action"] = properties.updateAction;
  }
  if (properties.formatsApplied !== undefined && properties.formatsApplied !== null) {
    eventProperties["Formats applied"] = properties.formatsApplied;
  }

  // @ts-ignore
  let userProperties: any = {};

  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("1 on 1 Check-in Updated", {
        "1 on 1 ID": properties.oneOn1Id,
        "1 on 1 role": properties.oneOn1Role,
        "Manager's user aggregate ID": properties.managersUserAggregateId,
        "Schedule ID": properties.scheduleId,
        "Subject's user aggregate ID": properties.subjectsUserAggregateId,
        "Element updated": properties.elementUpdated,
        "Update action": properties.updateAction,
        "Formats applied": properties.formatsApplied,
        }, "2qrvX-2fveF9", "b6c5c85ec16cd43db8da5d4bff4aed1a803ffb1647932db60aac241a5bc4354a");
    }
    // destination AmplitudeCustom
    AmplitudeCustom.logEvent("1 on 1 Check-in Updated", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface OneOn1SpaceViewedProperties {
  managersUserAggregateId: string;
  role: RoleValueType;
  subjectsUserAggregateId: string;
  numberOf1On1sLoaded: number;
  conversationId: string;
}
/**
 * 1 on 1 Space Viewed: A user views the 1 on 1 check-in space. The event is sent once for the page view, not one event per check-in.
 *
 * When to trigger this event:
 * 1. Triggered when a user views the 1 on 1 check-in space.
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/events/lWYJXEoSqixy/trigger/Ot8a1er4-
 * 2. Triggered when a user loads more historical 1 on 1s.
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/events/lWYJXEoSqixy/trigger/fsL09wILw
 *
 * @param properties the properties associatied with this event
 * @param properties.managersUserAggregateId: The user aggregate ID of the manager involved in the 1 on 1
 * @param properties.role: The role of the user viewing the 1 on 1 space.
 * @param properties.subjectsUserAggregateId: The user aggregate ID of the subject/direct report involved in the 1 on 1
 * @param properties.numberOf1On1sLoaded: The number of 1 on 1s loaded in the 1 on 1s space.
 * @param properties.conversationId: The unique ID of the 1 on 1. This is unique between each subject-manager combination. i.e. if a subject has multiple managers, then the Conversation ID will be different between the two managers.
 *
 * @see {@link https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/events/lWYJXEoSqixy}
 */
export function oneOn1SpaceViewed(properties: OneOn1SpaceViewedProperties) {
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "lWYJXEoSqixy", "6095510608d7ad983016859311fb5593f3c87e7fb5ed3a59e3e5066c307d7a2a", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("1 on 1 Space Viewed", {
      "Manager's user aggregate ID": properties.managersUserAggregateId,
      "Role": properties.role,
      "Subject's user aggregate ID": properties.subjectsUserAggregateId,
      "Number of 1 on 1s loaded": properties.numberOf1On1sLoaded,
      "Conversation ID": properties.conversationId,
      }, {});
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("lWYJXEoSqixy", "1 on 1 Space Viewed", messages, [
      {id: "4P_acBqHwWKq", name: "Manager's user aggregate ID", value: properties.managersUserAggregateId},
      {id: "qGMJj2-Bu4y9", name: "Role", value: properties.role},
      {id: "fa7n-RfCjkz7", name: "Subject's user aggregate ID", value: properties.subjectsUserAggregateId},
      {id: "Zsdnw_YVpL", name: "Number of 1 on 1s loaded", value: properties.numberOf1On1sLoaded},
      {id: "INz91iyeo", name: "Conversation ID", value: properties.conversationId},
      ], []);
    }
  }

  // @ts-ignore
  let eventProperties: any = {};
  eventProperties["Manager's user aggregate ID"] = properties.managersUserAggregateId;
  eventProperties["Role"] = properties.role;
  eventProperties["Subject's user aggregate ID"] = properties.subjectsUserAggregateId;
  eventProperties["Number of 1 on 1s loaded"] = properties.numberOf1On1sLoaded;
  eventProperties["Conversation ID"] = properties.conversationId;

  // @ts-ignore
  let userProperties: any = {};

  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("1 on 1 Space Viewed", {
        "Manager's user aggregate ID": properties.managersUserAggregateId,
        "Role": properties.role,
        "Subject's user aggregate ID": properties.subjectsUserAggregateId,
        "Number of 1 on 1s loaded": properties.numberOf1On1sLoaded,
        "Conversation ID": properties.conversationId,
        }, "lWYJXEoSqixy", "6095510608d7ad983016859311fb5593f3c87e7fb5ed3a59e3e5066c307d7a2a");
    }
    // destination AmplitudeCustom
    AmplitudeCustom.logEvent("1 on 1 Space Viewed", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface CalibrationFlagToggledProperties {
  calibrationViewId: number;
  flagToggleState: string;
}
/**
 * Calibration Flag Toggled: The flag next to a manager review in a calibration view is toggled.
 *
 * When to trigger this event:
 * 1. When the user clicks the flag icon to the left of the table, it toggles on or off. This event tracks the toggling of the flag.
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/events/nu-RphKTs/trigger/9X362RKSF
 *
 * @param properties the properties associatied with this event
 * @param properties.calibrationViewId: ID of the calibration view the event originated from.
 * @param properties.flagToggleState: Whether the flag was toggled on or off
 *
 * @see {@link https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/events/nu-RphKTs}
 */
export function calibrationFlagToggled(
  properties: CalibrationFlagToggledProperties) {
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "nu-RphKTs", "1b0a46b50cba3d53e86628eac6ee50a1d1083ee28a3cb0ea1848e22f83f82d72", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Calibration Flag Toggled", {
      "Calibration view ID": properties.calibrationViewId,
      "Flag toggle state": properties.flagToggleState,
      }, {});
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("nu-RphKTs", "Calibration Flag Toggled", messages, [
      {id: "HmZojgQc2T", name: "Calibration view ID", value: properties.calibrationViewId},
      {id: "9Vnjjp7FFk", name: "Flag toggle state", value: properties.flagToggleState},
      ], []);
    }
  }

  // @ts-ignore
  let eventProperties: any = {};
  eventProperties["Calibration view ID"] = properties.calibrationViewId;
  eventProperties["Flag toggle state"] = properties.flagToggleState;

  // @ts-ignore
  let userProperties: any = {};

  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Calibration Flag Toggled", {
        "Calibration view ID": properties.calibrationViewId,
        "Flag toggle state": properties.flagToggleState,
        }, "nu-RphKTs", "1b0a46b50cba3d53e86628eac6ee50a1d1083ee28a3cb0ea1848e22f83f82d72");
    }
    // destination AmplitudeCustom
    AmplitudeCustom.logEvent("Calibration Flag Toggled", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface CalibrationViewPaginationSizeChangedProperties {
  calibrationViewId: number;
  previousPaginationSize: number;
  paginationSize: number;
}
/**
 * Calibration View Pagination Size Changed: A user changes the number of Manager Reviews shown per page in a calibration.
 *
 * When to trigger this event:
 * 1. A user chooses one of the values in the pagination dropdown (between 5 and 100). Opening the dropdown and selecting a value returns the selected number of Manager Reviews per page.
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/events/M52LR3eJv_/trigger/owwsdiDWT
 *
 * @param properties the properties associatied with this event
 * @param properties.calibrationViewId: ID of the calibration view the event originated from.
 * @param properties.previousPaginationSize: The number of records displayed per page before the change.
 * @param properties.paginationSize: The number of records displayed per page after the change.
 *
 * @see {@link https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/events/M52LR3eJv_}
 */
export function calibrationViewPaginationSizeChanged(
  properties: CalibrationViewPaginationSizeChangedProperties) {
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "M52LR3eJv_", "a162e616727553b9da41c3a0e1db30dd25b711c9fb0a8884ab7ad7bd1d51f9cf", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Calibration View Pagination Size Changed", {
      "Calibration view ID": properties.calibrationViewId,
      "Previous pagination size": properties.previousPaginationSize,
      "Pagination size": properties.paginationSize,
      }, {});
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("M52LR3eJv_", "Calibration View Pagination Size Changed", messages, [
      {id: "HmZojgQc2T", name: "Calibration view ID", value: properties.calibrationViewId},
      {id: "VQPMo_SyLH", name: "Previous pagination size", value: properties.previousPaginationSize},
      {id: "AaY_oFPjpj", name: "Pagination size", value: properties.paginationSize},
      ], []);
    }
  }

  // @ts-ignore
  let eventProperties: any = {};
  eventProperties["Calibration view ID"] = properties.calibrationViewId;
  eventProperties["Previous pagination size"] = properties.previousPaginationSize;
  eventProperties["Pagination size"] = properties.paginationSize;

  // @ts-ignore
  let userProperties: any = {};

  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Calibration View Pagination Size Changed", {
        "Calibration view ID": properties.calibrationViewId,
        "Previous pagination size": properties.previousPaginationSize,
        "Pagination size": properties.paginationSize,
        }, "M52LR3eJv_", "a162e616727553b9da41c3a0e1db30dd25b711c9fb0a8884ab7ad7bd1d51f9cf");
    }
    // destination AmplitudeCustom
    AmplitudeCustom.logEvent("Calibration View Pagination Size Changed", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface ManagerReviewViewedProperties {
  context: string | null | undefined;
  calibrationViewId: number | null | undefined;
  performanceReviewCycleId: number | null | undefined;
  managerReviewId: number;
  managerReviewStatus: string;
  managerReviewCycleId: number;
  isCalibrated: boolean;
  hasPeerAndUpwardFeedback: boolean;
  selfReflectionsCollectedDuringCycle: boolean;
}
/**
 * Manager Review Viewed: A user views a manager review. A manager review can be accessed from the admin view, the manager view, the individual contributor view, the admin view of a calibration or a shared calibration.
 *
 * When to trigger this event:
 * 1. An admin views an individual manager review by opening the kebab menu on the employee row in a calibration.
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/events/WXct1Wg4sQ/trigger/_59umAYZb
 * 2. A user views an individual manager review from a calibration that has been shared with them.
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/events/WXct1Wg4sQ/trigger/drCGPPyV4
 * 3. A user views an individual manager review after it has been shared by their manager, from either the 'Shared Performance Reviews' page, the notifications page or a platform communication (email/slack/ms teams).
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/events/WXct1Wg4sQ/trigger/BCv9UVJzT
 * 4. A manager views an individual manager review for one of their direct reports from the manager menu, the task list or a platform communication.
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/events/WXct1Wg4sQ/trigger/irsTc6WBM
 * 5. An admin views an individual manager review from the admin menu.
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/events/WXct1Wg4sQ/trigger/tISR56D09
 *
 * @param properties the properties associatied with this event
 * @param properties.context: In what context was this action performed? For events that can occur from more than one location (e.g. feedback given in Slack or the main app? manager review viewed from the admin view, the manager view, the subject view, the admin calibration view or the shared calibration view?)
 * @param properties.calibrationViewId: ID of the calibration view the event originated from.
 * @param properties.performanceReviewCycleId: The ID of the performance review cycle (also known as evaluation cycle).
 * @param properties.managerReviewId: The ID of the individual manager review for a specific direct report.
 * @param properties.managerReviewStatus: The status of the individual manager review: incomplete (untouched), dirty, complete or shared.
 * @param properties.managerReviewCycleId: The ID of the manager review cycle (also known as the performance cycle) within a performance review cycle (also known as the evaluation cycle).
 * @param properties.isCalibrated: Is the manager view included in a published calibration view?
 * @param properties.hasPeerAndUpwardFeedback: Does the cycle have peer and upward feedback?
 * @param properties.selfReflectionsCollectedDuringCycle: Are self-reflections collected during this cycle? This will be true when the self-reflection cycle start date is <=1 month before the manager review phase start date
 *
 * @see {@link https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/events/WXct1Wg4sQ}
 */
export function managerReviewViewed(properties: ManagerReviewViewedProperties
  ) {
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "WXct1Wg4sQ", "c3e7c1cfad40dcf828a1fa96163d471023fcf861fee914a456dbcf5e1144a023", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Manager Review Viewed", {
      "Context": properties.context,
      "Calibration view ID": properties.calibrationViewId,
      "Performance review cycle ID": properties.performanceReviewCycleId,
      "Manager review ID": properties.managerReviewId,
      "Manager review status": properties.managerReviewStatus,
      "Manager review cycle ID": properties.managerReviewCycleId,
      "Is calibrated": properties.isCalibrated,
      "Has peer and upward feedback": properties.hasPeerAndUpwardFeedback,
      "Self reflections collected during cycle": properties.selfReflectionsCollectedDuringCycle,
      }, {});
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("WXct1Wg4sQ", "Manager Review Viewed", messages, [
      {id: "B-_x0L8pUXgk", name: "Context", value: properties.context},
      {id: "HmZojgQc2T", name: "Calibration view ID", value: properties.calibrationViewId},
      {id: "1xneEbR4cM", name: "Performance review cycle ID", value: properties.performanceReviewCycleId},
      {id: "8GRMbCCGQp", name: "Manager review ID", value: properties.managerReviewId},
      {id: "hvuLAFBuds", name: "Manager review status", value: properties.managerReviewStatus},
      {id: "CeOT3CL22hYH", name: "Manager review cycle ID", value: properties.managerReviewCycleId},
      {id: "Yx800PeVVM", name: "Is calibrated", value: properties.isCalibrated},
      {id: "zMuoGizrvQ", name: "Has peer and upward feedback", value: properties.hasPeerAndUpwardFeedback},
      {id: "DCD4Im3KI0", name: "Self reflections collected during cycle", value: properties.selfReflectionsCollectedDuringCycle},
      ], []);
    }
  }

  // @ts-ignore
  let eventPropertiesAmplitudeCustom: any = {};
  if (properties.context !== undefined && properties.context !== null) {
    eventPropertiesAmplitudeCustom["Context"] = properties.context;
  }
  if (properties.calibrationViewId !== undefined && properties.calibrationViewId !== null) {
    eventPropertiesAmplitudeCustom["Calibration view ID"] = properties.calibrationViewId;
  }
  if (properties.performanceReviewCycleId !== undefined && properties.performanceReviewCycleId !== null) {
    eventPropertiesAmplitudeCustom["Performance review cycle ID"] = properties.performanceReviewCycleId;
  }
  eventPropertiesAmplitudeCustom["Manager review ID"] = properties.managerReviewId;
  eventPropertiesAmplitudeCustom["Manager review status"] = properties.managerReviewStatus;
  eventPropertiesAmplitudeCustom["Performance cycle ID"] = properties.managerReviewCycleId;
  eventPropertiesAmplitudeCustom["Is calibrated"] = properties.isCalibrated;
  eventPropertiesAmplitudeCustom["Has peer and upward feedback"] = properties.hasPeerAndUpwardFeedback;
  eventPropertiesAmplitudeCustom["Self reflections collected during cycle"] = properties.selfReflectionsCollectedDuringCycle;

  // @ts-ignore
  let userPropertiesAmplitudeCustom: any = {};

  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Manager Review Viewed", {
        "Context": properties.context,
        "Calibration view ID": properties.calibrationViewId,
        "Performance review cycle ID": properties.performanceReviewCycleId,
        "Manager review ID": properties.managerReviewId,
        "Manager review status": properties.managerReviewStatus,
        "Manager review cycle ID": properties.managerReviewCycleId,
        "Is calibrated": properties.isCalibrated,
        "Has peer and upward feedback": properties.hasPeerAndUpwardFeedback,
        "Self reflections collected during cycle": properties.selfReflectionsCollectedDuringCycle,
        }, "WXct1Wg4sQ", "c3e7c1cfad40dcf828a1fa96163d471023fcf861fee914a456dbcf5e1144a023");
    }
    // destination AmplitudeCustom
    AmplitudeCustom.logEvent("Manager Review Viewed", (Object as any).assign({}, eventPropertiesAmplitudeCustom));
  } else {
    // do nothing
  }
}

export interface EmployeeProfileViewedProperties {
  context: string | null | undefined;
  calibrationViewId: number | null | undefined;
}
/**
 * Employee Profile Viewed: A user views the Employee Profile. The Employee Profile can be accessed from multiple places - e.g. a calibration, a manager review. The Employee Profile can contain the employee feedback, self-reflections, goals, past performance ratings, notes and general information.
 *
 * When to trigger this event:
 * 1. An admin clicks on the Employee Profile button from a calibration.
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/events/6hv5E4D5t8/trigger/mqttCl4Lm
 * 2. A user clicks on the Employee Profile button from a calibration that has been shared with them.
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/events/6hv5E4D5t8/trigger/EqvZQMCDa
 *
 * @param properties the properties associatied with this event
 * @param properties.context: In what context was this action performed? For events that can occur from more than one location (e.g. feedback given in Slack or the main app? manager review viewed from the admin view, the manager view, the subject view, the admin calibration view or the shared calibration view?)
 * @param properties.calibrationViewId: ID of the calibration view the event originated from.
 *
 * @see {@link https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/events/6hv5E4D5t8}
 */
export function employeeProfileViewed(
  properties: EmployeeProfileViewedProperties) {
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "6hv5E4D5t8", "2aa63e8bd689d0fe35578f2ea9084dd7d14d812c267b57c25df55defcac61e2a", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Employee Profile Viewed", {
      "Context": properties.context,
      "Calibration view ID": properties.calibrationViewId,
      }, {});
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("6hv5E4D5t8", "Employee Profile Viewed", messages, [
      {id: "B-_x0L8pUXgk", name: "Context", value: properties.context},
      {id: "HmZojgQc2T", name: "Calibration view ID", value: properties.calibrationViewId},
      ], []);
    }
  }

  // @ts-ignore
  let eventProperties: any = {};
  if (properties.context !== undefined && properties.context !== null) {
    eventProperties["Context"] = properties.context;
  }
  if (properties.calibrationViewId !== undefined && properties.calibrationViewId !== null) {
    eventProperties["Calibration view ID"] = properties.calibrationViewId;
  }

  // @ts-ignore
  let userProperties: any = {};

  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Employee Profile Viewed", {
        "Context": properties.context,
        "Calibration view ID": properties.calibrationViewId,
        }, "6hv5E4D5t8", "2aa63e8bd689d0fe35578f2ea9084dd7d14d812c267b57c25df55defcac61e2a");
    }
    // destination AmplitudeCustom
    AmplitudeCustom.logEvent("Employee Profile Viewed", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface CalibrationViewStatisticsInteractedProperties {
  calibrationViewId: number;
  interaction: string;
  elementClickedName: string;
  context: string | null | undefined;
}
/**
 * Calibration View Statistics Interacted: A user interacts with the calibration hero statistics. They can select to show the Assessment Group (default) or the Status statistics. They can also select a particular assessment group or status to filter the manager reviews in the calibration.
 *
 * When to trigger this event:
 * 1. An admin selects the 'Status' or the 'Assessment groups' button to change the Hero Statistics at the top of the screen of a calibration.
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/events/j2wWJnZ-cn/trigger/pu5GtH6g0
 * 2. An admin selects a particular assessment group or status to filter the manager reviews in the calibration.
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/events/j2wWJnZ-cn/trigger/uKMM8IdME
 * 3. A user selects the 'Status' or the 'Assessment groups' button to change the Hero Statistics in a calibration that has been shared with them.
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/events/j2wWJnZ-cn/trigger/fCbwp2ytY
 * 4. A user selects a particular assessment group or status to filter the manager reviews in a calibration that has been shared with them
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/events/j2wWJnZ-cn/trigger/-X3hdklLc
 *
 * @param properties the properties associatied with this event
 * @param properties.calibrationViewId: ID of the calibration view the event originated from.
 * @param properties.interaction: The kind of interaction the user performed with the element. eg. opened, closed, skipped, selected, deselected, added, removed, changed, sorted
 * @param properties.elementClickedName: The name of the element clicked. If it's a hyper link this will be the link text, for buttons, the button name.
 * @param properties.context: In what context was this action performed? For events that can occur from more than one location (e.g. feedback given in Slack or the main app? manager review viewed from the admin view, the manager view, the subject view, the admin calibration view or the shared calibration view?)
 *
 * @see {@link https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/events/j2wWJnZ-cn}
 */
export function calibrationViewStatisticsInteracted(
  properties: CalibrationViewStatisticsInteractedProperties) {
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "j2wWJnZ-cn", "ddf47f31a8e302088d51553f067606bc29a40015f747f68be4ff3cdd67233e5b", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Calibration View Statistics Interacted", {
      "Calibration view ID": properties.calibrationViewId,
      "Interaction": properties.interaction,
      "Element clicked name": properties.elementClickedName,
      "Context": properties.context,
      }, {});
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("j2wWJnZ-cn", "Calibration View Statistics Interacted", messages, [
      {id: "HmZojgQc2T", name: "Calibration view ID", value: properties.calibrationViewId},
      {id: "NwXyKAX00-Ye", name: "Interaction", value: properties.interaction},
      {id: "ZnfX_VrsAxb3", name: "Element clicked name", value: properties.elementClickedName},
      {id: "B-_x0L8pUXgk", name: "Context", value: properties.context},
      ], []);
    }
  }

  // @ts-ignore
  let eventProperties: any = {};
  eventProperties["Calibration view ID"] = properties.calibrationViewId;
  eventProperties["Interaction"] = properties.interaction;
  eventProperties["Element clicked name"] = properties.elementClickedName;
  if (properties.context !== undefined && properties.context !== null) {
    eventProperties["Context"] = properties.context;
  }

  // @ts-ignore
  let userProperties: any = {};

  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Calibration View Statistics Interacted", {
        "Calibration view ID": properties.calibrationViewId,
        "Interaction": properties.interaction,
        "Element clicked name": properties.elementClickedName,
        "Context": properties.context,
        }, "j2wWJnZ-cn", "ddf47f31a8e302088d51553f067606bc29a40015f747f68be4ff3cdd67233e5b");
    }
    // destination AmplitudeCustom
    AmplitudeCustom.logEvent("Calibration View Statistics Interacted", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface CalibrationViewExportedProperties {
  calibrationViewId: number;
  viewedInFullWidth: boolean;
}
/**
 * Calibration View Exported: An admin requests to export the data from a calibration view to a csv. The csv is received via email.
 *
 * When to trigger this event:
 * 1. An admin clicks on the 'Export CSV' button in a calibration view.
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/events/tch7wE5hHP/trigger/HaENtHtyU
 *
 * @param properties the properties associatied with this event
 * @param properties.calibrationViewId: ID of the calibration view the event originated from.
 * @param properties.viewedInFullWidth: Is the calibration viewed in full width?
 *
 * @see {@link https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/events/tch7wE5hHP}
 */
export function calibrationViewExported(
  properties: CalibrationViewExportedProperties) {
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "tch7wE5hHP", "c45263e4e6461e3e04a7e1b30f2d10d27b561ac5c9072b2f9c776745a673d0ae", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Calibration View Exported", {
      "Calibration view ID": properties.calibrationViewId,
      "Viewed in full width": properties.viewedInFullWidth,
      }, {});
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("tch7wE5hHP", "Calibration View Exported", messages, [
      {id: "HmZojgQc2T", name: "Calibration view ID", value: properties.calibrationViewId},
      {id: "7mOpOcARk", name: "Viewed in full width", value: properties.viewedInFullWidth},
      ], []);
    }
  }

  // @ts-ignore
  let eventProperties: any = {};
  eventProperties["Calibration view ID"] = properties.calibrationViewId;
  eventProperties["Viewed in full width"] = properties.viewedInFullWidth;

  // @ts-ignore
  let userProperties: any = {};

  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Calibration View Exported", {
        "Calibration view ID": properties.calibrationViewId,
        "Viewed in full width": properties.viewedInFullWidth,
        }, "tch7wE5hHP", "c45263e4e6461e3e04a7e1b30f2d10d27b561ac5c9072b2f9c776745a673d0ae");
    }
    // destination AmplitudeCustom
    AmplitudeCustom.logEvent("Calibration View Exported", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface CalibrationViewManagerRatingChangedProperties {
  calibrationViewId: number;
  performanceQuestionType: PerformanceQuestionTypeValueType;
  managerReviewCycleId: number;
  managerReviewId: number;
}
/**
 * Calibration View Manager Rating Changed: An admin updates a Manager Review rating (assessment group or rating question) from a calibration
 *
 * When to trigger this event:
 * 1. An admin changes the Rating question or Assessment Group question within the calibration via the column drop-downs.
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/events/XJiChHpesm/trigger/YD2DVTZwo
 *
 * @param properties the properties associatied with this event
 * @param properties.calibrationViewId: ID of the calibration view the event originated from.
 * @param properties.performanceQuestionType: Performance has a number of question types, including Rating, Multiple-choice, Free text, and Assessment group.
 * @param properties.managerReviewCycleId: The ID of the manager review cycle (also known as the performance cycle) within a performance review cycle (also known as the evaluation cycle).
 * @param properties.managerReviewId: The ID of the individual manager review for a specific direct report.
 *
 * @see {@link https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/events/XJiChHpesm}
 */
export function calibrationViewManagerRatingChanged(
  properties: CalibrationViewManagerRatingChangedProperties) {
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "XJiChHpesm", "606645738bbcbc190ad969d728b71b01ba467deea3f0374e2265d486c4432de2", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Calibration View Manager Rating Changed", {
      "Calibration view ID": properties.calibrationViewId,
      "Performance question type": properties.performanceQuestionType,
      "Manager review cycle ID": properties.managerReviewCycleId,
      "Manager review ID": properties.managerReviewId,
      }, {});
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("XJiChHpesm", "Calibration View Manager Rating Changed", messages, [
      {id: "HmZojgQc2T", name: "Calibration view ID", value: properties.calibrationViewId},
      {id: "2iWw27Qsg", name: "Performance question type", value: properties.performanceQuestionType},
      {id: "CeOT3CL22hYH", name: "Manager review cycle ID", value: properties.managerReviewCycleId},
      {id: "8GRMbCCGQp", name: "Manager review ID", value: properties.managerReviewId},
      ], []);
    }
  }

  // @ts-ignore
  let eventPropertiesAmplitudeCustom: any = {};
  eventPropertiesAmplitudeCustom["Calibration view ID"] = properties.calibrationViewId;
  eventPropertiesAmplitudeCustom["Performance question type"] = properties.performanceQuestionType;
  eventPropertiesAmplitudeCustom["Performance cycle ID"] = properties.managerReviewCycleId;
  eventPropertiesAmplitudeCustom["Manager review ID"] = properties.managerReviewId;

  // @ts-ignore
  let userPropertiesAmplitudeCustom: any = {};

  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Calibration View Manager Rating Changed", {
        "Calibration view ID": properties.calibrationViewId,
        "Performance question type": properties.performanceQuestionType,
        "Manager review cycle ID": properties.managerReviewCycleId,
        "Manager review ID": properties.managerReviewId,
        }, "XJiChHpesm", "606645738bbcbc190ad969d728b71b01ba467deea3f0374e2265d486c4432de2");
    }
    // destination AmplitudeCustom
    AmplitudeCustom.logEvent("Calibration View Manager Rating Changed", (Object as any).assign({}, eventPropertiesAmplitudeCustom));
  } else {
    // do nothing
  }
}

/**
 * Create Calibration View Started: An admin starts setting up a new calibration view.
 *
 * When to trigger this event:
 * 1. An admin views the page to set up a calibration view after clicking on the 'Create calibration view' button.
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/events/If9EkVKZhR/trigger/G-zKaxWr7
 *
 * @see {@link https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/events/If9EkVKZhR}
 */
export function createCalibrationViewStarted() {
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "If9EkVKZhR", "c2be04e31381280d520b4899e02da9f2e2dd6e514c063294e6f05e399f2620af", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Create Calibration View Started", {}, {});
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("If9EkVKZhR", "Create Calibration View Started", messages, [], []);
    }
  }

  // @ts-ignore
  let eventProperties: any = {};

  // @ts-ignore
  let userProperties: any = {};

  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Create Calibration View Started", {}, "If9EkVKZhR", "c2be04e31381280d520b4899e02da9f2e2dd6e514c063294e6f05e399f2620af");
    }
    // destination AmplitudeCustom
    AmplitudeCustom.logEvent("Create Calibration View Started", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface CalibrationViewCreatedProperties {
  calibrationViewId: number;
  viewedInFullWidth: boolean;
}
/**
 * Calibration View Created: An admin creates a new calibration view.
 *
 * When to trigger this event:
 * 1. An admin creates a new calibration view by clicking on the 'Create view' button, writing a name and clicking on the 'Create' button.
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/events/6h0wwmAEsi/trigger/Y1J6WI9rL
 *
 * @param properties the properties associatied with this event
 * @param properties.calibrationViewId: ID of the calibration view the event originated from.
 * @param properties.viewedInFullWidth: Is the calibration viewed in full width?
 *
 * @see {@link https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/events/6h0wwmAEsi}
 */
export function calibrationViewCreated(
  properties: CalibrationViewCreatedProperties) {
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "6h0wwmAEsi", "1192114e6c5b4d4826705cf957467114ba54df34d546fd4eaee6d7e00046b5b3", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Calibration View Created", {
      "Calibration view ID": properties.calibrationViewId,
      "Viewed in full width": properties.viewedInFullWidth,
      }, {});
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("6h0wwmAEsi", "Calibration View Created", messages, [
      {id: "HmZojgQc2T", name: "Calibration view ID", value: properties.calibrationViewId},
      {id: "7mOpOcARk", name: "Viewed in full width", value: properties.viewedInFullWidth},
      ], []);
    }
  }

  // @ts-ignore
  let eventProperties: any = {};
  eventProperties["Calibration view ID"] = properties.calibrationViewId;
  eventProperties["Viewed in full width"] = properties.viewedInFullWidth;

  // @ts-ignore
  let userProperties: any = {};

  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Calibration View Created", {
        "Calibration view ID": properties.calibrationViewId,
        "Viewed in full width": properties.viewedInFullWidth,
        }, "6h0wwmAEsi", "1192114e6c5b4d4826705cf957467114ba54df34d546fd4eaee6d7e00046b5b3");
    }
    // destination AmplitudeCustom
    AmplitudeCustom.logEvent("Calibration View Created", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface CalibrationViewPublishedProperties {
  calibrationViewId: number;
}
/**
 * Calibration View Published: An admin confirms publish for a calibration view.

A calibration view is usually published when a calibration session has taken place and ratings have changed or notes have been taken. On publishing the calibration view the changes to assessment groups will be shared with the respective managers, and the rating values will no longer be editable in the calibration view.
 *
 * When to trigger this event:
 * 1. An admin clicks 'Publish' once seeing the 'Confirm' pop up box.

The admin triggers the 'Confirm' pop up box after clicking on the Aqua 'Publish' button on the top right of the screen.
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/events/j95Buf8BNu/trigger/y0XvR2brB
 *
 * @param properties the properties associatied with this event
 * @param properties.calibrationViewId: ID of the calibration view the event originated from.
 *
 * @see {@link https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/events/j95Buf8BNu}
 */
export function calibrationViewPublished(
  properties: CalibrationViewPublishedProperties) {
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "j95Buf8BNu", "101264e29e94e5f4777acd36dc1bfee0a9107e9e2d1aec1364b13787afdd0bbf", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Calibration View Published", {
      "Calibration view ID": properties.calibrationViewId,
      }, {});
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("j95Buf8BNu", "Calibration View Published", messages, [
      {id: "HmZojgQc2T", name: "Calibration view ID", value: properties.calibrationViewId},
      ], []);
    }
  }

  // @ts-ignore
  let eventProperties: any = {};
  eventProperties["Calibration view ID"] = properties.calibrationViewId;

  // @ts-ignore
  let userProperties: any = {};

  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Calibration View Published", {
        "Calibration view ID": properties.calibrationViewId,
        }, "j95Buf8BNu", "101264e29e94e5f4777acd36dc1bfee0a9107e9e2d1aec1364b13787afdd0bbf");
    }
    // destination AmplitudeCustom
    AmplitudeCustom.logEvent("Calibration View Published", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface PublishCalibrationModalViewedProperties {
  calibrationViewId: number;
}
/**
 * Publish Calibration Modal Viewed: An admin views the modal to confirm publishing a calibration view.
 *
 * When to trigger this event:
 * 1. An admin views the modal to confirm publishing a calibration view after hitting the green 'Publish' button
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/events/GLk9Zvh3Mj/trigger/KuGdPLySj
 *
 * @param properties the properties associatied with this event
 * @param properties.calibrationViewId: ID of the calibration view the event originated from.
 *
 * @see {@link https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/events/GLk9Zvh3Mj}
 */
export function publishCalibrationModalViewed(
  properties: PublishCalibrationModalViewedProperties) {
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "GLk9Zvh3Mj", "07408b02d04aa84153cc222e466a9c044cf577dce5b7e2cd36b7d63e7ade065e", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Publish Calibration Modal Viewed", {
      "Calibration view ID": properties.calibrationViewId,
      }, {});
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("GLk9Zvh3Mj", "Publish Calibration Modal Viewed", messages, [
      {id: "HmZojgQc2T", name: "Calibration view ID", value: properties.calibrationViewId},
      ], []);
    }
  }

  // @ts-ignore
  let eventProperties: any = {};
  eventProperties["Calibration view ID"] = properties.calibrationViewId;

  // @ts-ignore
  let userProperties: any = {};

  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Publish Calibration Modal Viewed", {
        "Calibration view ID": properties.calibrationViewId,
        }, "GLk9Zvh3Mj", "07408b02d04aa84153cc222e466a9c044cf577dce5b7e2cd36b7d63e7ade065e");
    }
    // destination AmplitudeCustom
    AmplitudeCustom.logEvent("Publish Calibration Modal Viewed", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface ManagerReviewDashboardColumnsChangedProperties {
  columnType: string;
  performanceReviewCycleId: number;
  managerReviewCycleId: number | null | undefined;
  interaction: string;
}
/**
 * Manager Review Dashboard Columns Changed: A manager hides or exposes a column when viewing the manager review dashboard for their direct reports.
 *
 * When to trigger this event:
 * 1. In the direct reports' manager review dashboard, a manager clicks on the 'Manage columns' dropdown and then deselects a column.
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/events/0FVGyVUN6r/trigger/ik3aSJx1e
 * 2. In the direct reports' manager review dashboard, a manager clicks on the 'Manage columns' dropdown and then selects a column.
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/events/0FVGyVUN6r/trigger/qz8X4E3gK
 *
 * @param properties the properties associatied with this event
 * @param properties.columnType: The type of the column added or removed from the table, or sorted. In performance: 'Job title', 'Manager', 'Status', etc. In Group Search, 'Responses', 'Index factor', 'Index factor change', 'Comparison', etc.
 * @param properties.performanceReviewCycleId: The ID of the performance review cycle (also known as evaluation cycle).
 * @param properties.managerReviewCycleId: The ID of the manager review cycle (also known as the performance cycle) within a performance review cycle (also known as the evaluation cycle).
 * @param properties.interaction: The kind of interaction the user performed with the element. eg. opened, closed, skipped, selected, deselected, added, removed, changed, sorted
 *
 * @see {@link https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/events/0FVGyVUN6r}
 */
export function managerReviewDashboardColumnsChanged(
  properties: ManagerReviewDashboardColumnsChangedProperties) {
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "0FVGyVUN6r", "0a6ff82365acff50d15772d8c1f78071c2c1267ead8813aad8f621a8f239d437", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Manager Review Dashboard Columns Changed", {
      "Column type": properties.columnType,
      "Performance review cycle ID": properties.performanceReviewCycleId,
      "Manager review cycle ID": properties.managerReviewCycleId,
      "Interaction": properties.interaction,
      }, {});
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("0FVGyVUN6r", "Manager Review Dashboard Columns Changed", messages, [
      {id: "nwjVfmn9e", name: "Column type", value: properties.columnType},
      {id: "1xneEbR4cM", name: "Performance review cycle ID", value: properties.performanceReviewCycleId},
      {id: "CeOT3CL22hYH", name: "Manager review cycle ID", value: properties.managerReviewCycleId},
      {id: "NwXyKAX00-Ye", name: "Interaction", value: properties.interaction},
      ], []);
    }
  }

  // @ts-ignore
  let eventPropertiesAmplitudeCustom: any = {};
  eventPropertiesAmplitudeCustom["Column type"] = properties.columnType;
  eventPropertiesAmplitudeCustom["Performance review cycle ID"] = properties.performanceReviewCycleId;
  if (properties.managerReviewCycleId !== undefined && properties.managerReviewCycleId !== null) {
    eventPropertiesAmplitudeCustom["Performance cycle ID"] = properties.managerReviewCycleId;
  }
  eventPropertiesAmplitudeCustom["Interaction"] = properties.interaction;

  // @ts-ignore
  let userPropertiesAmplitudeCustom: any = {};

  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Manager Review Dashboard Columns Changed", {
        "Column type": properties.columnType,
        "Performance review cycle ID": properties.performanceReviewCycleId,
        "Manager review cycle ID": properties.managerReviewCycleId,
        "Interaction": properties.interaction,
        }, "0FVGyVUN6r", "0a6ff82365acff50d15772d8c1f78071c2c1267ead8813aad8f621a8f239d437");
    }
    // destination AmplitudeCustom
    AmplitudeCustom.logEvent("Manager Review Dashboard Columns Changed", (Object as any).assign({}, eventPropertiesAmplitudeCustom));
  } else {
    // do nothing
  }
}

export interface DirectReportsPerformanceReviewCycleViewedProperties {
  performanceReviewCycleId: number;
  activeTab: string;
}
/**
 * Direct Reports Performance Review Cycle Viewed: A manager views the dashboard with information about their direct reports in a particular performance review cycle.
 *
 * When to trigger this event:
 * 1. A manager views the dashboard with information about their direct reports in a particular performance review cycle.
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/events/IbH4tGYv6Y/trigger/5edakAppM
 *
 * @param properties the properties associatied with this event
 * @param properties.performanceReviewCycleId: The ID of the performance review cycle (also known as evaluation cycle).
 * @param properties.activeTab: The tab rendered when a page has multiple tabs (e.g. Manager Review, Feedback, Sharing)
 *
 * @see {@link https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/events/IbH4tGYv6Y}
 */
export function directReportsPerformanceReviewCycleViewed(
  properties: DirectReportsPerformanceReviewCycleViewedProperties) {
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "IbH4tGYv6Y", "f80b4c55345c99e0596f5c2b02b70e51300ccb299b60d6886c58464c2deb0d7e", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Direct Reports Performance Review Cycle Viewed", {
      "Performance review cycle ID": properties.performanceReviewCycleId,
      "Active tab": properties.activeTab,
      }, {});
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("IbH4tGYv6Y", "Direct Reports Performance Review Cycle Viewed", messages, [
      {id: "1xneEbR4cM", name: "Performance review cycle ID", value: properties.performanceReviewCycleId},
      {id: "Gn29cvZD7r", name: "Active tab", value: properties.activeTab},
      ], []);
    }
  }

  // @ts-ignore
  let eventProperties: any = {};
  eventProperties["Performance review cycle ID"] = properties.performanceReviewCycleId;
  eventProperties["Active tab"] = properties.activeTab;

  // @ts-ignore
  let userProperties: any = {};

  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Direct Reports Performance Review Cycle Viewed", {
        "Performance review cycle ID": properties.performanceReviewCycleId,
        "Active tab": properties.activeTab,
        }, "IbH4tGYv6Y", "f80b4c55345c99e0596f5c2b02b70e51300ccb299b60d6886c58464c2deb0d7e");
    }
    // destination AmplitudeCustom
    AmplitudeCustom.logEvent("Direct Reports Performance Review Cycle Viewed", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface AdminCalibrationNotesOpenedProperties {
  calibrationViewId: number;
  managerReviewId: number;
  calibrationViewStatus: string;
  hasNotes: boolean;
}
/**
 * Admin Calibration Notes Opened: An admin opens the calibration notes dossier for a manager review throughout the calibration process.
Calibration notes are intended to be shared with managers once the calibration process is complete.
 *
 * When to trigger this event:
 * 1. Triggered when an admin selects Calibration notes from the ellipses menu next to a manager review in a calibration view.
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/events/db36jFM3cH/trigger/j2hXVVNkh
 *
 * @param properties the properties associatied with this event
 * @param properties.calibrationViewId: ID of the calibration view the event originated from.
 * @param properties.managerReviewId: The ID of the individual manager review for a specific direct report.
 * @param properties.calibrationViewStatus: The status of the calibration view: 'In progress' or 'Published'. After the calibration view is published, the ratings values and the calibration notes are no longer editable.
 * @param properties.hasNotes: Are there any calibration notes in the dossier when the user opens it?
 *
 * @see {@link https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/events/db36jFM3cH}
 */
export function adminCalibrationNotesOpened(
  properties: AdminCalibrationNotesOpenedProperties) {
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "db36jFM3cH", "c464d9fca290da4d5693a811f02d0d86012f8b82d2de91fc5710771f15e5a62d", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Admin Calibration Notes Opened", {
      "Calibration view ID": properties.calibrationViewId,
      "Manager review ID": properties.managerReviewId,
      "Calibration view status": properties.calibrationViewStatus,
      "Has notes": properties.hasNotes,
      }, {});
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("db36jFM3cH", "Admin Calibration Notes Opened", messages, [
      {id: "HmZojgQc2T", name: "Calibration view ID", value: properties.calibrationViewId},
      {id: "8GRMbCCGQp", name: "Manager review ID", value: properties.managerReviewId},
      {id: "-I9RKw05Ng", name: "Calibration view status", value: properties.calibrationViewStatus},
      {id: "87p77L1nDL", name: "Has notes", value: properties.hasNotes},
      ], []);
    }
  }

  // @ts-ignore
  let eventProperties: any = {};
  eventProperties["Calibration view ID"] = properties.calibrationViewId;
  eventProperties["Manager review ID"] = properties.managerReviewId;
  eventProperties["Calibration view status"] = properties.calibrationViewStatus;
  eventProperties["Has notes"] = properties.hasNotes;

  // @ts-ignore
  let userProperties: any = {};

  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Admin Calibration Notes Opened", {
        "Calibration view ID": properties.calibrationViewId,
        "Manager review ID": properties.managerReviewId,
        "Calibration view status": properties.calibrationViewStatus,
        "Has notes": properties.hasNotes,
        }, "db36jFM3cH", "c464d9fca290da4d5693a811f02d0d86012f8b82d2de91fc5710771f15e5a62d");
    }
    // destination AmplitudeCustom
    AmplitudeCustom.logEvent("Admin Calibration Notes Opened", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface CalibrationNoteSavedProperties {
  calibrationViewId: number;
  managerReviewId: number;
  isNew: boolean;
}
/**
 * Calibration Note Saved: An admin saves a calibration note for a manager review throughout the calibration process. Calibration notes are intended to be shared with managers once the calibration process is complete.
 *
 * When to trigger this event:
 * 1. Triggered when an admin selects the Save button after creating or editing a calibration note.

Admin can open the calibration notes for a manager review by selecting Calibration notes from the ellipses menu next to it.
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/events/DGFNrT0AIX/trigger/LgfaGpj8K
 *
 * @param properties the properties associatied with this event
 * @param properties.calibrationViewId: ID of the calibration view the event originated from.
 * @param properties.managerReviewId: The ID of the individual manager review for a specific direct report.
 * @param properties.isNew: Did the user create a new note as result of this interaction? (vs editing an existing note)
 *
 * @see {@link https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/events/DGFNrT0AIX}
 */
export function calibrationNoteSaved(
  properties: CalibrationNoteSavedProperties) {
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "DGFNrT0AIX", "1b70a2bbf44182d8e1ecb26777c80bf4d6a58ad1ba1925566e92a24797f0bcf2", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Calibration Note Saved", {
      "Calibration view ID": properties.calibrationViewId,
      "Manager review ID": properties.managerReviewId,
      "Is new": properties.isNew,
      }, {});
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("DGFNrT0AIX", "Calibration Note Saved", messages, [
      {id: "HmZojgQc2T", name: "Calibration view ID", value: properties.calibrationViewId},
      {id: "8GRMbCCGQp", name: "Manager review ID", value: properties.managerReviewId},
      {id: "8huAp7dv3l", name: "Is new", value: properties.isNew},
      ], []);
    }
  }

  // @ts-ignore
  let eventProperties: any = {};
  eventProperties["Calibration view ID"] = properties.calibrationViewId;
  eventProperties["Manager review ID"] = properties.managerReviewId;
  eventProperties["Is new"] = properties.isNew;

  // @ts-ignore
  let userProperties: any = {};

  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Calibration Note Saved", {
        "Calibration view ID": properties.calibrationViewId,
        "Manager review ID": properties.managerReviewId,
        "Is new": properties.isNew,
        }, "DGFNrT0AIX", "1b70a2bbf44182d8e1ecb26777c80bf4d6a58ad1ba1925566e92a24797f0bcf2");
    }
    // destination AmplitudeCustom
    AmplitudeCustom.logEvent("Calibration Note Saved", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface ManagerCalibrationNotesViewedProperties {
  managerReviewId: number;
  performanceReviewCycleId: number;
}
/**
 * Manager Calibration Notes Viewed: A manager views the calibration notes assigned to one of their direct reports in the manager review dashboard. The calibration notes are shared with the manager after the calibration view is published.
 *
 * When to trigger this event:
 * 1. Triggered when the calibration notes dossier is displayed for a particular employee in the manager review dashboard.
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/events/RR1TLueSpk/trigger/jzz_WSY7Y
 *
 * @param properties the properties associatied with this event
 * @param properties.managerReviewId: The ID of the individual manager review for a specific direct report.
 * @param properties.performanceReviewCycleId: The ID of the performance review cycle (also known as evaluation cycle).
 *
 * @see {@link https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/events/RR1TLueSpk}
 */
export function managerCalibrationNotesViewed(
  properties: ManagerCalibrationNotesViewedProperties) {
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "RR1TLueSpk", "3e4174b70b0b92e5da95d6c86668e2946f2663c82ed4766ef7924ac394592966", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Manager Calibration Notes Viewed", {
      "Manager review ID": properties.managerReviewId,
      "Performance review cycle ID": properties.performanceReviewCycleId,
      }, {});
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("RR1TLueSpk", "Manager Calibration Notes Viewed", messages, [
      {id: "8GRMbCCGQp", name: "Manager review ID", value: properties.managerReviewId},
      {id: "1xneEbR4cM", name: "Performance review cycle ID", value: properties.performanceReviewCycleId},
      ], []);
    }
  }

  // @ts-ignore
  let eventProperties: any = {};
  eventProperties["Manager review ID"] = properties.managerReviewId;
  eventProperties["Performance review cycle ID"] = properties.performanceReviewCycleId;

  // @ts-ignore
  let userProperties: any = {};

  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Manager Calibration Notes Viewed", {
        "Manager review ID": properties.managerReviewId,
        "Performance review cycle ID": properties.performanceReviewCycleId,
        }, "RR1TLueSpk", "3e4174b70b0b92e5da95d6c86668e2946f2663c82ed4766ef7924ac394592966");
    }
    // destination AmplitudeCustom
    AmplitudeCustom.logEvent("Manager Calibration Notes Viewed", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface ManagerReviewSharingPermissionsUpdatedProperties {
  performanceReviewCycleId: number;
  managerReviewCycleId: number;
  sharingEnabled: boolean;
}
/**
 * Manager Review Sharing Permissions Updated: An admin enables or disables sharing the manager review for a particular performance review cycle.
 *
 * When to trigger this event:
 * 1. An admin toggles the manager review 'Allow sharing' button.
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/events/L6tRGTQGwu/trigger/lnvQy0Zyh
 *
 * @param properties the properties associatied with this event
 * @param properties.performanceReviewCycleId: The ID of the performance review cycle (also known as evaluation cycle).
 * @param properties.managerReviewCycleId: The ID of the manager review cycle (also known as the performance cycle) within a performance review cycle (also known as the evaluation cycle).
 * @param properties.sharingEnabled: Is sharing enabled after this interaction?
 *
 * @see {@link https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/events/L6tRGTQGwu}
 */
export function managerReviewSharingPermissionsUpdated(
  properties: ManagerReviewSharingPermissionsUpdatedProperties) {
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "L6tRGTQGwu", "459b78c0c8684a675b5a404353b70745440624a89baa541cba3d9bc0be3f2c24", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Manager Review Sharing Permissions Updated", {
      "Performance review cycle ID": properties.performanceReviewCycleId,
      "Manager review cycle ID": properties.managerReviewCycleId,
      "Sharing enabled": properties.sharingEnabled,
      }, {});
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("L6tRGTQGwu", "Manager Review Sharing Permissions Updated", messages, [
      {id: "1xneEbR4cM", name: "Performance review cycle ID", value: properties.performanceReviewCycleId},
      {id: "CeOT3CL22hYH", name: "Manager review cycle ID", value: properties.managerReviewCycleId},
      {id: "c6gTY2cWWtSP", name: "Sharing enabled", value: properties.sharingEnabled},
      ], []);
    }
  }

  // @ts-ignore
  let eventPropertiesAmplitudeCustom: any = {};
  eventPropertiesAmplitudeCustom["Performance review cycle ID"] = properties.performanceReviewCycleId;
  eventPropertiesAmplitudeCustom["Performance cycle ID"] = properties.managerReviewCycleId;
  eventPropertiesAmplitudeCustom["Sharing enabled"] = properties.sharingEnabled;

  // @ts-ignore
  let userPropertiesAmplitudeCustom: any = {};

  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Manager Review Sharing Permissions Updated", {
        "Performance review cycle ID": properties.performanceReviewCycleId,
        "Manager review cycle ID": properties.managerReviewCycleId,
        "Sharing enabled": properties.sharingEnabled,
        }, "L6tRGTQGwu", "459b78c0c8684a675b5a404353b70745440624a89baa541cba3d9bc0be3f2c24");
    }
    // destination AmplitudeCustom
    AmplitudeCustom.logEvent("Manager Review Sharing Permissions Updated", (Object as any).assign({}, eventPropertiesAmplitudeCustom));
  } else {
    // do nothing
  }
}

/**
 * Goal Tree Expanded: A user expands a company goal in the Goal Tree view.
 *
 * When to trigger this event:
 * 1. Triggered on button click
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/events/jvOkvDd-IX/trigger/J54_zlDRY
 *
 * @see {@link https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/events/jvOkvDd-IX}
 */
export function goalTreeExpanded() {
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "jvOkvDd-IX", "15d121c6cde869521ce599e20ee9b25fccd3b4cd57b69b0e7ec6f9b8d5432335", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Goal Tree Expanded", {}, {});
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("jvOkvDd-IX", "Goal Tree Expanded", messages, [], []);
    }
  }

  // @ts-ignore
  let eventProperties: any = {};

  // @ts-ignore
  let userProperties: any = {};

  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Goal Tree Expanded", {}, "jvOkvDd-IX", "15d121c6cde869521ce599e20ee9b25fccd3b4cd57b69b0e7ec6f9b8d5432335");
    }
    // destination AmplitudeCustom
    AmplitudeCustom.logEvent("Goal Tree Expanded", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface PerformanceGoalViewedProperties {
  goalId: string[];
  goalType: GoalTypeValueType;
  navigationSource: string | null | undefined;
}
/**
 * Performance Goal Viewed: A user views a performance goal.
 *
 * When to trigger this event:
 * 1. Any user clicks on a goal in the list of goals, which opens up a drawer where they can edit the goal.
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/events/tAZ0yocGKN/trigger/w9UfUxOC9
 *
 * @param properties the properties associatied with this event
 * @param properties.goalId: The system ID of the Performance Goal.
 * @param properties.goalType: Type of goal set e.g. company level goal
 * @param properties.navigationSource: How did the user navigate to completing this event (e.g. from the task widget, the platform menus or a platform communication?)
 *
 * @see {@link https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/events/tAZ0yocGKN}
 */
export function performanceGoalViewed(
  properties: PerformanceGoalViewedProperties) {
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "tAZ0yocGKN", "b890b8b3cfdd8caeb9e5d237ee0b9cf0f5313569f6caab7ae9a51c1ea8110a05", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Performance Goal Viewed", {
      "Goal ID": properties.goalId,
      "Goal type": properties.goalType,
      "Navigation source": properties.navigationSource,
      }, {});
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("tAZ0yocGKN", "Performance Goal Viewed", messages, [
      {id: "DvqwsgF7b2N1", name: "Goal ID", value: properties.goalId},
      {id: "YlrJzTuaYeG7", name: "Goal type", value: properties.goalType},
      {id: "wtVE7Zo-ioYV", name: "Navigation source", value: properties.navigationSource},
      ], []);
    }
  }

  // @ts-ignore
  let eventProperties: any = {};
  eventProperties["Goal ID"] = properties.goalId;
  eventProperties["Goal type"] = properties.goalType;
  if (properties.navigationSource !== undefined && properties.navigationSource !== null) {
    eventProperties["Navigation source"] = properties.navigationSource;
  }

  // @ts-ignore
  let userProperties: any = {};

  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Performance Goal Viewed", {
        "Goal ID": properties.goalId,
        "Goal type": properties.goalType,
        "Navigation source": properties.navigationSource,
        }, "tAZ0yocGKN", "b890b8b3cfdd8caeb9e5d237ee0b9cf0f5313569f6caab7ae9a51c1ea8110a05");
    }
    // destination AmplitudeCustom
    AmplitudeCustom.logEvent("Performance Goal Viewed", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface CopyGoalPageViewedProperties {
  baseGoalId: string;
}
/**
 * Copy Goal Page Viewed: A user views the page to copy a goal
 *
 * When to trigger this event:
 * 1. On page view
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/events/-Yh8EWceMk/trigger/lHJDhwmYx
 *
 * @param properties the properties associatied with this event
 * @param properties.baseGoalId: The system ID of the original goal that the new goal was copied from.
 *
 * @see {@link https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/events/-Yh8EWceMk}
 */
export function copyGoalPageViewed(properties: CopyGoalPageViewedProperties) {
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "-Yh8EWceMk", "cbf9431fc7cf8a57f57c830aad8cead0e13e9740a927baca7d0a469be979bc7d", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Copy Goal Page Viewed", {
      "Base goal ID": properties.baseGoalId,
      }, {});
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("-Yh8EWceMk", "Copy Goal Page Viewed", messages, [
      {id: "ETzfLMS9XI", name: "Base goal ID", value: properties.baseGoalId},
      ], []);
    }
  }

  // @ts-ignore
  let eventProperties: any = {};
  eventProperties["Base goal ID"] = properties.baseGoalId;

  // @ts-ignore
  let userProperties: any = {};

  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Copy Goal Page Viewed", {
        "Base goal ID": properties.baseGoalId,
        }, "-Yh8EWceMk", "cbf9431fc7cf8a57f57c830aad8cead0e13e9740a927baca7d0a469be979bc7d");
    }
    // destination AmplitudeCustom
    AmplitudeCustom.logEvent("Copy Goal Page Viewed", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface CalibrationViewFullWidthToggledProperties {
  viewedInFullWidth: boolean;
  calibrationViewId: number | null | undefined;
}
/**
 * Calibration View Full Width Toggled: A user toggles the minimize/maximize icon to change the width of the table in the Calibrations pages
 *
 * When to trigger this event:
 * 1. Clicking on the "Minimize width" button reduced the width of the calibrations table
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/events/A5LBiD8ciV/trigger/ZTwA-lutW
 * 2. Clicking on the "Maximize width" button increases the width of the calibrations table
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/events/A5LBiD8ciV/trigger/q9Xh7P9Fc
 *
 * @param properties the properties associatied with this event
 * @param properties.viewedInFullWidth: Is the calibration viewed in full width?
 * @param properties.calibrationViewId: ID of the calibration view the event originated from.
 *
 * @see {@link https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/events/A5LBiD8ciV}
 */
export function calibrationViewFullWidthToggled(
  properties: CalibrationViewFullWidthToggledProperties) {
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "A5LBiD8ciV", "6946a9417e4a57c612b28eb10d01000d695f94b55fcbf487f8aa2e6565f80f5c", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Calibration View Full Width Toggled", {
      "Viewed in full width": properties.viewedInFullWidth,
      "Calibration view ID": properties.calibrationViewId,
      }, {});
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("A5LBiD8ciV", "Calibration View Full Width Toggled", messages, [
      {id: "7mOpOcARk", name: "Viewed in full width", value: properties.viewedInFullWidth},
      {id: "HmZojgQc2T", name: "Calibration view ID", value: properties.calibrationViewId},
      ], []);
    }
  }

  // @ts-ignore
  let eventProperties: any = {};
  eventProperties["Viewed in full width"] = properties.viewedInFullWidth;
  if (properties.calibrationViewId !== undefined && properties.calibrationViewId !== null) {
    eventProperties["Calibration view ID"] = properties.calibrationViewId;
  }

  // @ts-ignore
  let userProperties: any = {};

  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Calibration View Full Width Toggled", {
        "Viewed in full width": properties.viewedInFullWidth,
        "Calibration view ID": properties.calibrationViewId,
        }, "A5LBiD8ciV", "6946a9417e4a57c612b28eb10d01000d695f94b55fcbf487f8aa2e6565f80f5c");
    }
    // destination AmplitudeCustom
    AmplitudeCustom.logEvent("Calibration View Full Width Toggled", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface ManagerReviewAcknowledgedProperties {
  managerReviewId: number;
  managerReviewCycleId: number;
  performanceReviewCycleId: number;
  addedNotes: boolean;
}
/**
 * Manager Review Acknowledged: An employee acknowledges in the platform that their performance review discussion has happened. They can add a note to their acknowledgement.
 *
 * When to trigger this event:
 * 1. A employee clicks on the 'Submit' button after selecting the checkbox to acknowledge their manager review.
 * View in Avo: https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/events/dj_QE_qh7/trigger/34qoVGlp-
 *
 * @param properties the properties associatied with this event
 * @param properties.managerReviewId: The ID of the individual manager review for a specific direct report.
 * @param properties.managerReviewCycleId: The ID of the manager review cycle (also known as the performance cycle) within a performance review cycle (also known as the evaluation cycle).
 * @param properties.performanceReviewCycleId: The ID of the performance review cycle (also known as evaluation cycle).
 * @param properties.addedNotes: Did the employee add any notes to their review acknowledgement?
 *
 * @see {@link https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/events/dj_QE_qh7}
 */
export function managerReviewAcknowledged(
  properties: ManagerReviewAcknowledgedProperties) {
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "dj_QE_qh7", "9a506f1b1db23c00cc6cf76109f590ab75c62fc2514aafb220e9b95e0f9f9304", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Manager Review Acknowledged", {
      "Manager review ID": properties.managerReviewId,
      "Manager review cycle ID": properties.managerReviewCycleId,
      "Performance review cycle ID": properties.performanceReviewCycleId,
      "Added notes": properties.addedNotes,
      }, {});
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("dj_QE_qh7", "Manager Review Acknowledged", messages, [
      {id: "8GRMbCCGQp", name: "Manager review ID", value: properties.managerReviewId},
      {id: "CeOT3CL22hYH", name: "Manager review cycle ID", value: properties.managerReviewCycleId},
      {id: "1xneEbR4cM", name: "Performance review cycle ID", value: properties.performanceReviewCycleId},
      {id: "tS8bM8oJj", name: "Added notes", value: properties.addedNotes},
      ], []);
    }
  }

  // @ts-ignore
  let eventProperties: any = {};
  eventProperties["Manager review ID"] = properties.managerReviewId;
  eventProperties["Manager review cycle ID"] = properties.managerReviewCycleId;
  eventProperties["Performance review cycle ID"] = properties.performanceReviewCycleId;
  eventProperties["Added notes"] = properties.addedNotes;

  // @ts-ignore
  let userProperties: any = {};

  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Manager Review Acknowledged", {
        "Manager review ID": properties.managerReviewId,
        "Manager review cycle ID": properties.managerReviewCycleId,
        "Performance review cycle ID": properties.performanceReviewCycleId,
        "Added notes": properties.addedNotes,
        }, "dj_QE_qh7", "9a506f1b1db23c00cc6cf76109f590ab75c62fc2514aafb220e9b95e0f9f9304");
    }
    // destination AmplitudeCustom
    AmplitudeCustom.logEvent("Manager Review Acknowledged", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export default {
  AvoEnv,
  initAvo,
  avoInspectorApiKey,
  PerformanceQuestionType,
  GoalPriority,
  OneOn1Role,
  Visibility,
  GoalType,
  UpdateAction,
  Role,
  IsAlignedTo,
  calibrationViewEdited,
  calibrationViewSharingUpdated,
  calibrationViewViewed,
  managerReviewSubmitted,
  companyGoalsIndexViewed,
  individualGoalsIndexViewed,
  performanceGoalCreated,
  performanceGoalUpdated,
  oneOn1CheckInUpdated,
  oneOn1SpaceViewed,
  calibrationFlagToggled,
  calibrationViewPaginationSizeChanged,
  managerReviewViewed,
  employeeProfileViewed,
  calibrationViewStatisticsInteracted,
  calibrationViewExported,
  calibrationViewManagerRatingChanged,
  createCalibrationViewStarted,
  calibrationViewCreated,
  calibrationViewPublished,
  publishCalibrationModalViewed,
  managerReviewDashboardColumnsChanged,
  directReportsPerformanceReviewCycleViewed,
  adminCalibrationNotesOpened,
  calibrationNoteSaved,
  managerCalibrationNotesViewed,
  managerReviewSharingPermissionsUpdated,
  goalTreeExpanded,
  performanceGoalViewed,
  copyGoalPageViewed,
  calibrationViewFullWidthToggled,
  managerReviewAcknowledged,
}

// AVOMODULEMAP:"Avo"
// AVOEVENTMAP:["calibrationViewEdited","calibrationViewSharingUpdated","calibrationViewViewed","managerReviewSubmitted","companyGoalsIndexViewed","individualGoalsIndexViewed","performanceGoalCreated","performanceGoalUpdated","oneOn1CheckInUpdated","oneOn1SpaceViewed","calibrationFlagToggled","calibrationViewPaginationSizeChanged","managerReviewViewed","employeeProfileViewed","calibrationViewStatisticsInteracted","calibrationViewExported","calibrationViewManagerRatingChanged","createCalibrationViewStarted","calibrationViewCreated","calibrationViewPublished","publishCalibrationModalViewed","managerReviewDashboardColumnsChanged","directReportsPerformanceReviewCycleViewed","adminCalibrationNotesOpened","calibrationNoteSaved","managerCalibrationNotesViewed","managerReviewSharingPermissionsUpdated","goalTreeExpanded","performanceGoalViewed","copyGoalPageViewed","calibrationViewFullWidthToggled","managerReviewAcknowledged"]
