import React, { useEffect, useState } from "react"
import { ProgressSlider } from "@Goals/components"
import useDebounce from "@Hooks/timeout/useDebounce"

type ProgressSliderFacadeProps = {
  progress: number
  onProgressChange: (v: number) => void
}

export const ProgressSliderFacade = (props: ProgressSliderFacadeProps) => {
  const [internalProgress, setInternalProgress] = useState(props.progress)
  const [isDirty, setIsDirty] = useState(false)

  const debouncedInternalProgress = useDebounce(internalProgress, 500)

  useEffect(() => {
    setIsDirty(false)
    setInternalProgress(props.progress)
  }, [props.progress])

  useEffect(() => {
    if (isDirty) {
      props.onProgressChange(debouncedInternalProgress)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedInternalProgress])

  const handleOnProgressChange = (val: number) => {
    setIsDirty(true)
    setInternalProgress(val)
  }

  return (
    <ProgressSlider
      min={0}
      max={100}
      showSpokes={false}
      showThumbValue
      thumbValueUnits={"%"}
      progress={internalProgress}
      onProgressChange={handleOnProgressChange}
    />
  )
}
