import React, { CSSProperties, FunctionComponent } from "react"
import "./Loader.scss"
import Aid from "@OneOnOnes/constants/automationId"
import Icon from "../Icon/Icon"

const Loader: FunctionComponent<{
  className?: string
  style?: CSSProperties
}> = ({ style, className }) => (
  <div
    className={`Loader ${className}`}
    style={style}
    data-automation-id={Aid.loader}
  >
    <Icon iconName="spinner" inheritSize={true} />
  </div>
)

export default Loader
