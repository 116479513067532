import _ from "lodash"
import Reflux from "./ActionsInitializer"
import UIActions from "./UIActions"
import SteadyfootAgent from "./lib/SteadyfootAgent"
import strings from "../locale/strings"
import {
  addLoadingState,
  addToasts,
  extractResponseKey,
} from "./lib/apiActionHelpers"
import endpoints from "../constants/endpointsDeprecated"

const { GENERAL_URLS, USERS_URLS } = endpoints
const { NOTES_URL } = GENERAL_URLS
const { USERS_URL, CURRENT_USER_URL } = USERS_URLS
const agent = SteadyfootAgent.defaultInstance

const ASYNC_SETTINGS = {
  children: ["completed", "failed"],
}

const UserActions = Reflux.createActions({
  loadCurrent: ASYNC_SETTINGS,
  reloadCurrent: ASYNC_SETTINGS,
  searchForManager: ASYNC_SETTINGS,
  list: ASYNC_SETTINGS,

  update: ASYNC_SETTINGS,
  updateAvatar: ASYNC_SETTINGS,

  loadById: ASYNC_SETTINGS,
  loadUserByEmail: ASYNC_SETTINGS,

  getNote: ASYNC_SETTINGS,
  saveNote: ASYNC_SETTINGS,
  notifyNoteEditing: {},
})

/* User Actions
===============================*/

UserActions.loadCurrent.listenAndPromise(() =>
  addToasts(
    { defaultError: strings.toast.app.unableToConnect },
    extractResponseKey("user", null, agent.get(CURRENT_USER_URL))
  )
)

UserActions.list.listenAndPromise(
  ({ page = 1, per_page = 15, ...search } = {}) => {
    return new Promise((resolve, reject) => {
      agent
        .get(USERS_URL)
        .query({
          page,
          per_page,
          ...search,
        })
        .end((err, res) => {
          if (err || !res.body.users) {
            reject(err)
          } else {
            resolve({
              users: res.body.users,
              meta: res.body.meta,
            })
          }
        })
    })
  }
)

UserActions.reloadCurrent.listenAndPromise(() =>
  extractResponseKey("user", null, agent.get(CURRENT_USER_URL))
)

UserActions.update.preEmit = function ({ user }, { silent = false } = {}) {
  return updateUser(user, "update", { silent })
}

UserActions.updateAvatar.preEmit = function (avatar) {
  UIActions.setLoadingState(true)

  return updateUser({ avatar }, "updateAvatar")
}

UserActions.loadById.listenAndPromise((id, { showLoadingState = false } = {}) =>
  addLoadingState(
    showLoadingState,
    extractResponseKey("user", agent.get(`${USERS_URL}/${id}`))
  )
)

UserActions.getNote.listenAndPromise(({ userId }) =>
  extractResponseKey(
    "note",
    agent.get(NOTES_URL).query({ about_user_id: userId })
  ).catch(() => Promise.reject({ userId }))
)

UserActions.saveNote.listenAndPromise(({ noteBody, userId }) =>
  extractResponseKey(
    "note",
    agent
      .post(NOTES_URL)
      .send({ note: { body: noteBody, about_user_id: userId } })
  ).catch(() => Promise.reject({ noteBody, userId }))
)

UserActions.loadUserByEmail.listenAndPromise((email) =>
  addToasts({}, loadUserByEmail(email))
)

export function loadUserByEmail(email, { url = USERS_URL } = {}) {
  return extractResponseKey("users", agent.get(url).query({ q: email })).then(
    (users) => _.find(users, { email })
  )
}

function updateUser(updates, actionName, { silent = false } = {}) {
  agent
    .put(CURRENT_USER_URL)
    .send({ user: updates })
    .end((err, res) => {
      UIActions.setLoadingState(false)

      if (err || !res) {
        UserActions[actionName].failed(res && res.body, err)
        UserActions.update.failed(res && res.body, err)
      } else {
        const { user } = res.body
        UserActions[actionName].completed(user)
        UserActions.update.completed(user)

        if (!silent) {
          UIActions.notify(strings.profile.updatedProfile)
        }
      }
    })
}

export default UserActions
