import React from "react"
import { parseJSON } from "@Goals/utils"

type UseLocalStorageState<T> = [T, Function]

export function useLocalStorageState<T>(
  key: string,
  defaultValue: T
): UseLocalStorageState<T> {
  const [state, setState] = React.useState(
    () => parseJSON<T>(localStorage.getItem(key)) ?? defaultValue
  )

  React.useEffect(() => {
    localStorage.setItem(key, JSON.stringify(state))
  }, [state, key])

  return [state, setState]
}
