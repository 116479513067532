import { convertToMessageTranslations } from "@Locale/translations"

export default {
  en: () => import("./strings.json").then(convertToMessageTranslations),
  da: () => import("./da.json").then(convertToMessageTranslations),
  de: () => import("./de.json").then(convertToMessageTranslations),
  "en-GB": () => import("./en-GB.json").then(convertToMessageTranslations),
  es: () => import("./es.json").then(convertToMessageTranslations),
  fr: () => import("./fr.json").then(convertToMessageTranslations),
  he: () => import("./he.json").then(convertToMessageTranslations),
  hu: () => import("./hu.json").then(convertToMessageTranslations),
  id: () => import("./id.json").then(convertToMessageTranslations),
  it: () => import("./it.json").then(convertToMessageTranslations),
  ja: () => import("./ja.json").then(convertToMessageTranslations),
  kr: () => import("./kr.json").then(convertToMessageTranslations),
  mi: () => import("./mi.json").then(convertToMessageTranslations),
  nl: () => import("./nl.json").then(convertToMessageTranslations),
  pl: () => import("./pl.json").then(convertToMessageTranslations),
  pt: () => import("./pt.json").then(convertToMessageTranslations),
  ru: () => import("./ru.json").then(convertToMessageTranslations),
  sv: () => import("./sv.json").then(convertToMessageTranslations),
  th: () => import("./th.json").then(convertToMessageTranslations),
  uk: () => import("./uk.json").then(convertToMessageTranslations),
  vi: () => import("./vi.json").then(convertToMessageTranslations),
  zh: () => import("./zh.json").then(convertToMessageTranslations),
  "zh-TW": () => import("./zh-TW.json").then(convertToMessageTranslations),
}
