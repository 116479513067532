import Reflux from "reflux-core"
import { Set } from "immutable"
import AdminSurveyActions from "../refluxActions/AdminSurveyActions"
import ManageUsersActions from "../refluxActions/ManageUsersActions"
import PerformanceReviewAdminActions from "../refluxActions/PerformanceReviewAdminActions"

const ManageUsersStore = Reflux.createStore({
  init: function () {
    this.data = {
      selectedUserIds: Set(),
      waitingForResponse: false,
      initialLoadFinished: false,
      csvUploading: false,
      addableUserIds: null,
      deactivatedEmails: null,
      notFoundEmails: null,
    }

    this.listenTo(PerformanceReviewAdminActions.getUserIds, this.onGetUserIds)
    this.listenTo(
      PerformanceReviewAdminActions.getUserIds.completed,
      this.onGetUserIdsCompleted
    )
    this.listenTo(
      PerformanceReviewAdminActions.createCycle.completed,
      this.onEvalCycleFetched
    )
    this.listenTo(
      PerformanceReviewAdminActions.updateCycle.completed,
      this.onEvalCycleFetched
    )
    this.listenTo(
      PerformanceReviewAdminActions.getStarterTemplate.completed,
      this.onEvalCycleFetched
    )
    this.listenTo(
      PerformanceReviewAdminActions.getCycle.completed,
      this.onEvalCycleFetched
    )

    this.listenTo(
      AdminSurveyActions.getSurveyAudienceUsers,
      this.onGetSurveyAudienceUsers
    )
    this.listenTo(
      AdminSurveyActions.getSurveyAudienceUsers.completed,
      this.onGetSurveyAudienceUsersCompleted
    )
    this.listenTo(
      AdminSurveyActions.createSurvey.completed,
      this.clearSelectedUsers
    )
    this.listenTo(
      AdminSurveyActions.updateSurvey.completed,
      this.clearSelectedUsers
    )

    this.listenTo(ManageUsersActions.toggleMatchingUserIds, () => {
      this.data = { ...this.data, waitingForResponse: true }
      this.trigger(this.data)
    })

    this.listenTo(
      ManageUsersActions.toggleMatchingUserIds.completed,
      ({ userIds, checked }) => {
        this.data = {
          ...this.data,
          waitingForResponse: false,
          selectedUserIds: checked
            ? this.data.selectedUserIds.union(Set(userIds))
            : this.data.selectedUserIds.subtract(Set(userIds)),
        }

        this.trigger(this.data)
      }
    )

    // Quick imperative way to set the initial users.
    this.listenTo(ManageUsersActions.setInitialUsers, ({ userIds }) => {
      this.data = {
        ...this.data,
        initialLoadFinished: true,
        selectedUserIds: Set(userIds),
      }
      this.trigger(this.data)
    })

    // Quick imperative way to clear the store
    this.listenTo(
      ManageUsersActions.clearSelectedUsers,
      this.clearSelectedUsers
    )

    this.listenTo(
      ManageUsersActions.updateSelectedUsers,
      ({ userIds, checked }) => {
        this.data = {
          ...this.data,
          selectedUserIds: checked
            ? this.data.selectedUserIds.union(userIds)
            : this.data.selectedUserIds.subtract(userIds),
        }
        this.trigger(this.data)
      }
    )

    this.listenTo(ManageUsersActions.submitUserCsv, this.handleCsvUploadStarted)
    this.listenTo(
      ManageUsersActions.submitUserCsv.completed,
      this.handleCsvUploadResponse
    )
  },

  getInitialState() {
    return this.data
  },

  clearSelectedUsers() {
    this.data = {
      ...this.data,
      selectedUserIds: Set(),
    }

    this.trigger(this.data)
  },

  onEvalCycleFetched(performanceCycle) {
    this.data = {
      ...this.data,
      selectedUserIds: performanceCycle?.user_ids
        ? Set(performanceCycle.user_ids)
        : Set(),
    }

    this.trigger(this.data)
  },

  onGetUserIds() {
    this.data = {
      ...this.data,
      waitingForResponse: true,
      initialLoadFinished: false,
    }

    this.trigger(this.data)
  },

  onGetSurveyAudienceUsers() {
    this.data = {
      ...this.data,
      waitingForResponse: true,
      initialLoadFinished: false,
    }

    this.trigger(this.data)
  },

  onGetUserIdsCompleted(userIds) {
    this.data = {
      ...this.data,
      selectedUserIds: Set(userIds),
      waitingForResponse: false,
      initialLoadFinished: true,
    }

    this.trigger(this.data)
  },

  onGetSurveyAudienceUsersCompleted({ userIds }) {
    this.data = {
      ...this.data,
      selectedUserIds: Set(userIds),
      waitingForResponse: false,
      initialLoadFinished: true,
    }

    this.trigger(this.data)
  },

  handleCsvUploadStarted() {
    this.data = {
      ...this.data,
      csvUploading: true,
      addableUserIds: null,
      deactivatedEmails: null,
      notFoundEmails: null,
    }
    this.trigger(this.data)
  },

  handleCsvUploadResponse({
    addableUserIds,
    deactivatedEmails,
    notFoundEmails,
  }) {
    this.data = {
      ...this.data,
      csvUploading: false,
      addableUserIds,
      deactivatedEmails,
      notFoundEmails,
    }
    this.trigger(this.data)
  },
})

export default ManageUsersStore
