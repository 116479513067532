import Reflux from "reflux-core"
import AdminFeedbackStatsActions from "../refluxActions/AdminFeedbackStatsActions"

export const createAdminFeedbackStatsStore = () =>
  Reflux.createStore({
    init() {
      this.data = {
        dateRangeId: null,
        reviewStats: null,
        reviewPeriods: null,
      }

      this.listenTo(
        AdminFeedbackStatsActions.getReviewStats.completed,
        ({ reviewStats, dateRangeId }) => {
          this.data = { ...this.data, dateRangeId, reviewStats }
          this.trigger(this.data)
        }
      )

      this.listenTo(
        AdminFeedbackStatsActions.getReviewPeriods.completed,
        (reviewPeriods) => {
          this.data = { ...this.data, reviewPeriods }
          this.trigger(this.data)
        }
      )
    },

    getInitialState() {
      return this.data
    },
  })

export default createAdminFeedbackStatsStore()
