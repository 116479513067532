import * as React from "react"
import ErrorPage from "./ErrorPage"
import image from "./image.png"

// 404 error page, copy/pasted from here:
//   https://github.com/cultureamp/big-frontend-repo/blob/master/packages/error-pages/pages/error-404.ts
// and here:
//   https://github.com/cultureamp/big-frontend-repo/blob/master/packages/error-pages/components/ErrorPage.tsx
// Once these pages are made available in a private npm repo, we can scrap this
// duplicated code.
// Can't use use-query-params hooks here as we haven't configured provider on top of this component
export const ErrorPage403 = () => {
  return (
    <ErrorPage
      code={403}
      title="403 Forbidden"
      message="Hey, something's not quite right"
      body="Hmm, you don't have access to this functionality. Check with your account admin if this issue persists."
      imageUrl={image}
    />
  )
}
