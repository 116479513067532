import { path } from "static-path"

const adminRoot = path("/admin/calibrations")
const adminViewsIndex = adminRoot.path("views")
const adminViewsCreate = adminViewsIndex.path("create")
const adminViewsDetail = adminViewsIndex.path(":calibrationViewId")
const adminViewsEdit = adminViewsDetail.path("edit")
const adminError = adminRoot.path("error")

const nonAdminRoot = path("/calibrations")
const nonAdminViewsIndex = nonAdminRoot.path("views")
const nonAdminViewsDetail = nonAdminViewsIndex.path(":calibrationViewId")

export const calibrationsPaths = {
  admin: {
    root: adminRoot,
    views: {
      index: adminViewsIndex,
      create: adminViewsCreate,
      details: adminViewsDetail,
      edit: adminViewsEdit,
    },
    error: adminError,
  },
  nonAdmin: {
    root: nonAdminRoot,
    views: {
      index: nonAdminViewsIndex,
      details: nonAdminViewsDetail,
    },
  },
}
