import Reflux from "reflux-core"
import _ from "lodash"
import PerformanceReviewAdminActions from "../refluxActions/PerformanceReviewAdminActions"
import BaseCycleStore from "../../refluxStores/lib/BaseCycleStore"
import PerformanceCycle from "../../models/PerformanceCycle"
import observeSearchActions from "../../refluxStores/lib/observeSearchActions"
import PerformanceReview from "../../models/PerformanceReview"

export const createPerformanceReviewAdminStore = () =>
  Reflux.createStore({
    ...BaseCycleStore,

    init() {
      BaseCycleStore.init.call(this, { actions: PerformanceReviewAdminActions })

      this.data = {
        ...this.data,
        companyQuestions: null,
        companyBuckets: null,
        defaultBuckets: null,
        defaultQuestions: null,
        incompleteManagers: null,
      }

      observeSearchActions(this, {
        getResults: () => this.data.searchResults,
        searchAction:
          PerformanceReviewAdminActions.searchSavedViewPerformanceReviews,
        pageAction:
          PerformanceReviewAdminActions.pageSearchResultsSavedViewPerformanceReviews,
        clearAction: PerformanceReviewAdminActions.clearSearchResults,
        onChange: (searchResults, searchState) => {
          this.data = {
            ...this.data,
            searchResults,
            searchState,
          }
          this.trigger(this.data)
        },
      })

      this.listenTo(
        PerformanceReviewAdminActions.getIncompleteManagers.completed,
        this.handleIncompleteManagers
      )

      this.listenTo(
        PerformanceReviewAdminActions.getCycles.completed,
        this.setCycles
      )

      this.listenTo(
        PerformanceReviewAdminActions.getLocalisedCycle.completed,
        this.addOrReplaceCycle
      )

      this.listenTo(
        PerformanceReviewAdminActions.getDefaultBucketsAndQuestions.completed,
        this.setDefaultBucketsAndQuestions
      )
      this.listenTo(
        PerformanceReviewAdminActions.updateReview.completed,
        this.onGetReview
      )
      this.listenTo(
        PerformanceReviewAdminActions.shareReview.completed,
        this.onShareReview
      )

      this.listenTo(
        PerformanceReviewAdminActions.updateFlag,
        ({ review, flag }) => this.onLocalUpdateReview({ id: review.id, flag })
      )

      this.listenTo(
        PerformanceReviewAdminActions.updateFlag.failed,
        ({ review }) =>
          this.onLocalUpdateReview({ id: review.id, flag: review.flag })
      )

      this.listenTo(
        PerformanceReviewAdminActions.createCycle.completed,
        this.onLatestCycleReceived
      )
      this.listenTo(
        PerformanceReviewAdminActions.completeCycle.completed,
        this.onLatestCycleReceived
      )
      this.listenTo(
        PerformanceReviewAdminActions.reopenCycle.completed,
        this.onLatestCycleReceived
      )
      this.listenTo(
        PerformanceReviewAdminActions.updateCycle.completed,
        this.onLatestCycleReceived
      )
      this.listenTo(
        PerformanceReviewAdminActions.getCycle.completed,
        this.onLatestCycleReceived
      )
      this.listenTo(
        PerformanceReviewAdminActions.deleteCycle.completed,
        this.onDeleteCycle
      )

      this.listenTo(
        PerformanceReviewAdminActions.getReview.completed,
        this.onGetReview
      )
      this.listenTo(
        PerformanceReviewAdminActions.getTeamReviews.completed,
        this.onGetTeamReviews
      )
      this.listenTo(
        PerformanceReviewAdminActions.setNextReview,
        this.onSetNextReview
      )

      this.listenTo(
        PerformanceReviewAdminActions.notifyBucketEditing,
        this.onNotifyBucketEditing
      )
      this.listenTo(
        PerformanceReviewAdminActions.addBucketAssignment.completed,
        this.onAddBucketAssignment
      )
      this.listenTo(
        PerformanceReviewAdminActions.addBucketAssignment.failed,
        this.onAddBucketAssignmentFailed
      )
      this.listenTo(
        PerformanceReviewAdminActions.addPendingBucketAssignment.completed,
        this.onAddPendingBucketAssignment
      )
      this.listenTo(
        PerformanceReviewAdminActions.notifyAnswerEditing,
        this.onAnswerEdit
      )
      this.listenTo(
        PerformanceReviewAdminActions.updateAnswer.completed,
        this.onUpdateAnswer.bind(this, "saved")
      )
      this.listenTo(
        PerformanceReviewAdminActions.updateAnswer.failed,
        this.onUpdateAnswer.bind(this, "failed")
      )

      this.listenTo(
        PerformanceReviewAdminActions.clearReviews,
        this.clearReviews
      )

      // Here we return directly the results without merging it with the previous pages.
      this.listenTo(
        PerformanceReviewAdminActions
          .pageSearchResultsSavedViewPerformanceReviewsWithoutMergingResults
          .completed,
        ({ results, meta, clientMeta }) => {
          this.data = {
            ...this.data,
            searchResults: results,
            searchState: { ...meta, ...clientMeta },
          }
          this.trigger(this.data)
        }
      )

      this.listenTo(
        PerformanceReviewAdminActions.getPerformanceCyclesForCalibrationView
          .completed,
        this.updatePerformanceCycles
      )
      this.listenTo(
        PerformanceReviewAdminActions.getPerformanceCyclesForCalibrationView
          .failed,
        this.onGetPerformanceCyclesForCalibrationViewFailed
      )

      this.listenTo(
        PerformanceReviewAdminActions.saveCalibrationViewPerformanceReviewNote
          .completed,
        this.onSaveCalibrationViewPerformanceReviewNote
      )
    },

    // Callback from createCycle.completed, completeCycle.completed,
    // reopenCycle.completed, updateCycle.completed, and getCycle.completed.
    onLatestCycleReceived(cycle) {
      // When we receive the latest cycle as a result of a cycle update, the
      // response doesn't seem to include the `completion_stats`.
      // As a result, if you're on the EvaluationOverviewPage, and you say,
      // end a cycle, the process will succeed, but the completion progress
      // bar in the table will just disappear (refreshing the page will bring
      // it back).
      // So, it's a bit of a hack, but what we're doing here is grabbing the
      // old existing `completion_stats` if they were already pulled, and then
      // merging them with the cycle of the api response.
      const oldCompletionStats =
        this.data.cyclesById[cycle.id]?.completion_stats
      const newCycle =
        oldCompletionStats && !cycle.completion_stats
          ? new PerformanceCycle({
              ...cycle,
              completion_stats: oldCompletionStats,
            })
          : cycle

      this.addOrReplaceCycle(newCycle) // Found in BaseCycleStore
    },

    setDefaultBucketsAndQuestions({
      performance_buckets: defaultBuckets,
      performance_questions: defaultQuestions,
    }) {
      this.data = {
        ...this.data,
        defaultBuckets: _.sortBy(defaultBuckets, (b) => -b.value),
        defaultQuestions,
      }
      this.trigger(this.data)
    },

    onDeleteCycle({ id }) {
      this.setCycles(_.reject(this.data.cycles, { id }))
    },

    handleIncompleteManagers(incompleteManagers) {
      this.data = { ...this.data, incompleteManagers }
      this.trigger(this.data)
    },

    transferBucketCount(oldBucketId, newBucketId) {
      const { searchState } = this.data
      const stats = _.get(searchState, "stats")

      if (!stats || oldBucketId === newBucketId) {
        return
      }

      // Single-cycle bucket counts map bucket IDs to counts, e.g.: {"1": 4, "2": 10}.
      //
      // Multi-cycle bucket counts map bucket titles to objects:
      // {'Top Performer': {ids: [1, 2], count: 10}, 'Bottom Performer': {ids: [3, 5], count: 5}}
      //
      const newBucketCounts = _.mapValues(
        stats.bucket_assignment_counts,
        (value, key) => {
          const isSingleCycle = typeof value === "number"

          const matchesBucket = (bucketId) =>
            isSingleCycle
              ? Number(key) === bucketId
              : value.ids.includes(bucketId)

          const updateValue = (delta) =>
            isSingleCycle
              ? value + delta
              : { ...value, count: value.count + delta }

          if (matchesBucket(oldBucketId)) {
            return updateValue(-1)
          } else if (matchesBucket(newBucketId)) {
            return updateValue(1)
          } else {
            return value
          }
        }
      )

      // If we are moving to/from not being evaluated, increment/decrement
      // the not evaluated count
      const newNotEvaluatedCount =
        stats.not_evaluated_count + (!oldBucketId ? -1 : !newBucketId ? 1 : 0)

      this.data = {
        ...this.data,
        searchState: {
          ...searchState,
          stats: {
            ...stats,
            bucket_assignment_counts: newBucketCounts,
            not_evaluated_count: newNotEvaluatedCount,
          },
        },
      }
    },

    onNotifyBucketEditing({ review, bucketId }) {
      this.transferBucketCount(
        _.get(
          review.latest_bucket_assignment,
          "performance_bucket_in_cycle.id"
        ),
        bucketId
      )

      this.onAddBucketAssignment({
        performance_review_id: review.id,
        performance_bucket_in_cycle: { id: bucketId },
      })
    },

    onAddBucketAssignmentFailed({ review, bucketId, revert }) {
      if (revert) {
        this.transferBucketCount(
          bucketId,
          _.get(
            review.latest_bucket_assignment,
            "performance_bucket_in_cycle.id"
          )
        )

        this.onLocalUpdateReview({
          id: review.id,
          latest_bucket_assignment: review.latest_bucket_assignment,
        })
      }
    },

    onAddPendingBucketAssignment(response) {
      // We cannot rely on any existing base cycle functions to update the state,
      // as we are trying to _remove_ values from the object, but the current
      // model based implementation merges values. We're going to instantiate a new
      // model here instead.
      const newSearchResults = this.data.searchResults.map((review) => {
        if (review.id !== response.data.performance_review.id) {
          return review
        }
        return new PerformanceReview(response.data.performance_review)
      })
      const newStats = response.data.meta.stats

      this.data = {
        ...this.data,
        searchResults: newSearchResults,
        searchState: {
          ...this.data.searchState,
          stats: {
            ...this.data.searchState.stats,
            ...newStats,
          },
        },
      }
      this.trigger(this.data)
    },

    updatePerformanceCycles(response) {
      response.data.performance_cycles.forEach((cycle) => {
        this.addOrReplaceCycle(new PerformanceCycle(cycle))
      })
    },

    onGetPerformanceCyclesForCalibrationViewFailed() {
      this.data.cycles = []
      this.trigger(this.data)
    },

    onSaveCalibrationViewPerformanceReviewNote(response) {
      // We cannot rely on any existing base cycle functions to update the state,
      // as we are trying to _remove_ values from the object, but the current
      // model based implementation merges values. We're going to instantiate a new
      // model here instead.
      const newSearchResults = this.data.searchResults.map((review) => {
        if (review.id !== response.data.performance_review.id) {
          return review
        }
        return new PerformanceReview(response.data.performance_review)
      })

      this.data = {
        ...this.data,
        searchResults: newSearchResults,
      }
      this.trigger(this.data)
    },
  })

export default createPerformanceReviewAdminStore()
