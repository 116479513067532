import {
  ApolloClient,
  InMemoryCache,
  NormalizedCacheObject,
  split,
  HttpLink,
} from "@apollo/client"
import { createConsumer } from "@rails/actioncable"
import ActionCableLink from "graphql-ruby-client/subscriptions/ActionCableLink"
import { getMainDefinition } from "apollo-utilities"
import Settings from "./settings"

const cache = new InMemoryCache()

const defaultHttpLink = new HttpLink({
  uri: Settings.PERFORMANCE_API_GQL_URL,
  credentials: "same-origin",
})

const cable = createConsumer(Settings.PERFORMANCE_API_WS_URL)

// Create a WebSocket link:
// using the ability to split links, you can send data to each link
// depending on what kind of operation is being sent
const websocketLink = (httpLink: HttpLink) =>
  split(
    // split based on operation type
    ({ query }) => {
      const definition = getMainDefinition(query)
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      )
    },
    new ActionCableLink({ cable }),
    httpLink
  )

export const client: ApolloClient<NormalizedCacheObject> = new ApolloClient({
  cache,
  link: websocketLink(defaultHttpLink),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "cache-and-network",
      returnPartialData: true,
    },
    query: {
      fetchPolicy: "network-only",
      errorPolicy: "all",
    },
  },
})
