import { GoalForTree, GoalOwner, GoalType, GoalStatus } from "@Goals/types"
import { perfApiGet } from "@API/utils"
import { GoalFilterTypes } from "@Types/Goals"

export type RawGoalOwner = {
  employee_aggregate_id: string
  full_name: string
  user_profile_image_url: string
}

export const transformToGoalOwner = (owner: RawGoalOwner): GoalOwner => ({
  employeeAggregateId: owner.employee_aggregate_id,
  fullName: owner.full_name,
  userProfileImageUrl: owner.user_profile_image_url,
})

export type RawGoalStatus = "created" | "ongoing" | "blocked" | "accomplished"

export type RawGoalForTree = {
  id: number
  sub_title: string
  uuid: string
  name: string
  goal_type: GoalType
  due_at: Date
  status: RawGoalStatus
  owners: RawGoalOwner[]
  incoming_count: number
}

const goalStatusMap: Record<RawGoalStatus, GoalStatus> = {
  created: "In progress",
  ongoing: "In progress",
  blocked: "Blocked",
  accomplished: "Accomplished",
}

export const transformToGoalForTree = (goal: RawGoalForTree): GoalForTree => ({
  id: goal.id,
  subTitle: goal.sub_title,
  uuid: goal.uuid,
  name: goal.name,
  goalType: goal.goal_type,
  dueAt: goal.due_at,
  status: goalStatusMap[goal.status],
  owners: goal.owners.map(transformToGoalOwner),
  incomingCount: goal.incoming_count,
})

export const companyGoalForTreeUrl = "/goals/tree/company_goals"
export const getCompanyGoalForTree = async ({
  from,
  to,
  "statuses[]": status,
}: GoalFilterTypes): Promise<GoalForTree[]> => {
  return await perfApiGet<{ data: RawGoalForTree[] }>(companyGoalForTreeUrl, {
    params: {
      from,
      to,
      "statuses[]": status,
    },
  }).then((res) => {
    return res.data.data.map(transformToGoalForTree)
  })
}

export const goalForTreeUrl = "/goals/tree/:id/incoming"
export const getGoalForTree = async (id: string): Promise<GoalForTree[]> => {
  return await perfApiGet<{ data: RawGoalForTree[] }>(goalForTreeUrl, {
    params: {
      id,
    },
  }).then((res) => res.data.data.map(transformToGoalForTree))
}
