import Reflux from "reflux-core"
import _ from "lodash"
import FeedbackActions from "../refluxActions/FeedbackActions"
import UIActions from "../refluxActions/UIActions"

const FeedbackStore = Reflux.createStore({
  // Use explicit "init" notation instead of "listenables" to
  // catch Action naming errors
  init: function () {
    this.initializeData()

    this.listenTo(
      FeedbackActions.loadReview.completed,
      this.onSingleLoadCompleted
    )
    this.listenTo(FeedbackActions.loadReviews.completed, this.onLoadCompleted)
    this.listenTo(FeedbackActions.shareReview.completed, this.onReviewShared)
    this.listenTo(FeedbackActions.view.completed, this.onViewCompleted)
    this.listenTo(FeedbackActions.view.failed, this.onViewFailed)
    this.listenTo(FeedbackActions.comment.completed, this.onReviewChange)
    this.listenTo(
      FeedbackActions.updateReactions.completed,
      this.onReviewChange
    )

    this.listenTo(FeedbackActions.localClear, () => {
      this.initializeData()
      this.trigger(this.data)
    })
  },

  initializeData: function () {
    this.data = {
      reviews: null,
      review: null,
      pagination: null,
    }
  },

  getInitialState: function () {
    return this.data
  },

  onSingleLoadCompleted: function (newReview) {
    const review = this.data

    if (review && review.id === newReview.id) {
      this.data = { ...this.data, review: { ...review, ...newReview } }
    } else {
      this.data = { ...this.data, review: newReview }
    }

    if (this.data.reviews) {
      this.updateReviews(newReview)
    }
    this.trigger(this.data)
  },

  onReviewShared: function (reviewId) {
    // We reload the review after sharing to refresh
    // the "sharee_ids" property of the review,
    // i.e. the list of IDs of people the review
    // has been shared with.
    FeedbackActions.loadReview(reviewId)
  },

  onLoadCompleted: function ({ reviews, meta }) {
    UIActions.setLoadingState(false)
    const { pagination } = meta

    if (pagination && pagination.current_page > 1) {
      const oldReviews = this.data.reviews

      // REVIEW: based on how this store currently works, it assumes pages are loaded in order,
      // starting with the first. So if we receive a subsequent page and `this.data.reviews` is null,
      // ignore it.
      // TODO: adapt this store to use observeSearchActions
      //
      if (oldReviews) {
        this.data = _.assign({}, this.data, {
          reviews: oldReviews.concat(reviews),
          pagination: pagination,
        })
      }
    } else {
      this.data = _.assign({}, this.data, { reviews, pagination })
    }
    this.trigger(this.data)
  },

  onReviewChange: function (updatedReview) {
    if (_.get(this.data.review, "id") === updatedReview.id) {
      this.data = { ...this.data, review: updatedReview }
    }

    if (this.data.reviews) {
      this.updateReviews(updatedReview)
    }

    this.trigger(this.data)
  },

  onViewCompleted(review) {
    this.updateViewStatus(review, true)
  },

  onViewFailed(review) {
    this.updateViewStatus(review, false)
  },

  updateReviews(review) {
    if (this.data.reviews) {
      this.data = {
        ...this.data,
        reviews: this.data.reviews.map((r) =>
          r.id === review.id ? review : r
        ),
      }
    }
  },

  updateViewStatus(updatedReview, viewed) {
    if (_.get(this.data.review, "id") === updatedReview.id) {
      this.data.review = { ...this.data.review, viewed }
    }

    if (this.data.reviews) {
      this.data = {
        ...this.data,
        reviews: this.data.reviews.map((review) =>
          review.id === updatedReview.id ? { ...review, viewed } : review
        ),
      }
    }

    this.trigger(this.data)
  },
})

export default FeedbackStore
