import Settings from "../settings"

const matchMediaSupported = !(
  // The `addEventListener` calls blow up legacy Edge (<= v18/pre chromium),
  // so we disable the functionality of updating after page load.
  (
    navigator.userAgent.match(/Edge/) ||
    // old versions of Safari don't have an addEventListener
    window.matchMedia("").addEventListener === undefined
  )
)

/**
 * Return true if user is on a mobile browser
 */
export function isMobileBrowser() {
  if (!matchMediaSupported) return false
  return window.matchMedia(`(max-width: ${Settings.MOBILE_WIDTH}px)`).matches
}

export function isSmallScreenBrowser() {
  if (!matchMediaSupported) return false
  return window.matchMedia(`(max-width: ${Settings.SMALL_SCREEN_WIDTH}px)`)
    .matches
}
