import Reflux from "reflux-core"
import AdminSurveyResponseActions from "../refluxActions/AdminSurveyResponseActions"
import BaseSurveyResponseStore from "../../refluxStores/BaseSurveyResponseStore"

const AdminSurveyResponseStore = Reflux.createStore({
  ...BaseSurveyResponseStore,

  init() {
    BaseSurveyResponseStore.init.call(this, {
      actions: AdminSurveyResponseActions,
    })

    this.data = {
      ...this.data,
      completeSurveyResponse: null,
    }

    this.listenTo(
      AdminSurveyResponseActions.getSurveyResponse.completed,
      (completeSurveyResponse) => {
        this.data = {
          ...this.data,
          completeSurveyResponse,
        }
        this.trigger(this.data)
      }
    )
  },
})

export default AdminSurveyResponseStore
