import Reflux from "reflux-core"
import AdminIncompleteSurveyResponseActions from "../refluxActions/AdminIncompleteSurveyResponseActions"
import BaseIncompleteSurveyResponseStore from "../../refluxStores/BaseIncompleteSurveyResponseStore"

const AdminIncompleteSurveyResponseStore = Reflux.createStore({
  ...BaseIncompleteSurveyResponseStore,

  init() {
    BaseIncompleteSurveyResponseStore.init.call(this, {
      actions: AdminIncompleteSurveyResponseActions,
    })
  },
})

export default AdminIncompleteSurveyResponseStore
