import { JsonDecoder } from "ts.data.json"
import { SuggestedArticle } from "@cultureamp/help-center"
import { perfApiPost } from "../../api/utils"
import { captureDecoderException } from "../../api/decoders/utils"

const suggestedArticleDecoder = JsonDecoder.object<SuggestedArticle>(
  {
    url: JsonDecoder.string,
    title: JsonDecoder.string,
  },
  "SuggestedArticles"
)

const suggestedArticlesDecoder = JsonDecoder.array(
  suggestedArticleDecoder,
  "SuggestedArticles"
)

const fullResponseDecoder = JsonDecoder.object(
  {
    suggested_articles: suggestedArticlesDecoder,
  },
  "FullResponseDecoder"
)

export const getSuggestedArticles = async (route: string) => {
  const response = await perfApiPost("/suggested_articles", {
    params: {
      route: route,
    },
  })

  if (response) {
    try {
      return await fullResponseDecoder.decodePromise(response.data)
    } catch (error) {
      captureDecoderException(error)
      throw error
    }
  } else {
    throw Error
  }
}
