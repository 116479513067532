import React, { createContext, useContext, useState, useEffect } from "react"
import { CurrentUserContext } from "@Redux/reducers/currentUser"

const getLocaleData = async (localeCode: string) => {
  switch (localeCode) {
    case "da":
    case "de":
    case "es":
    case "fr":
    case "he":
    case "hu":
    case "it":
    case "ja":
    case "kr":
    case "nl":
    case "pl":
    case "pt":
    case "ru":
    case "sv":
    case "th":
    case "vi":
    case "zh":
      return (
        await import(
          /* webpackChunkName: "i18n/[request]" */ `flatpickr/dist/l10n/${localeCode}`
        )
      )[localeCode]

    default:
      break
  }
}

export const FlatPickrLocaleContext = createContext<unknown>(undefined)

/**
 * This should be at the top level of the application as it will
 * be used by our flatpickr components to get the current configuration
 * for the user's locale. It does not make sense to have this per slice
 * as they are configuration values rather than strings
 */
export const FlatPickrLocaleProvider: React.FunctionComponent = ({
  children,
}) => {
  const [flatPickrData, setFlatPickrData] = useState<unknown>()

  const currentUser = useContext(CurrentUserContext)

  useEffect(() => {
    const locale = currentUser == null ? "en" : currentUser.locale
    const shortLocaleCode = locale.split("-")[0]

    getLocaleData(shortLocaleCode).then((bundle) => {
      if (bundle) {
        setFlatPickrData(bundle)
      }
    })
  }, [currentUser])

  return (
    <FlatPickrLocaleContext.Provider value={flatPickrData}>
      {children}
    </FlatPickrLocaleContext.Provider>
  )
}
