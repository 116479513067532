import React from "react"
import { Route, Switch } from "react-router-dom"
import R from "@OneOnOnes/constants/routes"
import { PrivateOneOnOneRoute } from "./routing/withOneOnOne"

const OneOnOneHomepage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "mainPages" */ "./components/OneOnOneHomepage/OneOnOneHomepage"
    )
)
const OneOnOneSpacePage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "mainPages" */ "./components/OneOnOneSpacePage/OneOnOneSpacePage"
    )
)
const AdminOneOnOnesStatsPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "adminPages" */ "./components/AdminOneOnOnesStatsPage/AdminOneOnOnesStatsPage"
    )
)

const OneOnOneCalendarSyncPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "calendarSyncPage" */ "./components/OneOnOneCalendarSyncPage/OneOnOneCalendarSyncPage"
    )
)

const OneOnOneApp = React.lazy(
  () =>
    import(/* webpackChunkName: "App" */ "./components/OneOnOneApp/OneOnOneApp")
)

const OneOnOneErrorPage403 = React.lazy(
  () =>
    import(
      /* webpackChunkName: "App" */ "./components/OneOnOneErrorPage403/OneOnOneErrorPage403"
    )
)

// 1-1s Routes
// You will notice that in 1-1 routes we have our own way of authenticating
// users. This is because we need to allow engagement users to use our area of the
// product, not just performance users.
export const OneOnOneRoutes = (
  <PrivateOneOnOneRoute path={R.oneOnOne}>
    <OneOnOneApp>
      <Switch>
        <Route exact path={R.oneOnOne} component={OneOnOneHomepage} />
        <Route
          exact
          path={R.calendarSync}
          component={OneOnOneCalendarSyncPage}
        />
        <Route path={R.oneOnOneError403} component={OneOnOneErrorPage403} />
        <Route path={R.oneOnOneConversation} component={OneOnOneSpacePage} />
        <Route path={R.oneOnOneSpace} component={OneOnOneSpacePage} />
      </Switch>
    </OneOnOneApp>
  </PrivateOneOnOneRoute>
)

export const OneOnOneAdminRoutes = (
  <PrivateOneOnOneRoute path="/admin/1-1s_stats">
    <OneOnOneApp>
      <Route
        exact
        path="/admin/1-1s_stats"
        component={AdminOneOnOnesStatsPage}
      />
    </OneOnOneApp>
  </PrivateOneOnOneRoute>
)
