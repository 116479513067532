/**
 * DEPRECATED: we are no longer using decoders to validate server responses.
 * Please do not use them in new code.
 *
 * See src/api/decoders/README.md for more info.
 */
import { JsonDecoder } from "ts.data.json"
import { Pagination } from "../../../types/Pagination"

const paginationDecoder = JsonDecoder.object<Pagination>(
  {
    currentPage: JsonDecoder.number,
    nextPage: JsonDecoder.failover(undefined, JsonDecoder.number),
    previousPage: JsonDecoder.failover(undefined, JsonDecoder.number),
    totalCount: JsonDecoder.failover(0, JsonDecoder.number),
    totalPages: JsonDecoder.number,
  },
  "pagination",
  {
    currentPage: "current_page",
    nextPage: "next_page",
    previousPage: "prev_page",
    totalCount: "total_count",
    totalPages: "total_pages",
  }
)

export default paginationDecoder
