import * as React from "react"
import { CurrentUserContext } from "@Redux/reducers/currentUser"
import { LaunchDarklyProvider } from "./LaunchDarklyProvider"

export const LaunchDarklyProviderWrapper: React.FC<{
  children: React.ReactElement
}> = ({ children }) => {
  const performanceUser = React.useContext(CurrentUserContext)
  return performanceUser ? (
    <LaunchDarklyProvider
      bootstrapFlagSet={performanceUser.launchdarkly_flags}
      accountId={performanceUser.company.account_id}
      userId={performanceUser.employee_aggregate_id}
    >
      <div>{children}</div>
    </LaunchDarklyProvider>
  ) : (
    children
  )
}
