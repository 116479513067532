/**
 * DEPRECATED: we are no longer using decoders to validate server responses.
 * Please do not use them in new code.
 *
 * See src/api/decoders/README.md for more info.
 */
import { JsonDecoder, Err, err, Ok, ok, Result } from "ts.data.json"
import { captureException } from "../../utils/errorHandling"

export const expectSuccess = <T>(result: Result<T>) => {
  expect(result).toBeInstanceOf(Ok)
}

export const expectSuccessWithValue = <T>(result: Result<T>, value: T) => {
  expectSuccess(result)
  expect(result).toMatchObject(ok(value))
}

export const expectError = <T>(result: Result<T>) => {
  expect(result).toBeInstanceOf(Err)
}

export const expectErrorWithValue = <T extends string>(
  result: Result<T>,
  value: T
) => {
  expect(result).toBeInstanceOf(Err)
  expect(result).toMatchObject(err(value))
}

/**
 * This custom decoder exists to replace our use of `JsonDecoder.array(JsonDecoder.oneOf([JsonDecoder.isExactly("foo")))`
 * to validate an array of enums or similar type. The problem the old pattern
 * caused was that it would break when something new was added to the enum.
 * This makes the FE forwards incompatible with the BE. The BE should be able
 * to be updated and deployed without worrying about breaking the FE so easily.
 *
 * This custom decoder will filter out anything it doesn't recognise instead of
 * throwing an error. This means any parts of the frontend that depend on the
 * data being decoded will still not recieve any new enum values they don't
 * expect.
 *
 * Custom decoders aren't very well documented in the library we use, but I
 * found these two examples by the library author:
 * - https://stackblitz.com/edit/typescript-uze2bj
 * - https://stackblitz.com/edit/typescript-97ergz
 */
export const allowListFilter = <TAllowed extends string | number | boolean>(
  allowList: TAllowed[]
) =>
  new JsonDecoder.Decoder<TAllowed[]>((unknownInput: unknown[]) => {
    try {
      if (!Array.isArray(unknownInput))
        return err("allowListFilter decoder must be passed an array")

      const inputIsOnlyBasicTypes = unknownInput.every((v) =>
        ["string", "number", "boolean"].includes(typeof v)
      )
      if (!inputIsOnlyBasicTypes)
        return err(
          "allowListFilter can only validate string, number, or boolean types"
        )

      const checked: TAllowed[] = unknownInput.filter((s) =>
        allowList.includes(s)
      )
      return ok(checked)
    } catch (e) {
      return err(
        "an unknown error occured parsing input in the allowListFilter decoder"
      )
    }
  })

/**
 * This function removes PII that might be contained in errors thrown by our `ts.data.json` decoders, while trying to
 * preserve as much of the error as possible. It's based on several assumptions:
 * - Object keys will never contains PII (only object values)
 * - Only the last item on the error stack can contain PII
 * - We only use decoder.decodePrmoise to decode async (sync decoding returns an error object instead of throwing an error string)
 *
 * These assumptions have been manually verified by poking and prodding the library, and also looking at it's source code.
 * I believe the only errors it can throw are in this file https://github.com/joanllenas/ts.data.json/blob/3203af0355031c945224f6c0faee45958f6cfa8c/src/json-decoder.ts#L562-L614
 *
 * This function works by splitting the error string into a stack, all errors that are not the last on the stack end with
 * `with error:`, so we use that to split the string. Then we look at the last item on the stack on search for patterns which
 * will contain a debug value (see link to library source code above for where these came from).
 */
export const removePiiFromDecoderError = (errorString: string) => {
  const SEPARATOR = " with error: "
  try {
    const errors = errorString.split(SEPARATOR)
    const lastError = errors.pop()
    if (!lastError) return errorString

    const safeLastError = lastError
      .replace(/.* is not a valid/, "###_REDACTED_### is not a valid")
      .replace(/.* is not exactly/, "###_REDACTED_### is not exactly")
      .replace(/.* is not undefined/, "###_REDACTED_### is not undefined")
      .replace(/.* is not null/, "###_REDACTED_### is not null")
      .replace(
        / decoder failed because .* can't be decoded /,
        " decoder failed because ###_REDACTED_### can't be decoded "
      )

    return [...errors, safeLastError].join(SEPARATOR)
  } catch (_) {
    // this error in intentionally thrown away in case in contains PII
    return "An unknown error occured while decoding an api response"
  }
}

/**
 * This function exists to gloss over some weirdness of the ts.json.data library. The library will
 * throw a string, but we want to be semantic and throw an Error. If a string is thrown, we know it
 * has come from a decoder (surely no other code would throw a string 🤞) and we can remove any PII
 * from it. If it's an error object then it's an unknown error and should be passed to
 * captureException as normal
 */
export const captureDecoderException = (error: unknown) => {
  if (typeof error === "string") {
    captureException(new Error(removePiiFromDecoderError(error)))
  } else {
    captureException(error)
  }
}
