enum UserFilterType {
  Departments = "DEPARTMENTS",
  Managers = "MANAGERS",
  JobTitles = "JOB_TITLES",
  Levels = "LEVELS",
  Users = "USERS",
  More = "MORE",
  DemographicValues = "DEMOGRAPHIC_VALUES",
  UserStatus = "USER_STATUS",
}

export default UserFilterType
