import Reflux from "reflux-core"
import observeSearchActions from "../../refluxStores/lib/observeSearchActions"
import AdminManagerFeedbackStatsActions from "../refluxActions/AdminManagerFeedbackStatsActions"

export const createAdminManagerFeedbackStatsStore = () =>
  Reflux.createStore({
    init() {
      this.data = {
        feedbackStats: null,
        invitationStats: null,
        individualStats: null,
        pagination: null,
        departmentId: null,
        dateRangeId: null,
        managerId: null,
        managerChartStats: null,
        invitesById: {},
        invitesByToken: {},
        invitesByUserId: {},
      }

      this.listenTo(
        AdminManagerFeedbackStatsActions.getManagerFeedbackStats.completed,
        ({ feedbackStats, departmentId, dateRangeId }) => {
          this.data = { ...this.data, feedbackStats, departmentId, dateRangeId }
          this.trigger(this.data)
        }
      )

      observeSearchActions(this, {
        getResults: () => this.data.invitationStats,
        searchAction: AdminManagerFeedbackStatsActions.getInvitationStats,
        pageAction: AdminManagerFeedbackStatsActions.pageInvitationStats,
        onChange: (
          invitationStats,
          { pagination, departmentId, dateRangeId }
        ) => {
          this.data = {
            ...this.data,
            invitationStats,
            pagination,
            departmentId,
            dateRangeId,
          }
          this.trigger(this.data)
        },
      })

      observeSearchActions(this, {
        getResults: () => this.data.individualStats,
        searchAction: AdminManagerFeedbackStatsActions.getIndividualStats,
        pageAction: AdminManagerFeedbackStatsActions.pageIndividualStats,
        onChange: (
          individualStats,
          { stats, pagination, managerId, dateRangeId }
        ) => {
          this.data = {
            ...this.data,
            individualStats,
            pagination: pagination,
            managerId: managerId,
            dateRangeId: dateRangeId,
            managerChartStats: stats,
          }
          this.trigger(this.data)
        },
      })

      this.listenTo(
        AdminManagerFeedbackStatsActions.getInvitation.completed,
        (invite) => {
          this.data = {
            ...this.data,
            invitesById: {
              ...this.data.invitesById,
              [invite.id]: invite,
            },
            invitesByToken: {
              ...this.data.invitesByToken,
              [invite.token]: invite,
            },
          }
          this.trigger(this.data)
        }
      )

      this.listenTo(
        AdminManagerFeedbackStatsActions.getInvitationsForUser.completed,
        ({ userId, invites }) => {
          this.data = {
            ...this.data,
            invitesByUserId: {
              ...this.data.invitesByUserId,
              userId,
              [userId]: invites.filter((q) => !q.withdrawn_at),
            },
          }
          this.trigger(this.data)
        }
      )

      this.listenTo(
        AdminManagerFeedbackStatsActions.withdrawManagerFeedbackRequest
          .completed,
        ({ id, subject_id }) => {
          const {
            invitesByUserId,
            invitesByUserId: { userId },
            individualStats,
          } = this.data
          this.data = {
            ...this.data,
            invitesByUserId: {
              ...invitesByUserId,
              [userId]: invitesByUserId[invitesByUserId.userId].filter(
                (q) => q.id !== id
              ),
            },
            individualStats: individualStats.map((item) =>
              item.direct.id === subject_id
                ? {
                    ...item,
                    total_invitations_count: item.total_invitations_count - 1,
                  }
                : item
            ),
          }
          this.trigger(this.data)
        }
      )
    },

    getInitialState() {
      return this.data
    },
  })

export default createAdminManagerFeedbackStatsStore()
