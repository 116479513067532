import * as React from "react"
import { RefObject, useLayoutEffect } from "react"
import { useThrottleCallback } from "@react-hook/throttle"

const isModifiedEvent = (
  event: React.MouseEvent<HTMLElement> | React.MouseEvent
) => !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey)

// Returns true if the user has left clicked the link.
// Returns false if the user has right clicked, middle clicked, or has
// used a modifier key like ctrl, alt, shift, windows.
export const isRegularMouseClick = (
  event: React.MouseEvent<HTMLElement> | React.MouseEvent
) =>
  // ignore everything but left clicks, or anything with modifier keys
  event.button === 0 && !isModifiedEvent(event)

export const useThrottledEventListener = (
  // Warning, if the document isn't rendered to the dom immediately, the
  // event will not fire. We don't account for when elementRef.current changes
  // from undefined to an actual dom element.
  elementRef: RefObject<HTMLElement>,
  eventName: string,
  handler: (event: Event) => void,
  options?: boolean | AddEventListenerOptions
) => {
  const throttledHandler = useThrottleCallback(handler, 10, false)

  useLayoutEffect(() => {
    const element = elementRef?.current
    if (!element) return

    // Make sure element supports addEventListener
    if (!element.addEventListener) return

    const eventListener = (event: Event) => throttledHandler(event)
    element.addEventListener(eventName, eventListener, options)
    // Remove event listener on cleanup
    return () => {
      element.removeEventListener(eventName, eventListener)
    }
  }, [eventName, handler, throttledHandler, elementRef, options])
}
