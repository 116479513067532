import React from "react"
import cx from "classnames"
import { Icon } from "@kaizen/component-library"
import { Heading } from "@kaizen/typography"
import arrowBack from "@kaizen/component-library/icons/arrow-backward.icon.svg"
import styles from "./Drawer.scss"
import Aid from "../../../constants/automationId"

type DrawerProps = {
  children: React.ReactNode
  drawerHeading?: React.ReactElement | string
  drawerFooter?: React.ReactElement | string
  onToggle?: () => void
  isOpen?: boolean
}

export const DrawerSection = () => {}

export const Drawer: React.FunctionComponent<DrawerProps> = ({
  children,
  drawerHeading,
  drawerFooter,
  onToggle,
  isOpen,
}) => {
  return (
    <div
      className={cx(styles.drawer, {
        [styles.drawerOpen]: isOpen,
      })}
      data-automation-id={Aid.sideNavDrawer}
    >
      <button
        data-automation-id={Aid.userDrawerToggle}
        type="button"
        onClick={onToggle}
        className={styles.drawerHeading}
      >
        <span className={styles.drawerToggle}>
          <Icon icon={arrowBack} role="presentation" />
        </span>
        <span className={styles.drawerHeadingElement}>
          <Heading variant="heading-6">{drawerHeading}</Heading>
        </span>
      </button>
      <div className={styles.drawerContent}>{children}</div>
      {drawerFooter && (
        <div className={styles.drawerFooter}>{drawerFooter}</div>
      )}
    </div>
  )
}
