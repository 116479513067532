import * as React from "react"
import ErrorPage from "./ErrorPage"
import image from "./image.png"

// 404 error page, copy/pasted from here:
//   https://github.com/cultureamp/big-frontend-repo/blob/master/packages/error-pages/pages/error-404.ts
// and here:
//   https://github.com/cultureamp/big-frontend-repo/blob/master/packages/error-pages/components/ErrorPage.tsx
// Once these pages are made available in a private npm repo, we can scrap this
// duplicated code.
// Can't use use-query-params hooks here as we haven't configured provider on top of this component
export const ErrorPage404 = () => {
  return (
    <ErrorPage
      code={404}
      title="404 Page Not Found"
      message="Hey, you look a bit lost. Can we help?"
      body="Hmm, that's strange. We can't seem to find the page you're looking for either."
      imageUrl={image}
    />
  )
}

export default ErrorPage404
