import moment from "moment-timezone"
import { IntlShape } from "react-intl"
import strings from "../locale/strings"

/**
 * Takes a date/time string (with a time zone), and converts it to a destination
 * time zone. Then returns that same "wall-clock" date/time in the user's local
 * time zone.
 *
 * NOTE: THIS IS NOT THE SAME AS CONVERTING TO LOCAL TIME.
 *
 * The purpose of this function is to get around an issue with react-flatpickr,
 * which only accepts local or UTC date/time values.
 */
export const replaceTimezoneAsLocal = (
  date: string | undefined | null,
  timezone: string
) => {
  if (!date) return date
  if (!timezone) throw Error("Timezone not supplied")

  const d = moment(date).tz(timezone)

  // return same "wall-clock" date/time in local time zone
  return moment([
    d.year(),
    d.month(),
    d.date(),
    d.hour(),
    d.minute(),
    d.second(),
    d.millisecond(),
  ]).format()
}

/**
 * Takes a date/time string (with a time zone), and converts it to the user's
 * local time zone. Then returns that same "wall-clock" date/time in the
 * specified time zone.
 */
export const replaceLocalDateWithTimezone = (
  date: string,
  timezone: string
) => {
  if (!timezone) throw Error("Timezone not supplied")
  if (!date) return date

  const d = moment(date)

  // return same "wall-clock" date/time in specified time zone
  return moment
    .tz(
      [
        d.year(),
        d.month(),
        d.date(),
        d.hour(),
        d.minute(),
        d.second(),
        d.millisecond(),
      ],
      timezone
    )
    .format()
}

/**
 * Same as `replaceTimezoneAsLocal`, you can specify the timezone to replace
 */
export const replaceTimezone = (
  date: string | undefined | null,
  oldTimezone: string,
  newTimezone: string
) => {
  if (!oldTimezone) throw Error("Old timezone not supplied")
  if (!newTimezone) throw Error("New timezone not supplied")
  if (!date) return date

  const d = moment(date).tz(oldTimezone)

  return moment
    .tz(
      [
        d.year(),
        d.month(),
        d.date(),
        d.hour(),
        d.minute(),
        d.second(),
        d.millisecond(),
      ],
      newTimezone
    )
    .format()
}

export const getLocalTimezone = () =>
  Intl.DateTimeFormat().resolvedOptions().timeZone ||
  moment.tz.guess() /* required for ie11 */

export const convertToUtc = (date: string | undefined | null) =>
  date ? moment(date).utc().format() : date

export const formatDateToLocalTime = (date: string | null | undefined) => {
  if (!date) return date
  return moment(date).format("LLL")
}

export const formatDateToLocalDate = (date: string | null | undefined) => {
  if (!date) return date
  return moment(date).format("LL")
}

/**
 * Returns the passed in date (usually will be the current date), and converts
 * it to the passed in timezone. Then, it sets the time to 00:00:00 (ie. midnight).
 * Use case - say you have a date picker, and you'd like to disable all dates
 * prior to today. You could just get the current date, but, you also have
 * a timezone selection. If you're in Melbourne, it's 9am, and you've selected
 * the New York timezone, the minimum start date should actually be yesterday,
 * not today.
 *
 * Then...the returned result is in local time. The reason for this is because
 * our date picker library requires the date in that format.
 */
export const getStartOfDayAsLocalTime = (date: Date, timezone?: string) => {
  const convertedDate = timezone ? moment(date).tz(timezone) : moment(date)

  return moment([
    convertedDate.year(),
    convertedDate.month(),
    convertedDate.date(),
  ]).format()
}

export const formatShortDate = (date: string | null | undefined) => {
  if (!date) return date
  return moment(date).format("ll")
}

const formatYear = (date: string) => {
  return moment(date).format("YYYY")
}

const formatDayMonth = (date: string) => {
  return moment(date).format("MMM D")
}

const formatDay = (date: string) => {
  return moment(date).format("D")
}

const singleMonthFormat = (
  startDate: string,
  endDate: string,
  formatMessage: IntlShape["formatMessage"]
) => {
  return formatMessage(strings.time.singleMonthFormat, {
    startDayMonth: formatDayMonth(startDate),
    endDay: formatDay(endDate),
    endYear: formatYear(endDate),
  })
}

export const multipleMonthFormat = (
  startDate: string,
  endDate: string,
  formatMessage: IntlShape["formatMessage"]
) => {
  return formatMessage(strings.time.dateRangeFormat, {
    startDayMonth: formatDayMonth(startDate),
    endDayMonth: formatDayMonth(endDate),
    endYear: formatYear(endDate),
  })
}

const isDateRangeAcrossMultipleYears = (startDate: string, endDate: string) => {
  const startYear = Number(moment(startDate).format("YYYY"))
  const endYear = Number(moment(endDate).format("YYYY"))
  return startYear !== endYear
}

const isDateRangeAcrossMultipleMonths = (
  startDate: string,
  endDate: string
) => {
  const startMonth = Number(moment(startDate).month() + 1)
  const endMonth = Number(moment(endDate).month() + 1)
  return startMonth !== endMonth
}

const selectDateFormattingForMultipleYears = (
  startDate: string,
  endDate: string,
  formatMessage: IntlShape["formatMessage"]
) => {
  return formatMessage(strings.time.dateRange, {
    startDate: formatShortDate(startDate),
    endDate: formatShortDate(endDate),
  })
}

const selectDateFormattingForSingleYear = (
  startDate: string,
  endDate: string,
  formatMessage: IntlShape["formatMessage"]
) => {
  return isDateRangeAcrossMultipleMonths(startDate, endDate)
    ? multipleMonthFormat(startDate, endDate, formatMessage)
    : singleMonthFormat(startDate, endDate, formatMessage)
}

export const formatDateRange = (
  startDate: string,
  endDate: string,
  formMessage: IntlShape["formatMessage"]
) => {
  return isDateRangeAcrossMultipleYears(startDate, endDate)
    ? selectDateFormattingForMultipleYears(startDate, endDate, formMessage)
    : selectDateFormattingForSingleYear(startDate, endDate, formMessage)
}
