import React, { useCallback, useEffect, useState } from "react"
import { Paragraph } from "@kaizen/typography"
import { Icon } from "@kaizen/component-library/components/Icon"
import exclamationIcon from "@kaizen/component-library/icons/exclamation.icon.svg"
import percentageIcon from "@kaizen/component-library/icons/percentage.icon.svg"
import { InputStatus, Label } from "@kaizen/draft-form"
import { NumberInput, ProgressSlider } from "@Goals/components"
import { updateKeyResult } from "@Goals/domain/keyResult"
import { KeyResult } from "@Goals/types"
import useDebounce from "@Hooks/timeout/useDebounce"

import styles from "./KeyResultItem.scss"

type ErrorBannerProps = {
  message: string
}

const ErrorBanner = ({ message }: ErrorBannerProps) => {
  return (
    <div className={styles.errorBanner}>
      <Icon icon={exclamationIcon} role={"presentation"} />
      <Paragraph variant="extra-small">{message}</Paragraph>
    </div>
  )
}

export type KeyResultProps = {
  keyResult: KeyResult
  onChange?: (keyResult: KeyResult) => void
}

export const KeyResultItem = ({ keyResult, onChange }: KeyResultProps) => {
  const { start, target, title } = keyResult
  const [isDirty, setIsDirty] = useState(false)
  const [current, setCurrent] = useState(keyResult.current)
  const debouncedCurrent = useDebounce(current, 500)

  useEffect(() => setCurrent(keyResult.current), [keyResult])

  useEffect(() => {
    if (onChange && isDirty) {
      onChange(updateKeyResult(keyResult, { current: debouncedCurrent }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedCurrent])

  const [status, setStatus] = useState<InputStatus>("default")

  const isValid = useCallback(
    (newCurrentValue: number) =>
      newCurrentValue >= start && newCurrentValue <= target,
    [start, target]
  )

  const handleOnChangeCurrentValue = (newCurrentValue: number) => {
    if (!isValid(newCurrentValue)) {
      setStatus("error")
      return
    }

    if (status === "error") {
      setStatus("default")
    }

    setIsDirty(true)
    setCurrent(newCurrentValue)
  }

  const unitIcon = keyResult.metricType === "percentage" && (
    <Icon
      icon={percentageIcon}
      role={"img"}
      title={"percentage-sign"}
      inheritSize
    />
  )

  return (
    <div className={styles.container}>
      <div className={styles.labelWrapper}>
        <Label htmlFor={`key-result-progress-input-${keyResult.id}`}>
          {title}
        </Label>
      </div>
      <div className={styles.progressBarInputContainer}>
        <ProgressSlider
          min={start}
          max={target}
          progress={current > target ? target : current}
          onProgressChange={handleOnChangeCurrentValue}
        />
        <div className={styles.inputContainer}>
          <NumberInput
            id={`key-result-progress-input-${keyResult.id}`}
            value={current}
            onChange={handleOnChangeCurrentValue}
            status={status}
            unitIcon={unitIcon}
          />
        </div>
      </div>
      {status === "error" && (
        <ErrorBanner message={`Value must be between ${start} and ${target}`} />
      )}
    </div>
  )
}
