/**
 * DEPRECATED: we are no longer using decoders to validate server responses.
 * Please do not use them in new code.
 *
 * See src/api/decoders/README.md for more info.
 */
import { JsonDecoder } from "ts.data.json"
import { PersonalGoal } from "../../../types/Goals"
import { outgoingAlignedGoalDecoder } from "../AlignedGoalDecoder/alignedGoalDecoder"
import {
  accessPermissionsDecoder,
  goalIndividualsDecoder,
  goalKeyResultDecoder,
  goalPriorityDecoder,
} from "../BaseGoalDecoder/baseGoalDecoder"

import { goalOwnerDecoder } from "../TeamGoalPreviewDecoder/teamGoalPreviewDecoder"

export const personalGoalDecoder: JsonDecoder.Decoder<PersonalGoal> =
  JsonDecoder.object<PersonalGoal>(
    {
      id: JsonDecoder.number,
      name: JsonDecoder.string,
      completion: JsonDecoder.number,
      commentsCount: JsonDecoder.number,
      dueDate: JsonDecoder.string.map((dateString) => new Date(dateString)),
      priority: goalPriorityDecoder,
      type: JsonDecoder.isExactly("my_goal").map((): "personal" => "personal"),
      description: JsonDecoder.failover(undefined, JsonDecoder.string),
      keyResults: JsonDecoder.failover(
        [],
        JsonDecoder.array(goalKeyResultDecoder, "keyResults")
      ),
      subType: JsonDecoder.failover("", JsonDecoder.string),
      visibility: JsonDecoder.string,
      status: JsonDecoder.string,
      individuals: JsonDecoder.failover(
        [],
        JsonDecoder.array(goalIndividualsDecoder, "individuals")
      ),
      owners: JsonDecoder.failover(
        [],
        JsonDecoder.array(goalOwnerDecoder, "goalOwners")
      ),
      outgoingAlignedGoals: JsonDecoder.failover(
        [],
        JsonDecoder.array(outgoingAlignedGoalDecoder, "outgoingAlignedGoals")
      ),
      outgoingAlignmentAssociations: JsonDecoder.failover(
        undefined,
        JsonDecoder.array(
          JsonDecoder.object<{
            id: number
            alignedGoalId: number
          }>(
            {
              id: JsonDecoder.number,
              alignedGoalId: JsonDecoder.number,
            },
            "outgoing alignment association",
            {
              alignedGoalId: "incoming_alignment_goal_id",
            }
          ),
          "outgoing alignment associations "
        )
      ),
      editLevel: JsonDecoder.failover(undefined, JsonDecoder.string),
      access_permissions: accessPermissionsDecoder,
    },
    "personalGoal",
    {
      commentsCount: "number_of_comments",
      dueDate: "due_at",
      priority: "priority",
      type: "goal_type",
      keyResults: "key_results",
      outgoingAlignedGoals: "outgoing_aligned_goals",
      individuals: "access_permissions",
      outgoingAlignmentAssociations: "outgoing_alignment_associations",
      owners: "goal_owners",
      subType: "sub_type",
      editLevel: "edit_level",
    }
  )
