/**
 * DEPRECATED: we are no longer using decoders to validate server responses.
 * Please do not use them in new code.
 *
 * See src/api/decoders/README.md for more info.
 */
import { JsonDecoder } from "ts.data.json"
import {
  OutgoingAlignedGoal,
  IncomingAlignedGoalsCount,
} from "../../../types/Goals"
import { goalTypeDecoder } from "../BaseGoalDecoder/baseGoalDecoder"

export const outgoingAlignedGoalDecoder = JsonDecoder.object(
  {
    id: JsonDecoder.number,
    name: JsonDecoder.string,
    uuid: JsonDecoder.failover("", JsonDecoder.string),
    goal_type: goalTypeDecoder,
    team_name: JsonDecoder.failover("", JsonDecoder.string),
    department: JsonDecoder.failover(
      { title: "" },
      JsonDecoder.object(
        { title: JsonDecoder.string },
        "OutgoingAlignedGoal.departmentDecoder"
      )
    ),
  },
  "outgoingAlignedGoal"
).map<OutgoingAlignedGoal>(({ goal_type, team_name, department, ...keys }) => ({
  goalType: goal_type as "team" | "department" | "company",
  teamName: team_name,
  departmentName: department.title,
  ...keys,
}))

export const incomingAlignedGoalsCountDecoder =
  JsonDecoder.object<IncomingAlignedGoalsCount>(
    {
      individualGoalsCount: JsonDecoder.failover(0, JsonDecoder.number),
      teamGoalsCount: JsonDecoder.failover(0, JsonDecoder.number),
      departmentGoalsCount: JsonDecoder.failover(0, JsonDecoder.number),
    },
    "incomingAlignedGoalsCount",
    {
      individualGoalsCount: "my_goals_count",
      teamGoalsCount: "team_goals_count",
      departmentGoalsCount: "department_goals_count",
    }
  )
