import React, { useMemo, useState } from "react"
import { flatten, uniq } from "ramda"
import { MessageDescriptor } from "react-intl"
import { Button } from "@kaizen/button"
import { FieldMessage, InputStatus, Label, TextField } from "@kaizen/draft-form"
import { Select } from "@kaizen/draft-select"
import { Well } from "@kaizen/draft-well"
import { useIntl } from "@Common/locale/useIntl"
import { NumberField } from "@Goals/components"
import { createKeyResult, updateKeyResult } from "@Goals/domain/keyResult"
import { KeyResult, KeyResultMetricType } from "@Goals/types"
import strings from "@Locale/strings"

import Aid from "@Constants/automationId"
import styles from "./KeyResultForm.scss"

type MetricTypeSelectOption = {
  label: MessageDescriptor
  value: KeyResultMetricType
}

const NUMBER_OPTION: Readonly<MetricTypeSelectOption> = {
  label: strings.keyResultsForm.type.options.number,
  value: "numeric",
}

const PERCENTAGE_OPTION: Readonly<MetricTypeSelectOption> = {
  label: strings.keyResultsForm.type.options.percentage,
  value: "percentage",
}

const METRIC_TYPE_OPTIONS: Readonly<MetricTypeSelectOption>[] = [
  NUMBER_OPTION,
  PERCENTAGE_OPTION,
]

const getOptionForValue = (value: string) =>
  METRIC_TYPE_OPTIONS.find((option) => option.value === value)

const internationaliseOption = (
  option: MetricTypeSelectOption | undefined,
  formatMessageFunction: Function
): MetricTypeSelectOption | undefined => {
  return option
    ? {
        label: formatMessageFunction(option.label),
        value: option.value,
      }
    : undefined
}

export type KeyResultFormProps = {
  id: string
  keyResult?: KeyResult
  onSubmit: (keyResult: KeyResult) => void
  onCancel?: () => void
}

type FormErrors = {
  start: MessageDescriptor[]
  current: MessageDescriptor[]
  target: MessageDescriptor[]
}

const validateKeyResultValues = (
  startValue: number,
  currentValue: number,
  targetValue: number
) => {
  const errors: FormErrors = {
    start: [],
    target: [],
    current: [],
  }

  if (startValue < 0 || startValue >= 1e9) {
    errors.start = [
      strings.keyResultsForm.validationMessages.startValueWithinLimits,
    ]
  }

  if (currentValue < 0 || currentValue >= 1e9) {
    errors.current = [
      strings.keyResultsForm.validationMessages.currentValueWithinLimits,
    ]
  }

  if (targetValue < 0 || targetValue >= 1e9) {
    errors.target = [
      strings.keyResultsForm.validationMessages.targetValueWithinLimits,
    ]
  }

  return errors
}

const validateKeyResultRange = (
  startValue: number,
  currentValue: number,
  targetValue: number
) => {
  const errors = validateKeyResultValues(startValue, currentValue, targetValue)

  const targetGreaterThanStartMessage =
    strings.keyResultsForm.validationMessages.targetGreaterThanStart
  if (startValue >= targetValue) {
    errors.start = [...errors.start, targetGreaterThanStartMessage]
    errors.target = [...errors.target, targetGreaterThanStartMessage]
  }

  const invalidCurrentValueMessage =
    strings.keyResultsForm.validationMessages.currentBetweenStartAndTarget
  if (currentValue < startValue || currentValue > targetValue) {
    errors.start = [...errors.start, invalidCurrentValueMessage]
    errors.current = [...errors.current, invalidCurrentValueMessage]
    errors.target = [...errors.target, invalidCurrentValueMessage]
  }

  return errors
}

export const KeyResultForm = ({
  id,
  keyResult,
  onSubmit,
  onCancel,
}: KeyResultFormProps) => {
  const { formatMessage } = useIntl()

  const [values, setValues] = useState<KeyResult>(
    keyResult || createKeyResult()
  )

  const [errors, setErrors] = useState<FormErrors>({
    start: [],
    current: [],
    target: [],
  })

  const validationMessages = useMemo(
    () => uniq(flatten(Object.values(errors))),
    [errors]
  )

  const startValueStatus = useMemo<InputStatus>(() => {
    if (errors.start.length) {
      return "error"
    }
    return "default"
  }, [errors])

  const currentValueStatus = useMemo<InputStatus>(() => {
    if (errors.current.length) {
      return "error"
    }
    return "default"
  }, [errors])

  const targetValueStatus = useMemo<InputStatus>(() => {
    if (errors.target.length) {
      return "error"
    }
    return "default"
  }, [errors])

  const submitButtonLabel = useMemo(
    () =>
      keyResult
        ? formatMessage(strings.general.save)
        : formatMessage(strings.keyResultsForm.addButton),
    [formatMessage, keyResult]
  )

  const onStartValueChange = (startValue: number) => {
    setErrors(
      validateKeyResultValues(startValue, values.current, values.target)
    )
    setValues(updateKeyResult(values, { start: startValue }))
  }

  const onCurrentValueChange = (currentValue: number) => {
    setErrors(
      validateKeyResultValues(values.start, currentValue, values.target)
    )
    setValues(updateKeyResult(values, { current: currentValue }))
  }

  const onTargetValueChange = (targetValue: number) => {
    setErrors(
      validateKeyResultValues(values.start, values.current, targetValue)
    )
    setValues(updateKeyResult(values, { target: targetValue }))
  }

  const handleOnSubmit = () => {
    const errors = validateKeyResultRange(
      values.start,
      values.current,
      values.target
    )
    setErrors(errors)

    const hasErrors = flatten(Object.values(errors)).length > 0
    if (hasErrors) {
      return
    }

    onSubmit(values)
  }

  return (
    <Well>
      <div className={styles.container}>
        <TextField
          id={`${id}-title`}
          inputType="text"
          inputValue={values.title}
          labelText={formatMessage(strings.keyResultsForm.title.label)}
          placeholder={formatMessage(strings.keyResultsForm.title.placeholder)}
          onChange={(e) => {
            setValues(updateKeyResult(values, { title: e.target.value }))
          }}
        />

        <div className={styles.row}>
          <div
            className={styles.metricTypeInputContainer}
            data-automation-id={Aid.newGoalKeyResultMetricTypeFieldInput}
          >
            <Label
              id={`${id}-type-label`}
              labelText={formatMessage(strings.keyResultsForm.type.label)}
            />
            <Select
              id={`${id}-type`}
              aria-labelledby={`${id}-type-label`}
              options={METRIC_TYPE_OPTIONS.map((option) =>
                internationaliseOption(option, formatMessage)
              )}
              value={internationaliseOption(
                getOptionForValue(values.metricType),
                formatMessage
              )}
              onChange={(option) =>
                setValues(updateKeyResult(values, { metricType: option.value }))
              }
            />
          </div>

          <div className={styles.startTargetInputContainer}>
            <NumberField
              id={`${id}-start`}
              labelText={formatMessage(
                strings.keyResultsForm.valuesLabels.start
              )}
              onChange={onStartValueChange}
              value={values.start}
              status={startValueStatus}
            />
            <NumberField
              id={`${id}-target`}
              labelText={formatMessage(
                strings.keyResultsForm.valuesLabels.target
              )}
              onChange={onTargetValueChange}
              value={values.target}
              status={targetValueStatus}
            />
            <NumberField
              id={`${id}-current`}
              labelText={formatMessage(
                strings.keyResultsForm.valuesLabels.current
              )}
              onChange={onCurrentValueChange}
              value={values.current}
              status={currentValueStatus}
            />
          </div>
        </div>

        <div className={styles.footer}>
          <div className={styles.validationMessageContainer}>
            {validationMessages.length > 0 && (
              <FieldMessage
                id={`${id}-key-result-form-validation-message`}
                automationId={`${id}-key-result-form-validation-message`}
                message={
                  <ul>
                    {validationMessages.map((validationMessage, index) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <li key={`${validationMessage}-${index}`}>
                        <span>{formatMessage(validationMessage)}</span>
                      </li>
                    ))}
                  </ul>
                }
                status={"error"}
              />
            )}
          </div>

          <div className={styles.actions}>
            <div className={styles.action}>
              <Button
                secondary
                label={formatMessage(strings.keyResultsForm.cancelButton)}
                onClick={onCancel}
              />
            </div>

            <div className={styles.action}>
              <Button
                disabled={!values.title}
                primary
                label={submitButtonLabel}
                onClick={handleOnSubmit}
                data-automation-id={Aid.addKeyResult}
              />
            </div>
          </div>
        </div>
      </div>
    </Well>
  )
}
