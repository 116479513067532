import { CurrentUser } from "@Types/User"
import { ConfigurationOptions } from "@Constants/configurationOptions"
import FeatureFlags from "@Constants/featureFlags"
import User from "@Models/User"

export type UserRoles = "admin" | "hr_business_partner" | "manager"

export type Configurations =
  | ConfigurationOptions
  | "allow_goals"
  | "allow_new_goals_interface"
  | "allow_praise"
  | "allow_self_reflections"
  | "allow_performance_cycles"
  | "allow_reviews"

export type CurrentSession = {
  roles: UserRoles[]
  configuration: Configurations[]
  featureFlags: FeatureFlags[]
}

const rolesFromUserObject = (user: CurrentUser): UserRoles[] => {
  const result: UserRoles[] = []
  if (user.is_a_manager) {
    result.push("manager")
  }
  if (User.isHrbp(user)) {
    result.push("hr_business_partner")
  }
  return result.concat(user.role_names as UserRoles[])
}

export const sessionDetailsFromUser = (user?: CurrentUser | null) => {
  if (user == null) {
    return
  }
  // pick out the bits we care about for the session
  // future API will match this set up
  return {
    roles: rolesFromUserObject(user),
    configuration: [
      ...user.configuration_options_obj.map(({ name }) => name),
      ...Object.entries(user.company)
        .filter(([key, val]) => key.startsWith("allow") && val === true)
        .map(([key]) => key),
    ] as Configurations[],
    featureFlags: [
      ...(Object.entries(user.flags)
        .filter(([_, enabled]) => enabled)
        .map(([flag]) => flag) as FeatureFlags[]),
    ],
  }
}
