import { companyGoalForTreeUrl } from "@Goals/api"
import { GoalForTree } from "@Goals/types"
import { chain } from "lodash"
import { Query, useQueryClient } from "react-query"
import { Dictionary } from "lodash"
import { Goal } from "../../types/Goals"
import {
  buildPath as incomingGoalCacheKey,
  queryGroup as incomingQueryGroup,
} from "../hooks/useGoalForTree"

/**
 * Hook used to invalidate the GoalsTree cache when actions are performed on
 * goals
 */
const useGoalsTreeCacheInvalidator = () => {
  /**
   * Invalidates the cache for specified goalIds and its related parents
   */
  const invalidateGoalIds = (goalIds: number[]): void => {
    const cachedGoalsById = getCachedGoalsById()

    chain(goalIds)
      .map((gid) => cachedGoalsById[gid]?.uuid)
      .compact()
      .map(incomingGoalCacheKey)
      .value()
      .forEach(invalidateCacheKey)
  }

  /**
   * Invalidates the necessary parts of the GoalsTree cache when there is an
   * update to a goal
   */
  const invalidateOnGoalUpdated = (goal: Goal): void => {
    invalidateGoalIncomingBranches(goal)
  }

  /**
   * Invalidates the necessary parts of the GoalsTree cache when a goal is
   * deleted
   */
  const invalidateOnGoalDeleted = (goal: Goal): void => {
    invalidateGoalIncomingBranches(goal)
    invalidateRoot()
  }

  // private

  const queryClient = useQueryClient()

  const invalidateCacheKey = (cacheKey: string): void => {
    queryClient.invalidateQueries(cacheKey, { exact: true })
  }

  const invalidateRoot = (): void => {
    queryClient.invalidateQueries(companyGoalForTreeUrl)
  }

  const invalidateGoalIncomingBranches = (goal: Goal): void => {
    if (!goal.outgoingAlignedGoals) {
      return
    }

    goal.outgoingAlignedGoals
      .map((goal) => incomingGoalCacheKey(goal.uuid))
      .forEach(invalidateCacheKey)
  }

  const getCachedGoalsById = (): Dictionary<GoalForTree> => {
    return chain(getRootQueryData())
      .concat(getIncomingQueriesGroupData())
      .flatMap(([, queryData]) => queryData)
      .compact()
      .keyBy("id")
      .value()
  }
  const incomingQueryGroupPredicate = (query: Query) =>
    query.meta?.group == incomingQueryGroup

  type QueriesData = GoalForTree[] | undefined

  const getIncomingQueriesGroupData = () =>
    queryClient.getQueriesData<QueriesData>({
      predicate: incomingQueryGroupPredicate,
    })

  const getRootQueryData = () =>
    queryClient.getQueriesData<QueriesData>(companyGoalForTreeUrl)

  return {
    invalidateOnGoalUpdated,
    invalidateOnGoalDeleted,
    invalidateGoalIds,
  }
}

export default useGoalsTreeCacheInvalidator
