import * as React from "react"
import { injectIntl, IntlShape } from "react-intl"
import { Paragraph } from "@kaizen/typography"
import { Button } from "@kaizen/button"
import addIcon from "@kaizen/component-library/icons/add.icon.svg"
import strings from "@Locale/strings"
import { KeyResult } from "@Goals/types"
import { KeyResultListFacade } from "@Goals/components"
import styles from "./GoalKeyResults.scss"

type GoalKeyResultsProps = {
  keyResults: KeyResult[]
  onChange: (keyResults: KeyResult[]) => void
  readOnly?: boolean
  addKeyResultsHandler?: () => void
  intl: IntlShape
}

const GoalKeyResults = ({
  keyResults,
  onChange,
  readOnly = false,
  addKeyResultsHandler,
  intl,
}: GoalKeyResultsProps) => {
  const { formatMessage } = intl
  return (
    <div className={styles.container}>
      {keyResults?.length > 0 ? (
        <KeyResultListFacade
          keyResults={keyResults}
          readOnly={readOnly}
          onChange={onChange}
        />
      ) : (
        <>
          <Paragraph variant="body">
            {formatMessage(strings.goalsPage.goalsDossier.keyResultsEmptyState)}
          </Paragraph>
          {!readOnly ? (
            <div className={styles.addKeyResultButtonContainer}>
              <Button
                onClick={addKeyResultsHandler}
                iconPosition="start"
                icon={addIcon}
                label={formatMessage(
                  strings.goalsPage.goalsDossier.addKeyResultButtonText
                )}
                primary={false}
                secondary
                reversed={false}
              />
            </div>
          ) : null}
        </>
      )}
    </div>
  )
}

export default injectIntl(GoalKeyResults)
