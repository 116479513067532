import { path } from "static-path"

const teamsIndex = path("/teams")
const archivedIndex = teamsIndex.path("archived")
const archivedDetails = archivedIndex.path(":teamId")
const create = teamsIndex.path("new/:teamId")
const edit = teamsIndex.path("edit/:teamId")
const details = teamsIndex.path(":teamId")

export const teamsManagementPaths = {
  index: teamsIndex,
  create: create,
  edit: edit,
  details: details,
  archived: {
    index: archivedIndex,
    details: archivedDetails,
  },
}
