import { useCallback, useContext, useEffect } from "react"
import { getTeamSummariesResponseDecoder } from "@API/decoders/GetTeamSummariesResponseDecoder/getTeamSummariesResponseDecoder"
import { TeamSummariesContext } from "@Redux/reducers/teamSummaries"
import useDataAPI from "@Domain/performanceApi/useDataAPI"
import { captureDecoderException } from "@API/decoders/utils"
import { GoalFilterTypes } from "@Types/Goals"
import { TeamSummary } from "@Types/reduxState/teamSummaries"

const useTeamSummaries = (filters?: GoalFilterTypes) => {
  const { state, dispatch } = useContext(TeamSummariesContext)
  const { fire, data, loading } = useDataAPI("/goals/teams_summary", "GET")
  const hasSearchFilter = filters?.q && filters.q.length > 0

  useEffect(() => {
    fire({
      ...filters,
      page: 1,
    })
  }, [filters, fire])

  useEffect(() => {
    if (data) {
      getTeamSummariesResponseDecoder
        .decodePromise(data)
        .then((response) => {
          if (hasSearchFilter) {
            dispatch({
              type: "FETCH_TEAM_SUMMARIES_SEARCH",
              payload: response,
            })
          } else {
            if (filters?.all_teams) {
              dispatch({
                type: "FETCH_ALL_TEAM_SUMMARIES",
                payload: response,
              })
            } else {
              dispatch({
                type: "FETCH_MY_TEAM_SUMMARIES",
                payload: response,
              })
            }
          }
        })
        .catch((error) => {
          captureDecoderException(error)
          throw error
        })
    }
  }, [data, dispatch, filters, hasSearchFilter])

  const filteredIds = hasSearchFilter
    ? state.searchResults
    : state[filters?.all_teams ? "allTeams" : "myTeams"]

  const { nextPage } = filteredIds
  const fetchMore = useCallback(() => {
    if (nextPage) {
      fire({ ...filters, page: nextPage })
    }
  }, [nextPage, filters, fire])

  const refetch = useCallback(() => {
    const { currentPage } = filteredIds
    fire({ ...filters, page: currentPage })
  }, [filteredIds, filters, fire])

  const teams = Object.values(filteredIds.pages)
    .reduce((res, teams) => [...res, ...teams], [])
    .map((goalId) => state.teamSummariesById[goalId] as TeamSummary)

  const hasMore = filteredIds.totalCount > teams.length

  return {
    hasMore,
    fetchMore,
    refetch,
    teams,
    loading,
  }
}

export default useTeamSummaries
