import Reflux from "reflux-core"
import _ from "lodash"
import PerformanceReviewActions from "../refluxActions/PerformanceReviewActions"

const PerformanceReviewHackStore = Reflux.createStore({
  init() {
    this.data = {
      pastReviewsByUserId: {},
    }

    this.listenTo(
      PerformanceReviewActions.updateReview.completed,
      this.onGetReview
    )

    this.listenTo(
      PerformanceReviewActions.getPastReviewsByUserId.completed,
      ({ results, clientMeta: { userId } }) => {
        this.data = {
          ...this.data,
          pastReviewsByUserId: {
            ...this.data.pastReviewsByUserId,
            [userId]: results,
          },
        }

        this.trigger(this.data)
      }
    )

    this.listenTo(
      PerformanceReviewActions.loadSharedReviews.completed,
      ({ shared_performance_reviews: sharedReviews }) => {
        this.data = {
          ...this.data,
          sharedReviews,
        }
        this.trigger(this.data)
      }
    )

    this.listenTo(
      PerformanceReviewActions.loadSharedReviewById.completed,
      (review) => {
        this.data = {
          ...this.data,
          currentSharedPerformanceReview: review,
        }
        this.trigger(this.data)
      }
    )

    this.listenTo(
      PerformanceReviewActions.getCollaboratorReview.completed,
      (review) => {
        this.data = {
          ...this.data,
          collaboratorReview: review,
        }
        this.trigger(this.data)
      }
    )
  },

  getInitialState() {
    return this.data
  },

  onGetReview(updatedReview) {
    this.data = {
      ...this.data,
      pastReviewsByUserId: _.mapValues(
        this.data.pastReviewsByUserId,
        (reviews) =>
          reviews.map((review) =>
            // Past reviews have additional fields, so merge in the updates rather than replace
            review.id === updatedReview.id ? review.with(updatedReview) : review
          )
      ),
    }

    this.trigger(this.data)
  },
})

export default PerformanceReviewHackStore
