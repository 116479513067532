/**
 * DEPRECATED: we are no longer using decoders to validate server responses.
 * Please do not use them in new code.
 *
 * See src/api/decoders/README.md for more info.
 */
import { JsonDecoder } from "ts.data.json"
import { DepartmentGoalPreview, DepartmentGoal } from "../../../types/Goals"
import {
  outgoingAlignedGoalDecoder,
  incomingAlignedGoalsCountDecoder,
} from "../AlignedGoalDecoder/alignedGoalDecoder"
import {
  accessPermissionsDecoder,
  goalKeyResultDecoder,
  goalPriorityDecoder,
} from "../BaseGoalDecoder/baseGoalDecoder"
import { goalOwnerDecoder } from "../TeamGoalPreviewDecoder/teamGoalPreviewDecoder"

export const departmentGoalDecoder: JsonDecoder.Decoder<DepartmentGoal> =
  JsonDecoder.object<DepartmentGoal>(
    {
      id: JsonDecoder.number,
      name: JsonDecoder.string,
      completion: JsonDecoder.number,
      commentsCount: JsonDecoder.number,
      dueDate: JsonDecoder.string.map((dateString) => new Date(dateString)),
      priority: goalPriorityDecoder,
      type: JsonDecoder.isExactly("department_goal").map(
        (): "department" => "department"
      ),
      owners: JsonDecoder.failover(
        [],
        JsonDecoder.array(goalOwnerDecoder, "goalOwners")
      ),
      departmentId: JsonDecoder.number,
      departmentName: JsonDecoder.failover("", JsonDecoder.string),
      description: JsonDecoder.failover(undefined, JsonDecoder.string),
      status: JsonDecoder.string,
      keyResults: JsonDecoder.failover(
        [],
        JsonDecoder.array(goalKeyResultDecoder, "keyResults")
      ),
      visibility: JsonDecoder.string,
      incomingAlignedGoalsCount: JsonDecoder.failover(
        {},
        incomingAlignedGoalsCountDecoder
      ),
      outgoingAlignedGoals: JsonDecoder.failover(
        [],
        JsonDecoder.array(outgoingAlignedGoalDecoder, "outgoingAlignedGoals")
      ),
      outgoingAlignmentAssociations: JsonDecoder.failover(
        undefined,
        JsonDecoder.array(
          JsonDecoder.object<{
            id: number
            alignedGoalId: number
          }>(
            {
              id: JsonDecoder.number,
              alignedGoalId: JsonDecoder.number,
            },
            "outgoing alignment association",
            {
              alignedGoalId: "incoming_alignment_goal_id",
            }
          ),
          "outgoing alignment associations "
        )
      ),
      editLevel: JsonDecoder.failover(undefined, JsonDecoder.string),
      access_permissions: accessPermissionsDecoder,
    },
    "departmentGoal",
    {
      commentsCount: "number_of_comments",
      dueDate: "due_at",
      priority: "priority",
      type: "goal_type",
      owners: "goal_owners",
      departmentId: "department_id",
      departmentName: "department_name",
      keyResults: "key_results",
      outgoingAlignedGoals: "outgoing_aligned_goals",
      incomingAlignedGoalsCount: "incoming_aligned_goals_counts",
      outgoingAlignmentAssociations: "outgoing_alignment_associations",
      editLevel: "edit_level",
    }
  )

export const departmentGoalPreviewDecoder: JsonDecoder.Decoder<DepartmentGoalPreview> =
  JsonDecoder.object<DepartmentGoalPreview>(
    {
      owners: JsonDecoder.array(goalOwnerDecoder, "owners"),
      type: JsonDecoder.isExactly("department_goal").map(
        (): "department" => "department"
      ),
      id: JsonDecoder.number,
      name: JsonDecoder.string,
      completion: JsonDecoder.number,
      commentsCount: JsonDecoder.number,
      dueDate: JsonDecoder.string.map((dateString) => new Date(dateString)),
      priority: goalPriorityDecoder,
      status: JsonDecoder.string,
      departmentName: JsonDecoder.failover("", JsonDecoder.string),
      outgoingAlignedGoals: JsonDecoder.failover(
        [],
        JsonDecoder.array(outgoingAlignedGoalDecoder, "outgoingAlignedGoals")
      ),
      keyResults: JsonDecoder.failover(
        [],
        JsonDecoder.array(goalKeyResultDecoder, "key_results")
      ),
    },
    "departmentGoalPreview",
    {
      commentsCount: "number_of_comments",
      dueDate: "due_at",
      priority: "priority",
      type: "goal_type",
      owners: "goal_owners",
      departmentName: "department_name",
    }
  )

type ResponseType = {
  goals: DepartmentGoalPreview[]
  overallProgress: number
}

export const getDepartmentGoalsResponseDecoder = JsonDecoder.object(
  {
    department_goals: JsonDecoder.array(
      JsonDecoder.object<ResponseType>(
        {
          goals: JsonDecoder.array(
            departmentGoalPreviewDecoder,
            "departmentGoals"
          ),
          overallProgress: JsonDecoder.failover(0, JsonDecoder.number),
        },
        "departmentGoal",
        {
          overallProgress: "overall_progress",
        }
      ),
      "getDepartmentGoalsResponse array"
    ),
  },
  "getDepartmentGoalsResponse"
).map(({ department_goals }) =>
  department_goals[0] ? department_goals[0] : { goals: [], overallProgress: 0 }
)
