import { Goal, GoalPriority, GoalType, UpdatingGoal } from "@Types/Goals"
import { logKnownEvent } from "@Analytics/utils"
import Avo from "@Avo/Avo"
import { History } from "history"
import { RouteNames, RoutePathValue } from "@Goals/constants"

type GoalUpdate =
  | {
      key: "name"
      valueTo: string
    }
  | {
      key: "status"
      valueTo: string
    }
  | {
      key: "progress"
      valueTo: number
    }

function getGoalUpdate(
  goal: Goal,
  goalUpdate: UpdatingGoal
): GoalUpdate | undefined {
  if (goalUpdate.keyResults || goalUpdate.completion) {
    return {
      key: "progress",
      valueTo: goal.completion,
    }
  } else if (goalUpdate.status) {
    return {
      key: "status",
      valueTo: goal.status,
    }
  } else if (goalUpdate.name) {
    return {
      key: "name",
      valueTo: goal.name,
    }
  }
}

const visibilityForAvo = (visibility: string) => {
  switch (visibility) {
    case "everyone":
      return Avo.Visibility.EVERYONE
    case "manager":
      return Avo.Visibility.ONLY_MY_MANAGER
    case "owner":
      return Avo.Visibility.ONLY_ME
    case "individuals":
      return Avo.Visibility.SPECIFY_USERS
  }
}
const goalPriorityForAvo = (priority: GoalPriority) => {
  switch (priority) {
    case "low":
      return Avo.GoalPriority.LOW
    case "medium":
      return Avo.GoalPriority.MEDIUM
    case "high":
      return Avo.GoalPriority.HIGH
  }
}
const goalTypeForAmplitude = (goalType: GoalType) => {
  switch (goalType) {
    case "company":
      return "Company"
    case "department":
      return "Department"
    case "team":
      return "Team"
    case "personal":
      return "Individual"
  }
}
const goalTypeAlignedTo = (
  goal: Goal
): "Company" | "Department" | "Team" | undefined => {
  if (goal.outgoingAlignedGoals && goal.outgoingAlignedGoals.length > 0) {
    return goalTypeForAmplitude(
      goal.outgoingAlignedGoals[0].goalType as GoalType
    ) as "Company" | "Department" | "Team"
  }

  return undefined
}

export function logGoalCreated(
  goal: Goal,
  baseGoalId: string | undefined = undefined
) {
  Avo.performanceGoalCreated({
    baseGoalId: baseGoalId,
    context: undefined,
    goalDueDate: goal.dueDate.toDateString(),
    goalId: [goal.id.toString()],
    goalPriority: goalPriorityForAvo(goal.priority),
    goalType: goalTypeForAmplitude(goal.type),
    isAligned:
      goal.outgoingAlignedGoals && goal.outgoingAlignedGoals.length > 0
        ? "Yes"
        : "No",
    isAlignedTo: goalTypeAlignedTo(goal),
    method: baseGoalId ? "DUPLICATE" : null,
    navigationSource: undefined,
    numberOfGoalOwners: goal.owners.length,
    numberOfKeyResultsSet: goal.keyResults.length,
    visibility: visibilityForAvo(goal.visibility),
  })
}

export function logGoalComment(goalID: string, goalType: GoalType) {
  Avo.performanceGoalUpdated({
    elementUpdated: "comment",
    elementValueChangedFrom: undefined,
    elementValueChangedTo: undefined,
    goalId: [`${goalID}`],
    goalType: goalTypeForAmplitude(goalType),
  })
}

export function logGoalUpdated(goal: Goal, goalUpdate: UpdatingGoal) {
  const goalUpdateLog = getGoalUpdate(goal, goalUpdate)

  if (goalUpdateLog?.key === "name") {
    logKnownEvent({
      type: "Performance Goal Content Edited",
      properties: {
        "Goal ID": goal.id,
        "Fields edited": ["name"],
      },
    })
  } else {
    Avo.performanceGoalUpdated({
      elementUpdated: goalUpdateLog?.key,
      elementValueChangedFrom: undefined, // not used
      elementValueChangedTo: goalUpdateLog?.valueTo,
      goalId: [`${goal.id}`],
      goalType: goalTypeForAmplitude(goal.type),
    })
  }
}

export function logGoalDeleted({ goalID }: { goalID: string }) {
  logKnownEvent({
    type: "Performance Goal Deleted",
    properties: {
      "Goal ID": [goalID],
    },
  })
}

export function logDirectReportsGoalsIndexViewed({
  numberOfGoals,
  numberOfGoalsCreated,
  numberOfGoalsBlocked,
  numberOfGoalsInProgress,
  numberOfGoalsAccomplished,
}: {
  numberOfGoals: number
  numberOfGoalsCreated: number
  numberOfGoalsBlocked: number
  numberOfGoalsInProgress: number
  numberOfGoalsAccomplished: number
}) {
  logKnownEvent({
    type: "Direct Reports Goals Index Viewed",
    properties: {
      "Number of goals": numberOfGoals,
      "Number of goals created": numberOfGoalsCreated,
      "Number of goals blocked": numberOfGoalsBlocked,
      "Number of goals in progress": numberOfGoalsInProgress,
      "Number of goals accomplished": numberOfGoalsAccomplished,
    },
  })
}

type GoalViewedLoggerPayload = {
  goal: Goal
  history: History
}

const goalPageNameFromRouterHistory = (
  history: History
): string | undefined => {
  const pathname = history.location.pathname as RoutePathValue
  return RouteNames[pathname]
}

export const logGoalViewed = (payload: GoalViewedLoggerPayload) => {
  Avo.performanceGoalViewed({
    goalId: [`${payload.goal.id}`],
    goalType: goalTypeForAmplitude(payload.goal.type),
    navigationSource: goalPageNameFromRouterHistory(payload.history),
  })
}

export const logGoalTreeExpanded = () => {
  Avo.goalTreeExpanded()
}

export * from "./goalsIndexViewedLoggers"
