import Reflux from "reflux-core"
import UserActions from "../refluxActions/UserActions"
import SessionStorage from "./lib/SessionStorage"

const db = new SessionStorage({ key: "PreviousUserStore" })

const PreviousUserStore = Reflux.createStore({
  // Use explicit "init" notation instead of "listenables" to
  // catch Action naming errors
  init() {
    this.listenTo(UserActions.loadCurrent.completed, this.updatePreviousUser)
    this.listenTo(UserActions.reloadCurrent.completed, this.updatePreviousUser)
  },

  getInitialState() {
    return db.loadData()
  },

  updatePreviousUser(fullUser) {
    // Explicitly extract information to avoid leaking sensitive information to local storage
    const user = {
      email: fullUser.email,
    }

    db.updateData(user)
    this.trigger(db.getData())
  },
})

export default PreviousUserStore
