import Reflux from "reflux-core"
import { Map } from "immutable"
import UserActions from "../refluxActions/UserActions"

export default Reflux.createStore({
  init() {
    this.data = {
      usersById: Map(),
      noteByUserId: Map(),
    }

    UserActions.loadById.completed.listen((user) => {
      this.data = {
        ...this.data,
        usersById: this.data.usersById.set(user.id, user),
      }

      this.trigger(this.data)
    })

    UserActions.notifyNoteEditing.listen(({ noteBody, userId }) => {
      this.updateNote({
        body: noteBody,
        about_user_id: userId,
        saveState: "saving",
      })
      this.trigger(this.data)
    })

    UserActions.getNote.completed.listen((note) => {
      this.updateNote({ ...note, saveState: null })
      this.trigger(this.data)
    })

    UserActions.getNote.failed.listen(({ userId }) => {
      this.updateNote({ about_user_id: userId, saveState: null })
      this.trigger(this.data)
    })

    UserActions.saveNote.completed.listen((note) => {
      this.updateNote({ ...note, saveState: "saved" })
      this.trigger(this.data)
    })

    UserActions.saveNote.failed.listen(({ noteBody, userId }) => {
      this.updateNote({
        body: noteBody,
        about_user_id: userId,
        saveState: "failed",
      })
      this.trigger(this.data)
    })
  },

  updateNote(updatedNote) {
    const currentNote = this.data.noteByUserId.get(updatedNote.about_user_id)
    this.data = {
      ...this.data,
      noteByUserId: this.data.noteByUserId.set(
        updatedNote.about_user_id,
        currentNote ? { ...currentNote, ...updatedNote } : updatedNote
      ),
    }
  },

  getInitialState() {
    return this.data
  },
})
