import moment, { Moment } from "moment"
import React, { useCallback, useMemo, useEffect, useRef, useState } from "react"
import { FilterMenuButton } from "@kaizen/draft-filter-menu-button"
import { useIntl } from "@Common/locale/useIntl"
import { SmartDefaultSection, SmartDefaultOption } from "./SmartDefaultSection"
import { Calendar } from "./Calendar"
import styles from "./styles.scss"
import strings from "../../locale/strings"
import Aid from "../../constants/automationId"

interface CommonDatePicker {
  id: string
  values?: Date[]
  onChange: (dates: Date[]) => void
  label?: string
  minimumDate?: Date
  isDisabled?: boolean
  automationId?: Aid
  onClearButtonClick?: () => void
}

interface SingleDatePicker extends CommonDatePicker {
  allowDateRange: false
}

interface RangeDatePicker extends CommonDatePicker {
  allowDateRange: true
  customRangeLabel: string
  smartDefaultOptions: SmartDefaultOption[]
  smartDefaultLabel?: React.ReactNode | string
}

export const DatePickerFilter = (props: SingleDatePicker | RangeDatePicker) => {
  const { formatMessage } = useIntl()
  const {
    id,
    onChange,
    label = "",
    minimumDate,
    allowDateRange,
    values,
    onClearButtonClick,
  } = props
  const [isDropdownVisible, setIsDropdownVisible] = React.useState(false)
  const calendarRef = useRef<HTMLDivElement>(null)
  const [selectedDates, setSelectedDates] = useState<Moment[] | undefined>(
    undefined
  )

  useEffect(() => {
    const dates = values?.map((val) => moment(val))
    setSelectedDates(dates)
  }, [values])

  const toggleDropdown = () => {
    setIsDropdownVisible(!isDropdownVisible)
  }

  const hideDropdown = () => {
    setIsDropdownVisible(false)
  }

  // This function will check if user click on outside of the component,
  // and will restore the selected value to the previous saved state
  const handleReset = useCallback(
    (e: MouseEvent) => {
      const target = e.target as HTMLElement
      const clickInsideCalendar =
        calendarRef.current && calendarRef.current.contains(target)

      if (!clickInsideCalendar) {
        // If selectedDates array contains less than 2 items it means user hasn't fully finish select the date range
        // therefore, when they click outside of the component, we need to reset the values to the previous selected
        // date range
        if (selectedDates && selectedDates.length < 2) {
          const dates = values?.map((val) => moment(val))
          setSelectedDates(dates)
        }
        hideDropdown()
      }
    },
    [calendarRef, values, selectedDates]
  )

  React.useEffect(() => {
    // Attach an event listener so that when user click on the outside of the component
    // it will reset the temporary selected value to the `values` props
    window.addEventListener("click", handleReset)
    return () => window.removeEventListener("click", handleReset)
  }, [handleReset]),
    selectedDates
  const dateLabel = useMemo(() => {
    if (!selectedDates) {
      return ""
    }

    if (selectedDates.length === 0 && props.allowDateRange) {
      return `${formatMessage(
        strings.filters.datePicker.startDate
      )} - ${formatMessage(strings.filters.datePicker.endDate)}`
    }

    const dates = selectedDates.map((val) => moment(val))

    const format = "ll"
    const startDateString = dates[0] && dates[0].format(format)

    const endDateString =
      (dates[1] && dates[1].format(format)) ||
      (dates[0] && formatMessage(strings.filters.datePicker.endDate))
    return props.allowDateRange
      ? startDateString && `${startDateString} - ${endDateString}`
      : startDateString
  }, [props.allowDateRange, formatMessage, selectedDates])

  const dateMetadata = useMemo(() => {
    if (!selectedDates || selectedDates.length === 0) {
      return `${formatMessage(
        strings.filters.datePicker.selectStartDate
      )} - ${formatMessage(strings.filters.datePicker.selectEndDate)}`
    }

    const dates = selectedDates.map((val) => moment(val))

    const format = "ll"
    const startDateString = dates[0] && dates[0].format(format)

    const endDateString =
      (dates[1] && dates[1].format(format)) ||
      (dates[0] && formatMessage(strings.filters.datePicker.selectEndDate))
    return allowDateRange
      ? startDateString && `${startDateString} - ${endDateString}`
      : startDateString
  }, [allowDateRange, formatMessage, selectedDates])

  const handleCalendarOnChange = useCallback(
    (dates: Moment[]) => {
      setSelectedDates(dates.sort((a, b) => (a.isBefore(b) ? -1 : 1)))

      if (!allowDateRange) {
        hideDropdown()
      }

      if (allowDateRange && dates.length === 2) {
        dates[1].endOf("day")
        onChange(dates.map((val) => val.toDate()))
      }
    },
    [setSelectedDates, allowDateRange, onChange]
  )

  const handleSmartDefaultOnClick = (option?: SmartDefaultOption) => {
    if (option) {
      setSelectedDates(option.values)
      option.values[1].endOf("day")
      onChange(option.values.map((val) => val.toDate()))
    } else {
      setSelectedDates([])
    }
  }

  return (
    <FilterMenuButton
      id={id}
      labelText={label}
      metadata={dateLabel}
      isDropdownVisible={isDropdownVisible}
      toggleDropdown={toggleDropdown}
      hideDropdown={hideDropdown}
      onFilterClear={onClearButtonClick || undefined}
    >
      <>
        <div className={styles.container}>
          {/*
            I need to take the prop value directly like this in order the inference prop (`smartDefaultOptions`) to work
            otherwise the typescript compiler will complain if I use the deconstruct variable

            See [here](https://github.com/microsoft/TypeScript/issues/28599) for the issue detail
            */}
          {props.allowDateRange ? (
            <SmartDefaultSection
              options={props.smartDefaultOptions}
              optionClickHandler={handleSmartDefaultOnClick}
              label={props.smartDefaultLabel}
              customRangeLabel={props.customRangeLabel}
              selectedDates={selectedDates}
            />
          ) : null}
          <div ref={calendarRef} className={styles.calendarContainer}>
            <Calendar
              minimumDate={minimumDate}
              selectedDates={selectedDates}
              onChange={handleCalendarOnChange}
              allowDateRange={allowDateRange}
            />
          </div>
        </div>

        {allowDateRange && (
          <>
            <div className={styles.divider} />
            <div className={styles.metaWithActionContainer}>
              <div className={styles.metadata}>{dateMetadata}</div>
            </div>
          </>
        )}
      </>
    </FilterMenuButton>
  )
}
