import { useCallback, useContext, useEffect, useState } from "react"
import { logGoalComment } from "@Goals/analytics"
import { GoalType } from "@Types/Goals"
import newCommentDecoder from "../../api/decoders/NewCommentDecoder/newCommentDecoder"
import newCommentEncoder from "../../api/encoders/newCommentEncoder"
import { CommentsContext } from "../../reduxReducers/comments"
import { CommentSourceType } from "./useComments"
import useDataAPI from "../performanceApi/useDataAPI"

const useCreateComment = (
  sourceType: CommentSourceType,
  sourceId: string,
  goalType: GoalType
) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data, loading, error, fire } = useDataAPI("/comments", "POST")
  const { dispatch } = useContext(CommentsContext)
  const [parentCommentId, setParentCommentId] = useState<number>()

  const createComment = useCallback(
    (body: string, parentCommentId?: number) => {
      setParentCommentId(parentCommentId)
      fire(
        newCommentEncoder({
          sourceId,
          sourceType,
          body,
          parentCommentId,
        })
      )
    },
    [fire, sourceId, sourceType]
  )

  useEffect(() => {
    if (data) {
      const newComment = newCommentDecoder(data)
      if (parentCommentId) {
        dispatch({
          type: "ADD_NEW_REPLY",
          payload: {
            sourceId: Number(sourceId),
            reply: newComment,
            parentCommentId,
          },
        })
      } else {
        dispatch({
          type: "ADD_NEW_COMMENT",
          payload: {
            sourceId: Number(sourceId),
            comment: newComment,
          },
        })
      }

      if (sourceType === "goal") {
        logGoalComment(sourceId, goalType)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return { createComment, loading }
}

export default useCreateComment
