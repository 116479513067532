import * as React from "react"
import { Box } from "@kaizen/component-library"
import { Card } from "@kaizen/draft-card"
import cx from "classnames"

import styles from "./NavigationTreeNode.scss"

interface RootTreeNodeProps {
  logoUrl: string
  companyName: string
}

export const RootTreeNode: React.FC<RootTreeNodeProps> = ({
  logoUrl,
  companyName,
}) => {
  return (
    <div className={cx(styles.nodeContainer)}>
      <div className={cx(styles.rootNode)}>
        <Card>
          {logoUrl ? (
            <Box p={1}>
              <img src={logoUrl} alt={companyName} />
            </Box>
          ) : (
            <Box p={2}>
              <div>{companyName}</div>
            </Box>
          )}
        </Card>
      </div>
    </div>
  )
}
