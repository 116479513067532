import * as React from "react"
import { CheckboxGroup, CheckboxField } from "@kaizen/draft-form"
import { DropdownOption } from "./FilterSelectDropdown"
import { sortByName } from "./utils"
import styles from "./FilterSelectDropdown.scss"

type Props<T> = {
  savedFilterOptions: Array<DropdownOption<T>>
  onCheckboxChange: (option: DropdownOption<T>) => void
  selectedFilterOption: Array<DropdownOption<T>>
  options: Array<DropdownOption<T>>
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const BasicSelectOptions = <T extends any>({
  options,
  savedFilterOptions,
  onCheckboxChange,
  selectedFilterOption,
}: Props<T>) => {
  const checkedStatus = (option: DropdownOption<T>) => {
    return selectedFilterOption.filter(
      (filter) => filter.value === option.value
    ).length > 0
      ? "on"
      : "off"
  }

  const uncheckedFilterOptions = options.filter((option) =>
    savedFilterOptions.every((filter) => option.value !== filter.value)
  )

  const renderSavedFilters = () => {
    return (
      <>
        <CheckboxGroup labelText="">
          {savedFilterOptions.sort(sortByName).map((option) => (
            <div
              className={styles.checkboxRow}
              key={`checkbox-row-${option.value}`}
            >
              <CheckboxField
                onCheck={() => {
                  onCheckboxChange(option)
                }}
                id={`checkbox-${option.label}`}
                checkedStatus={checkedStatus(option)}
                labelText={option.label}
              />
            </div>
          ))}
        </CheckboxGroup>
      </>
    )
  }

  return (
    <>
      {savedFilterOptions.length > 0 ? renderSavedFilters() : null}
      {uncheckedFilterOptions.length > 0 ? (
        <CheckboxGroup labelText="">
          {uncheckedFilterOptions.sort(sortByName).map((option) => (
            <div
              className={styles.checkboxRow}
              key={`checkbox-row-${option.value}`}
            >
              <CheckboxField
                onCheck={() => {
                  onCheckboxChange(option)
                }}
                id={`checkbox-${option.label}`}
                checkedStatus={checkedStatus(option)}
                labelText={option.label}
              />
            </div>
          ))}
        </CheckboxGroup>
      ) : null}
    </>
  )
}
