import * as Sentry from "@sentry/browser"

const errorsBlockList: RegExp[] = [new RegExp(/Network error: Failed to fetch/)]

/**
 * Report errors to our error reporter of choice.
 * Takes an optional data param to pass more debugging
 * information.
 *
 * Note: IT IS IMPERATIVE THAT THE DATA DOES NOT CONTAIN
 * PII IF YOU ARE GOING TO USE THE DATA PARAM.
 *
 * @param {Error} err Error to report to exception handler.
 * @param {T} data Warning: Ensure data does not contain PII.
 */
export const captureException = <T>(err: unknown, data?: T) => {
  // filter errors from the block list
  if (
    err instanceof Error &&
    errorsBlockList.some((regex) => regex.test(err.message))
  ) {
    return
  }

  if (data) {
    Sentry.addBreadcrumb({
      category: "debug",
      level: "debug",
      message: "Extra captureException debug info",
      data: data,
    })
  }

  Sentry.captureException(err)
}
